import { useCallback, useMemo } from "react";

import { getPaths } from "core/getPaths";
import { useSearchStore } from "shared/contexts";
import type { CompetitionModel } from "shared/types";
import { SearchableEntitiesEnum } from "shared/types";

import useClub from "./useClub";
import useCoach from "./useCoach";
import useCompetition from "./useCompetition";
import useFacility from "./useFacility";
import useGroup from "./useGroup";
import useLeague from "./useLeague";
import usePlayer from "./usePlayer";
import useRefereeManager from "./useRefereeManager";
import useStore from "./useStore";
import useTeam from "./useTeam";
import useUser from "./useUser";

const useGlobalSearch = () => {
	const { findLeagues } = useLeague();
	const { findCompetitions } = useCompetition();
	const { findGroups } = useGroup();
	const { getClubs } = useClub();
	const { findRefereeOrgsForSearch } = useRefereeManager();
	const { findPlayers } = usePlayer();
	const { getFacilities } = useFacility();
	const { findCoaches } = useCoach();
	const { findTeams } = useTeam();
	const { findUsers } = useUser();
	const { findStores } = useStore();

	const { setState, ...store } = useSearchStore();

	const paths = getPaths();

	const getDataByKeyword = useCallback(
		async ({
			searchType,
			search,
			customParams,
			returnFullModel
		}: {
			searchType: string;
			search?: string;
			customParams?: Record<string, any>;
			returnFullModel?: boolean;
		}) => {
			if (searchType === SearchableEntitiesEnum.LEAGUES) {
				const leagues = await findLeagues({
					search,
					ignoreStateUpdate: true,
					ignoreLoading: true,
					...customParams
				});

				return returnFullModel
					? leagues
					: leagues.map(league => ({
							id: league.id,
							name: league.name || "",
							address: league.location?.address || "",
							logo: league.logo
						}));
			}

			if (searchType === SearchableEntitiesEnum.COMPETITIONS) {
				const competitions = await findCompetitions({
					search,
					noStateUpdate: true,
					...customParams
				});

				return returnFullModel
					? competitions
					: competitions.map(competition => ({
							id: competition.id,
							name: competition.name || "",
							logo: competition.logo,
							address: competition.league?.location?.address || ""
						}));
			}

			if (searchType === SearchableEntitiesEnum.PLAYERS) {
				const players = await findPlayers(search);

				return returnFullModel
					? players
					: players.map(player => ({
							id: player.id,
							name: player.name || "",
							address: ""
						}));
			}

			if (searchType === SearchableEntitiesEnum.TEAMS) {
				const teams = await findTeams(search, customParams);

				return returnFullModel
					? teams
					: teams.map(player => ({
							id: player.id,
							name: player.name || "",
							address: ""
						}));
			}

			if (searchType === SearchableEntitiesEnum.USERS) {
				const users = await findUsers(search);

				return returnFullModel
					? users
					: users.map(user => ({
							id: user.id,
							name: user.name || "",
							address: "",
							logo: user.avatar
						}));
			}

			if (searchType === SearchableEntitiesEnum.STORES) {
				const { stores } = await findStores({ search });

				return returnFullModel
					? stores
					: stores.map(store => ({
							id: store.id,
							name: store.name || "",
							address: "",
							logo: store.logo
						}));
			}

			if (searchType === SearchableEntitiesEnum.GROUPS) {
				const { groups } = await findGroups(search);

				return returnFullModel
					? groups
					: groups.map(group => ({
							id: group.id,
							name: group.name || "",
							address: (group.competition as CompetitionModel).name || "",
							link: paths.group.getPath(),
							logo: group.logo
						}));
			}

			if (searchType === SearchableEntitiesEnum.CLUBS) {
				const clubs = await getClubs({ search, ignoreLoading: true, ignoreStateUpdate: true });

				return returnFullModel
					? clubs
					: clubs.map(club => ({
							id: club.id,
							name: club.name || "",
							address: club.location?.address || "",
							logo: club.logo
						}));
			}

			if (searchType === SearchableEntitiesEnum.REFEREE_ORGANIZATIONS) {
				const refs = await findRefereeOrgsForSearch(search);

				return returnFullModel
					? refs
					: refs.map(ref => ({
							id: ref.id!,
							name: ref.name || "",
							address: ref.location?.address || "",
							logo: ref.logo
						}));
			}

			if (searchType === SearchableEntitiesEnum.FACILITIES) {
				const facilities = await getFacilities({ search }, true);

				return returnFullModel
					? facilities
					: facilities.map(ref => ({
							id: ref.id!,
							name: ref.name || "",
							address: ref.location?.address || "",
							logo: ref.logo
						}));
			}

			if (searchType === SearchableEntitiesEnum.COACHES) {
				const coaches = await findCoaches(search);

				return returnFullModel
					? coaches
					: (coaches || []).map(coach => ({
							id: coach.id,
							name: coach.name || "",
							address: coach.clubs?.length ? coach.clubs[0]?.name : "",
							logo: coach.avatar
						}));
			}
		},
		[
			findLeagues,
			findCompetitions,
			findPlayers,
			findTeams,
			findUsers,
			findGroups,
			findStores,
			paths.group,
			getClubs,
			findRefereeOrgsForSearch,
			getFacilities,
			findCoaches
		]
	);

	const methods = useMemo(() => {
		return {
			getSearchResults: async ({
				searchType,
				search,
				dontUpdateState,
				customParams,
				returnFullModel
			}: {
				searchType: string;
				search?: string;
				dontUpdateState?: boolean;
				customParams?: Record<string, any>;
				returnFullModel?: boolean;
			}) => {
				setState({ loading: true });

				try {
					const data = await getDataByKeyword({ searchType, search, customParams, returnFullModel });
					!dontUpdateState && setState({ searchResults: data });

					return data;
				} catch (err) {
					console.error(err);
				} finally {
					setState({ loading: false });
				}
			},
			clearSearchHistory: () => {
				setState({ searchHistory: [] });
			},
			updateSearchHistory: (searchHistory: string) => {
				setState(ctx => {
					const history = ctx.searchHistory.slice(0, 4);

					const index = history.findIndex(s => s === searchHistory);

					if (index > -1) {
						history.splice(index, 1);
					}

					return { searchHistory: [searchHistory, ...history] };
				});
			},
			setSearchType: (searchType: SearchableEntitiesEnum) => {
				setState({ searchType });
			}
		};
	}, [getDataByKeyword, setState]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { getData, ...methods };
};

export default useGlobalSearch;

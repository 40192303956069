import { z } from "zod";

import { baseCreateType, logoResolver } from "./CreateTypes";
import { GenderValEnum } from "./GenderEnum";
import { InvitationStatus } from "./Invitation";
import { statsResolver } from "./StatModel";
import { fullTeamResolver } from "./TeamModel";
import { UploadValidateTypes } from "./UploadsModel";

export enum RelationshipEnum {
	FATHER = "FATHER",
	MOTHER = "MOTHER",
	GRANDPARENT = "GRANDPARENT",
	OTHER = "OTHER"
}

export const playerResolver = baseCreateType.pick({ location: true }).extend({
	name: z.string().optional().nullable(), // could be null until user fill it after accept invitation
	avatar: logoResolver.nullable(),
	id: z.string(),
	shortName: z.string().optional().nullable(),
	firstName: z.string().optional().nullable(),
	lastName: z.string().optional().nullable(),
	birthDate: z.string().optional().nullable(),
	email: z.string().optional().nullable(),
	phone: z.string().optional().nullable(),
	status: z.nativeEnum(InvitationStatus).optional(),
	inClubStatus: z.nativeEnum(InvitationStatus).optional().nullable(),
	gender: z.nativeEnum(GenderValEnum).optional().nullable(),
	club: z
		.object({
			id: z.string(),
			name: z.string()
		})
		.optional(),
	teams: z
		.array(
			fullTeamResolver.pick({ id: true, name: true, logo: true, club: true, stats: true }).extend({
				jerseyNumber: z.number().optional().nullable()
			})
		)
		.optional(),
	team: fullTeamResolver.pick({ id: true, name: true, logo: true, club: true, stats: true }).optional(),
	competitions: z.array(z.object({ id: z.string(), name: z.string() })).optional(),
	guardians: z
		.array(
			z.object({
				id: z.string(),
				firstName: z.string().nullable(),
				lastName: z.string().nullable(),
				email: z.string().optional(),
				phone: z.string().optional(),
				relationship: z.nativeEnum(RelationshipEnum).optional()
			})
		)
		.optional(),
	following: z.boolean().optional(), // TODO: it's a fake field for UI only
	stats: statsResolver.nullable().optional(),
	playerTags: z.string().array().optional()
});
export type PlayerModel = z.infer<typeof playerResolver>;

export const guardianResolver = playerResolver
	.pick({ id: true, firstName: true, lastName: true, phone: true, email: true })
	.extend({
		relationship: z.nativeEnum(RelationshipEnum).optional()
	});
export type GuardianModel = z.infer<typeof guardianResolver>;

export const playerInfoResolver = playerResolver.pick({
	id: true,
	name: true,
	shortName: true,
	avatar: true,
	location: true
});
export type PlayerInfoModel = z.infer<typeof playerInfoResolver>;

export const playerOverviewResolver = playerResolver.pick({ id: true, teams: true });

export type PlayerOverviewModel = z.infer<typeof playerOverviewResolver>;

export interface CreateClubPlayerModel {
	birthDate: string;
	teams: { id: string }[];
	gender: GenderValEnum;
	firstName: string;
	lastName: string;
	email?: string | null;
	jerseyNumber?: number | null;
}

export interface UpdateClubPlayerFormModal
	extends Pick<PlayerModel, "id" | "firstName" | "lastName" | "gender" | "birthDate"> {
	teams: { add: { id: string }[]; remove: { id: string }[]; update?: { id: string; jerseyNumber?: number }[] };
}

export const bulkPlayerUploadValidateResolver = z.object({
	id: z.string(),
	type: z.nativeEnum(UploadValidateTypes),
	url: z.string()
});

export type BulkPlayerUploadValidateModel = z.infer<typeof bulkPlayerUploadValidateResolver>;

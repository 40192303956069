import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	facility: {
		create: {
			name: null,
			address: null,
			logo: null,
			story: null,
			photos: null
		},
		invite: {
			people: null
		},
		setup: {
			fields: null
		}
	}
});

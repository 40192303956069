import { useCallback, useMemo } from "react";

import { useCreateFlowStore } from "../contexts";
import type { CreateFlowStep } from "../types";

const useCreateFlowRouter = () => {
	const { setState, ...store } = useCreateFlowStore();

	const methods = useMemo(
		() => ({
			setStep: (step: number) => {
				setState({ step });
			},
			setValidating: (validating: boolean) => {
				setState({ validating });
			},
			setStepsKeys: (stepsKeys: CreateFlowStep[]) => {
				setState({ stepsKeys: stepsKeys.sort((a, b) => a.stepIndex - b.stepIndex), callbackValidating: false });
			},
			setAllowGoNext: (allowGoNext: boolean) => {
				setState({ allowGoNext });
			},
			registerNextCallback: (nextCallback?: () => void) => {
				setState({ nextCallback });
			},
			registerBackCallback: (backCallback?: () => void) => {
				setState({ backCallback });
			},
			setCallbackValidating: (callbackValidating: boolean) => {
				setState({ callbackValidating });
			}
		}),
		[setState]
	);

	const checkIfMounted = useCallback(
		(stepName: string) => store.stepsKeys.find(step => step.key === stepName)?.stepIndex === store.step,
		[store.step, store.stepsKeys]
	);

	const getNextStep = useCallback(() => {
		const currentStepIndex = store.stepsKeys.findIndex(x => x.stepIndex === store.step);
		if (store.stepsKeys.length >= currentStepIndex + 1) {
			return store.stepsKeys[currentStepIndex + 1];
		}

		return undefined;
	}, [store.step, store.stepsKeys]);

	const getPrevStep = useCallback(() => {
		const currentStepIndex = store.stepsKeys.findIndex(x => x.stepIndex === store.step);
		if (store.stepsKeys?.length >= currentStepIndex - 1) {
			return store.stepsKeys[currentStepIndex - 1];
		}

		return undefined;
	}, [store.step, store.stepsKeys]);

	const findStepByKey = useCallback(
		(key: string) => {
			return store.stepsKeys.find(x => x.key === key);
		},
		[store.stepsKeys]
	);

	const getCreateFlowData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getNextStep, checkIfMounted, getPrevStep, findStepByKey, getCreateFlowData };
};

export default useCreateFlowRouter;

import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	home: null,
	events: null,
	search: null,
	email: {
		unsubscribe: null
	},
	profile: {
		overview: null,
		matches: null,
		standings: null,
		stats: null,
		career: null,
		social: null
	},
	myOrganizations: null,
	myKids: null,
	feed: null,
	following: null,
	store: null
});

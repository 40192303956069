import { z } from "zod";

export const locationModel = z.object({
	lat: z.number(),
	lng: z.number(),
	address: z.string().optional().nullable(),
	id: z.string().optional(),
	distanceInMiles: z.number().optional()
});

export type LocationModel = z.infer<typeof locationModel>;

export const locationInputModel = locationModel.pick({ id: true, lat: true, lng: true }).extend({
	photo: z.string().optional(),
	address: z.string(),
	label: z.string(),
	value: z.string()
});

export type LocationInputModel = z.infer<typeof locationInputModel>;

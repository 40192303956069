import config from "config/appConfig";

import type { EntityRoles, MatchAttendanceModel, MatchModel } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.MATCH_DATA}`;

export interface Values {
	match?: MatchModel;
	matchInfo?: MatchModel;
	userMatchRoles: EntityRoles[];
	loadingOverview: boolean;
	loadingTeams: boolean;
	loadingInfo: boolean;
	loadingCreateFriendlyMatch: boolean;
	loadingAttendanceOverview: boolean;
	attendanceStatus: MatchAttendanceModel | null;
}

export const initialState: IStateProps<Values> = {
	match: undefined,
	matchInfo: undefined,
	userMatchRoles: [],
	loadingOverview: false,
	loadingInfo: false,
	loadingTeams: false,
	loadingCreateFriendlyMatch: false,
	loadingAttendanceOverview: false,
	attendanceStatus: null
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

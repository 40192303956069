import { z } from "zod";

export enum MediaModelSizeEnum {
	source = "source",
	small = "small", // 50
	medium = "medium", // 100
	large = "large", // 300
	blurhash = "blurhash",
	external = "external",
	adaptive = "adaptive" // for video
}

// {
//   "name": "external",
//   "region": null,
//   "bucketName": null,
//   "path": null,
//   "width": null,
//   "height": null,
//   "mimeType": null,
//   "url": "https://lh3.googleusercontent.com/a/AEdFTp5UkXqHt1FwrG-tVX20s2DcxPkMvirI1plyFQwf=s96-c"
// }

export const mediaModelFullObject = z.object({
	file: z.object({
		url: z.string(),
		location: z.object({
			bucket: z.string(),
			key: z.string(),
			kind: z.string(),
			region: z.string(),
			ver: z.string()
		})
	})
});

export const mediaModelResolver = z.object({
	name: z.nativeEnum(MediaModelSizeEnum),
	region: z.string().optional().nullable(),
	bucketName: z.string().optional().nullable(),
	path: z.string().optional().nullable(), // path to s3 object
	url: z.string().optional().nullable(), // path to external img url
	width: z.number().or(z.string()).nullable().optional(), // for gifs
	height: z.number().or(z.string()).nullable().optional(), // for gifs
	mimeType: z.string().optional().nullable(),
	id: z.string().optional(), // unique id of asset
	blurhash: z.string().optional().nullable(),
	version: z.string().optional().nullable()
});

export type MediaModelFullObject = z.infer<typeof mediaModelFullObject>;
export type MediaModel = z.infer<typeof mediaModelResolver>;

import { useCallback, useMemo } from "react";

import { useFacilityStore } from "shared/contexts";

import { useFacilityApiService } from "shared/services";
import type { EntityRoles, FacilityModel, LogoModel, MediaModel } from "shared/types";
import type { CrudFieldModel, FieldInfo, UpdateFacilityData } from "shared/types/FacilityModel";

import usePlatformEnv from "./usePlatformEnv";
import useToast from "./useToast";

const useFacility = () => {
	const facilityService = useFacilityApiService();

	const { setState, setInitial, ...store } = useFacilityStore();

	const { showToast } = useToast();

	const { isShowRoleToasts } = usePlatformEnv();

	const methods = useMemo(
		() => ({
			createFacility: async (facilityData: Omit<FacilityModel, "id">) => {
				const { facility } = await facilityService.createFacility(facilityData);
				setState({ facility });
				return facility;
			},
			getFacilityOverview: async (id: string, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loading: true });
				const { facility } = await facilityService.getFacilityOverview(id);
				setState(ctx => ({ overview: facility, loading: ignoreLoading ? ctx.loadingOverview : false }));
				return facility;
			},
			getFacilityInfo: async (id: string, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loadingInfo: true });

				try {
					const { facility } = await facilityService.getFacilityInfo(id);
					setState(ctx => ({ info: facility, loadingInfo: ignoreLoading ? ctx.loadingInfo : false }));

					if (facility?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for facility: ${facility.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return facility;
				} catch {
					showToast({
						text: "Facility couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingInfo: false });
					}
				}
			},
			getFacilityPhotos: async (id: string, dontUpdateState?: boolean) => {
				setState({ loadingPhotos: true });
				const { facility } = await facilityService.getFacilityPhotos(id);
				setState(ctx => ({
					loadingPhotos: false,
					facilityPhotos: dontUpdateState ? ctx.facilityPhotos : facility.photos.filter(p => !p.removed)
				}));

				return facility;
			},
			updateNewFacility(
				newFacilityData: Partial<Omit<FacilityModel, "logo">> & { logo?: string | null | MediaModel | LogoModel }
			) {
				setState(ctx => ({
					newFacility: { ...ctx.newFacility, ...newFacilityData }
				}));
			},
			getFacilityFields: async (id: string) => {
				setState({ loadingFields: true });
				const { facility } = await facilityService.getFacilityFields(id);

				setState({ loadingFields: false, fields: facility.fields });

				return facility.fields;
			},
			updateFacility: async (
				data: Partial<Omit<UpdateFacilityData, "logo">> & { logo?: string | null | MediaModel | LogoModel },
				ignoreLoading?: boolean,
				ignoreToast?: boolean
			) => {
				!ignoreLoading && setState({ loading: true });

				try {
					const { facility } = await facilityService.updateFacility(data);

					setState(ctx => ({ overview: { ...ctx.overview, ...facility } }));

					if (!ignoreToast) {
						showToast({ text: "Facility updated successfully", noIcon: true });
					}
				} catch (error) {
					showToast({ text: "Error updating facility", noIcon: true });
					console.log("update failed", error);
				} finally {
					!ignoreLoading && setState({ loading: false });
				}
			},
			updateFacilityFields: async ({
				facilityId,
				create,
				update,
				remove
			}: {
				facilityId: string;
				create: CrudFieldModel[];
				update: CrudFieldModel[];
				remove: { id: string }[];
			}) => {
				setState({ updating: facilityId });
				try {
					await facilityService.updateFacilityFields({ facilityId, create, update, remove });

					showToast({ text: "Fields updated successfully", noIcon: true });
				} catch (error) {
					showToast({ text: "Error updating fields", noIcon: true });
					console.log("update failed", error);
				} finally {
					setState({ updating: undefined });
				}
			},
			createFields: async (facilityId: string, fields: FieldInfo[]) => {
				return facilityService.createFields(facilityId, fields);
			},
			setFields: (fields?: FieldInfo[]) => {
				setState({ fields });
			},
			setFacilityFieldsDialog: (facilityFieldsDialog?: {
				open: boolean;
				activeId?: string;
				focusNewField?: boolean;
			}) => {
				setState({ facilityFieldsDialog });
			},
			getFacilities: async (params: { search?: string; onlyMy?: boolean }, preventStateUpdate?: boolean) => {
				!preventStateUpdate && setState({ loading: true });
				const { facilities } = await facilityService.getFacilities(params);
				!preventStateUpdate && setState({ facilities, loading: false });
				return facilities;
			},
			findFacilities: async (params: {
				competitionId?: string;
				search?: string;
				maxMiles?: number;
				limit?: number;
				withFields?: boolean;
			}) => {
				setState({ loading: true });
				const { facilities } = await facilityService.findFacilities(params);
				setState({ searchFacilities: facilities, loading: false });
				return facilities;
			},
			setUserFacilityRoles: (userFacilityRoles: EntityRoles[]) => {
				setState({ userFacilityRoles });
			},
			resetStore() {
				setInitial();
			}
		}),
		[facilityService, setState, showToast, setInitial, isShowRoleToasts]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useFacility;

import type { FullTeamModel, TeamGroupsModel } from "shared/types";

export enum MatchResultEnum {
	WON = "WON",
	LOST = "LOST",
	DRAW = "DRAW",
	HOME_WIN = "HOME_WIN",
	AWAY_WIN = "AWAY_WIN"
}

export interface CompetitionTeamsStandingModel {
	group: TeamGroupsModel;
	teams?: FullTeamModel[];
	highlightedTeamIds?: string[];
	positionTeamId?: string;
}

import { z } from "zod";

import { clubResolver, competitionGroupResolver, competitionResolver, fullTeamResolver } from "shared/types";

import { leagueResolver } from "./LeagueModel";
import { playerResolver } from "./PlayerTypes";

export enum StatEntityEnum {
	TEAM = "team",
	PLAYER = "player"
}

export enum StatTypeEnum {
	MATCHES_PLAYED = "matchesPlayed",
	GOALS_SCORED = "goalsScored",
	GOALS_PER_MATCH = "goalsPerMatch",
	STARS_RECEIVED = "starsReceived",
	RED_CARDS_RECEIVED = "redCardsReceived",
	YELLOW_CARDS_RECEIVED = "yellowCardsReceived"
}

export const statsResolver = z.object({
	matchesPlayed: z.number().nullable(),
	goalsScored: z.number().nullable(),
	goalsPerMatch: z.number().nullable(),
	starsReceived: z.number().nullable(),
	yellowCardsReceived: z.number().nullable().optional(),
	redCardsReceived: z.number().nullable().optional()
});

export type StatModel = z.infer<typeof statsResolver>;

export const statItemResolver = z.lazy(() =>
	z.object({
		stats: statsResolver,
		league: leagueResolver.optional(),
		competition: competitionResolver.partial().optional(),
		group: competitionGroupResolver.partial().optional(),
		groups: competitionGroupResolver.partial().array().optional(),
		player: playerResolver.partial().optional(),
		teams: z.array(fullTeamResolver).optional(),
		team: fullTeamResolver.partial().optional(),
		club: clubResolver.optional()
	})
);

export type StatItemModel = z.infer<typeof statItemResolver>;

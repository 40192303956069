import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type {
	BaseCreateType,
	FullTeamModel,
	MatchModel,
	MatchStatusEnum,
	PaginationParamsModel,
	PaginationTokenType,
	StatItemModel,
	TeamCompetitionsModel,
	TeamEventModel,
	TeamGroupsModel,
	TeamPlayersModel
} from "shared/types";
import {
	followedTeamsResolver,
	statItemResolver,
	teamCompetitionsResolver,
	teamEventResolver,
	teamGroupsResolver,
	teamPlayersResolver
} from "shared/types";

import BaseConverter from "./converters/baseConverter";

const useTeamApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getTeamOverview: async (id: string) => {
				return BaseConverter.convert<FullTeamModel>(
					await actions.get(API_URLS.TEAM.GET_OVERVIEW, {
						"team-id": id
					})
				);
			},
			updateTeam: async (teamId: string, name: string) => {
				return BaseConverter.convert<{ team: { id: string } }>(
					await actions.post(API_URLS.TEAM.UPDATE_TEAM, {
						teamId,
						name
					})
				);
			},
			findTeams: async (search?: string, customParams?: Record<string, any>) => {
				return BaseConverter.convert<{ teams: FullTeamModel[] }>(
					await actions.get(
						API_URLS.TEAM.FIND_TEAMS,
						{
							search,
							...customParams
						},
						{
							resolvers: [{ resolver: teamPlayersResolver, fields: ["teams"] }]
						}
					)
				);
			},
			getFollowedTeams: async ({ search, after }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					teams: BaseCreateType[];
					nextToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.TEAM.LIST_FOLLOWED_TEAMS,
						{
							search: search || undefined,
							after: after || undefined
						},
						{
							resolvers: [{ resolver: followedTeamsResolver }]
						}
					)
				);
			},
			getPlayers: async ({ teamId, after, limit }: { teamId: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{ team: TeamPlayersModel; nextToken: PaginationTokenType }>(
					await actions.get(
						API_URLS.TEAM.GET_PLAYERS,
						{
							"team-id": teamId,
							after,
							limit
						},
						{
							resolvers: [
								{
									resolver: teamPlayersResolver,
									fields: ["team"]
								}
							]
						}
					)
				);
			},
			getTeamMatches: async ({
				limit,
				after,
				teamId,
				filters
			}: { teamId: string; filters?: MatchStatusEnum[] } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					matches: MatchModel[];
					afterToken: PaginationTokenType;
					beforeToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.TEAM.GET_MATCHES, {
						"team-id": teamId,
						"match-status": !filters?.length ? undefined : filters.flat().join(","),
						limit,
						after
					})
				);
			},
			getTeamCompetitions: async (teamId: string) => {
				return BaseConverter.convert<{ team: TeamCompetitionsModel }>(
					await actions.get(
						API_URLS.TEAM.GET_COMPETITIONS,
						{
							"team-id": teamId
						},
						{
							resolvers: [
								{
									resolver: teamCompetitionsResolver,
									fields: ["team"]
								}
							]
						}
					)
				);
			},
			getTeamCompetitionsStats: async (teamId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.TEAM.GET_COMPETITIONS_STATS,
						{
							"team-id": teamId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getTeamPlayersCompetitionStats: async (teamId: string, competitionId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.TEAM.GET_PLAYER_COMPETITIONS_STATS,
						{
							"team-id": teamId,
							"competition-id": competitionId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getTeamGroups: async (teamId: string) => {
				return BaseConverter.convert<{ groups: TeamGroupsModel[] }>(
					await actions.get(
						API_URLS.TEAM.GET_GROUPS,
						{
							"team-id": teamId
						},
						{
							resolvers: [
								{
									resolver: teamGroupsResolver,
									fields: ["groups"]
								}
							]
						}
					)
				);
			},
			getTeamEvents: async (props: {
				teamId: string;
				matchStatus?: MatchStatusEnum;
				limit?: number;
				after?: string;
				before?: string;
				timestampGte?: string;
				timestampLt?: string;
			}) => {
				return BaseConverter.convert<{
					events: TeamEventModel[];
					nextToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.TEAM.GET_EVENTS, props, {
						resolvers: [{ resolver: teamEventResolver, fields: ["events"] }]
					})
				);
			},
			getTeamInfo: async (id: string) => {
				return BaseConverter.convert<{ team: BaseCreateType }>(
					await actions.get(API_URLS.TEAM.GET_INFO, {
						"team-id": id
					})
				);
			}
		}),
		[actions]
	);
};

export default useTeamApiService;

import { useMemo } from "react";

import { useGifApiService } from "shared/services";

import useToast from "./useToast";

const useGif = () => {
	const gifService = useGifApiService();

	const { showToast } = useToast();

	const methods = useMemo(
		() => ({
			search: async ({
				keyword = "",
				limit = 18,
				offset = 0
			}: {
				keyword?: string;
				limit?: number;
				offset?: number;
			}) => {
				try {
					const { gifs } = await gifService.search({ keyword, offset, limit });
					return gifs;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				}
			}
		}),
		[gifService, showToast]
	);

	return { ...methods };
};

export default useGif;

import { z } from "zod";

import { BackendEntityType, InvitationStatus, baseCreateType, logoResolver, mediaModelResolver } from "shared/types";

import { userProfileResolver } from "./UserModel";

export enum InvitationTypes {
	SOCCER_REFEREE_ORGANIZATION_REFEREE = "invitation.soccer.reforg.referee",
	SOCCER_COMPETITION_REFEREE_ORGANIZATION = "invitation.soccer.competition.reforg",
	SOCCER_CLUB_COACH = "invitation.soccer.club.coach",
	SOCCER_CLUB_PLAYER = "invitation.soccer.club.player",
	SOCCER_CLUB_MANAGER = "invitation.soccer.club.manager",
	SOCCER_LEAGUE_MANAGER = "invitation.soccer.league.manager",
	SOCCER_FACILITY_MANAGER = "invitation.soccer.facility.manager",
	SOCCER_REFEREE_ORGANIZATION_MANAGER = "invitation.soccer.reforg.manager",
	SOCCER_CHILD_INVITATION = "invitation.core.person.child",
	SOCCER_CLUB_FOLLOWER = "invitation.soccer.club.follower",
	SOCCER_LEAGUE_FOLLOWER = "invitation.soccer.league.follower",
	SOCCER_COMPETITION_FOLLOWER = "invitation.soccer.competition.follower",
	SOCCER_TEAM_FOLLOWER = "invitation.soccer.team.follower",
	CORE_USER_FOLLOWER = "invitation.core.person.follower",
	SOCCER_GROUP_FOLLOWER = "invitation.soccer.group.follower",
	SOCCER_COMPETITION_SCOUT = "invitation.soccer.competition.scout"
}

export interface InvitationPersonaModel {
	name: string;
	avatarUrl: string;
	email: string;
}

export const invitationCreator1Resolver = z.object({
	id: z.string(),
	name: z.string().nullable(),
	shortName: z.string().nullable(),
	avatar: mediaModelResolver
});

const invitationCreator2Resolver = z.object({
	creator: invitationCreator1Resolver
});

const invitationTarget1Resolver = z.object({
	id: z.string()
});

const soccerEntity = baseCreateType
	.pick({ id: true, name: true })
	.extend({
		image: mediaModelResolver.nullable().optional()
	})
	.nullable()
	.optional();

const invitationTarget2Resolver = z.object({
	soccer: z.object({
		club: soccerEntity,
		league: soccerEntity,
		facility: soccerEntity,
		reforg: soccerEntity,
		competition: soccerEntity
	}),
	entityId: z.string().optional(),
	entityType: z.nativeEnum(BackendEntityType)
});

const invitationTargets = z.array(
	z.object({
		entityId: z.string().nullable(),
		entityType: z.nativeEnum(InvitationTypes).or(z.nativeEnum(BackendEntityType)),
		soccer: z
			.object({
				club: soccerEntity,
				league: soccerEntity,
				facility: soccerEntity,
				reforg: soccerEntity,
				competition: soccerEntity,
				team: soccerEntity,
				group: soccerEntity
			})
			.optional(),
		core: z.object({
			emailAddress: z
				.object({
					address: z.string()
				})
				.optional(),
			person: userProfileResolver
				.extend({
					image: logoResolver.optional()
				})
				.optional()
		})
	})
);

export const invitationResolver = z.object({
	id: z.string(),
	creator: invitationCreator1Resolver.or(invitationCreator2Resolver).nullable().optional(),
	invitee: z
		.object({
			emailAddress: z.object({
				address: z.string()
			})
		})
		.optional(),
	target: invitationTarget1Resolver.or(invitationTarget2Resolver),
	targets: invitationTargets.optional(),
	status: z.nativeEnum(InvitationStatus).optional(),
	type: z.nativeEnum(InvitationTypes).optional(),
	createdAt: z.string().optional(),
	responded: z.boolean().optional(),
	expired: z.boolean().optional()
});

export type InvitationModel = z.infer<typeof invitationResolver>;
export type InvitationCreator1Resolver = z.infer<typeof invitationCreator1Resolver>;
export type InvitationCreator2Resolver = z.infer<typeof invitationCreator2Resolver>;
export type InvitationTarget1Resolver = z.infer<typeof invitationTarget1Resolver>;
export type InvitationTarget2Resolver = z.infer<typeof invitationTarget2Resolver>;

import { useCallback, useMemo } from "react";

import type { MapPosition } from "shared/contexts/GoogleContext/GoogleContext";

import { useGoogleStore } from "../contexts";

const useGoogleMaps = () => {
	const { setState, ...store } = useGoogleStore();

	const methods = useMemo(
		() => ({
			setPosition: (position: MapPosition) => {
				setState({ position });
			},
			setFullscreenMode: (fullscreenMode?: boolean) => {
				setState({ fullscreenMode });
			}
		}),
		[setState]
	);

	const getGoogleMapsData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getGoogleMapsData };
};

export default useGoogleMaps;

import { DateTime } from "luxon";

export const getDateTime = (time: DateTime | Date | string, zone?: string) => {
	if (typeof time === "string") {
		let date = zone ? DateTime.fromISO(time, { zone }) : DateTime.fromISO(time);
		if (!date.isValid) {
			date = DateTime.fromJSDate(new Date(time));
		}
		return date;
	} else if ((time as DateTime)?.isValid) {
		return time as DateTime;
	}
	return DateTime.fromJSDate(time as Date);
};

export const getTimeUpdatedDate = (time: DateTime | Date | string, date: DateTime) => {
	let dateTime = getDateTime(time);

	if (dateTime?.isValid && date?.isValid) {
		dateTime = dateTime.set({ day: date.day, month: date.month, year: date.year, second: 0, millisecond: 0 });
	}

	return dateTime;
};

import { useCallback, useMemo } from "react";

import { useCoachStore } from "shared/contexts";
import { useCoachApiService } from "shared/services";

import useToast from "./useToast";

import type { CreateClubCoachModal, PaginationParamsModel, UpdateClubCoachFormModal } from "../types";

const useCoach = () => {
	const { setState, setInitial, ...store } = useCoachStore();
	const coachService = useCoachApiService();

	const { showToast } = useToast();

	const methods = useMemo(() => {
		return {
			listClubCoaches: async ({ limit = 10, after, clubId }: { clubId: string } & PaginationParamsModel) => {
				setState({ loading: true });
				if (!after) {
					setState({ coaches: [], nextListingToken: null });
				}
				try {
					const { club, nextToken } = await coachService.listClubCoaches({ limit, after, clubId });
					setState(ctx => ({
						coaches: !!after ? [...ctx.coaches, ...club.coaches] : club.coaches,
						nextListingToken: nextToken
					}));
					return club.coaches;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingToken: null });
				} finally {
					setState({ loading: false });
				}
			},
			listTeamCoaches: async ({ teamId, limit = 10, after }: { teamId: string } & PaginationParamsModel) => {
				setState({ loading: true });
				if (!after) {
					setState({ coaches: [], nextListingTeamCoachesToken: null });
				}
				try {
					const { team, nextToken } = await coachService.listTeamCoaches({ teamId, limit, after });
					setState(ctx => ({
						coaches: !!after ? [...ctx.coaches, ...team.coaches] : team.coaches,
						nextListingTeamCoachesToken: nextToken
					}));
					return team.coaches;
				} catch (err) {
					console.log(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingTeamCoachesToken: null });
				} finally {
					setState({ loading: false });
				}
			},
			findCoaches: async (search?: string) => {
				try {
					const { coaches } = await coachService.findCoaches(search);
					return coaches;
				} catch (err) {
					console.log(err);
					showToast({ text: "Could not find coaches, please try again", noIcon: true });
				}
			},
			inviteCoachesToClub: async (clubId: string, invitations: { email: string }[]) => {
				setState({ inviting: true });
				try {
					const { createdCoachCount } = await coachService.inviteCoachesToClub(clubId, invitations);

					createdCoachCount &&
						showToast({ text: `Coach invitations were sent to ${createdCoachCount} coaches`, noIcon: true });
				} catch (err) {
					console.log(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ inviting: false });
				}
			},
			createCoaches: async (clubId: string, coaches: Partial<CreateClubCoachModal>[]) => {
				setState({ loading: true });

				try {
					await coachService.createCoaches(clubId, coaches);
					showToast({ text: "Coaches were created successfully", noIcon: true });
				} catch (error) {
					showToast({ text: "Error on create coaches", noIcon: true });
					console.log("Create coaches failed", error);
				} finally {
					setState({ loading: false });
				}
			},
			updateCoaches: async (params: {
				clubId: string;
				coaches: {
					remove: { id: string }[];
					update: UpdateClubCoachFormModal[];
				};
			}) => {
				setState({ loading: true });

				try {
					await coachService.updateCoaches(params);
					if (params.coaches.remove?.length) {
						showToast({
							text: `Selected ${params.coaches.remove.length > 1 ? "coaches were" : "coach was"} removed from the club`,
							noIcon: true
						});
					}

					if (params.coaches.update.some(x => x.teams.add.length)) {
						showToast({
							text: "Selected coaches were successfully added to the selected team",
							noIcon: true
						});
					}

					if (params.coaches.update.some(x => x.teams.remove.length)) {
						showToast({
							text: "Selected coaches were successfully removed from the selected team",
							noIcon: true
						});
					}
				} catch (error) {
					console.log("Update coaches failed", error);
				} finally {
					setState({ loading: false });
				}
			},
			resetStore() {
				setInitial();
			}
		};
	}, [setState, coachService, showToast, setInitial]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useCoach;

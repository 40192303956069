import { z } from "zod";

import { baseCreateType, logoResolver } from "./CreateTypes";
import { EntitySoccerFacilityRoles } from "./FirebaseUserRole";
import { ratingResolver } from "./RatingModel";

export enum FacilityTypeEnum {
	INDOOR = "INDOOR",
	OUTDOOR = "OUTDOOR"
}

export enum FacilitySurfaceTypeEnum {
	GRASS = "GRASS",
	DIRT = "DIRT",
	GRAVEL = "GRAVEL",
	TURF = "TURF"
}

export enum FacilityUnits {
	METER = "METER",
	YARD = "YARD"
}

export const supportedFormatsOpts = [
	{
		label: "11v11",
		value: 11
	},
	{
		label: "9v9",
		value: 9
	},
	{
		label: "8v8",
		value: 8
	},
	{
		label: "7v7",
		value: 7
	},
	{
		label: "5v5",
		value: 5
	},
	{
		label: "4v4",
		value: 4
	},
	{
		label: "3v3",
		value: 3
	}
];

export const facilityPhotoArrayResolver = z.array(
	z.object({
		id: z.string(),
		image: logoResolver,
		removed: z.boolean()
	})
);

export const facilityPhotosResolver = baseCreateType.pick({ id: true, name: true }).extend({
	photos: facilityPhotoArrayResolver
});

export const crudFieldResolver = z.object({
	id: z.string().optional(),
	name: z.string(),
	type: z.nativeEnum(FacilityTypeEnum),
	surface: z.object({
		type: z.nativeEnum(FacilitySurfaceTypeEnum),
		dimensions: z.object({
			unit: z.nativeEnum(FacilityUnits),
			length: z.number(),
			width: z.number()
		})
	})
});

export const fieldInfo = baseCreateType.pick({ id: true, location: true, logo: true }).extend({
	id: z.string().optional(),
	name: z.string().nullable(),
	type: z.nativeEnum(FacilityTypeEnum).nullable(),
	surfaceType: z.nativeEnum(FacilitySurfaceTypeEnum).nullable(),
	surfaceUnit: z.nativeEnum(FacilityUnits).nullable(),
	surfaceLength: z.number().nullable(),
	surfaceWidth: z.number().nullable(),
	rating: ratingResolver.optional().nullable(),
	facility: z.lazy(() => baseCreateType.optional())
});

export const facilityModel = baseCreateType.extend({
	fields: z.lazy(() => z.array(fieldInfo).optional()),
	rating: ratingResolver.optional(),
	members: z
		.object({
			add: z.array(
				z.object({
					email: z.string(),
					role: z.nativeEnum(EntitySoccerFacilityRoles)
				})
			),
			remove: z.array(
				z.object({
					userId: z.string(),
					role: z.nativeEnum(EntitySoccerFacilityRoles)
				})
			)
		})
		.optional()
});

export type FacilityModel = z.infer<typeof facilityModel>;
export type FieldInfo = z.infer<typeof fieldInfo>;
export type CrudFieldModel = z.infer<typeof crudFieldResolver>;
export type FacilityPhotos = z.infer<typeof facilityPhotosResolver>;
export type FacilityPhotoArray = z.infer<typeof facilityPhotoArrayResolver>;

export type UpdateFacilityData = Partial<FacilityModel> & {
	facilityId: string;
	photos?: {
		add: {
			url: string;
		}[];
		remove: {
			id: string;
		}[];
	};
};

export const setupFacilityFieldReservation = z.object({
	id: z.string(),
	fields: z.array(
		z.object({
			id: z.string(),
			formats: z.array(z.number()),
			periods: z.array(
				z.object({
					id: z.string().optional(),
					date: z.string(),
					start: z.string(),
					end: z.string(),
					formats: z
						.object({
							reservationId: z.string(),
							format: z.number()
						})
						.array()
						.optional()
				})
			)
		})
	)
});

export type SetupFacilityFieldReservationModel = z.infer<typeof setupFacilityFieldReservation>;

export const newFieldReservation = z.object({
	fieldId: z.string(),
	supportedPlayersPerTeam: z.number(),
	startsAt: z.string(),
	endsAt: z.string()
});

export type NewFieldReservationModel = z.infer<typeof newFieldReservation>;

export const updateFieldReservation = newFieldReservation.omit({ fieldId: true }).extend({
	id: z.string()
});

export type UpdateFieldReservationModel = z.infer<typeof updateFieldReservation>;

export const fieldReservation = newFieldReservation.extend({
	id: z.string().optional()
});

export type FieldReservationModel = z.infer<typeof fieldReservation>;

const reservationInfo = z.object({
	supportedPlayersPerTeam: z.number(),
	startsAt: z.string(),
	endsAt: z.string()
});

export const setupFieldReservationParams = z.object({
	competitionId: z.string(),
	reservations: z.object({
		create: z.array(
			reservationInfo.extend({
				fieldId: z.string()
			})
		),
		remove: z.array(z.object({ id: z.string() })),
		update: z.array(
			reservationInfo.extend({
				id: z.string()
			})
		)
	})
});

export type SetupFieldReservationParams = z.infer<typeof setupFieldReservationParams>;

import config from "config/appConfig";

import type { PaginationTokenType, PersonObject } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

import type { AdminBaseEntityModel } from "../../types/AdminTypes";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.ADMIN_DATA}`;

export interface Values {
	listingUsers: boolean;
	assigningUserRoles: boolean;
	people: PersonObject[];
	listPeopleNextToken: PaginationTokenType;

	listingReportedUsers: boolean;
	reportedPeople: PersonObject[];
	listReportedPeopleNextToken: PaginationTokenType;
	banningUsers: boolean;
	ignoringReportedUsers: boolean;

	listingReportedPosts: boolean;
	reportedPosts: PersonObject[];
	listReportedPostsNextToken: PaginationTokenType;
	deletingReportedPosts: boolean;
	ignoringReportedPosts: boolean;

	loadingClubs: boolean;
	clubs: AdminBaseEntityModel[];
	listClubNextToken: PaginationTokenType;
	listClubPreviousToken: PaginationTokenType;

	loadingLeagues: boolean;
	leagues: AdminBaseEntityModel[];
	listLeagueNextToken: PaginationTokenType;
	listLeaguePreviousToken: PaginationTokenType;

	loadingFacilities: boolean;
	facilities: AdminBaseEntityModel[];
	listFacilityNextToken: PaginationTokenType;
	listFacilityPreviousToken: PaginationTokenType;

	loadingRefOrgs: boolean;
	refOrgs: AdminBaseEntityModel[];
	listRefOrgNextToken: PaginationTokenType;
	listRefOrgPreviousToken: PaginationTokenType;

	submitting: boolean;
}

export const initialState: IStateProps<Values> = {
	listingUsers: false,
	assigningUserRoles: false,
	people: [],
	listPeopleNextToken: null,

	listingReportedUsers: false,
	reportedPeople: [],
	listReportedPeopleNextToken: null,
	banningUsers: false,
	ignoringReportedUsers: false,

	listingReportedPosts: false,
	reportedPosts: [],
	listReportedPostsNextToken: null,
	deletingReportedPosts: false,
	ignoringReportedPosts: false,

	loadingClubs: false,
	clubs: [],
	listClubNextToken: null,
	listClubPreviousToken: null,

	loadingLeagues: false,
	leagues: [],
	listLeagueNextToken: null,
	listLeaguePreviousToken: null,

	loadingFacilities: false,
	facilities: [],
	listFacilityNextToken: null,
	listFacilityPreviousToken: null,

	loadingRefOrgs: false,
	refOrgs: [],
	listRefOrgNextToken: null,
	listRefOrgPreviousToken: null,

	submitting: false
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

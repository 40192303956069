import config from "config/appConfig";

import type { PaymentTransactionModel } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.PAYMENT_DATA}`;

export interface Values {
	paying: boolean;

	loadingTransactionDetails: boolean;
	transactionDetails?: PaymentTransactionModel;
}

export const initialState: IStateProps<Values> = {
	paying: false,

	loadingTransactionDetails: false,
	transactionDetails: undefined
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import config from "config/appConfig";

import type { EntityRoles, FacilityModel } from "shared/types";
import type { FacilityPhotoArray, FieldInfo } from "shared/types/FacilityModel";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.FACILITY_DATA}`;

export interface Values {
	overview?: FacilityModel;
	info?: FacilityModel;
	facility?: FacilityModel;
	newFacility?: Partial<FacilityModel>;
	fields: FieldInfo[];
	loading: boolean;
	loadingFields: boolean;
	facilities: FacilityModel[];
	facilityFieldsDialog?: {
		open: boolean;
		activeId?: string;
		focusNewField?: boolean;
	};
	facilityPhotos: FacilityPhotoArray;
	searchFacilities: FacilityModel[];
	updating?: string;
	userFacilityRoles: EntityRoles[];
	loadingPhotos: boolean;
	loadingInfo: boolean;
}

export const initialState: IStateProps<Values> = {
	userFacilityRoles: [],
	overview: undefined,
	info: undefined,
	fields: [],
	facility: undefined,
	newFacility: undefined,
	facilityFieldsDialog: undefined,
	updating: undefined,
	loadingPhotos: false,
	loadingFields: false,
	loading: false,
	facilities: [],
	searchFacilities: [],
	facilityPhotos: [],
	loadingInfo: false
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { z } from "zod";

import { AgeGroupEnum } from "./AgeGroupEnum";
import { baseCreateType } from "./CreateTypes";
import { GenderEnum } from "./GenderEnum";
import { structAge, structGender, structLevel } from "./SetupStructModel";

const ageResolver = z.object({
	id: z.string().optional(),
	name: z.string().optional().nullable(),
	structEntry: structAge.nullable().optional(),
	type: z.nativeEnum(AgeGroupEnum),
	date: z.string().nullable(),
	value: z.number().optional()
});

const genderResolver = z.object({
	id: z.string().optional(),
	structEntry: structGender.nullable().optional(),
	value: z.nativeEnum(GenderEnum)
});

const levelResolver = z.object({
	id: z.string().optional(),
	structEntry: structLevel.nullable().optional(),
	ord: z.number(),
	name: z.string()
});

export const AgeGenderLevelModel = baseCreateType.pick({ logo: true }).extend({
	age: ageResolver.optional(),
	gender: genderResolver.optional(),
	level: levelResolver.optional()
});

export type AgeGenderLevelModel = z.infer<typeof AgeGenderLevelModel>;
export type EntityAge = z.infer<typeof ageResolver>;
export type EntityGender = z.infer<typeof genderResolver>;
export type EntityLevel = z.infer<typeof levelResolver>;

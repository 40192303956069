export enum InvitationStatus {
	APPLIED = "APPLIED",
	ACCEPTED = "ACCEPTED", // Deprecated. We should use ACTIVE instead.
	REJECTED = "REJECTED",
	REMOVED = "REMOVED",
	PRIVATE = "PRIVATE",
	PENDING = "PENDING",
	ASSIGNED = "ASSIGNED",
	NOT_ASSIGNED = "NOT_ASSIGNED",
	NOT_INVITED = "NOT_INVITED",
	ACTIVE = "ACTIVE",
	PAID = "PAID",
	IN_PROGRESS = "IN_PROGRESS",
	FAILED = "FAILED"
}

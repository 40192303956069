import { z } from "zod";

import { locationModel } from "./LocationModel";

export enum EducationDegree {
	MASTERS_DEGREE = "Masters Degree",
	BACHELORS_DEGREE = "Bachelors Degree",
	HIGH_SCHOOL_DIPLOMA = "High School Diploma"
}

export const userEducationResolver = z.object({
	id: z.string(),
	areasOfInterest: z.string().nullable(),
	degree: z.nativeEnum(EducationDegree).nullable(),
	description: z.string().nullable(),
	location: locationModel.nullable(),
	schoolName: z.string(),
	startsOn: z.string().nullable(), // ISO date
	endsOn: z.string().nullable() // ISO date
});

export type UserEducationModel = z.infer<typeof userEducationResolver>;

export const personEducationResolver = z.object({
	person: z.object({
		id: z.string(),
		education: userEducationResolver.array()
	})
});

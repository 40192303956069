import { useMemo } from "react";

import { fetchAuthSession } from "@aws-amplify/auth";
import config from "config/appConfig";

import { DateTime, Interval } from "luxon";

// import useMutex from "shared/hooks/useMutex";

import { useNavigate } from "react-router-dom";

import { getPaths } from "core/getPaths";

import useLocalStorage from "../../localStorage/localStorage";

const { auth: authRoutes } = getPaths();

let cashedToken = "";
let cashedExpiredTime = 0;

const useJwt = () => {
	const localStorage = useLocalStorage();
	const navigate = useNavigate();

	// const { lock } = useMutex();

	return useMemo(() => {
		const refreshToken = async () => {
			return await fetchAuthSession({
				forceRefresh: true
			});
		};

		const setJwt = (token: string, expired: number): void => {
			cashedToken = token;
			cashedExpiredTime = expired;

			localStorage.set(config.TOKEN_KEY, token);
			localStorage.set(config.TOKEN_EXPIRED_KEY, expired.toString());
		};

		const getJwt = async (): Promise<string> => {
			// const releaseLock = await lock();

			try {
				const token = cashedToken || (localStorage.get(config.TOKEN_KEY, null) as string);
				const expiredTime = cashedExpiredTime || (localStorage.get(config.TOKEN_EXPIRED_KEY, 0) as number);

				let validToken = token;
				if (expiredTime) {
					const expiredDatetime = DateTime.fromSeconds(expiredTime);
					const currentDatetime = DateTime.now();
					const diff = Interval.fromDateTimes(currentDatetime, expiredDatetime);
					const diffMinutes = diff.length("minutes");
					if (isNaN(diffMinutes) || diffMinutes < 15) {
						const newSession = await refreshToken();
						if (newSession) {
							const token = newSession.tokens?.accessToken.toString();
							const expired = newSession.tokens?.idToken?.payload?.exp;

							if (token && expired) {
								setJwt(token, expired);
								validToken = token;
							} else {
								throw new Error("Can’t refresh token");
							}
						}
					}
				}

				return validToken;
			} catch (err) {
				console.warn(err);
				navigate(authRoutes.signIn.getPath());
				return "";
			} finally {
				// releaseLock();
			}
		};

		const hasJwt = (): boolean => {
			const token = cashedToken || (localStorage.get(config.TOKEN_KEY, null) as string);
			return !!token;
		};

		return {
			hasJwt,
			getJwt,
			setJwt,
			injectJwt: async config => {
				const token = await getJwt();
				const header = token ? { Authorization: `Bearer ${token}` } : {};
				return {
					...config,
					headers: {
						"Content-Type": "application/json",
						...header,
						...(config ? config.headers || {} : {})
					}
				};
			},
			injectBase: config => {
				return {
					...config,
					headers: {
						"Content-Type": "application/json",
						...(config ? config.headers || {} : {})
					}
				};
			},
			clearJwt: () => {
				cashedToken = "";
				localStorage.remove(config.TOKEN_KEY);
			}
		};
	}, [localStorage, navigate]);
};

export default useJwt;

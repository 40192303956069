import { z } from "zod";

export enum UploadSourceType {
	googleDrive = "GOOGLE_DRIVE",
	s3 = "S3"
}

export enum UploadValidateTypes {
	SOCCER_PLAYERS = "upload.soccer.players"
}

export const googleSheetLinkResolver = z.object({
	id: z.string(),
	url: z.string()
});

export type GoogleSheetLinkModel = z.infer<typeof googleSheetLinkResolver>;

export const validatedUploadResolver = z.object({
	id: z.string(),
	type: z.string(),
	url: z.string()
});

export type ValidatedUploadModel = z.infer<typeof validatedUploadResolver>;

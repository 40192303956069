import { useCallback, useMemo, useRef } from "react";

import type { DocumentData, QueryConstraint, Unsubscribe } from "firebase/firestore";
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, startAfter } from "firebase/firestore";

import { useFBNotificationsStore } from "shared/contexts";

import { useFirebase, useUser } from "shared/hooks";

import type { FBEvent, FBNotification, FBUserModel } from "shared/types";

const useFBNotifications = () => {
	const notificationSubscriptions = useRef<Unsubscribe[] | null>(null);
	const eventSubscriptions = useRef<Unsubscribe[] | null>(null);

	const { setState, setInitial, ...store } = useFBNotificationsStore();

	const { getUser, getCurrentFirestore } = useFirebase();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const getAuthUserRef = useCallback((userUid: string) => `/auth/${userUid}`, []);

	const getUserRef = useCallback((userId: string) => `/users/${userId}`, []);

	const DB = useMemo(() => getCurrentFirestore(), [getCurrentFirestore]);

	const userId = useMemo(() => user?.person.id || "", [user?.person.id]);

	const methods = useMemo(
		() => ({
			getAuthUser: async () => {
				const user = await getUser();
				if (user) {
					const docRef = doc(DB, getAuthUserRef(user.uid));
					const docSnap = await getDoc(docRef);

					if (docSnap.exists()) {
						return docSnap.data() as FBUserModel;
					}
				}

				return null;
			},
			subscribeToNotifications: async () => {
				let unsub: Unsubscribe | undefined;
				const authUser = await getUser();
				if (authUser && userId) {
					unsub = onSnapshot(
						query(collection(DB, getUserRef(userId), "notifications"), orderBy("triggeredAt", "desc"), limit(10)),
						docsSnap => {
							const notifications: FBNotification[] = [];

							docsSnap.forEach(doc => {
								const notificationInfo = doc.data();
								notificationInfo && notifications.push(notificationInfo as FBNotification);
							});

							setState(ctx => {
								const newNtfList = !!ctx.notifications.length
									? [...ctx.notifications, ...notifications]
									: notifications;
								return {
									notifications: newNtfList.filter(
										(item, index, arr) =>
											index === arr.findIndex(x => x.triggeredAt.nanoseconds === item.triggeredAt.nanoseconds)
									)
								};
							});
						}
					);

					notificationSubscriptions.current = (notificationSubscriptions.current || []).concat([unsub]);
				}

				return unsub;
			},
			unsubscribeFromNotifications: async () => {
				(notificationSubscriptions.current || []).forEach(unsub => {
					unsub();
				});
			},
			loadMoreNotifications: async ({
				token,
				loadLimit = 10,
				reset
			}: {
				token?: DocumentData;
				loadLimit?: number;
				reset?: boolean;
			}) => {
				const authUser = await getUser();
				if (authUser && userId) {
					setState({ loading: true });

					const queryParams: QueryConstraint[] = [orderBy("triggeredAt", "desc"), limit(loadLimit)];
					if (token && !reset) {
						queryParams.push(startAfter(token));
					}

					const ntfsQuery = query(collection(DB, getUserRef(userId), "notifications"), ...queryParams);
					const data = await getDocs(ntfsQuery);

					const ntfsList: FBNotification[] = [];
					data.docs?.forEach(userDoc => {
						ntfsList.push(userDoc.data() as FBNotification);
					});

					setState(ctx => {
						const newNtfList = !!ctx.notifications.length && !reset ? [...ctx.notifications, ...ntfsList] : ntfsList;
						return {
							notifications: newNtfList.filter(
								(item, index, arr) =>
									index === arr.findIndex(x => x.triggeredAt.nanoseconds === item.triggeredAt.nanoseconds)
							),
							nextToken: data.docs?.length ? data.docs[data.docs.length - 1] : undefined,
							loading: false
						};
					});
				}
			},
			subscribeToEvents: async () => {
				const authUser = await getUser();
				let unsub: Unsubscribe | undefined;
				if (authUser && userId) {
					unsub = onSnapshot(collection(DB, getUserRef(userId), "events"), docsSnap => {
						const events: FBEvent[] = [];

						docsSnap.forEach(doc => {
							const eventInfo = doc.data();
							eventInfo && events.push(eventInfo as FBEvent);
						});

						setState(ctx => ({
							events: !!ctx.events.length
								? [...ctx.events, ...events].filter(
										(item, index, arr) =>
											index === arr.findIndex(x => x.triggeredAt.nanoseconds === item.triggeredAt.nanoseconds)
									)
								: events.filter(
										(item, index, arr) =>
											index === arr.findIndex(x => x.triggeredAt.nanoseconds === item.triggeredAt.nanoseconds)
									)
						}));
					});

					eventSubscriptions.current = (eventSubscriptions.current || []).concat([unsub]);
				}

				return unsub;
			},
			unsubscribeFromEvents: async () => {
				(eventSubscriptions.current || []).forEach(unsub => {
					unsub();
				});
			},
			resetStore() {
				setInitial();
			}
		}),
		[setState, setInitial, getUser, DB, getUserRef, getAuthUserRef, userId]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useFBNotifications;

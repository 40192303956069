import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	organization: {
		create: {
			name: null,
			address: null,
			logo: null,
			story: null
		},
		invite: {
			people: null,
			referee: null
		},
		season: {
			find: null
		}
	},
	referee: null
});

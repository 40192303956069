import { z } from "zod";

import { locationModel } from "./LocationModel";

export const userExperienceResolver = z.object({
	id: z.string(),
	title: z.string(),
	company: z.string(),
	description: z.string().nullable(),
	location: locationModel.nullable(),
	startsOn: z.string(), // ISO date
	endsOn: z.string().nullable() // ISO date
});

export type UserExperienceModel = z.infer<typeof userExperienceResolver>;

export const personExperienceResolver = z.object({
	person: z.object({
		id: z.string(),
		experiences: z.array(userExperienceResolver)
	})
});

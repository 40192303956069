import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	admin: {
		dashboard: null,
		users: null,
		reportedContent: null,
		reportedUsers: null,
		manageClubs: null,
		manageLeagues: null,
		manageFacilities: null,
		manageRefOrgs: null
	}
});

import { useMemo } from "react";

import { fetchAuthSession, updatePassword } from "@aws-amplify/auth";

import useToast from "./useToast";

const useCognito = () => {
	const { showToast } = useToast();

	const methods = useMemo(
		() => ({
			getCurrentAmplifySession: async () => {
				try {
					return await fetchAuthSession();
				} catch (error) {
					return null;
				}
			},
			changeUserPassword: async (oldPassword: string, newPassword: string) => {
				try {
					const session = await methods.getCurrentAmplifySession();

					if (session) {
						await updatePassword({
							oldPassword,
							newPassword
						});
						showToast({ text: "Password changed successfully", noIcon: true });
					}
				} catch (error) {
					showToast({ text: "Failed to change password, please try again later" });
				}
			}
		}),
		[showToast]
	);

	return methods;
};

export default useCognito;

import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

export interface Values {
	isDisabled: boolean;
}

export const initialState: IStateProps<Values> = {
	isDisabled: false
};

export default createStore<Values>({
	initialState,
	persistant: true
});

import config from "config/appConfig";

import type {
	EntityRoles,
	PaginationTokenType,
	SearchConnectionModel,
	ShortPaymentTransactionModel,
	UserEducationModel,
	UserExperienceModel,
	UserModel,
	UserOverviewModel,
	UserProfileModel,
	UserSocialLinkedAccountModel
} from "shared/types";
import { PostReactionSkins } from "shared/types";
import type { PendingPaymentRequestOrderModel } from "shared/types/PaymentModel";
import type { ChildModel, UserEmailsModel } from "shared/types/UserModel";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.USER_DATA}`;

export interface Values {
	loading: boolean;
	loadingUserInfo: boolean;
	loadingUserProfileInfo: boolean;
	loadingOverview: boolean;

	user?: UserModel;
	following?: string;
	updatingProfile: boolean;
	myProfile?: UserProfileModel;
	profile?: UserProfileModel;
	overview?: UserOverviewModel;

	loadingPersonExperience: boolean;
	updatingPersonExperience: boolean;
	personExperiences: UserExperienceModel[];

	loadingPersonEducation: boolean;
	updatingPersonEducation: boolean;
	personEducation: UserEducationModel[];

	loadingLinkedAccounts: boolean;
	updatingPersonLinkedAccounts: boolean;
	personLinkedAccounts: UserSocialLinkedAccountModel[];

	connecting?: string;
	rejectingConnectedUserId?: string;

	reactionSkin: PostReactionSkins;
	searchedConnections: SearchConnectionModel[];
	searchingConnections: boolean;
	nextListingSearchConnectionsToken: PaginationTokenType;

	loadingMyKids: boolean;
	myKids: ChildModel[];

	userPersonRoles: EntityRoles[];
	loadingFollowedUsers: boolean;
	followedUsersNextToken: PaginationTokenType;
	tempCredentials?: {
		username: string;
		email: string;
	};
	unsubscribingFromEmails: boolean;
	loadingEmails: boolean;
	emailsList: UserEmailsModel | null;
	confirmingNewEmail: boolean;
	manageProfileModalOpen: boolean;
	manageProfileModal: { open: boolean; person?: UserOverviewModel };

	loadingPendingPaymentOrders: boolean;
	pendingPaymentOrders: PendingPaymentRequestOrderModel[];
	pendingPaymentOrdersNextToken: PaginationTokenType;

	loadingPaymentTransactions: boolean;
	paymentTransactions: ShortPaymentTransactionModel[];
	paymentTransactionsNextToken: PaginationTokenType;

	redirectUrl?: string;

	temporalDependencyCheck: {
		notifications: boolean;
		invitations: boolean;
		chats: boolean;
	};
}

export const initialState: IStateProps<Values> = {
	loading: false,
	loadingUserInfo: false,
	loadingUserProfileInfo: false,
	loadingOverview: false,
	user: undefined,
	following: undefined,
	updatingProfile: false,
	myProfile: undefined,
	profile: undefined,
	overview: undefined,

	loadingPersonExperience: false,
	updatingPersonExperience: false,
	personExperiences: [],

	loadingPersonEducation: false,
	updatingPersonEducation: false,
	personEducation: [],

	loadingLinkedAccounts: false,
	updatingPersonLinkedAccounts: false,
	personLinkedAccounts: [],

	connecting: undefined,
	rejectingConnectedUserId: undefined,

	reactionSkin: PostReactionSkins.MEDIUM_LIGHT,
	searchedConnections: [],
	searchingConnections: false,

	loadingMyKids: false,
	myKids: [],

	nextListingSearchConnectionsToken: null,
	userPersonRoles: [],
	loadingFollowedUsers: false,
	followedUsersNextToken: null,
	unsubscribingFromEmails: false,
	loadingEmails: false,
	emailsList: null,
	confirmingNewEmail: false,
	manageProfileModalOpen: false,
	manageProfileModal: {
		open: false,
		person: undefined
	},

	loadingPendingPaymentOrders: false,
	pendingPaymentOrders: [],
	pendingPaymentOrdersNextToken: null,

	loadingPaymentTransactions: false,
	paymentTransactions: [],
	paymentTransactionsNextToken: null,

	redirectUrl: undefined,

	temporalDependencyCheck: {
		notifications: false,
		invitations: false,
		chats: false
	}
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

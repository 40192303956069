import { useMemo } from "react";

import { DateTime } from "luxon";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import type {
	ChatModel,
	ConversationInfo,
	PaginationParamsModel,
	PaginationTokenType,
	PostAttachmentModel,
	conversationsEnum
} from "shared/types";
import { chatResolver, conversationsResolver, paginationTokenResolver } from "shared/types";

import BaseConverter from "./converters/baseConverter";

interface MessageActionReturnType {
	conversation: {
		id: string;
	};
}

const useMessagingApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getConversation: async (type: conversationsEnum, params: string[], lastSenderMessageCount?: number) => {
				return BaseConverter.convert<{ conversation: ConversationInfo }>(
					await actions.post(
						API_URLS.MESSAGING.GET_CONVERSATION,
						{
							subject: {
								type,
								params
							},
							lastSenderMessageCount
						},
						{
							resolvers: [
								{
									resolver: chatResolver,
									fields: ["conversation"]
								}
							]
						}
					)
				);
			},
			getConversationsList: async (pagination: PaginationParamsModel) => {
				return BaseConverter.convert<{
					conversations: ChatModel[];
					nextToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.MESSAGING.GET_CONVERSATIONS,
						{
							...pagination
						},
						{
							resolvers: [
								{
									resolver: conversationsResolver,
									fields: ["conversations"]
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			sendMessage: async ({
				conversationId,
				message,
				attachments
			}: {
				conversationId: string;
				message: {
					text: string;
					tag: string;
				};
				attachments?: PostAttachmentModel[];
			}) => {
				const sentAt = DateTime.now()
					.plus({ seconds: 1 })
					.set({
						millisecond: 0
					})
					.toUTC()
					.toISO({ suppressMilliseconds: true, includeOffset: false });

				return BaseConverter.convert(
					await actions.post(API_URLS.MESSAGING.SEND_MESSAGE, {
						conversationId,
						message: {
							sentAt,
							tag: message.tag,
							body: {
								text: message.text
							},
							attachments: attachments?.map(attachment => ({
								entity: attachment.entity,
								kind: attachment.kind
							}))
						}
					})
				);
			},
			updateMessage: async (conversationId: string, id: string, updatedText: string) => {
				return BaseConverter.convert(
					await actions.put(API_URLS.MESSAGING.UPDATE_MESSAGE, {
						conversationId,
						message: {
							id,
							body: {
								text: updatedText
							}
						}
					})
				);
			},
			updateConversation: async ({
				conversationId,
				mute,
				pin,
				updateLastOpened
			}: {
				conversationId: string;
				mute?: boolean;
				pin?: boolean;
				updateLastOpened?: boolean;
			}) => {
				return BaseConverter.convert<MessageActionReturnType>(
					await actions.put(API_URLS.MESSAGING.UPDATE_CONVERSATION, {
						conversationId,
						mute,
						pin,
						updateLastOpened
					})
				);
			},
			deleteMessage: async (conversationId: string, messageId: string) => {
				return BaseConverter.convert<MessageActionReturnType>(
					await actions.post(API_URLS.MESSAGING.DELETE_MESSAGE, {
						conversationId,
						messageId
					})
				);
			}
		}),
		[actions]
	);
};

export default useMessagingApiService;

import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import type {
	CoachClubInviteResolver,
	CoachModel,
	CreateClubCoachModal,
	PaginationParamsModel,
	PaginationTokenType,
	UpdateClubCoachFormModal
} from "shared/types";

import BaseConverter from "./converters/baseConverter";

const useClubApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			listClubCoaches: async ({ clubId, limit, after }: { clubId: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					club: {
						id: string;
						coaches: CoachModel[];
					};
					nextToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.COACH.LIST_CLUB_COACHES, {
						"club-id": clubId,
						limit,
						after
					})
				);
			},
			listTeamCoaches: async ({ teamId, limit, after }: { teamId: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					team: {
						id: string;
						coaches: CoachModel[];
					};
					nextToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.COACH.LIST_TEAM_COACHES, {
						"team-id": teamId,
						limit,
						after
					})
				);
			},
			findCoaches: async (search?: string) => {
				return BaseConverter.convert<{
					coaches: CoachModel[];
				}>(
					await actions.get(API_URLS.COACH.FIND, {
						search
					})
				);
			},
			inviteCoachesToClub: async (clubId: string, coaches: { email: string }[]) => {
				return BaseConverter.convert<CoachClubInviteResolver>(
					await actions.post(API_URLS.COACH.INVITE, {
						clubId,
						coaches
					})
				);
			},
			createCoaches: async (clubId: string, coaches: Partial<CreateClubCoachModal>[]) => {
				return BaseConverter.convert<{ club: { id: string }; createdCoachCount: number }>(
					await actions.post(API_URLS.COACH.CREATE, {
						clubId,
						coaches
					})
				);
			},
			updateCoaches: async ({
				clubId,
				coaches
			}: {
				clubId: string;
				coaches: {
					remove: { id: string }[];
					update: UpdateClubCoachFormModal[];
				};
			}) => {
				return BaseConverter.convert<{ club: { id: string }; updatedCoachCount: number }>(
					await actions.post(API_URLS.CLUB.UPDATE_CLUB_COACHES, {
						clubId,
						coaches
					})
				);
			}
		}),
		[actions]
	);
};

export default useClubApiService;

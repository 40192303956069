import { useMemo } from "react";

import axios from "axios";
import appConfig from "config/appConfig";

import { AppConfig } from "config";
import { API_URLS } from "shared/constants";

import BaseConverter from "./converters/baseConverter";

export interface FirebaseLinkOptions {
	link: string;
	iosInfo: {
		iosBundleId: string;
		iosAppStoreId: string;
	};
	androidInfo: {
		androidPackageName: string;
	};
	socialMetaTagInfo: {
		socialTitle: string;
		socialImageLink?: string;
		socialDescription: string;
	};
	domainUriPrefix: string;
	analyticsInfo: {
		googlePlayAnalytics: {
			utmCampaign: string;
			utmMedium: string;
			utmTerm: string;
			utmSource: string;
			utmContent: string;
		};
	};
}

const useClubApiService = () => {
	return useMemo(
		() => ({
			createFBLink: async (data: Partial<FirebaseLinkOptions>, customLink?: string) => {
				return BaseConverter.convert<{
					previewLink: string;
					shortLink: string;
				}>(
					await axios.post(API_URLS.FIREBASE.DEEP_LINK(appConfig.GLOBAL_CONSTANTS.FIREBASE_CONFIG.API_KEY), {
						dynamicLinkInfo: {
							...data,
							link: customLink ? `${window.location.origin}${customLink}` : window.location.href,
							iosInfo: {
								iosBundleId: AppConfig.GLOBAL_CONSTANTS.FIREBASE_CONFIG.DYNAMIC_LINKS.MOBILE_IOS_BUNDLE_ID,
								iosAppStoreId: AppConfig.GLOBAL_CONSTANTS.FIREBASE_CONFIG.DYNAMIC_LINKS.MOBILE_IOS_STORE_ID
							},
							androidInfo: {
								androidPackageName: AppConfig.GLOBAL_CONSTANTS.FIREBASE_CONFIG.DYNAMIC_LINKS.MOBILE_ANDROID_BUNDLE_ID
							},
							domainUriPrefix: AppConfig.GLOBAL_CONSTANTS.FIREBASE_CONFIG.DYNAMIC_LINKS.FIREBASE_DYNAMIC_LINK_DOMAIN,
							navigationInfo: {
								enableForcedRedirect: false
							}
						}
					})
				);
			}
		}),
		[]
	);
};

export default useClubApiService;

import { useCallback, useMemo } from "react";

import { useClubStore } from "shared/contexts";
import { useClubApiService } from "shared/services";

import usePlatformEnv from "./usePlatformEnv";
import useToast from "./useToast";

import { useSoccerPayment } from "./index";
import { BackendEntityType } from "../types";

import type {
	ClubModel,
	CreateClubPlayerModel,
	EntityRoles,
	EntitySoccerClubRoles,
	FetchClubPlayersParams,
	FetchClubTeamsParams,
	IgnoreStateUpdate,
	LogoModel,
	MatchesFilterModel,
	MediaModel,
	PaginationParamsModel,
	SetupEntityModel,
	TeamModel,
	UpdateClubPlayerFormModal,
	UploadValidateTypes
} from "../types";

const useClub = () => {
	const clubService = useClubApiService();

	const store = useClubStore();
	const { setState, setInitial } = useClubStore();

	const { showToast } = useToast();

	const { getPaymentRequests, getPaymentHistory, getPendingOrders } = useSoccerPayment();

	const { isShowRoleToasts } = usePlatformEnv();

	const methods = useMemo(() => {
		return {
			createClub: async (clubData: Omit<ClubModel, "id">) => {
				try {
					setState({ submitting: true });
					const { club } = await clubService.createClub(clubData);
					setState({ club });
					return club;
				} catch (err) {
					console.error(err);
					showToast({ text: "Can't create a new club", noIcon: true });
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			setupClub: async (data: SetupEntityModel & { id: string }) => {
				setState({ loadingStructure: true });
				const { club } = await clubService.setupClub(data);
				setState({ club, structure: club.struct, loadingStructure: false });
				return club.struct;
			},
			createClubTeams: async ({
				teams,
				id,
				ignoreLoading,
				ignoreStateUpdate
			}: { teams: TeamModel[]; id: string } & IgnoreStateUpdate) => {
				try {
					if (!ignoreLoading) {
						setState({ submitting: true });
					}

					const { club } = await clubService.createClubTeams({ teams, id });
					if (!ignoreStateUpdate) {
						setState(ctx => ({ club: { ...ctx.club, teams: club.teams } }));
					}
					showToast({ text: "Club teams were successfully created", noIcon: true });
					return club.teams!;
				} catch (err) {
					console.error(err);
					showToast({ text: "Can't create new club teams", noIcon: true });
					return [];
				} finally {
					if (!ignoreLoading) {
						setState({ submitting: false });
					}
				}
			},
			deleteClubTeams: async ({
				clubId,
				teams,
				ignoreLoading
			}: {
				clubId: string;
				teams: { id: string }[];
			} & IgnoreStateUpdate) => {
				if (!ignoreLoading) {
					setState({ submitting: true });
				}
				try {
					await clubService.deleteClubTeams(clubId, teams);
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					if (!ignoreLoading) {
						setState({ submitting: false });
					}
				}
			},
			getFollowedClubs: async (params: { search?: string } & PaginationParamsModel) => {
				setState({ loadingFollowedClubs: true });
				const { clubs, nextToken } = await clubService.getFollowedClubs(params);
				setState({ followedClubsNextToken: nextToken, loadingFollowedClubs: false });
				return clubs;
			},
			updateNewClub(
				newClubData: Partial<Omit<ClubModel, "logo">> & {
					logo?: string | null | MediaModel | LogoModel;
				}
			) {
				setState(ctx => ({
					newClub: { ...ctx.newClub, ...newClubData }
				}));
			},
			updateNewSetupClub(newSetupClubData?: Partial<SetupEntityModel>) {
				setState(ctx => ({
					newSetupClub: { ...ctx.newSetupClub, ...newSetupClubData }
				}));
			},
			getClubMatches: async ({
				limit = 10,
				after,
				...rest
			}: {
				clubId: string;
				filters?: Partial<MatchesFilterModel>;
			} & PaginationParamsModel) => {
				setState({ loadingMatches: true });
				if (!after) {
					setState({ matches: [], nextListingMatchesToken: null });
				}

				try {
					const { matches, nextToken } = await clubService.getClubMatches({ limit, after, ...rest });
					setState(ctx => ({
						matches: !!after ? [...ctx.matches, ...matches] : matches,
						nextListingMatchesToken: nextToken
					}));
					return matches;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingMatchesToken: null });
				} finally {
					setState({ loadingMatches: false });
				}
			},
			getClubs: async (
				params: { search?: string; onlyMy?: boolean; clubRoles?: EntitySoccerClubRoles[] } & IgnoreStateUpdate
			): Promise<ClubModel[]> => {
				try {
					!params?.ignoreLoading && setState({ loading: true });

					const { clubs } = await clubService.getClubs(params);
					setState(ctx => ({
						clubs: params?.ignoreStateUpdate ? ctx.clubs : clubs,
						loading: params?.ignoreLoading ? ctx.loading : false
					}));

					return clubs;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return [];
				} finally {
					!params?.ignoreLoading && setState({ loading: false });
				}
			},
			getClubOverview: async (id: string, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loadingOverview: true });

				try {
					const { club } = await clubService.getClubOverview(id);
					setState(ctx => ({
						overview: club,
						loadingOverview: ignoreLoading ? ctx.loadingOverview : false
					}));
					return club;
				} catch {
					showToast({
						text: "Club couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingOverview: false });
					}
				}
			},
			getClubInfo: async (id: string, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loadingInfo: true });

				try {
					const { club } = await clubService.getClubInfo(id);
					setState(ctx => ({ info: club, loadingInfo: ignoreLoading ? ctx.loadingInfo : false }));

					if (club?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for club: ${club.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return club;
				} catch {
					showToast({
						text: "Club couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingInfo: false });
					}
				}
			},
			getStructure: async ({
				id,
				ignoreLoading,
				ignoreStateUpdate
			}: {
				id: string;
			} & IgnoreStateUpdate) => {
				if (!ignoreLoading) {
					setState({ loadingStructure: true });
				}

				try {
					const { club } = await clubService.getStructure(id);
					if (!ignoreStateUpdate) {
						setState({ structure: club.struct });
					}
					return club.struct;
				} catch {
					showToast({
						text: "Can't get club structure",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingStructure: false });
					}
				}
			},
			getTeams: async ({
				after,
				ignoreLoading,
				ignoreStateUpdate,
				...rest
			}: FetchClubTeamsParams & PaginationParamsModel & IgnoreStateUpdate) => {
				if (!ignoreLoading) {
					setState({ loadingTeams: true });
				}

				try {
					if (!after && !ignoreStateUpdate) {
						setState({ teams: [], nextListingTeamsToken: null });
					}

					const { club, nextToken } = await clubService.getTeams({ ...rest, after });

					if (!ignoreStateUpdate) {
						setState(ctx => ({
							nextListingTeamsToken: nextToken,
							teams: !!after ? [...ctx.teams, ...club.teams!] : club.teams!
						}));
					}

					return {
						teams: club.teams,
						nextListingTeamsToken: nextToken
					};
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return {
						teams: [],
						nextListingTeamsToken: null
					};
				} finally {
					if (!ignoreLoading) {
						setState({ loadingTeams: false });
					}
				}
			},
			updateClub: async (
				data: Partial<Omit<ClubModel, "logo">> & {
					logo?: LogoModel | string | null;
					clubId: string;
				},
				dontShowToast?: boolean
			) => {
				setState({ loading: true });

				try {
					const { club } = await clubService.updateClub(data);

					setState(ctx => ({ overview: { ...ctx.overview, ...club } }));

					!dontShowToast && showToast({ text: "Club updated successfully", noIcon: true });
				} catch (error) {
					showToast({ text: "Error updating club", noIcon: true });
					console.log("update failed", error);
				} finally {
					setState({ loading: false });
				}
			},
			getCompetitions: async ({
				after,
				limit = 10,
				...rest
			}: {
				clubId: string;
				withTeams?: boolean;
			} & PaginationParamsModel) => {
				setState({ loadingCompetitions: true });
				if (!after) {
					setState({ competitions: [], nextListingCompetitionsToken: null });
				}
				try {
					const { club, nextToken } = await clubService.getCompetitions({ after, limit, ...rest });
					setState(ctx => ({
						competitions: !!after ? [...ctx.competitions, ...(club?.competitions || [])] : club?.competitions,
						nextListingCompetitionsToken: nextToken
					}));
					return club?.competitions || [];
				} catch (err) {
					console.log(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingCompetitionsToken: null });
				} finally {
					setState({ loadingCompetitions: false });
				}
			},
			getTeamFilters: async (clubId: string) => {
				setState({ loadingTeamFilters: true });

				try {
					const { club } = await clubService.getTeamFilters(clubId);
					setState({ teamFilters: club });
				} catch (error) {
					console.error({ error });
				} finally {
					setState({ loadingTeamFilters: false });
				}
			},
			getPlayers: async (
				{ limit = 1000, after, ...rest }: FetchClubPlayersParams & PaginationParamsModel,
				dontUpdateState?: boolean
			) => {
				setState({ loadingPlayers: true });
				if (!after) {
					setState({ players: [], nextListingPlayersToken: null });
				}

				try {
					const { club, nextToken } = await clubService.getPlayers({ limit, after, ...rest });
					setState(ctx => ({
						players: dontUpdateState ? ctx.players : !!after ? [...ctx.players, ...club.players] : club.players,
						nextListingPlayersToken: nextToken
					}));
					return club.players;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingPlayersToken: null });
				} finally {
					setState({ loadingPlayers: false });
				}
			},
			setClubSettingsModal: (clubSettingsModal?: { open: boolean; club: ClubModel }) => {
				setState({ clubSettingsModal });
			},
			updateTempTeams: (teams: TeamModel[]) => {
				setState({ tempTeams: teams });
			},
			createPlayers: async (clubId: string, players: Partial<CreateClubPlayerModel>[], hideToast = false) => {
				setState({ creatingPlayers: true });

				try {
					const { createdPlayerCount } = await clubService.createPlayers(clubId, players);

					if (!hideToast) {
						showToast({ text: "Players were created successfully", noIcon: true });
					}
					return createdPlayerCount;
				} catch (error) {
					showToast({ text: "Error on create players", noIcon: true });
					console.log("Create players failed", error);
					return 0;
				} finally {
					setState({ creatingPlayers: false });
				}
			},
			addPlayersByCompetition: async (competitionId: string, players: { id: string }[]) => {
				setState({ addingPlayers: true });

				try {
					const { createdPlayerCount } = await clubService.addPlayersByCompetition(competitionId, players);
					showToast({ text: "Players were added successfully", noIcon: true });
					return createdPlayerCount;
				} catch (error) {
					showToast({ text: "Error on adding players", noIcon: true });
					console.log("Create players failed", error);
					return 0;
				} finally {
					setState({ addingPlayers: false });
				}
			},
			uploadPlayers: async (
				params: { clubId: string; teamId?: string; upload: { id: string; sourceUrl: string } },
				hideToast = false
			) => {
				setState({ uploadingPlayers: true });

				try {
					await clubService.uploadPlayers(params);
					if (!hideToast) {
						showToast({ text: "System is creating the imported players", noIcon: true });
					}
				} catch (error) {
					showToast({ text: "Error on create players", noIcon: true });
					console.log("Create players failed", error);
				} finally {
					setState({ uploadingPlayers: false });
				}
			},
			updatePlayers: async (params: {
				clubId: string;
				players: {
					remove: { id: string }[];
					update: UpdateClubPlayerFormModal[];
				};
			}) => {
				setState({ loading: true });

				try {
					await clubService.updatePlayers(params);
					if (params.players.remove?.length) {
						showToast({
							text: `Selected ${
								params.players.remove.length > 1 ? "players were" : "player was"
							} removed from the club`,
							noIcon: true
						});
					}

					if (params.players.update.some(x => x.teams.add.length)) {
						showToast({
							text: "Selected players were successfully added to the selected team",
							noIcon: true
						});
					}

					if (params.players.update.some(x => x.teams.remove.length)) {
						showToast({
							text: "Selected players were successfully removed to the selected team",
							noIcon: true
						});
					}
				} catch (error) {
					console.log("Update players failed", error);
				} finally {
					setState({ loading: false });
				}
			},
			getCompetitionStats: async (clubId: string) => {
				setState({ loadingCompetitionStats: true });
				const { items } = await clubService.getCompetitionStats(clubId);
				setState({ loadingCompetitionStats: false });

				return items || [];
			},
			getTeamCompetitionStats: async (clubId: string, competitionId: string) => {
				setState({ loadingTeamCompetitionStats: true });
				const { items } = await clubService.getTeamCompetitionStats(clubId, competitionId);
				setState({ loadingTeamCompetitionStats: false });

				return items || [];
			},
			getPlayerCompetitionStats: async (clubId: string, competitionId: string) => {
				setState({ loadingPlayerCompetitionStats: true });
				const { items } = await clubService.getPlayerCompetitionStats(clubId, competitionId);
				setState({ loadingPlayerCompetitionStats: false });

				return items || [];
			},
			startValidateBulkUploadPlayers: async (type: UploadValidateTypes, url: string) => {
				try {
					setState({ playerBulkUpload: null });

					const data = await clubService.startValidateBulkUploadPlayers(type, url);
					if (data?.upload?.id) {
						setState({ playerBulkUpload: data.upload });
					}

					return data.upload || null;
				} catch (err) {
					console.error(err);
					showToast({ text: "Can't validate team roaster", noIcon: true });
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			setUserClubRoles: (userClubRoles: EntityRoles[]) => {
				setState({ userClubRoles });
			},
			setBulkUploadFileUrl: (bulkUploadFileUrl?: string) => {
				setState({ bulkUploadFileUrl });
			},
			getPaymentRequests: async ({
				clubId,
				after,
				...rest
			}: {
				clubId: string;
			} & PaginationParamsModel) => {
				setState({ loadingPaymentRequests: true });

				if (!after) {
					setState({ paymentRequests: [], nextListingPaymentRequestsToken: null });
				}

				try {
					const { nextToken, paymentRequests } = await getPaymentRequests({
						objectId: clubId,
						objectType: BackendEntityType.SoccerClub,
						after,
						...rest
					});

					setState(ctx => ({
						paymentRequests: !!after ? [...ctx.paymentRequests, ...(paymentRequests || [])] : paymentRequests,
						nextListingPaymentRequestsToken: nextToken
					}));

					return {
						paymentRequests,
						nextToken
					};
				} catch (error) {
					console.error(error);
					showToast({ text: "Failed to club load payments requests, please try again later." });
				} finally {
					setState({ loadingPaymentRequests: false });
				}
			},
			getPendingOrders: async ({
				clubId,
				after,
				...rest
			}: {
				clubId: string;
			} & PaginationParamsModel) => {
				setState({ loadingPendingOrders: true });

				if (!after) {
					setState({ paymentRequests: [], nextListingPendingOrdersToken: null });
				}

				try {
					const { nextToken, orders } = await getPendingOrders({
						objectId: clubId,
						objectType: BackendEntityType.SoccerClub,
						after,
						...rest
					});

					setState(ctx => ({
						pendingOrders: !!after ? [...ctx.pendingOrders, ...(orders || [])] : orders,
						nextListingPendingOrdersToken: nextToken
					}));

					return {
						orders,
						nextToken
					};
				} catch (error) {
					console.error(error);
					showToast({ text: "Failed to load club pending payment request orders, please try again later." });
				} finally {
					setState({ loadingPendingOrders: false });
				}
			},
			getPaymentHistory: async ({ id, after, limit }: { id: string } & PaginationParamsModel) => {
				setState({ loadingPaymentTransactions: true });

				try {
					if (!after) {
						setState({ paymentTransactions: [], paymentTransactionsNextToken: null });
					}

					const { transactions, nextToken } = await getPaymentHistory({
						objectId: id,
						objectType: BackendEntityType.SoccerClub,
						after,
						limit
					});

					setState(ctx => ({
						paymentTransactions: !!after ? [...ctx.paymentTransactions, ...(transactions || [])] : transactions,
						paymentTransactionsNextToken: nextToken
					}));
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ loadingPaymentTransactions: false });
				}
			},
			getPlayerTags: async ({
				id,
				ignoreLoading,
				ignoreStateUpdate
			}: {
				id: string;
			} & IgnoreStateUpdate) => {
				if (!ignoreLoading) {
					setState({ loadingPlayerTags: true });
				}

				try {
					const { playerTags } = await clubService.getPlayerTags(id);
					if (!ignoreStateUpdate) {
						setState({ playerTags });
					}
					return playerTags;
				} catch {
					showToast({
						text: "Can't get club player tags",
						noIcon: true
					});
					return [];
				} finally {
					if (!ignoreLoading) {
						setState({ loadingPlayerTags: false });
					}
				}
			},
			resendPlayersInvitation: async ({
				clubId,
				players,
				ignoreLoading
			}: {
				clubId: string;
				players: { id: string }[];
			} & IgnoreStateUpdate) => {
				if (!ignoreLoading) {
					setState({ loading: true });
				}

				try {
					const { invitations } = await clubService.resendPlayersInvitation(clubId, players);

					showToast({
						text: "Invitations are sent to selected player(s)",
						noIcon: true
					});

					return invitations;
				} catch {
					showToast({
						text: "Can't resend invitations to selected players",
						noIcon: true
					});
					return [];
				} finally {
					if (!ignoreLoading) {
						setState({ loading: false });
					}
				}
			},
			resetStore() {
				setInitial();
			}
		};
	}, [
		clubService,
		setState,
		showToast,
		setInitial,
		getPaymentRequests,
		isShowRoleToasts,
		getPaymentHistory,
		getPendingOrders
	]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useClub;

export enum EntityCoreInvitationRoles {
	ACCEPTOR = "role.core.invitation.acceptor",
	CREATOR = "role.core.invitation.creator"
	// INVITEE = "role.core.invitation.invitee"
}

export enum EntityCoreUserRoles {
	FOLLOWER = "role.core.user.follower",
	SELF = "role.core.user.self",
	ADMIN = "role.core.person.admin",
	GUARDIAN = "role.core.person.guardian"
}

export enum EntitySoccerClubRoles {
	COACH = "role.soccer.club.coach",
	CREATOR = "role.soccer.club.creator",
	FOLLOWER = "role.soccer.club.follower",
	MANAGER = "role.soccer.club.manager",
	OWNER = "role.soccer.club.owner",
	PLAYER = "role.soccer.club.player"
}

export enum EntitySoccerCompetitionRoles {
	CREATOR = "role.soccer.competition.creator",
	FOLLOWER = "role.soccer.competition.follower",
	SCOUT = "role.soccer.competition.scout"
}

export enum EntitySoccerFacilityRoles {
	CREATOR = "role.soccer.facility.creator",
	FOLLOWER = "role.soccer.facility.follower",
	MANAGER = "role.soccer.facility.manager",
	OWNER = "role.soccer.facility.owner"
}

export enum EntitySoccerFieldRoles {
	CREATOR = "role.soccer.field.creator",
	FOLLOWER = "role.soccer.field.follower"
}

export enum EntitySoccerGroupRoles {
	CREATOR = "role.soccer.group.creator",
	FOLLOWER = "role.soccer.group.follower"
}

export enum EntitySoccerLeagueRoles {
	CREATOR = "role.soccer.league.creator",
	FOLLOWER = "role.soccer.league.follower",
	OWNER = "role.soccer.league.owner",
	MANAGER = "role.soccer.league.manager"
}

export enum EntitySoccerMatchRoles {
	CREATOR = "role.soccer.match.creator",
	FOLLOWER = "role.soccer.match.follower",
	REFEREE = "role.soccer.match.referee"
}

export enum EntitySoccerRefereeRoles {
	FOLLOWER = "role.soccer.referee.follower",
	OWNER = "role.soccer.referee.owner"
}

export enum EntitySoccerRefereeOrgRoles {
	CREATOR = "role.soccer.reforg.creator",
	FOLLOWER = "role.soccer.reforg.follower",
	MANAGER = "role.soccer.reforg.manager",
	OWNER = "role.soccer.reforg.owner",
	REFEREE = "role.soccer.reforg.referee"
}

export enum EntitySoccerScheduleRoles {
	CREATOR = "role.soccer.schedule.creator"
}

export enum EntitySoccerSquadRoles {
	PLAYER = "role.soccer.squad.player"
}

export enum EntitySoccerTeamRoles {
	COACH = "role.soccer.team.coach",
	CREATOR = "role.soccer.team.creator",
	FOLLOWER = "role.soccer.team.follower",
	PLAYER = "role.soccer.team.player"
}

export enum EntitySoccerUserRoles {
	OWNER = "role.soccer.user.owner"
}

export type EntityCoreRoles = EntityCoreInvitationRoles | EntityCoreUserRoles;

export type EntitySoccerRoles =
	| EntitySoccerClubRoles
	| EntitySoccerCompetitionRoles
	| EntitySoccerFacilityRoles
	| EntitySoccerFieldRoles
	| EntitySoccerGroupRoles
	| EntitySoccerLeagueRoles
	| EntitySoccerMatchRoles
	| EntitySoccerRefereeRoles
	| EntitySoccerRefereeOrgRoles
	| EntitySoccerScheduleRoles
	| EntitySoccerSquadRoles
	| EntitySoccerTeamRoles
	| EntitySoccerUserRoles;

export type EntityRoles = EntityCoreRoles | EntitySoccerRoles;

export enum EntityTypes {
	CORE_USER = "core/user",
	SOCCER_CLUB = "soccer/club",
	SOCCER_COMPETITION = "soccer/competition",
	SOCCER_FACILITY = "soccer/facility",
	SOCCER_FIELD = "soccer/field",
	SOCCER_GROUP = "soccer/group",
	SOCCER_LEAGUE = "soccer/league",
	SOCCER_MATCH = "soccer/match",
	SOCCER_REFEREE_ORGANIZATION = "soccer/refereeOrganization",
	SOCCER_SQUAD = "soccer/squad",
	SOCCER_TEAM = "soccer/team"
}

export interface FBEntityRoleDocModel {
	entityId: string;
	entityType: EntityTypes;
	role: EntityRoles;
}

import { useEffect, useState } from "react";

import { useJsApiLoader } from "@react-google-maps/api";

import { AppConfig } from "config";

import type { LocationInputModel } from "shared/types";

const libraries = ["places"] as ["places"];

const useSearchForLocation = (query: string) => {
	useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: AppConfig.GLOBAL_CONSTANTS.GOOGLE_MAPS_KEY,
		libraries,
		region: "EN",
		language: "EN"
	});

	const [places, setPlaces] = useState<LocationInputModel[]>([]);

	useEffect(() => {
		if (query && google) {
			const places = new google.maps.places.PlacesService(document.createElement("div"));

			places.textSearch({ query: query }, (results, status) => {
				if (status === google?.maps.places.PlacesServiceStatus.OK && !!results?.length) {
					setPlaces(
						results.map(r => ({
							photo: r?.photos?.length ? (r.photos[0] as google.maps.places.PlacePhoto)!.getUrl({}) : undefined,
							label: r.name || "",
							address: r.formatted_address || "",
							value: `${r.geometry?.location?.lat() || 0}lng${r.geometry?.location?.lng() || 0}`,
							lat: r.geometry?.location?.lat() || 0,
							lng: r.geometry?.location?.lng() || 0
						}))
					);
				}
			});
		}
	}, [query]);

	return places;
};

export default useSearchForLocation;

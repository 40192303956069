import { useMemo } from "react";

import { z } from "zod";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type {
	CreatePostCommentParamModel,
	CreatePostParamModel,
	HashtagModel,
	PaginationParamsModel,
	PaginationTokenType,
	PostCommentModel,
	PostModel,
	PostReactionModel,
	PostReactionStatisticModel,
	PostReactionTypes,
	UpdatePostParamModel
} from "shared/types";
import {
	hashtagResolver,
	paginationTokenResolver,
	postCommentResolver,
	postReactionResolver,
	postReactionStatisticResolver,
	postResolver
} from "shared/types";

import BaseConverter from "./converters/baseConverter";

const usePostApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createPost: async (postInfo: CreatePostParamModel) => {
				return BaseConverter.convert<{ post: Partial<PostModel> }>(
					await actions.post(API_URLS.POST.CREATE, postInfo, {
						resolvers: [
							{
								resolver: postResolver.partial(),
								fields: ["post"]
							}
						]
					})
				);
			},
			updatePost: async (postInfo: UpdatePostParamModel) => {
				return BaseConverter.convert<{ post: Partial<PostModel> }>(
					await actions.post(API_URLS.POST.UPDATE, postInfo, {
						resolvers: [
							{
								resolver: postResolver.partial(),
								fields: ["post"]
							}
						]
					})
				);
			},
			getFeedList: async ({
				creatorId,
				entityId,
				before,
				after,
				limit
			}: { creatorId?: string; entityId?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					posts: PostModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.POST.GET_POSTS,
						{
							"creator-id": creatorId,
							"entity-id": entityId,
							limit,
							after,
							before
						},
						{
							resolvers: [
								{
									resolver: postResolver,
									fields: ["posts"]
								},
								{
									resolver: paginationTokenResolver,
									fields: ["nextToken", "previousToken"]
								}
							]
						}
					)
				);
			},
			reactOnPost: async (postId: string, reaction: PostReactionModel) => {
				return BaseConverter.convert<{ post: { id: string } }>(
					await actions.post(API_URLS.POST.REACT, {
						postId,
						reaction
					})
				);
			},
			unreactOnPost: async (postId: string) => {
				return BaseConverter.convert<{ post: { id: string } }>(
					await actions.post(API_URLS.POST.UNREACT, {
						postId
					})
				);
			},
			getPostReactionsList: async ({
				postId,
				type,
				before,
				after,
				limit
			}: { postId: string; type?: PostReactionTypes } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					reactions: PostReactionModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.POST.GET_REACTIONS,
						{
							"post-id": postId,
							type,
							limit,
							after,
							before
						},
						{
							resolvers: [
								{
									resolver: postReactionResolver,
									fields: ["reactions"]
								},
								{
									resolver: paginationTokenResolver,
									fields: ["nextToken", "previousToken"]
								}
							]
						}
					)
				);
			},
			getPostReactionStatistic: async (postId: string) => {
				return BaseConverter.convert<{ reactions: PostReactionStatisticModel[] }>(
					await actions.get(
						API_URLS.POST.GET_REACTIONS_STATISTIC,
						{
							"post-id": postId
						},
						{
							resolvers: [
								{
									resolver: postReactionStatisticResolver,
									fields: ["reactions"]
								}
							]
						}
					)
				);
			},
			getHashtagList: async ({ search, before, after, limit }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					hashtags: HashtagModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.POST.GET_HASHTAGS,
						{
							search,
							limit,
							after,
							before
						},
						{
							resolvers: [
								{
									resolver: hashtagResolver,
									fields: ["hashtags"]
								}
								// {
								// 	resolver: paginationTokenResolver,
								// 	fields: ["nextToken"]
								// }
							]
						}
					)
				);
			},
			createComment: async (commentInfo: CreatePostCommentParamModel) => {
				return BaseConverter.convert<{ comment: PostCommentModel }>(
					await actions.post(API_URLS.POST.CREATE_COMMENT, commentInfo, {
						resolvers: [
							{
								resolver: postResolver,
								fields: ["comment"]
							}
						]
					})
				);
			},
			getPostInfo: async ({ postId, after, limit }: { postId: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{ post: PostModel }>(
					await actions.get(
						API_URLS.POST.GET,
						{
							["post-id"]: postId,
							limit,
							after
						},
						{
							resolvers: [
								{
									resolver: postResolver,
									fields: ["post"]
								}
							]
						}
					)
				);
			},
			getPostComments: async ({ parentId, before, after, limit }: { parentId: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					comments: PostModel[];
					commentCount: number;
					nextCommentsToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.POST.GET_COMMENTS,
						{
							["parent-id"]: parentId,
							limit,
							before,
							after
						},
						{
							resolvers: [
								{
									resolver: postCommentResolver,
									fields: ["comments"]
								},
								{
									resolver: z.number(),
									fields: ["commentCount"]
								},
								{
									resolver: paginationTokenResolver,
									fields: ["nextCommentsToken"]
								}
							]
						}
					)
				);
			},
			deletePost: async (postId: string) => {
				return BaseConverter.convert<{ post: { id: string } }>(
					await actions.post(API_URLS.POST.REMOVE, {
						postId
					})
				);
			},
			deleteComment: async (commentId: string) => {
				return BaseConverter.convert<{ comment: { id: string } }>(
					await actions.post(API_URLS.POST.REMOVE_COMMENT, {
						commentId
					})
				);
			}
		}),
		[actions]
	);
};

export default usePostApiService;

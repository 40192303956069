import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import type { CreateClubPlayerModel } from "shared/types";
import { GenderValEnum } from "shared/types";
import { TableColumnFields } from "shared/types/BulkPlayerTable";
import { getDateTime } from "utils/getDateTime";
import { validateEmail, validateNonSpecial, validatePositiveNumber } from "utils/serviceUtils/validators";

import type { TablePlayerData } from "../Components/BulkPlayerTable";

const useBulkPlayerTableValidation = (isTeamImport = false) => {
	const { t } = useTranslation();

	const requiredFields = useMemo(
		(): {
			[field in TableColumnFields]: boolean;
		} => ({
			[TableColumnFields.INDEX]: false,
			[TableColumnFields.FIRSTNAME]: true,
			[TableColumnFields.LASTNAME]: false,
			[TableColumnFields.EMAIL]: false,
			[TableColumnFields.BIRTHDATE]: true,
			[TableColumnFields.GENDER]: true,
			[TableColumnFields.JERSEY_NUMBER]: isTeamImport
		}),
		[isTeamImport]
	);

	const methods = useMemo(() => {
		return {
			validateFirstname: (val: string, showErr = false) => {
				if (!val && requiredFields[TableColumnFields.FIRSTNAME]) {
					return showErr ? t("error:is_required", { field: t("player:first_name") }) : false;
				}

				if (val?.length < 2) {
					return showErr ? t("error:min_n_required", { count: 2 }) : false;
				}

				if (!!val?.length) {
					return validateNonSpecial(val)
						? showErr
							? ""
							: true
						: showErr
							? t("error:invalid", { field: t("player:first_name") })
							: false;
				}

				return showErr ? "" : true;
			},
			validateLastname: (val: string, showErr = false) => {
				if (!val && requiredFields[TableColumnFields.LASTNAME]) {
					return showErr ? t("error:is_required", { field: t("player:last_name") }) : false;
				}

				if (!!val?.length) {
					return validateNonSpecial(val)
						? showErr
							? ""
							: true
						: showErr
							? t("error:invalid", { field: t("player:last_name") })
							: false;
				}
				return showErr ? "" : true;
			},
			validateEmailAddress: (val: string, showErr = false) => {
				if (!val && requiredFields[TableColumnFields.EMAIL]) {
					return showErr ? t("error:is_required", { field: t("email") }) : false;
				}

				if (!!val?.length) {
					return validateEmail(val)
						? showErr
							? ""
							: true
						: showErr
							? t("error:invalid", { field: t("email") })
							: false;
				}
				return showErr ? "" : true;
			},
			validateDate: (val: string, showErr = false) => {
				if (!val && requiredFields[TableColumnFields.BIRTHDATE]) {
					return showErr ? t("error:is_required", { field: t("player:date_of_birth") }) : false;
				}

				if (!!val?.length) {
					// if (val.length < 8) {
					// 	return showErr ? t("error:invalid", { field: t("player:date_of_birth") }) : false;
					// }

					const date = getDateTime(val);
					return date?.isValid
						? showErr
							? ""
							: true
						: showErr
							? `${t("error:invalid", { field: t("player:date_of_birth") })}. ${t("date:valid_format")}: ${t("date:MM_DD_YYYY")}`
							: false;
				}
				return showErr ? "" : true;
			},
			validateGender: (val: string, showErr = false) => {
				if (!val && requiredFields[TableColumnFields.GENDER]) {
					return showErr ? t("error:is_required", { field: t("gender") }) : false;
				}

				if (![GenderValEnum.MALE, GenderValEnum.FEMALE].includes(val.toUpperCase() as GenderValEnum)) {
					return showErr ? t("error:invalid", { field: t("gender") }) : false;
				}

				return showErr ? "" : true;
			},
			validateJersey: (val: string, showErr = false) => {
				if (!val && requiredFields[TableColumnFields.JERSEY_NUMBER]) {
					return showErr ? t("error:is_required", { field: t("player:jersey_number") }) : false;
				}

				if (!!val?.length) {
					return validatePositiveNumber(val)
						? showErr
							? ""
							: true
						: showErr
							? t("error:invalid", { field: t("player:jersey_number") })
							: false;
				}
				return showErr ? "" : true;
			}
		};
	}, [requiredFields, t]);

	const checkRowStatus = useCallback(
		(rowData: TablePlayerData): "default" | "success" | "warning" | "error" => {
			let rowValidation: "default" | "success" | "warning" | "error" = "default";

			const isEmptyRow = !Object.values(rowData).some(x => x !== undefined);

			if (!isEmptyRow) {
				for (const field of Object.keys(requiredFields)) {
					const val = rowData[field]?.trim();

					if (requiredFields[field] && !val) {
						rowValidation = "error";
						break;
					}

					if (val !== undefined) {
						switch (field) {
							case TableColumnFields.FIRSTNAME:
								if (!methods.validateFirstname(val)) {
									rowValidation = "warning";
									continue;
								}
								break;

							case TableColumnFields.LASTNAME:
								if (!methods.validateLastname(val)) {
									rowValidation = "warning";
									continue;
								}
								break;

							case TableColumnFields.EMAIL:
								if (!methods.validateEmailAddress(val)) {
									rowValidation = "warning";
									continue;
								}
								break;

							case TableColumnFields.BIRTHDATE:
								if (!methods.validateDate(val)) {
									rowValidation = "warning";
									continue;
								}
								break;

							case TableColumnFields.GENDER:
								if (!methods.validateGender(val)) {
									rowValidation = "warning";
									continue;
								}
								break;

							case TableColumnFields.JERSEY_NUMBER:
								if (!methods.validateJersey(val)) {
									rowValidation = "warning";
									continue;
								}
								break;
						}
					}
				}

				if (rowValidation === "default") {
					rowValidation = "success";
				}
			}

			return rowValidation;
		},
		[requiredFields, methods]
	);

	const getValidPlayers = useCallback(
		(tableData: TablePlayerData[]) => {
			const correctPlayerRecords: Omit<CreateClubPlayerModel, "teams">[] = [];

			const requiredFieldKeys = Object.keys(requiredFields).filter(key => requiredFields[key]);
			tableData.forEach(row => {
				const playerInfo: Omit<CreateClubPlayerModel, "teams" | "gender"> & {
					gender: GenderValEnum | null;
				} = {
					firstName: "",
					lastName: "",
					email: "",
					birthDate: "",
					gender: null,
					jerseyNumber: null
				};
				let canBeImported =
					Object.values(row).some(x => x !== undefined) && requiredFieldKeys.every(key => row?.[key] !== undefined);

				if (canBeImported) {
					for (const field of Object.keys(row)) {
						const cellVal = row[field] || null;

						const isRequiredField = requiredFields?.[field] || false;

						if (isRequiredField && !cellVal) {
							canBeImported = false;
							break;
						} else {
							let isInvalid = false;

							switch (field) {
								case TableColumnFields.FIRSTNAME:
									isInvalid = !methods.validateFirstname(cellVal);

									if (!isInvalid) {
										playerInfo.firstName = cellVal;
									} else if (isRequiredField) {
										canBeImported = false;
									}
									break;

								case TableColumnFields.LASTNAME:
									isInvalid = !methods.validateLastname(cellVal);

									if (!isInvalid) {
										playerInfo.lastName = cellVal;
									} else if (isRequiredField) {
										canBeImported = false;
									}
									break;

								case TableColumnFields.EMAIL:
									isInvalid = !methods.validateEmailAddress(cellVal);

									if (!isInvalid) {
										playerInfo.email = cellVal;
									} else if (isRequiredField) {
										canBeImported = false;
									}
									break;

								case TableColumnFields.BIRTHDATE:
									isInvalid = !methods.validateDate(cellVal);

									if (!isInvalid) {
										playerInfo.birthDate = getDateTime(cellVal).toISODate()!;
									} else if (isRequiredField) {
										canBeImported = false;
									}
									break;

								case TableColumnFields.GENDER:
									let properCellVal = cellVal;
									if (cellVal?.toUpperCase() === GenderValEnum.MALE) {
										properCellVal = GenderValEnum.MALE;
									} else if (cellVal?.toUpperCase() === GenderValEnum.FEMALE) {
										properCellVal = GenderValEnum.FEMALE;
									} else {
										isInvalid = true;
									}

									if (!isInvalid) {
										playerInfo.gender = properCellVal as GenderValEnum;
									} else if (isRequiredField) {
										canBeImported = false;
									}
									break;

								case TableColumnFields.JERSEY_NUMBER:
									isInvalid = !methods.validateJersey(cellVal);

									if (!isInvalid) {
										playerInfo.jerseyNumber = Number(cellVal);
									} else if (isRequiredField) {
										canBeImported = false;
									}
									break;
							}

							if (!canBeImported) {
								break;
							}
						}
					}

					if (canBeImported) {
						correctPlayerRecords.push(playerInfo as Omit<CreateClubPlayerModel, "teams">);
					}
				}
			});

			return correctPlayerRecords;
		},
		[methods, requiredFields]
	);

	const getData = useCallback(() => {
		return {
			requiredFields
		};
	}, [requiredFields]);

	return { ...methods, getData, checkRowStatus, getValidPlayers };
};

export default useBulkPlayerTableValidation;

import config from "config/appConfig";

import type { InvitationModel, PaginationTokenType } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.INVITATION_DATA}`;

export interface Values {
	tempInvitation?: Partial<InvitationModel>;
	loading: boolean;
	invitations: InvitationModel[];
	nextToken: PaginationTokenType;
	previousToken: PaginationTokenType;
	inviting: boolean;
}

export const initialState: IStateProps<Values> = {
	tempInvitation: undefined,
	loading: false,
	nextToken: null,
	previousToken: null,
	invitations: [],
	inviting: false
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

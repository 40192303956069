import appConfig from "config/appConfig";
import i18n from "i18next";
// import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { LocalisationEntities, SupportedLanguages } from "shared/types";

const namespaces = [
	LocalisationEntities.COMMON,
	LocalisationEntities.FEED,
	LocalisationEntities.PLAYER,
	LocalisationEntities.DATE,
	LocalisationEntities.ERROR,
	LocalisationEntities.MATCH,
	LocalisationEntities.INVITATION,
	LocalisationEntities.PAYMENT,
	LocalisationEntities.FACILITY,
	LocalisationEntities.MESSAGING,
	LocalisationEntities.PROFILE,
	LocalisationEntities.COMPETITION,
	LocalisationEntities.ADMIN
];

i18n
	// .use(Backend)
	.use(initReactI18next)
	.init({
		lng: SupportedLanguages.ENGLISH,
		debug: appConfig.GLOBAL_CONSTANTS.ENV_STAGE === "dev" || appConfig.GLOBAL_CONSTANTS.ENV_STAGE === "local",
		fallbackLng: SupportedLanguages.ENGLISH,
		ns: namespaces,
		defaultNS: namespaces[0],
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: true
		},
		initImmediate: false,
		ignoreJSONStructure: false
		// backend: {
		// 	loadPath: "/i18n/{{lng}}/{{ns}}.json",
		// 	referenceLng: SupportedLanguages.ENGLISH
		// }
	});

export const changeLanguage = async (lng: SupportedLanguages) => {
	const promises: Promise<JSON>[] = [];

	namespaces.forEach(ns => {
		promises.push(import(`../assets/i18n/${lng}/${ns}.json`));
	});

	const translations = await Promise.all(promises);

	translations.forEach((t, i) => {
		i18n.addResourceBundle(lng, namespaces[i]!, t, true, true);
	});

	await i18n.changeLanguage(lng);
};

// Init EN by default
changeLanguage(SupportedLanguages.ENGLISH);

export default i18n;

import { z } from "zod";

import type { DropdownOptionModel } from "shared/uikit/Input/Dropdown";

import { competitionResolver } from "./CompetitionModel";
import type { LogoModel } from "./CreateTypes";
import { baseCreateType, logoResolver } from "./CreateTypes";
import { GenderValEnum } from "./GenderEnum";
import { InvitationStatus } from "./Invitation";
import { locationModel } from "./LocationModel";
import type { MediaModel } from "./MediaModel";
import { RefereeRoleEnum, refereeResolver } from "./OrganizationModel";
import { paginationTokenResolver } from "./Search";
import { fullTeamResolver } from "./TeamModel";

export enum SoccerPlayerPositionEnum {
	GK = "GK",
	RB = "RB",
	CB = "CB",
	LB = "LB",
	CDM = "CDM",
	RM = "RM",
	CM = "CM",
	LM = "LM",
	CAM = "CAM",
	RW = "RW",
	ST = "ST",
	LW = "LW"
}

export enum UserRoleEnum {
	STRANGER = "STRANGER",
	USER = "USER",
	SOCCER_USER = "SOCCER_USER",
	SOCCER_CLUB_MANAGER = "SOCCER_CLUB_MANAGER",
	SOCCER_LEAGUE_MANAGER = "SOCCER_LEAGUE_MANAGER",
	SOCCER_REFEREE_ORG_MANAGER = "SOCCER_REFEREE_ORG_MANAGER",
	SOCCER_REFEREE = "SOCCER_REFEREE",
	SOCCER_COACH = "SOCCER_COACH",
	SOCCER_PLAYER = "SOCCER_PLAYER",
	GLOBAL_ADMIN = "GLOBAL_ADMIN"
}

export const userBaseModel = z.object({
	avatar: logoResolver.nullable(),
	id: z.string(),
	name: z.string().optional(),
	shortName: z.string().optional()
});

export type UserBaseModel = z.infer<typeof userBaseModel>;

export const signedCookieResolver = z.object({
	"CloudFront-Key-Pair-Id": z.string(),
	"CloudFront-Policy": z.string(),
	"CloudFront-Signature": z.string()
});

export type SignedCookieModel = z.infer<typeof signedCookieResolver>;

export const userProfileEmailResolver = z.object({
	address: z.string().email(),
	verified: z.boolean(),
	primary: z.boolean()
});

export type UserProfileEmailModel = z.infer<typeof userProfileEmailResolver>;

export const userProfileResolver = baseCreateType.pick({ id: true, location: true, auth: true, about: true }).extend({
	name: z.string().optional().nullable(),
	shortName: z.string().nullable(),
	firstName: z.string().nullable().optional(),
	lastName: z.string().nullable().optional(),
	birthDate: z.string().nullable(),
	gender: z.nativeEnum(GenderValEnum).nullable(),
	avatar: logoResolver.nullable(),
	onboarded: z.boolean().nullable().optional(),
	location: locationModel.nullable().optional(),
	preferredFoot: z.string().nullable().optional(),
	preferredSoccerPosition: z.nativeEnum(SoccerPlayerPositionEnum).nullable().optional(),
	country: z.string().nullable().optional(),
	isAdmin: z.boolean().optional(),
	height: z
		.object({
			value: z.number(),
			unit: z.enum(["METER", "FOOT"])
		})
		.nullable()
		.optional(),
	emails: userProfileEmailResolver.array().optional().nullable()
});

export const followedUsersResolver = z.object({
	nextToken: paginationTokenResolver,
	previousToken: paginationTokenResolver,
	people: baseCreateType
		.pick({ id: true, location: true, name: true })
		.extend({
			avatar: logoResolver.nullable()
		})
		.array()
});

// Old API method format
export const userResolver = baseCreateType.pick({ auth: true }).extend({
	loginId: z.string().nullable().optional(),
	roles: z.nativeEnum(UserRoleEnum).array().optional(),
	personId: z.string().nullable().optional(),
	username: z.string().nullable().optional(),
	tokens: z
		.object({
			access: z.object({
				token: z.string(),
				payload: z.object({
					auth_time: z.number(),
					client_id: z.string(),
					"cognito:groups": z.string().array(),
					exp: z.number(),
					iat: z.number(),
					iss: z.string(),
					jti: z.string(),
					origin_jti: z.string(),
					scope: z.string(),
					sub: z.string(),
					token_use: z.string(),
					username: z.string(),
					version: z.number()
				})
			})
		})
		.nullable()
		.optional(),
	claims: z
		.object({
			"vyoo-sports-soccer:club-id": z.string().optional(),
			"vyoo-sports-soccer:league-id": z.string().optional(),
			"vyoo-sports-soccer:ref-org-id": z.string().optional(),
			"vyoo-sports-soccer:referee-id": z.string().optional(),
			"vyoo-sports:login-id": z.string().optional(),
			"vyoo-sports:user-id": z.string().optional()
		})
		.nullable()
		.optional(),
	signedCookie: signedCookieResolver.nullable().optional(),
	person: userProfileResolver,
	hasGuardians: z.boolean().optional()
});

export const searchPersonResolver = userProfileResolver
	.pick({ id: true, name: true, shortName: true, avatar: true })
	.extend({
		age: z.number().nullable(),
		clubs: z.array(baseCreateType.pick({ id: true, logo: true, name: true })),
		reforgs: z.array(baseCreateType.pick({ id: true, logo: true, name: true }))
	});

export type SearchPersonModel = z.infer<typeof searchPersonResolver>;

export enum GuardianKind {
	father = "FATHER",
	mother = "MOTHER",
	guardian = "GUARDIAN"
}

const childResolver = userProfileResolver
	.pick({
		id: true,
		avatar: true,
		birthDate: true,
		firstName: true,
		gender: true,
		lastName: true,
		name: true,
		shortName: true
	})
	.extend({
		email: z.string().nullable(),
		status: z.nativeEnum(InvitationStatus),
		guardianKind: z.nativeEnum(GuardianKind)
	});

export const childListResolver = z.object({
	person: z.object({
		children: z.array(childResolver)
	})
});

export type ChildModel = z.infer<typeof childResolver>;
export type ChildListModel = z.infer<typeof childListResolver>;

export type UserModel = z.infer<typeof userResolver>;

export type UserProfileModel = z.infer<typeof userProfileResolver>;

export const userOverviewTeamResolver = z.object({
	jerseyNumber: z.number().nullable(),
	team: fullTeamResolver.pick({ id: true, name: true, logo: true, club: true })
});

export type UserOverviewTeamModel = z.infer<typeof userOverviewTeamResolver>;

export const userOverviewPlayerResolver = z.object({
	teams: z.array(userOverviewTeamResolver)
});

export const userOverviewRefereeCompetitionResolver = z.lazy(() =>
	competitionResolver.pick({ id: true, name: true, logo: true, league: true }).extend({
		reforgs: z.array(refereeResolver.pick({ id: true, name: true, status: true })),
		roles: z.array(
			z.object({
				role: z.nativeEnum(RefereeRoleEnum),
				matchCount: z.number()
			})
		)
	})
);

export type UserOverviewRefereeCompetitionModel = z.infer<typeof userOverviewRefereeCompetitionResolver>;

export const userOverviewRefereeResolver = z.object({
	competitions: z.array(userOverviewRefereeCompetitionResolver),
	reforgs: z.array(
		refereeResolver.pick({ id: true, name: true }).extend({
			logo: logoResolver.nullable().optional()
		})
	)
});

export const userOverviewCoachResolver = z.object({
	teams: z.array(fullTeamResolver.pick({ id: true, logo: true, name: true, status: true, club: true }))
});

export const userOverviewResolver = userProfileResolver
	.pick({
		id: true,
		name: true,
		firstName: true,
		lastName: true,
		shortName: true,
		gender: true,
		birthDate: true,
		location: true,
		about: true,
		avatar: true,
		preferredFoot: true,
		preferredSoccerPosition: true,
		country: true,
		height: true
	})
	.extend({
		location: locationModel.nullable().optional(),
		player: userOverviewPlayerResolver.optional().nullable(),
		referee: userOverviewRefereeResolver.optional().nullable(),
		coach: userOverviewCoachResolver.optional().nullable(),
		onboarded: z.boolean().optional()
	});

export type UserOverviewModel = z.infer<typeof userOverviewResolver>;

export const newConnectionResolver = z
	.object({
		id: z.string(),
		status: z.nativeEnum(InvitationStatus),
		initiatorId: z.string().optional(),
		connectedUserId: z.string().optional()
	})
	.nullable();

export type NewConnectionResponseModel = z.infer<typeof newConnectionResolver>;

export const searchConnectionResolver = baseCreateType.pick({ id: true }).extend({
	name: z.string().optional().nullable(),
	shortName: z.string().nullable(),
	avatar: logoResolver.nullable()
});

export type SearchConnectionModel = z.infer<typeof searchConnectionResolver>;

export type UpdateUserProfileModelProps = Partial<Omit<UserProfileModel, "avatar" | "height" | "country">> & {
	avatar?: string | null | LogoModel | MediaModel;
	height?: number | null;
	country?: DropdownOptionModel | string | null;
};

const personEmailInfo = z.object({
	id: z.string(),
	primary: z.boolean(),
	verified: z.boolean(),
	address: z.string()
});

export type PersonEmailInfoModel = z.infer<typeof personEmailInfo>;

const personEmailObject = z.object({
	id: z.string(),
	emails: z.array(personEmailInfo)
});

export const userEmailsObjects = z.object({
	person: personEmailObject,
	children: z.array(personEmailObject)
});

export type UserEmailsModel = z.infer<typeof userEmailsObjects>;

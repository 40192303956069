import config from "config/appConfig";

import type { MatchModel, PaginationTokenType, PlayerInfoModel, PlayerOverviewModel } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.PLAYER_DATA}`;

export interface Values {
	matches?: {
		playerId: string;
		matches: MatchModel[];
	};
	loading: boolean;
	loadingMatches: boolean;
	beforeToken: PaginationTokenType;
	afterToken: PaginationTokenType;
	info?: PlayerInfoModel;
	overview?: PlayerOverviewModel;
	loadingOverview: boolean;
	loadingPlayerGroups: boolean;
	loadingStats: boolean;
}

export const initialState: IStateProps<Values> = {
	matches: undefined,
	loading: false,
	loadingMatches: false,
	beforeToken: null,
	afterToken: null,
	info: undefined,
	overview: undefined,
	loadingPlayerGroups: false,
	loadingStats: false,
	loadingOverview: false
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { useCallback, useMemo } from "react";

import { signOut } from "@aws-amplify/auth";

import { useNavigate } from "react-router-dom";

import type {
	EntityFollowListEnum,
	EntityRoles,
	PaginationParamsModel,
	PostReactionSkins,
	RSVPStatusEnum,
	UpdateUserProfileModelProps,
	UserEducationModel,
	UserExperienceModel,
	UserOverviewModel,
	UserProfileModel,
	UserSocialLinkedAccountModel
} from "shared/types";
import { TemporalDependencyTypes } from "shared/types";

import { capitalize } from "utils/serviceUtils/helpers";

import usePlatformEnv from "./usePlatformEnv";
import useToast from "./useToast";

import { getPaths } from "../../core/getPaths";
import { useUserStore } from "../contexts";

import { useUserApiService } from "../services";

const { auth: authRoutes } = getPaths();

const useUser = () => {
	const userService = useUserApiService();

	const navigate = useNavigate();

	const store = useUserStore();
	const { setState, setInitial } = useUserStore();

	const { showToast } = useToast();

	const { isShowRoleToasts } = usePlatformEnv();

	const methods = useMemo(() => {
		return {
			setUser(user: any) {
				setState({ user });
			},
			async getUserInfo() {
				try {
					setState({ loadingUserInfo: true });
					const user = await userService.getUserInfo();
					setState({ user, myProfile: user.person });

					if (user?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for user: ${user.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return user;
				} catch (error) {
					console.log(error);
				} finally {
					setState({ loadingUserInfo: false });
				}
			},
			async getProfileInfo(userId: string, ignoreLoading?: boolean, ignoreStateUpdate?: boolean) {
				if (!ignoreLoading) {
					setState({ loadingUserProfileInfo: true });
				}

				try {
					const { person, connection } = await userService.getUserProfileInfo(userId);

					if (!ignoreStateUpdate) {
						setState({ profile: person });
					}

					if (person?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for user: ${person.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return { person, connection };
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });

					return {
						person: null,
						connection: null
					};
				} finally {
					if (!ignoreLoading) {
						setState({ loadingUserProfileInfo: false });
					}
				}
			},
			async getOverview(userId: string, ignoreLoading?: boolean, ignoreStateUpdate?: boolean) {
				if (!ignoreLoading) {
					setState({ loadingOverview: true });
				}
				const { person } = await userService.getOverview(userId);
				if (!ignoreStateUpdate) {
					setState({ overview: person });
				}

				if (!ignoreLoading) {
					setState({ loadingOverview: false });
				}
				return person;
			},
			getFollowedUsers: async (params: { search?: string } & PaginationParamsModel) => {
				setState({ loadingFollowedUsers: true });
				const { people, nextToken } = await userService.getFollowedUsers(params);
				setState({ followedUsersNextToken: nextToken, loadingFollowedUsers: false });
				return people;
			},
			listMyEmails: async () => {
				setState({ loadingEmails: true });

				try {
					const emailsList = await userService.listMyEmails();
					setState({ emailsList });
					return emailsList;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return null;
				} finally {
					setState({ loadingEmails: false });
				}
			},
			async updateUserProfile(profile: Partial<UpdateUserProfileModelProps>, displayToast?: boolean) {
				try {
					setState({ updatingProfile: true });

					const { person } = await userService.updateUserProfile(profile);
					setState({ myProfile: person });

					displayToast && showToast({ text: "Profile updated successfully", noIcon: true });

					return person;
				} catch (error) {
					console.error(error);
					displayToast && showToast({ text: "Couldn't update profile, please try again" });
				} finally {
					setState({ updatingProfile: false });
				}
			},
			async updatePersonProfile(
				profile: Partial<UpdateUserProfileModelProps> & { personId: string },
				displayToast?: boolean
			) {
				try {
					setState({ updatingProfile: true });

					const { person } = await userService.updatePersonProfile(profile);

					displayToast && showToast({ text: "Person profile updated successfully", noIcon: true });

					return person;
				} catch (error) {
					console.error(error);
					displayToast && showToast({ text: "Couldn't update person profile, please try again" });
				} finally {
					setState({ updatingProfile: false });
				}
			},
			async followEntity(id: string, entityType: EntityFollowListEnum) {
				setState({ following: id });
				try {
					await userService.followEntity(id, entityType.toLowerCase());
					showToast({ text: `${capitalize(entityType)} was successfully followed`, noIcon: true });
				} catch {
					showToast({ text: `Couldn't follow ${capitalize(entityType)}, please try again later.`, noIcon: true });
				} finally {
					setState({ following: undefined });
				}
			},
			async getKids() {
				setState({ loadingMyKids: true });
				try {
					const { person } = await userService.getKids();
					setState({ myKids: person.children });
					return person.children;
				} catch {
					showToast({ text: "Couldn't fetch kids, please try again later.", noIcon: true });
					return [];
				} finally {
					setState({ loadingMyKids: false });
				}
			},
			async connectWithUser(connectedUserId: string) {
				try {
					setState({ connecting: connectedUserId });
					const { connection } = await userService.connectWithUser(connectedUserId);
					return connection;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't connect to user, please try again later.", noIcon: true });
				} finally {
					setState({ connecting: undefined });
				}
			},
			async rejectUserConnectionRequest(connectedUserId: string) {
				try {
					setState({ rejectingConnectedUserId: connectedUserId });
					const { connection } = await userService.rejectUserConnectionRequest(connectedUserId);
					return connection;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't reject connection request, please try again later.", noIcon: true });
				} finally {
					setState({ rejectingConnectedUserId: undefined });
				}
			},
			async cancelConnectionUserRequest(connectedUserId: string) {
				try {
					setState({ connecting: connectedUserId });
					const { connection } = await userService.cancelConnectionUserRequest(connectedUserId);
					return connection;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't cancel connection request, please try again later.", noIcon: true });
				} finally {
					setState({ connecting: undefined });
				}
			},
			async unfollowEntity(id: string, entityType: EntityFollowListEnum) {
				setState({ following: id });
				try {
					await userService.unfollowEntity(id, entityType.toLowerCase());
					showToast({ text: `${capitalize(entityType)} was successfully unfollowed`, noIcon: true });
				} catch {
					showToast({ text: `Couldn't unfollow ${capitalize(entityType)}, please try again later.`, noIcon: true });
				} finally {
					setState({ following: undefined });
				}
			},
			setReactionSkin(reactionSkin: PostReactionSkins) {
				setState({ reactionSkin });
			},
			searchConnections: async ({ limit = 10, after, ...rest }: { search?: string } & PaginationParamsModel) => {
				setState({ searchingConnections: true });

				if (!after) {
					setState({ searchedConnections: [], nextListingSearchConnectionsToken: null });
				}

				try {
					const { users, nextToken } = await userService.searchConnections({ limit, after, ...rest });
					setState(ctx => ({
						searchedConnections: !!after ? [...ctx.searchedConnections, ...users] : users,
						nextListingSearchConnectionsToken: nextToken
					}));
					return users;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingSearchConnectionsToken: null });
				} finally {
					setState({ searchingConnections: false });
				}
			},
			unsubscribeFromEmails: async () => {
				setState({ unsubscribingFromEmails: true });
				try {
					await userService.unsubscribeFromEmails();
					showToast({ text: "You have successfully unsubscribed from emails", noIcon: true });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ unsubscribingFromEmails: false });
				}
			},
			checkEmailAvailability: async (email: string) => {
				try {
					return await userService.checkEmailAvailability(email);
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return {
						available: false,
						login: "",
						email,
						providers: [],
						emailVerified: false,
						emailLogin: ""
					};
				}
			},
			findUsers: async (search?: string) => {
				const { people } = await userService.findUsers(search);
				return people;
			},
			confirmNewEmail: async (confirmationSessionId: string, confirmationCode: string) => {
				setState({ confirmingNewEmail: true });

				try {
					await userService.confirmNewEmail(confirmationSessionId, confirmationCode);
					showToast({ text: "Email was successfully confirmed", noIcon: true });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ confirmingNewEmail: false });
				}
			},
			addEmailAddress: async (email: string) => {
				try {
					await userService.addEmailAddress(email);
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				}
			},
			updateMyAttendance: async (eventId: string, attendance: RSVPStatusEnum) => {
				try {
					const attendanceInfo = await userService.updateMyAttendance(eventId, attendance);
					showToast({ text: "Your attendance was successfully updated", noIcon: true });
					return attendanceInfo;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return null;
				}
			},
			getPendingPaymentOrders: async ({ after, limit }: PaginationParamsModel) => {
				setState({ loadingPendingPaymentOrders: true });

				try {
					if (!after) {
						setState({ pendingPaymentOrders: [], pendingPaymentOrdersNextToken: null });
					}

					const { orders, nextToken } = await userService.getPendingOrders({ after, limit });

					setState(ctx => ({
						pendingPaymentOrders: !!after ? [...ctx.pendingPaymentOrders, ...(orders || [])] : orders,
						pendingPaymentOrdersNextToken: nextToken
					}));
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ loadingPendingPaymentOrders: false });
				}
			},
			getPaymentHistory: async ({ after, limit }: PaginationParamsModel) => {
				setState({ loadingPaymentTransactions: true });

				try {
					if (!after) {
						setState({ paymentTransactions: [], paymentTransactionsNextToken: null });
					}

					const { transactions, nextToken } = await userService.getPaymentHistory({ after, limit });

					setState(ctx => ({
						paymentTransactions: !!after ? [...ctx.paymentTransactions, ...(transactions || [])] : transactions,
						paymentTransactionsNextToken: nextToken
					}));
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ loadingPaymentTransactions: false });
				}
			},
			setMyProfile: (myProfile?: Partial<UserProfileModel>) => {
				setState(ctx => ({ ...ctx, myProfile: { ...ctx.myProfile, myProfile } }));
			},
			setUserPersonRoles: (userPersonRoles: EntityRoles[]) => {
				setState({ userPersonRoles });
			},
			setTempCredentials: (tempCredentials?: { username: string; email: string }) => {
				setState({ tempCredentials });
			},
			setRedirectUrl: (redirectUrl?: string) => {
				setState({ redirectUrl });
			},
			setManageProfileModal: (params: { open: boolean; person?: UserOverviewModel }) => {
				setState({ manageProfileModal: params });
			},
			checkNotificationUpdates: async () => {
				try {
					const data = await userService.checkNotificationUpdates();
					setState(ctx => ({
						temporalDependencyCheck: {
							...ctx.temporalDependencyCheck,
							...data
						}
					}));
				} catch (err) {
					console.error(err);
				}
			},
			updateNotificationTimeCheck: async (temporalDependencyTypes: { type: TemporalDependencyTypes }[]) => {
				userService.updateNotificationTimeCheck(temporalDependencyTypes);
				setState(ctx => {
					const updatedVal = { ...ctx.temporalDependencyCheck };
					for (const type of temporalDependencyTypes) {
						if (type.type === TemporalDependencyTypes.INVITATION_CHECK) {
							updatedVal.invitations = false;
						}

						if (type.type === TemporalDependencyTypes.NOTIFICATIONS_CHECK) {
							updatedVal.notifications = false;
						}

						if (type.type === TemporalDependencyTypes.CHATS_CHECK) {
							updatedVal.chats = false;
						}
					}

					return {
						temporalDependencyCheck: updatedVal
					};
				});
			},
			getPersonExperiences: async (personId: string) => {
				setState({ loadingPersonExperience: true });

				try {
					const { person } = await userService.getPersonExperiences(personId);
					setState({ personExperiences: person.experiences });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ personExperiences: [] });
				} finally {
					setState({ loadingPersonExperience: false });
				}
			},
			updateMyExperience: async (params: {
				add?: Omit<UserExperienceModel, "id">[];
				remove?: Pick<UserExperienceModel, "id">[];
				update?: (Partial<UserExperienceModel> & Pick<UserExperienceModel, "id">)[];
			}) => {
				setState({ updatingPersonExperience: true });

				try {
					await userService.updateMyExperience(params);
					showToast({ text: "Profile experience was updated successfully", noIcon: true });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ updatingPersonExperience: false });
				}
			},
			updatePersonExperience: async (params: {
				personId: string;
				add?: Omit<UserExperienceModel, "id">[];
				remove?: Pick<UserExperienceModel, "id">[];
				update?: (Partial<UserExperienceModel> & Pick<UserExperienceModel, "id">)[];
			}) => {
				setState({ updatingPersonExperience: true });

				try {
					await userService.updatePersonExperience(params);
					showToast({ text: "Person profile experience was updated successfully", noIcon: true });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ updatingPersonExperience: false });
				}
			},
			getPersonEducation: async (personId: string) => {
				setState({ loadingPersonEducation: true });

				try {
					const { person } = await userService.getPersonEducation(personId);
					setState({ personEducation: person.education });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ personEducation: [] });
				} finally {
					setState({ loadingPersonEducation: false });
				}
			},
			updateMyEducation: async (params: {
				add?: Partial<Omit<UserEducationModel, "id">>[];
				remove?: Pick<UserEducationModel, "id">[];
				update?: (Partial<UserEducationModel> & Pick<UserEducationModel, "id">)[];
			}) => {
				setState({ updatingPersonEducation: true });

				try {
					await userService.updateMyEducation(params);
					showToast({ text: "Profile education was updated successfully", noIcon: true });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ updatingPersonEducation: false });
				}
			},
			updatePersonEducation: async (params: {
				personId: string;
				add?: Partial<Omit<UserEducationModel, "id">>[];
				remove?: Pick<UserEducationModel, "id">[];
				update?: (Partial<UserEducationModel> & Pick<UserEducationModel, "id">)[];
			}) => {
				setState({ updatingPersonEducation: true });

				try {
					await userService.updatePersonEducation(params);
					showToast({ text: "Person profile education was updated successfully", noIcon: true });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ updatingPersonEducation: false });
				}
			},
			getPersonSocialLinkedAccounts: async (personId: string) => {
				setState({ loadingLinkedAccounts: true });

				try {
					const { person } = await userService.getPersonSocialLinkedAccounts(personId);
					setState({ personLinkedAccounts: person.linkedAccounts });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ personLinkedAccounts: [] });
				} finally {
					setState({ loadingLinkedAccounts: false });
				}
			},
			updatePersonSocialLinkedAccounts: async (params: {
				personId: string;
				add?: Partial<Omit<UserSocialLinkedAccountModel, "id">>[];
				remove?: Pick<UserSocialLinkedAccountModel, "id">[];
				update?: (Partial<UserSocialLinkedAccountModel> & Pick<UserSocialLinkedAccountModel, "id">)[];
			}) => {
				setState({ updatingPersonLinkedAccounts: true });

				try {
					await userService.updatePersonSocialLinkedAccounts(params);
					showToast({ text: "person profile social linked accounts were updated successfully", noIcon: true });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ updatingPersonLinkedAccounts: false });
				}
			},
			updateMySocialLinkedAccounts: async (params: {
				add?: Partial<Omit<UserSocialLinkedAccountModel, "id">>[];
				remove?: Pick<UserSocialLinkedAccountModel, "id">[];
				update?: (Partial<UserSocialLinkedAccountModel> & Pick<UserSocialLinkedAccountModel, "id">)[];
			}) => {
				setState({ updatingPersonLinkedAccounts: true });

				try {
					await userService.updateMySocialLinkedAccounts(params);
					showToast({ text: "Profile social linked accounts were updated successfully", noIcon: true });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ updatingPersonLinkedAccounts: false });
				}
			},
			deleteAccount: async () => {
				await userService.deleteAccount();
			},
			logout: async (redirectToLogout = true) => {
				await signOut();
				if (redirectToLogout) {
					navigate(authRoutes.logout.getPath());
				}
			},
			resetStore() {
				setInitial();
			}
		};
	}, [setState, userService, showToast, setInitial, isShowRoleToasts, navigate]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	const isSystemAdmin = useMemo(() => !!store?.myProfile?.isAdmin, [store?.myProfile?.isAdmin]);

	return { ...methods, getData, isSystemAdmin };
};

export default useUser;

import { API_URLS } from "shared/constants";
import { EntityFollowListEnum } from "shared/types";

export const getFollowEPURL = (entityType: EntityFollowListEnum) => {
	switch (entityType) {
		case EntityFollowListEnum.CLUB:
			return API_URLS.INVITATION.INVITE_CLUB_FOLLOWERS;
		case EntityFollowListEnum.COMPETITION:
			return API_URLS.INVITATION.INVITE_COMPETITION_FOLLOWERS;
		case EntityFollowListEnum.TEAM:
			return API_URLS.INVITATION.INVITE_TEAM_FOLLOWERS;
		case EntityFollowListEnum.LEAGUE:
			return API_URLS.INVITATION.INVITE_LEAGUE_FOLLOWERS;
		case EntityFollowListEnum.GROUP:
			return API_URLS.INVITATION.INVITE_GROUP_FOLLOWERS;
		case EntityFollowListEnum.USER:
		case EntityFollowListEnum.PLAYER:
			return API_URLS.INVITATION.INVITE_USER_FOLLOWERS;
	}
};

import { useLocation, useNavigate } from "react-router-dom";

import type { EntityRoles, TabsOpts } from "shared/types";

const useRoleBasedPages = ({
	tabs,
	roles,
	redirectTo
}: {
	tabs: TabsOpts[];
	roles: EntityRoles[];
	redirectTo: string;
}) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const currentPage = tabs.find(tab => tab.route === pathname);

	if (currentPage && currentPage.showFor && !currentPage.showFor.some(role => roles.includes(role as EntityRoles))) {
		navigate(redirectTo);
	}
};

export default useRoleBasedPages;

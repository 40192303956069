import { useCallback, useMemo } from "react";

import { useGlobalScrollStore } from "../contexts";

const clbList: { id: string; clbk: () => void }[] = [];

const useGlobalScroll = () => {
	const store = useGlobalScrollStore();
	const { setState } = useGlobalScrollStore();

	const methods = useMemo(
		() => ({
			subscribeToScrolledDownEvent: (id: string, clbk: () => void) => {
				clbList.push({ id, clbk });
			},
			unsubscribeToScrolledDownEvent: (id: string) => {
				const index = clbList.findIndex(x => x.id === id);
				if (index > -1) {
					clbList.splice(index, 1);
				}
			},
			pageScrolledDown: async () => {
				clbList.forEach(item => item.clbk());
			},
			disableCustomScroll: (disable: boolean) => {
				setState({ isDisabled: disable });
			}
		}),
		[setState]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useGlobalScroll;

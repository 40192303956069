import { z } from "zod";

export enum SocialLinkedAccountTypes {
	TWITTER = "Twitter",
	INSTAGRAM = "Instagram",
	YOUTUBE = "Youtube",
	DISCORD = "Discord",
	TIKTOK = "TikTok",
	FACEBOOK = "Facebook",
	HUDL = "Hudl",
	TRACE_UP = "TraceUp",
	STAT_SPORTS = "StatSports",
	PLAYER_MAKER = "PlayerMaker",
	VEO = "Veo",
	PIXEL_LOT = "PixelLot",
	OTHER = "Other"
}

export const userSocialLinkedAccountResolver = z.object({
	id: z.string(),
	type: z.nativeEnum(SocialLinkedAccountTypes),
	link: z.string()
});

export type UserSocialLinkedAccountModel = z.infer<typeof userSocialLinkedAccountResolver>;

export const personSocialLinkedAccountsResolver = z.object({
	person: z.object({
		id: z.string(),
		linkedAccounts: userSocialLinkedAccountResolver.array()
	})
});

import { useCallback, useMemo } from "react";

import { usePostStore } from "shared/contexts";

import { usePostApiService } from "shared/services";

import type {
	CreatePostCommentParamModel,
	CreatePostParamModel,
	PaginationParamsModel,
	PostModel,
	PostReactionModel,
	PostReactionTypes,
	UpdatePostParamModel
} from "shared/types";

import useToast from "./useToast";

const usePost = () => {
	const postService = usePostApiService();

	const { setState, setInitial, ...store } = usePostStore();

	const { showToast } = useToast();

	const methods = useMemo(
		() => ({
			getFeedList: async ({
				limit = 10,
				after,
				...rest
			}: { creatorId?: string; entityId?: string } & PaginationParamsModel) => {
				setState({ loadingPosts: true });

				if (!after) {
					setState({ posts: [], nextListingPostsToken: null });
				}

				try {
					const { posts, nextToken } = await postService.getFeedList({ limit, after, ...rest });
					setState(ctx => ({
						posts: !!after ? [...ctx.posts, ...posts] : posts,
						nextListingPostsToken: nextToken
					}));
					return posts;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingPostsToken: null });
				} finally {
					setState({ loadingPosts: false });
				}
			},
			addPostToFeed: (post: PostModel) => {
				setState(ctx => ({ posts: [post, ...ctx.posts] }));
			},
			createPost: async (postInfo: CreatePostParamModel) => {
				try {
					const { post } = await postService.createPost(postInfo);
					if (post) {
						showToast({
							text: "Post created",
							noIcon: true
						});
					}
					return post;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				}
			},
			updatePost: async (postInfo: UpdatePostParamModel) => {
				try {
					const { post } = await postService.updatePost(postInfo);
					if (post) {
						showToast({
							text: "Post updated",
							noIcon: true
						});
					}
					return post;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				}
			},
			reactOnPost: async (postId: string, reaction: PostReactionModel) => {
				try {
					const { post } = await postService.reactOnPost(postId, reaction);
					return !!post;
				} catch (err) {
					console.error(err);
				}
			},
			unreactOnPost: async (postId: string) => {
				try {
					const { post } = await postService.unreactOnPost(postId);
					return !!post;
				} catch (err) {
					console.error(err);
				}
			},
			getPostReactionsList: async ({
				limit = 10,
				...rest
			}: { postId: string; type?: PostReactionTypes } & PaginationParamsModel) => {
				try {
					return await postService.getPostReactionsList({ limit, ...rest });
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return null;
				}
			},
			getPostReactionStatistic: async (postId: string) => {
				try {
					const { reactions } = await postService.getPostReactionStatistic(postId);
					return reactions || [];
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return [];
				}
			},
			getHashtagList: async ({ limit = 10, after, ...rest }: { search?: string } & PaginationParamsModel) => {
				setState({ loadingHashtags: true });

				if (!after) {
					setState({ hashtags: [], nextListingHashtagsToken: null });
				}

				try {
					const { hashtags, nextToken } = await postService.getHashtagList({ limit, after, ...rest });
					setState(ctx => ({
						hashtags: !!after ? [...ctx.hashtags, ...hashtags] : hashtags,
						nextListingHashtagsToken: nextToken
					}));
					return hashtags;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingHashtagsToken: null });
				} finally {
					setState({ loadingHashtags: false });
				}
			},
			createComment: async (commentInfo: CreatePostCommentParamModel) => {
				try {
					const { comment } = await postService.createComment(commentInfo);
					if (comment) {
						showToast({
							text: "Comment created",
							noIcon: true
						});
					}
					return comment;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				}
			},
			getPostInfo: async ({ limit = 10, after, postId }: { postId: string } & PaginationParamsModel) => {
				try {
					const { post } = await postService.getPostInfo({ limit, after, postId });
					return {
						post,
						comments: post?.comments || [],
						nextToken: post?.nextCommentsToken
					};
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return {
						post: null,
						comments: [],
						nextToken: null
					};
				}
			},
			getPostComments: async ({ limit = 10, after, ...rest }: { parentId: string } & PaginationParamsModel) => {
				try {
					const { comments, commentCount, nextCommentsToken } = await postService.getPostComments({
						limit,
						after,
						...rest
					});
					return {
						comments: comments || [],
						commentCount: commentCount || 0,
						nextToken: nextCommentsToken || null
					};
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return {
						comments: [],
						commentCount: 0,
						nextToken: null
					};
				}
			},
			deletePost: async (postId: string) => {
				try {
					const { post } = await postService.deletePost(postId);
					if (post.id) {
						showToast({ text: "Post was successfully deleted", noIcon: true });
					}
					return post.id;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return null;
				}
			},
			deleteComment: async (commentId: string) => {
				try {
					const { comment } = await postService.deleteComment(commentId);
					if (comment.id) {
						showToast({ text: "Comment was successfully deleted", noIcon: true });
					}
					return comment.id;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return null;
				}
			},
			removePostFromFeed: async (postId: string) => {
				setState(ctx => ({ posts: ctx.posts.filter(x => x.id !== postId) }));
			},
			updatePostInFeed: async (updatedPost: PostModel) => {
				setState(ctx => ({ posts: ctx.posts.map(post => (post.id === updatedPost.id ? updatedPost : post)) }));
			},
			resetStore() {
				setInitial();
			}
		}),
		[postService, setState, showToast, setInitial]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default usePost;

import { z } from "zod";

import { CompetitionAdvancingStyles, baseCreateType, partialPaymentResolver } from "shared/types";

import { CompetitionTypes, TieBreakerEnums } from "./CompetitionModel";
import { logoResolver } from "./CreateTypes";
import { GenderEnum } from "./GenderEnum";
import { setupCategoryObject } from "./SetupEntityModel";

export const setupCompetitionObject = z.object({
	winPoints: z.number().nullable(),
	drawPoints: z.number().nullable(),
	lossPoints: z.number().nullable(),
	tieBreakerRules: z.array(z.nativeEnum(TieBreakerEnums)),
	ages: z.array(setupCategoryObject),
	genders: z.array(z.nativeEnum(GenderEnum)),
	levels: z.array(z.object({ name: z.string(), description: z.string().optional() })),
	competitionId: z.string().optional(),
	advancingStyle: z.nativeEnum(CompetitionAdvancingStyles).optional()
});

export enum CompetitionRegistrationTypes {
	OPEN = "OPEN",
	REGULAR = "REGULAR"
}

export enum CompetitionOpenRegistrationTypes {
	PLAYER = "PLAYER",
	TEAM = "TEAM"
}

export const createCompetitionObject = setupCompetitionObject.extend({
	logo: logoResolver.nullable(),
	location: z.object({ value: z.string(), label: z.string() }),
	leagueId: z.string(),
	type: z.nativeEnum(CompetitionTypes),
	name: z.string(),
	description: z.string().nullable(),
	startsOn: z.string(),
	endsOn: z.string(),
	registrationEndsOn: z.string().nullable(),
	paymentEndsOn: z.string().nullable(),
	website: z.string().nullable(),
	private: z.boolean(),
	goalDifferential: z.number().nullable(),
	registrationType: z.nativeEnum(CompetitionRegistrationTypes),
	openRegistrationType: z.nativeEnum(CompetitionOpenRegistrationTypes),
	registrationFee: z.number().nullable(),
	playerTag: z.string().optional().nullable(),
	club: baseCreateType.pick({ id: true, name: true }).optional().nullable(),
	partialPayment: z
		.lazy(() => partialPaymentResolver)
		.array()
		.optional()
});

export type CreateCompetitionObject = z.infer<typeof createCompetitionObject>;

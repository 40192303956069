import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import type { FriendlyMatchData, MatchAttendanceModel, MatchModel, MatchTeamsModel } from "shared/types";
import type { CalendarEventModel } from "shared/types/MatchStatusEnum";

import BaseConverter from "./converters/baseConverter";

const useMatchApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getMatchOverview: async (id: string) => {
				return BaseConverter.convert<MatchModel>(
					await actions.get(API_URLS.MATCH.OVERVIEW, {
						"match-id": id
					})
				);
			},
			getMatchInfo: async (id: string) => {
				return BaseConverter.convert<{ match: MatchModel }>(
					await actions.get(API_URLS.MATCH.INFO, {
						"match-id": id
					})
				);
			},
			getAttendanceOverview: async (entityId: string) => {
				return BaseConverter.convert<MatchAttendanceModel>(
					await actions.get(API_URLS.MATCH.GET_ATTENDANCE, {
						entityId,
						entityType: "MATCH"
					})
				);
			},
			getMatchTeams: async (id: string) => {
				return BaseConverter.convert<{ match: MatchTeamsModel }>(
					await actions.get(API_URLS.MATCH.TEAMS, {
						"match-id": id
					})
				);
			},
			createFriendlyMatch: async (data: FriendlyMatchData) => {
				return BaseConverter.convert<{ match: MatchTeamsModel }>(
					await actions.post(API_URLS.MATCH.CREATE_FRIENDLY, data)
				);
			},
			createCalendarEvent: async (props: CalendarEventModel) => {
				return BaseConverter.convert<{ match: MatchTeamsModel }>(
					await actions.post(API_URLS.MATCH.CALENDAR_EVENT, props)
				);
			}
		}),
		[actions]
	);
};

export default useMatchApiService;

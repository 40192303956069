import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import { paymentTransactionResolver } from "shared/types";
import type { BackendEntityType, CardObject, PaymentTransactionModel } from "shared/types";

import BaseConverter from "./converters/baseConverter";

const usePaymentApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			submitPayment: async (params: {
				orderId: string;
				card: CardObject;
				isPayFullAmount: boolean;
				payAsEntityId?: string;
				payAsEntityType?: BackendEntityType;
			}) => {
				return BaseConverter.convert<{ paymentMethod: { id: string } }>(
					await actions.post(API_URLS.PAYMENT.SUBMIT_PAYMENT, params)
				);
			},
			getTransactionDetails: async (transactionId: string) => {
				return BaseConverter.convert<{ transaction: PaymentTransactionModel }>(
					await actions.get(
						API_URLS.PAYMENT.GET_TRANSACTION_DETAILS,
						{ transactionId },
						{
							resolvers: [
								{
									resolver: paymentTransactionResolver,
									fields: ["transaction"]
								}
							]
						}
					)
				);
			}
		}),
		[actions]
	);
};

export default usePaymentApiService;

import { z } from "zod";

import { InvitationStatus, userExperienceResolver } from "shared/types";

import { userProfileResolver } from "./UserModel";

export const scoutResolver = userProfileResolver
	.pick({
		id: true,
		name: true,
		shortName: true,
		avatar: true,
		gender: true,
		birthDate: true,
		firstName: true,
		lastName: true
	})
	.extend({
		email: z.string().email(),
		status: z.nativeEnum(InvitationStatus),
		experience: userExperienceResolver.nullable()
	});

export type ScoutModel = z.infer<typeof scoutResolver>;

export const updateScoutResolver = userProfileResolver
	.pick({
		id: true,
		firstName: true,
		lastName: true
	})
	.extend({
		avatar: z.string().nullable().optional(),
		birthDate: z.string().nullable().optional(),
		email: z.string().email().optional(),
		experience: z
			.object({
				add: z
					.object({
						title: z.string(),
						company: z.string(),
						startsOn: z.string(), // ISO date
						endsOn: z.string().nullable() // ISO date
					})
					.optional(),
				update: z
					.object({
						id: z.string(),
						title: z.string().optional(),
						company: z.string().optional(),
						startsOn: z.string().optional(), // ISO date
						endsOn: z.string().nullable().optional() // ISO date
					})
					.optional(),
				remove: z
					.object({
						id: z.string()
					})
					.optional()
			})
			.optional()
	});

export type UpdateScoutModel = z.infer<typeof updateScoutResolver>;

export const newScoutResolver = updateScoutResolver
	.omit({
		id: true,
		email: true,
		experience: true
	})
	.extend({
		email: z.string().email(),
		experience: z
			.object({
				title: z.string(),
				company: z.string(),
				startsOn: z.string(), // ISO date
				endsOn: z.string().nullable() // ISO date
			})
			.optional()
	});

export type NewScoutModel = z.infer<typeof newScoutResolver>;

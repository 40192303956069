import { useCallback, useMemo } from "react";

import { useMatchStore } from "shared/contexts";
import { useToast, useUser } from "shared/hooks";
import { useMatchApiService } from "shared/services";
import type { EntityRoles, FriendlyMatchData } from "shared/types";
import { RSVPStatusEnum } from "shared/types";
import type { CalendarEventModel } from "shared/types/MatchStatusEnum";

import usePlatformEnv from "./usePlatformEnv";

const useMatch = () => {
	const matchService = useMatchApiService();

	const store = useMatchStore();
	const { setState } = useMatchStore();

	const { showToast } = useToast();

	const { updateMyAttendance } = useUser();

	const { isShowRoleToasts } = usePlatformEnv();

	const methods = useMemo(
		() => ({
			getMatchOverview: async (id: string) => {
				setState({
					loadingOverview: true
				});

				try {
					const match = await matchService.getMatchOverview(id);
					setState({ match });
					return match;
				} catch (error) {
					showToast({
						text: "Match couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					setState({
						loadingOverview: false
					});
				}
			},
			getMatchInfo: async (id: string) => {
				setState({
					loadingInfo: true
				});

				try {
					const { match } = await matchService.getMatchInfo(id);

					setState({
						matchInfo: match
					});

					if (match?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for match: ${match.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return match;
				} catch (error) {
					showToast({ text: "Error while fetching match info" });
				} finally {
					setState({
						loadingInfo: false
					});
				}
			},
			setUserMatchRoles: (userMatchRoles: EntityRoles[]) => {
				setState({ userMatchRoles });
			},
			getAttendanceOverview: async (id: string) => {
				setState({
					loadingAttendanceOverview: true
				});

				try {
					const attendanceStatus = await matchService.getAttendanceOverview(id);

					setState({
						attendanceStatus
					});

					return attendanceStatus;
				} catch (error) {
					showToast({ text: "Error while fetching attendance overview" });
				} finally {
					setState({
						loadingAttendanceOverview: false
					});
				}
			},
			getMatchTeams: async (id: string) => {
				setState({
					loadingTeams: true
				});

				try {
					const { match } = await matchService.getMatchTeams(id);

					return match;
				} catch (error) {
					showToast({ text: "Error while fetching match data" });
				} finally {
					setState({
						loadingTeams: false
					});
				}
			},
			createFriendlyMatch: async (data: FriendlyMatchData) => {
				setState({
					loadingCreateFriendlyMatch: true
				});

				try {
					const { match } = await matchService.createFriendlyMatch(data);

					showToast({ text: "Friendly match was successfully created", noIcon: true });

					return match;
				} catch (error) {
					showToast({ text: "Error while creating friendly match" });
				} finally {
					setState({
						loadingCreateFriendlyMatch: false
					});
				}
			},
			updateMyMatchAttendance: async (matchId: string, status: RSVPStatusEnum) => {
				const attendanceInfo = await updateMyAttendance(matchId, status);

				setState(ctx => {
					if (ctx.match?.id === matchId) {
						const prevStatus = ctx.attendanceStatus.myStatus;
						let sampleAttendance = [...(ctx.attendanceStatus?.sampleAttendees || [])];
						const myAttendanceIndex = sampleAttendance?.findIndex(x => x?.person?.id);
						if (myAttendanceIndex === -1) {
							sampleAttendance = [...sampleAttendance, attendanceInfo];
						} else {
							sampleAttendance[myAttendanceIndex] = attendanceInfo;
						}

						return {
							attendanceStatus: {
								...ctx.attendanceStatus,
								counts: {
									going:
										prevStatus === RSVPStatusEnum.GOING && status !== RSVPStatusEnum.GOING
											? ctx.attendanceStatus.counts.going - 1
											: prevStatus !== RSVPStatusEnum.GOING && status === RSVPStatusEnum.GOING
												? ctx.attendanceStatus.counts.going + 1
												: ctx.attendanceStatus.counts.going,
									notGoing:
										prevStatus === RSVPStatusEnum.DECLINE && status !== RSVPStatusEnum.DECLINE
											? ctx.attendanceStatus.counts.notGoing - 1
											: prevStatus !== RSVPStatusEnum.DECLINE && status === RSVPStatusEnum.DECLINE
												? ctx.attendanceStatus.counts.notGoing + 1
												: ctx.attendanceStatus.counts.notGoing,
									interested:
										prevStatus === RSVPStatusEnum.INTRESTED && status !== RSVPStatusEnum.INTRESTED
											? ctx.attendanceStatus.counts.interested - 1
											: prevStatus !== RSVPStatusEnum.INTRESTED && status === RSVPStatusEnum.INTRESTED
												? ctx.attendanceStatus.counts.interested + 1
												: ctx.attendanceStatus.counts.interested
								},
								myStatus: status,
								sampleAttendance
							}
						};
					}
					return ctx;
				});
			},
			createCalendarEvent: async (props: CalendarEventModel) => {
				setState({
					loadingCreateFriendlyMatch: true
				});

				try {
					await matchService.createCalendarEvent(props);
					showToast({ text: "Event was successfully created", noIcon: true });
				} catch (error) {
					showToast({ text: "Error while creating a calendar event match" });
				} finally {
					setState({
						loadingCreateFriendlyMatch: false
					});
				}
			}
		}),
		[matchService, setState, showToast, updateMyAttendance, isShowRoleToasts]
	);

	const getMatchData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getMatchData };
};

export default useMatch;

import { z } from "zod";

import { BackendEntityType } from "./AttachmentModel";
import { baseCreateType } from "./CreateTypes";

export const storeResolver = baseCreateType.pick({ id: true, name: true, logo: true }).extend({
	link: z.string(),
	entityId: z.string(),
	entityType: z.nativeEnum(BackendEntityType)
});

export type StoreModel = z.infer<typeof storeResolver>;

export const createStoreResolver = baseCreateType.pick({ name: true }).extend({
	logo: z.string().nullable(),
	link: z.string(),
	entityId: z.string(),
	entityType: z.nativeEnum(BackendEntityType)
});

export type CreateStoreModel = z.infer<typeof createStoreResolver>;

export const editStoreResolver = baseCreateType.pick({ id: true }).extend({
	name: z.string().optional(),
	link: z.string().optional(),
	logo: z.string().optional().nullable()
});

export type EditStoreModel = z.infer<typeof editStoreResolver>;

import { useEffect, useMemo, useState } from "react";

import type { InvitationPersonaModel } from "shared/types";

export const useFetchInvitationUsers = (keyword: string) => {
	const [searchedPersonas, setSearchedPersonas] = useState<Partial<InvitationPersonaModel>[]>([]);

	const fetchUsers = useMemo(
		() => async (keyword: string) => {
			const users: any[] = [];

			if (keyword?.length) {
				if (!keyword?.includes("@")) {
					users.push({
						name: `${keyword}@vyoo.me`,
						avatarUrl: "",
						email: `${keyword}@vyoo.me`
					});
					users.push({
						name: `${keyword}@gmail.com`,
						avatarUrl: "",
						email: `${keyword}@gmail.com`
					});
					users.push({
						name: `${keyword}@outlook.live`,
						avatarUrl: "",
						email: `${keyword}@outlook.live`
					});
				} else {
					users.push({
						name: keyword,
						avatarUrl: "",
						email: keyword
					});
				}
			}

			setSearchedPersonas(users);
		},
		[]
	);

	useEffect(() => {
		fetchUsers(keyword);
	}, [keyword, fetchUsers]);

	return searchedPersonas;
};

export default useFetchInvitationUsers;

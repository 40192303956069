import type {
	ClubModel,
	CompetitionClubWithTeamsModel,
	CompetitionFieldWithReservationModel,
	CompetitionGroupModel,
	CompetitionModel,
	CompetitionPlayerModel,
	CompetitionTeamPlayersModel,
	EntityRoles,
	FullTeamModel,
	MatchModel,
	PaginationTokenType,
	ScoutModel,
	TeamFiltersModelWithId,
	TournamentDivisionModel,
	TournamentStructModel
} from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

export interface Values {
	competitions: CompetitionModel[];
	loading: boolean;
	loadingOverview?: boolean;
	loadingInfo?: boolean;
	loadingTeams?: boolean;
	loadingGroups?: boolean;
	groups: CompetitionGroupModel[];
	submitting?: boolean;
	teams: FullTeamModel[];
	clubs: Partial<ClubModel>[];
	overview?: CompetitionModel;
	info?: CompetitionModel;
	loadingTeamFilters?: boolean;
	teamFilters?: TeamFiltersModelWithId;
	competitionSettingsModal?: boolean | CompetitionModel | string;
	loadingReferees?: boolean;
	loadingCompetitionTeamStats: boolean;
	loadingCompetitionPlayerStats: boolean;
	loadingClubs: boolean;
	loadingMatches: boolean;
	matches: MatchModel[];
	userCompetitionRoles: EntityRoles[];
	nextListingMatchesToken: PaginationTokenType;
	loadingFollowedCompetitions: boolean;
	followedCompetitionsNextToken: PaginationTokenType;

	loadingFieldReservations: boolean;
	fieldReservations: CompetitionFieldWithReservationModel[];

	loadingCompetitionDivision: boolean;

	loadingCompetitionDivisions: boolean;
	divisions: TournamentDivisionModel[];

	loadingStructure: boolean;
	structure?: TournamentStructModel;

	deleting: boolean;

	loadingFavoriteCompetitionDivisions: boolean;
	favoriteCompetition?: Partial<CompetitionModel>;
	favoriteCompetitions: Partial<CompetitionModel>[];
	favoriteCompetitionDivisions: Partial<TournamentDivisionModel>[];
	favoriteCompetitionGroups: Partial<CompetitionGroupModel>[];

	loadingScouts: boolean;
	updatingScouts: boolean;
	scouts: ScoutModel[];

	updatingTeams: boolean;

	loadingPlayers: boolean;
	players: CompetitionPlayerModel[];

	loadingPlayersInTeams: boolean;
	teamPlayers: CompetitionTeamPlayersModel[];

	loadingClubsWithTeams: boolean;
	clubsWithTeams: CompetitionClubWithTeamsModel[];

	loadingAudiences: boolean;
	audiences: {
		players: { id: string }[];
		teams: { id: string }[];
	};
}

export const initialState: IStateProps<Values> = {
	competitions: [],
	loading: false,
	loadingOverview: false,
	loadingInfo: false,
	loadingTeams: false,
	loadingGroups: false,
	groups: [],
	submitting: false,
	teams: [],
	overview: undefined,
	info: undefined,
	loadingTeamFilters: false,
	teamFilters: undefined,
	competitionSettingsModal: false,
	loadingReferees: false,
	loadingCompetitionTeamStats: false,
	loadingCompetitionPlayerStats: false,
	loadingClubs: false,
	loadingMatches: false,
	clubs: [],
	matches: [],
	userCompetitionRoles: [],
	nextListingMatchesToken: null,
	loadingFollowedCompetitions: false,
	followedCompetitionsNextToken: null,

	loadingFieldReservations: false,
	fieldReservations: [],

	loadingCompetitionDivision: false,
	loadingCompetitionDivisions: false,
	divisions: [],

	loadingStructure: false,
	structure: undefined,

	deleting: false,

	loadingFavoriteCompetitionDivisions: false,
	favoriteCompetition: undefined,
	favoriteCompetitions: [],
	favoriteCompetitionDivisions: [],
	favoriteCompetitionGroups: [],

	loadingScouts: false,
	updatingScouts: false,
	scouts: [],

	updatingTeams: false,

	loadingPlayers: false,
	players: [],

	loadingPlayersInTeams: false,
	teamPlayers: [],

	loadingClubsWithTeams: false,
	clubsWithTeams: [],

	loadingAudiences: false,
	audiences: {
		players: [],
		teams: []
	}
};

export default createStore<Values>({
	initialState,
	persistant: true
});

import { z } from "zod";

import { AttachmentKind, BackendEntityType } from "./AttachmentModel";

import { baseCreateType, logoResolver } from "./CreateTypes";
import { mediaModelResolver } from "./MediaModel";
import { paginationTokenResolver } from "./Search";
import { userProfileResolver } from "./UserModel";

export enum PostReactionSkins {
	DEFAULT = "DEFAULT",
	LIGHT = "LIGHT",
	MEDIUM_LIGHT = "MEDIUM_LIGHT",
	MEDIUM_DARK = "MEDIUM_DARK",
	DARK = "DARK"
}

export enum PostReactionTypes {
	LOVE = "LOVE",
	LIKE = "LIKE",
	LAUGH = "LAUGH",
	LOVING = "LOVING",
	WOW = "WOW",
	SAD = "SAD",
	ANGRY = "ANGRY",
	DISLIKE = "DISLIKE"
}

export const hashtagResolver = z.object({
	id: z.string().optional(),
	value: z.string()
});

export type HashtagModel = z.infer<typeof hashtagResolver>;

export const mentionResolver = baseCreateType.pick({ id: true }).extend({
	user: userProfileResolver.pick({ id: true, name: true, shortName: true, avatar: true })
});

export type MentionModel = z.infer<typeof mentionResolver>;

export const pollAnswerResolver = z.object({
	id: z.string(),
	text: z.string(),
	ord: z.number().optional(),
	voted: z.number().optional()
});

export type PollAnswerModel = z.infer<typeof pollAnswerResolver>;

export const pollVoteResolver = z.object({
	answerId: z.string(),
	votes: z.number()
});

export type PollVoteModel = z.infer<typeof pollVoteResolver>;

export const pollResolver = baseCreateType.pick({ id: true }).extend({
	question: z.string(),
	createdAt: z.string(),
	endsAt: z.string(),
	allowMultiple: z.boolean(),
	uniqueUserVotesCount: z.number().optional(),
	answers: z.array(pollAnswerResolver),
	viewer: z.array(z.object({ answerId: z.string() })).optional()
});

export type PollModel = z.infer<typeof pollResolver>;

export const postAttachmentResolver = z.object({
	id: z.string(),
	kind: z.nativeEnum(AttachmentKind),
	data: z.union([z.string(), mediaModelResolver, pollResolver]).optional(),
	image: logoResolver.nullable().optional(),
	gif: logoResolver.nullable().optional(),
	video: logoResolver.nullable().optional(),
	hashtag: hashtagResolver.nullable().optional(),
	mention: mentionResolver.nullable().optional(),
	poll: pollResolver.nullable().optional(),
	entity: z
		.object({
			type: z.nativeEnum(BackendEntityType).optional(),
			id: z.string(),
			name: z.string().nullable(),
			info: z.any()
		})
		.optional()
		.nullable()
});

export type PostAttachmentModel = z.infer<typeof postAttachmentResolver>;

export const postCreatorResolver = baseCreateType.pick({ id: true }).extend({
	name: z.string().optional().nullable(),
	shortName: z.string().optional().nullable(),
	avatar: logoResolver.nullable()
});

export type PostCreatorModel = z.infer<typeof postCreatorResolver>;

export const postReactionResolver = z.object({
	id: z.string().optional(),
	skin: z.nativeEnum(PostReactionSkins),
	type: z.nativeEnum(PostReactionTypes),
	user: userProfileResolver.pick({ id: true, name: true, shortName: true, avatar: true }).optional()
});

export type PostReactionModel = z.infer<typeof postReactionResolver>;

export const postReactionStatisticResolver = z.object({
	count: z.number(),
	type: z.nativeEnum(PostReactionTypes).or(z.literal("ALL"))
});

export type PostReactionStatisticModel = z.infer<typeof postReactionStatisticResolver>;

export const postReactionsInfoResolver = z.object({
	count: z.number(),
	preview: z.array(postReactionResolver),
	viewer: postReactionResolver.nullable()
});

export type PostReactionsInfoModel = z.infer<typeof postReactionsInfoResolver>;

export const postResolver = baseCreateType.pick({ id: true }).extend({
	createdAt: z.string(),
	publishedAt: z.string(),
	body: z.object({
		markdown: z.string(),
		plain: z.string().optional()
	}),
	attachments: z.array(postAttachmentResolver).optional(),
	creator: postCreatorResolver,
	reaction: postReactionsInfoResolver,
	commentCount: z.number().optional(),
	comments: z.array(z.lazy(() => postCommentResolver)).optional(),
	nextCommentsToken: paginationTokenResolver.optional()
});

export const postAttachmentInfoResolver = z.object({
	id: z.string(),
	author: z.object({
		id: z.string(),
		shortName: z.string(),
		name: z.string()
	}),
	type: z.nativeEnum(BackendEntityType)
});

export type PostAttachmentInfoModel = z.infer<typeof postAttachmentInfoResolver>;

export type PostModel = z.infer<typeof postResolver>;

export const postCommentResolver = postResolver.omit({ comments: true }).extend({
	comments: z.object({
		commentCount: z.number(),
		comments: z.array(z.lazy(() => postResolver)),
		nextCommentsToken: paginationTokenResolver
	})
});

export type PostCommentModel = z.infer<typeof postCommentResolver>;

export interface CreatePostParamModel {
	body: { markdown: string };
	attachments: CreatePostAttachmentType[];
}

export interface UpdatePostParamModel {
	postId: string;
	body: { markdown: string };
	attachments: {
		add: CreatePostAttachmentType[];
		remove: { attachmentId: string }[];
	};
}

export interface CreatePostAttachmentType {
	kind: AttachmentKind;
	entity?: {
		type: BackendEntityType | AttachmentKind;
		id: string;
	};
	name?: string | null;
	image?: { url: string };
	video?: { url: string };
	hashtag?: { value: string };
	mention?: { userId: string };
	poll?: CreatePostPollParamModel;
}

export interface CreatePostCommentParamModel {
	parentId?: string | null;
	postId: string;
	body: { markdown: string };
	attachments: CreatePostAttachmentType[];
}

export interface CreatePostPollParamModel {
	question: string;
	allowMultiple: boolean;
	endsAt: string;
	answers: {
		ord: number;
		text: string;
	}[];
}

import type { DateTime } from "luxon";
import { z } from "zod";

import { BackendEntityType } from "./AttachmentModel";
import type { ClubPlayersModel } from "./ClubModel";
import { baseCreateType, logoResolver } from "./CreateTypes";
import type { SearchableEntitiesEnum } from "./Search";
import type { FullTeamModel } from "./TeamModel";

export enum PendingPaymentStatusEnum {
	lastPaymentFailed = "lastPaymentFailed",
	subscriptionInProgress = "subscriptionInProgress",
	fulfilled = "fulfilled"
}

export enum PaymentRequestTypes {
	SOCCER_CLUB_PLAYER_GENERIC = "paymentRequest.soccer.club.player.generic",
	SOCCER_LEAGUE_CLUB_GENERIC = "paymentRequest.soccer.league.club.generic",
	SOCCER_COMPETITION_CLUB_GENERIC = "paymentRequest.soccer.competition.club.generic",
	SOCCER_COMPETITION_OPEN_REGISTRATION_GENERIC = "paymentRequest.soccer.competition.openRegistration.generic"
}

export enum PaymentRequestAudienceTypes {
	SOCCER_CLUB_PLAYER = "paymentRequestAudienceTypes.soccer.club.player",
	SOCCER_CLUB_MANAGER = "paymentRequestAudienceTypes.soccer.club.manager",
	SOCCER_CLUB_GENERIC = "paymentRequestAudienceTypes.soccer.club.generic",
	SOCCER_LEAGUE_PLAYER = "paymentRequestAudienceTypes.soccer.league.player",
	SOCCER_LEAGUE_MANAGER = "paymentRequestAudienceTypes.soccer.league.manager",
	SOCCER_TEAM_PLAYER = "paymentRequestAudienceTypes.soccer.team.player",
	SOCCER_TEAM_MANAGER = "paymentRequestAudienceTypes.soccer.team.manager",
	SOCCER_TEAM_GENERIC = "paymentRequestAudienceTypes.soccer.team.generic"
}

export enum StripeInvoiceStatus {
	DRAFT = "draft",
	OPEN = "open",
	PAID = "paid",
	UNCOLLECTIBLE = "uncollectible",
	VOID = "void"
}

export enum StripePaymentIntentStatus {
	REQUIRESPAYMENTMETHOD = "requires_payment_method",
	requiresconfirmation = "requires_confirmation",
	requiresaction = "requires_action",
	PROCESSING = "processing",
	SUCCEEDED = "succeeded",
	CANCELED = "canceled",
	REQUIRESCAPTURE = "requires_capture"
}

export enum CurrencyCode {
	USD = "usd"
}

export enum OrderStatus {
	ACTIVE = "ACTIVE",
	CANCELED = "CANCELED",
	FAULTY = "FAULTY",
	FULFILLED = "FULFILLED",
	PROCESSING_PAYMENT = "PROCESSING_PAYMENT",
	ACTIVE_SUBSCRIPTION = "ACTIVE_SUBSCRIPTION"
}

export enum PaymentStatus {
	CANCELED = "CANCELED",
	FAILED = "FAILED",
	PENDING = "PENDING",
	SUCCEEDED = "SUCCEEDED"
}

export enum ChargeFrequency {
	DAILY = "DAILY",
	WEEKLY = "WEEKLY",
	MONTHLY = "MONTHLY",
	YEARLY = "YEARLY"
}

export const partialPaymentResolver = z.object({
	upfrontAmount: z.number(),
	numberOfInstallments: z.number(),
	frequency: z.nativeEnum(ChargeFrequency)
});

export type PartialPaymentModel = z.infer<typeof partialPaymentResolver>;

export const paymentResolver = z.object({
	id: z.string(),
	ord: z.number(),
	amount: z.number(),
	currency: z.nativeEnum(CurrencyCode),
	status: z.nativeEnum(PaymentStatus),
	subscriptionId: z.string().nullable()
});

export type PaymentModel = z.infer<typeof paymentResolver>;

export const subscriptionResolver = z.object({
	id: z.string(),
	startsAt: z.string(),
	endsAt: z.string(),
	interval: z.object({
		days: z.number().optional(),
		weeks: z.number().optional(),
		months: z.number().optional(),
		years: z.number().optional()
	}),
	status: z.nativeEnum(OrderStatus)
});

export type SubscriptionModel = z.infer<typeof subscriptionResolver>;

export const audiencesResolver = z.object({
	type: z.nativeEnum(PaymentRequestAudienceTypes),
	player: z
		.object({
			clubId: z.string().optional(),
			teamId: z.string().optional(),
			playerId: z.string().optional()
		})
		.optional(),
	club: z
		.object({
			id: z.string(),
			teams: z.array(
				z.object({
					id: z.string()
				})
			)
		})
		.optional()
});

export type AudiencesModel = z.infer<typeof audiencesResolver>;

export const createPaymentRequestObject = z.object({
	type: z.nativeEnum(PaymentRequestTypes),
	name: z.string(),
	amount: z.number(),
	currency: z.string(),
	dueDateOn: z.string(),
	objectId: z.string(),
	linkedObjectId: z.string().optional(),
	partial: z.array(partialPaymentResolver),
	audiences: z.array(audiencesResolver)
});

export type CreatePaymentRequestObject = z.infer<typeof createPaymentRequestObject>;

export const linkedObjectResolver = z.object({
	id: z.string(),
	type: z.nativeEnum(BackendEntityType),
	competition: z
		.object({
			id: z.string().optional(),
			name: z.string().optional()
		})
		.optional()
});

export type LinkedObjectModel = z.infer<typeof linkedObjectResolver>;

export const paymentRequestResolver = z.object({
	id: z.string(),
	name: z.string(),
	currency: z.nativeEnum(CurrencyCode),
	orderAmount: z.number(),
	orderCount: z.number(),
	totalAmount: z.number(),
	collectedAmount: z.number().nullable(),
	audiences: z.array(audiencesResolver).optional(),
	dueDateOn: z.string(),
	partial: z.array(partialPaymentResolver),
	linkedObject: linkedObjectResolver.optional().nullable(),
	removedAt: z.string().nullable().optional(),
	parentRequestId: z.string().nullable().optional(),
	relatedPaymentRequests: z.array(z.lazy(() => paymentRequestResolver)).optional()
});

export type PaymentRequestModel = z.infer<typeof paymentRequestResolver>;

export const paymentRequestOrderBaseResolver = z.object({
	id: z.string(),
	name: z.string(),
	amount: z.number(),
	currency: z.nativeEnum(CurrencyCode),
	createdAt: z.string(),
	dueDate: z.string(),
	partial: z.array(partialPaymentResolver)?.optional()
});

export type PaymentRequestOrderBaseModal = z.infer<typeof paymentRequestOrderBaseResolver>;

export const paymentRequestOrderResolver = z.object({
	id: z.string(),
	amount: z.number(),
	currency: z.nativeEnum(CurrencyCode),
	lastTransactionId: z.string().nullable(),
	paidAmount: z.number(),
	refundedAmount: z.number(),
	paidAt: z.string().nullable(),
	status: z.nativeEnum(OrderStatus).nullable(),
	subject: z.object({
		id: z.string(),
		image: logoResolver.nullable().optional(),
		name: z.string()
	})
});

export type PaymentRequestOrderModel = z.infer<typeof paymentRequestOrderResolver>;

export const cardObject = z.object({
	number: z.string(),
	expMonth: z.number(),
	expYear: z.number(),
	cvc: z.string()
});

export type CardObject = z.infer<typeof cardObject>;

export const paymentTransactionResolver = z.object({
	amount: z.number(),
	createdAt: z.string(),
	platformCommissionAmount: z.number(),
	processorCommissionAmount: z.number(),
	status: z.nativeEnum(StripeInvoiceStatus).or(z.nativeEnum(StripePaymentIntentStatus)),
	paymentMethod: z.object({
		id: z.string(),
		brand: z.string(),
		card: z.object({
			expMonth: z.number(),
			expYear: z.number(),
			last4: z.string()
		})
	}),
	paymentRequest: paymentRequestResolver.pick({ id: true, name: true })
});

export type PaymentTransactionModel = z.infer<typeof paymentTransactionResolver>;

export const shortPaymentTransactionResolver = paymentRequestOrderResolver
	.pick({
		id: true,
		amount: true,
		currency: true,
		paidAt: true
	})
	.extend({
		paymentSystemOrderId: z.string(),
		status: z.nativeEnum(StripeInvoiceStatus).or(z.nativeEnum(StripePaymentIntentStatus))
	});

export type ShortPaymentTransactionModel = z.infer<typeof shortPaymentTransactionResolver>;

export const paymentAccountMetricsResolver = z.object({
	inbound: z.object({
		currentPeriod: z.number(),
		previousPeriod: z.number()
	}),
	outbound: z.object({
		currentPeriod: z.number(),
		previousPeriod: z.number()
	}),
	balance: z.object({
		total: z.number(),
		previousPeriod: z.number()
	})
});

export type PaymentAccountMetricsModel = z.infer<typeof paymentAccountMetricsResolver>;

export const pendingPaymentRequestOrderResolver = paymentRequestOrderResolver
	.pick({ id: true, amount: true, currency: true, status: true })
	.extend({
		onBehalfOf: baseCreateType
			.pick({ id: true, name: true })
			.extend({
				avatar: logoResolver.nullable(),
				clubName: z.string()
			})
			.nullable(),
		paymentRequest: z.object({
			id: z.string(),
			name: z.string(),
			createdAt: z.string(),
			dueDateOn: z.string(),
			type: z.nativeEnum(PaymentRequestTypes).optional(),
			linkedObjectType: z.string().nullable().optional(),
			partial: z.array(partialPaymentResolver),
			teamsCounts: z.number().optional(),
			object: z.object({
				id: z.string(),
				type: z.nativeEnum(BackendEntityType),
				league: z
					.object({
						name: z.string()
					})
					.optional(),
				name: z.string().nullable().optional()
			}),
			linkedObject: linkedObjectResolver.optional()
		}),
		payments: paymentResolver.array(),
		subscription: subscriptionResolver.nullable()
	});

export type PendingPaymentRequestOrderModel = z.infer<typeof pendingPaymentRequestOrderResolver>;

export interface PaymentFieldsForm {
	for: string;
	amount: string;

	// TODO: move to enum after we know all opts
	unit: string;

	dueDate: DateTime;
	// associateWith: string;
	partialPayment: boolean;
	numberOfInstallments: number;

	tax?: number;

	frequency: ChargeFrequency;

	upfrontAmount: number;

	audienceType: SearchableEntitiesEnum;
	selectedValues: Partial<FullTeamModel>[] | Partial<ClubPlayersModel>[];
	isAllSelected?: boolean;
}

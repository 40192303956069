import type { RefObject } from "react";
import { useEffect, useState } from "react";

const useOnScreen = (ref: RefObject<HTMLElement> | undefined) => {
	const [isIntersecting, setIntersecting] = useState(false);

	const observer = new IntersectionObserver(([entry]) => setIntersecting(entry?.isIntersecting || false));

	useEffect(() => {
		ref?.current && observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	});

	return isIntersecting;
};

export default useOnScreen;

import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

export interface MapPosition {
	lat: number;
	lng: number;
}

export interface Values {
	position: MapPosition;
	fullscreenMode?: boolean;
}

export const initialState: IStateProps<Values> = {
	position: {
		lat: 36.778261,
		lng: -119.4179324
	}
};

export default createStore<Values>({
	initialState
});

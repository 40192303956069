import config from "config/appConfig";

import type {
	CalendarEventModel,
	EntityRoles,
	FullTeamModel,
	MatchModel,
	PaginationTokenType,
	PlayerModel
} from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.TEAM_DATA}`;

export interface Values {
	loading: boolean;
	overview?: FullTeamModel;
	loadingPlayers?: boolean;
	players: PlayerModel[];
	loadingTeamCompetitions: boolean;
	loadingTeamGroups: boolean;
	loadingTeamCompetitionsStats: boolean;
	loadingTeamPlayerCompetitionStats: boolean;
	loadingTeamMatches: boolean;
	userTeamRoles: EntityRoles[];
	nextListingPlayersToken: PaginationTokenType;
	nextListingMatchesToken: PaginationTokenType;
	nextListingEventsToken: PaginationTokenType;
	teamMatches: MatchModel[];
	loadingFollowedTeams: boolean;
	followedTeamsNextToken: PaginationTokenType;
	loadingTeamEvents: boolean;
	teamEvents: { calendarEvent: CalendarEventModel | null; match: MatchModel | null }[];
}

export const initialState: IStateProps<Values> = {
	loading: false,
	overview: undefined,
	loadingPlayers: false,
	players: [],
	loadingTeamCompetitions: false,
	loadingTeamGroups: false,
	loadingTeamCompetitionsStats: false,
	loadingTeamPlayerCompetitionStats: false,
	loadingTeamMatches: false,
	userTeamRoles: [],
	nextListingPlayersToken: null,
	nextListingMatchesToken: null,
	nextListingEventsToken: null,
	teamMatches: [],
	loadingFollowedTeams: false,
	followedTeamsNextToken: null,
	loadingTeamEvents: false,
	teamEvents: []
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import { EntityFollowListEnum } from "shared/types";

import BaseConverter from "./converters/baseConverter";

const useEntityManagementApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getEntityMembers: async <Response>({
				entity,
				id,
				limit,
				after
			}: {
				entity: EntityFollowListEnum;
				id: string;
				limit?: number;
				after?: string;
			}) => {
				return BaseConverter.convert<Response>(
					await actions.get(
						API_URLS.ENTITY_MANAGEMENT.LIST_MEMBERS(
							entity === EntityFollowListEnum.ORG ? "referee-organization" : entity.toLowerCase()
						),
						{
							[entity === EntityFollowListEnum.ORG ? "refereeOrganization-id" : `${entity.toLowerCase()}-id`]: id,
							limit,
							after
						}
					)
				);
			}
		}),
		[actions]
	);
};

export default useEntityManagementApiService;

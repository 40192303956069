import { useCallback, useMemo } from "react";

import { useStoreApiService } from "shared/services";

import type { CreateStoreModel, EditStoreModel, PaginationParamsModel } from "shared/types";

import useToast from "./useToast";

import { useStoreStore } from "../contexts";

const useStore = () => {
	const storeService = useStoreApiService();

	const store = useStoreStore();
	const { setState, setInitial } = useStoreStore();

	const { showToast } = useToast();

	const methods = useMemo(() => {
		return {
			async createStore(newStore: CreateStoreModel) {
				try {
					setState({ submitting: true });
					const { store } = await storeService.createStore(newStore);
					showToast({
						text: "New store is created",
						noIcon: true
					});
					return store;
				} catch (error) {
					showToast({
						text: "Can't create new store",
						noIcon: true
					});
					console.log(error);
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			async updateStore(updatedStore: EditStoreModel) {
				try {
					setState({ submitting: true });
					const { store } = await storeService.updateStore(updatedStore);
					showToast({
						text: "Store is updated",
						noIcon: true
					});
					return store;
				} catch (error) {
					showToast({
						text: "Can't update store",
						noIcon: true
					});
					console.log(error);
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			async deleteStore(id: string) {
				try {
					setState({ submitting: true });
					const { store } = await storeService.deleteStore(id);
					showToast({
						text: "Store is successfully removed",
						noIcon: true
					});
					return store;
				} catch (error) {
					showToast({
						text: "Can't remove store",
						noIcon: true
					});
					console.log(error);
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			async getStore(storeId: string) {
				setState({ loading: true });

				try {
					const { store } = await storeService.getStore(storeId);

					return store;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return null;
				} finally {
					setState({ loading: false });
				}
			},
			async getEntityStores(entityId: string) {
				setState({ loading: true });

				try {
					const { stores } = await storeService.getEntityStores(entityId);

					return stores;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return [];
				} finally {
					setState({ loading: false });
				}
			},
			async findStores(params: { search?: string } & PaginationParamsModel) {
				setState({ loading: true });

				try {
					const { stores, nextToken, previousToken } = await storeService.findStores(params);

					setState(ctx => ({
						stores: !!params?.after ? [...ctx.stores, ...stores] : stores,
						nextToken,
						previousToken
					}));

					return {
						stores,
						nextToken,
						previousToken
					};
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return {
						stores: [],
						nextToken: null,
						previousToken: null
					};
				} finally {
					setState({ loading: false });
				}
			},
			resetStore() {
				setInitial();
			}
		};
	}, [setState, storeService, showToast, setInitial]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useStore;

import { useCallback, useMemo } from "react";

import usePlatformEnv from "./usePlatformEnv";
import useToast from "./useToast";

import { useSoccerPayment } from "./index";
import { useLeagueStore } from "../contexts";

import { useLeagueApiService } from "../services";
import type {
	CreateCompetitionObject,
	EntityRoles,
	LeagueModel,
	LogoModel,
	MediaModel,
	PaginationParamsModel,
	SetupEntityModel
} from "../types";
import { BackendEntityType } from "../types";

const useLeague = () => {
	const leagueService = useLeagueApiService();

	const { setState, setInitial, ...store } = useLeagueStore();

	const { showToast } = useToast();

	const { getPaymentRequests } = useSoccerPayment();

	const { isShowRoleToasts } = usePlatformEnv();

	const methods = useMemo(() => {
		return {
			createLeague: async (leagueData: Omit<LeagueModel, "id">) => {
				const { league } = await leagueService.createLeague(leagueData);
				setState({ league });
				return league;
			},
			setupLeague: async (data: SetupEntityModel & { id: string }) => {
				setState({ loadingStructure: true });
				const { league } = await leagueService.setupLeague(data);
				setState({ structure: league.struct, loadingStructure: false });
				return league;
			},
			updateNewLeague(
				newLeagueData?: Partial<Omit<LeagueModel, "logo">> & { logo?: string | null | MediaModel | LogoModel }
			) {
				setState(ctx => ({
					newLeague: { ...ctx.newLeague, ...newLeagueData }
				}));
			},
			updateNewSetupLeague(newSetupLeagueData?: Partial<SetupEntityModel>) {
				setState(ctx => ({
					newSetupLeague: { ...ctx.newSetupLeague, ...newSetupLeagueData }
				}));
			},
			createLeagueCompetition: async (data: CreateCompetitionObject, displayToast = false) => {
				try {
					const { competition } = await leagueService.createLeagueCompetition(data);
					setState({ leagueCompetition: competition });

					if (displayToast) {
						showToast({
							text: "New competition was successfully created.",
							noIcon: true
						});
					}
					return competition;
				} catch (error) {
					console.log(error);
					showToast({
						text: "Something went wrong. Please try again later."
					});
				}
			},
			setupLeagueCompetition: async (data: Partial<CreateCompetitionObject>) => {
				try {
					await leagueService.setupLeagueCompetition(data);
				} catch (error) {
					showToast({
						text: "Something went wrong. Please try again later."
					});
				}
			},
			getFollowedLeagues: async (params: { search?: string } & PaginationParamsModel) => {
				setState({ loadingFollowedLeagues: true });
				const { leagues, nextToken } = await leagueService.getFollowedLeagues(params);
				setState({ followedLeaguesNextToken: nextToken, loadingFollowedLeagues: false });
				return leagues;
			},
			getLeagues: async (ignoreStateUpdate?: boolean, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loading: true });
				const { leagues } = await leagueService.getLeagues();
				setState(ctx => ({
					leagues: ignoreStateUpdate ? ctx.leagues : leagues,
					loading: ignoreLoading ? ctx.loading : false
				}));
				return leagues;
			},
			findLeagues: async ({
				search,
				limit = 10,
				ignoreStateUpdate,
				ignoreLoading
			}: {
				search?: string;
				limit?: number;
				ignoreStateUpdate?: boolean;
				ignoreLoading?: boolean;
			}) => {
				!ignoreLoading && setState({ loading: true });
				const { leagues } = await leagueService.findLeagues({ search, limit });
				setState(ctx => ({
					leagues: ignoreStateUpdate ? ctx.leagues : leagues,
					loading: ignoreLoading ? ctx.loading : false
				}));
				return leagues;
			},
			setSeason: (season?: CreateCompetitionObject) => {
				setState({ season });
			},
			getLeagueOverview: async (id: string, ignoreLoading?: boolean) => {
				try {
					!ignoreLoading && setState({ loadingOverview: true });
					const { league } = await leagueService.getLeagueOverview(id);
					setState(ctx => ({ overview: league, loadingOverview: ignoreLoading ? ctx.loadingOverview : false }));
					return league;
				} catch {
					showToast({
						text: "League couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingOverview: false });
					}
				}
			},
			getLeagueInfo: async (id: string, ignoreLoading?: boolean) => {
				try {
					!ignoreLoading && setState({ loadingInfo: true });
					const { league } = await leagueService.getLeagueInfo(id);
					setState(ctx => ({ info: league, loading: ignoreLoading ? ctx.loadingInfo : false }));

					if (league?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for league: ${league.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return league;
				} catch {
					showToast({
						text: "League couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingInfo: false });
					}
				}
			},
			getStructure: async (id: string) => {
				setState({ loadingStructure: true });
				const { league } = await leagueService.getStructure(id);
				setState({ structure: league.struct, loadingStructure: false });
				return league.struct;
			},
			getCompetitions: async ({ leagueId, limit = 10, after }: { leagueId: string } & PaginationParamsModel) => {
				setState({ loadingCompetitions: true });
				if (!after) {
					setState({ competitions: [], nextListingCompetitionsToken: null });
				}

				try {
					const { league, nextToken } = await leagueService.getCompetitions({ leagueId, limit, after });
					setState(ctx => ({
						competitions: !!after ? [...ctx.competitions, ...(league?.competitions || [])] : league?.competitions,
						nextListingCompetitionsToken: nextToken
					}));
					return league?.competitions || [];
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingCompetitionsToken: null });
				} finally {
					setState({ loadingCompetitions: false });
				}
			},
			setLeagueSettingsModal: (leagueSettingsModal?: { open: boolean; league: LeagueModel }) => {
				setState({ leagueSettingsModal });
			},
			updateLeague: async (
				data: Partial<Omit<LeagueModel, "logo">> & { logo?: string | null | MediaModel | LogoModel; leagueId: string },
				dontShowToast?: boolean
			) => {
				setState({ loading: true });

				try {
					const { league } = await leagueService.updateLeague(data);

					setState(ctx => ({ overview: { ...ctx.overview, ...league } }));

					!dontShowToast && showToast({ text: "League updated successfully", noIcon: true });
				} catch (error) {
					showToast({ text: "Error updating league", noIcon: true });
					console.log("update failed", error);
				} finally {
					setState({ loading: false });
				}
			},
			getTeamStats: async (leagueId: string) => {
				setState({ loadingTeamStats: true });
				const { items } = await leagueService.getTeamStats(leagueId);

				setState({ loadingTeamStats: false });

				return items || [];
			},
			getPlayerStats: async (leagueId: string) => {
				setState({ loadingPlayerStats: true });
				const { items } = await leagueService.getPlayerStats(leagueId);

				setState({ loadingPlayerStats: false });

				return items || [];
			},
			setUserLeagueRoles: (userLeagueRoles: EntityRoles[]) => {
				setState({ userLeagueRoles });
			},
			getPaymentRequests: async ({ leagueId, after, ...rest }: { leagueId: string } & PaginationParamsModel) => {
				setState({ loadingPaymentRequests: true });

				if (!after) {
					setState({ paymentRequests: [], nextListingPaymentRequestsToken: null });
				}

				try {
					const { nextToken, paymentRequests } = await getPaymentRequests({
						objectId: leagueId,
						objectType: BackendEntityType.SoccerLeague,
						after,
						...rest
					});

					setState(ctx => ({
						paymentRequests: !!after ? [...ctx.paymentRequests, ...(paymentRequests || [])] : paymentRequests,
						nextListingPaymentRequestsToken: nextToken
					}));

					return {
						paymentRequests,
						nextToken
					};
				} catch (error) {
					console.error(error);
					showToast({ text: "Failed to load leagye payments requests, please try again later." });
				} finally {
					setState({ loadingPaymentRequests: false });
				}
			},
			resetStore() {
				setInitial();
			}
		};
	}, [leagueService, setState, showToast, setInitial, isShowRoleToasts, getPaymentRequests]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useLeague;

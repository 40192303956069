import { useCallback, useMemo } from "react";

import { useSoccerPaymentStore } from "shared/contexts";

import { useSoccerPaymentApiService } from "shared/services";
import type {
	BackendEntityType,
	CreatePaymentRequestObject,
	OrderStatus,
	PaginationParamsModel,
	PaymentRequestOrderModel
} from "shared/types";

import useToast from "./useToast";

const useSoccerPayment = () => {
	const soccerPaymentService = useSoccerPaymentApiService();

	const { setState, ...store } = useSoccerPaymentStore();

	const { showToast } = useToast();

	const methods = useMemo(
		() => ({
			createPaymentRequest: async (data: CreatePaymentRequestObject) => {
				setState({ creating: true });

				try {
					await soccerPaymentService.createPaymentRequest(data);
					showToast({ text: "Payment request was successfully created", noIcon: true });
				} catch (error) {
					showToast({ text: "Error creating payment request" });
					console.error(error);
				} finally {
					setState({ creating: false });
				}
			},
			getPaymentRequests: async (
				params: {
					objectId: string;
					objectType: BackendEntityType;
					linkedObjectId?: string;
					showRemoved?: boolean;
				} & PaginationParamsModel
			) => {
				setState({ loadingPaymentRequests: true });

				try {
					const { nextToken, paymentRequests } = await soccerPaymentService.getPaymentRequests(params);

					return {
						paymentRequests,
						nextToken
					};
				} catch (error) {
					console.error(error);
					showToast({ text: "Failed to load payments requests, please try again later." });

					return {
						paymentRequests: [],
						nextToken: null
					};
				} finally {
					setState({ loadingPaymentRequests: false });
				}
			},
			getPaymentHistory: async (
				params: { objectId: string; objectType: BackendEntityType; linkedObjectId?: string } & PaginationParamsModel
			) => {
				setState({ loadingPaymentTransactions: true });

				try {
					const { transactions, nextToken } = await soccerPaymentService.getPaymentHistory(params);

					return {
						transactions,
						nextToken
					};
				} catch (error) {
					console.error(error);
					showToast({ text: "Failed to load payments history, please try again later." });

					return {
						transactions: [],
						nextToken: null
					};
				} finally {
					setState({ loadingPaymentTransactions: false });
				}
			},
			getPaymentRequestOrders: async ({
				after,
				...rest
			}: {
				paymentRequestId: string;
				search?: string;
				status?: OrderStatus[];
			} & PaginationParamsModel) => {
				setState({ loadingPaymentRequestOrders: true });

				try {
					if (!after) {
						setState({ paymentRequestOrders: [], paymentRequestOrdersNextToken: null });
					}

					const { nextToken, orders } = await soccerPaymentService.getPaymentRequestOrders({ after, ...rest });

					setState(ctx => ({
						paymentRequestOrders: !!after ? [...ctx.paymentRequestOrders, ...(orders || [])] : orders,
						paymentRequestOrdersNextToken: nextToken
					}));

					return {
						orders,
						nextToken
					};
				} catch (error) {
					console.error(error);
					showToast({ text: "Failed to load payment request orders, please try again later." });

					return {
						orders: [],
						nextToken: null
					};
				} finally {
					setState({ loadingPaymentRequestOrders: false });
				}
			},
			getPaymentAccountMetrics: async (params: { objectId: string; startsAt?: Date; endsAt?: Date }) => {
				setState({ loadingPaymentAccountMetrics: true });

				try {
					const paymentAccountMetrics = await soccerPaymentService.getPaymentAccountMetrics(params);

					setState({ paymentAccountMetrics });
				} catch (error) {
					console.error(error);
					setState({ paymentAccountMetrics: undefined });
				} finally {
					setState({ loadingPaymentAccountMetrics: false });
				}
			},
			refundForOrder: async (params: { orderId: string; amount: number }) => {
				try {
					const { order } = await soccerPaymentService.refundForOrder(params);
					return order.refundedAmount;
				} catch (error) {
					console.error(error);
					showToast({ text: "Error on refunding money for order" });
					return 0;
				}
			},
			updatePaymentRequestOrder: async (params: { orderId: string; order: Partial<PaymentRequestOrderModel> }) => {
				setState(ctx => ({
					paymentRequestOrders: ctx.paymentRequestOrders.map(x =>
						x.id === params.orderId
							? {
									...x,
									...params.order
								}
							: x
					)
				}));
			},
			removePaymentRequest: async (id: string) => {
				try {
					setState({ submitting: true });
					await soccerPaymentService.removePaymentRequest(id);
					showToast({ text: "Payment request was successfully removed", noIcon: true });
				} catch (error) {
					showToast({ text: "Can't remove payment request" });
					console.error(error);
				} finally {
					setState({ submitting: false });
				}
			},
			getPendingOrders: async (params: { objectId: string; objectType: BackendEntityType } & PaginationParamsModel) => {
				try {
					setState({ submitting: true });
					const { orders, nextToken } = await soccerPaymentService.getPendingOrders(params);
					return { orders, nextToken };
				} catch (error) {
					showToast({ text: "Can't remove payment request" });
					console.error(error);
					return { orders: [], nextToken: null };
				} finally {
					setState({ submitting: false });
				}
			}
		}),
		[soccerPaymentService, setState, showToast]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useSoccerPayment;

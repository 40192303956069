import { useMemo } from "react";

import { useUploadsApiService } from "shared/services";

import useToast from "./useToast";

const useUploads = () => {
	const uploadsService = useUploadsApiService();

	const { showToast } = useToast();

	return useMemo(() => {
		return {
			getGoogleSheet: async () => {
				try {
					const { sheet } = await uploadsService.getGoogleSheet();
					return sheet;
				} catch (error) {
					showToast({ text: "Error inviting followers", noIcon: true });
					console.error("Invite followers to club failed", error);
				}
			}
		};
	}, [uploadsService, showToast]);
};

export default useUploads;

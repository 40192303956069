import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type {
	BaseCreateType,
	CompetitionGroupModel,
	CompetitionGroupPlayersModel,
	CreateScheduleMatchModel,
	EditCompetitionGroupModel,
	FullTeamModel,
	GroupScheduleModel,
	MatchModel,
	MatchTypes,
	PaginationParamsModel,
	PaginationTokenType,
	StatItemModel,
	StatModel
} from "shared/types";
import {
	competitionGroupPlayersResolver,
	competitionGroupResolver,
	followedGroupsResolver,
	groupScheduleResolver,
	statItemResolver,
	statsResolver
} from "shared/types";

import BaseConverter from "./converters/baseConverter";
const useGroupApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			findGroups: async (search?: string) => {
				return BaseConverter.convert<{ groups: CompetitionGroupModel[] }>(
					await actions.get(API_URLS.GROUP.FIND_GROUPS, {
						search
					})
				);
			},
			getGroupTeams: async (groupId: string) => {
				return BaseConverter.convert<{
					group: {
						id: string;
						teams: FullTeamModel[];
					};
				}>(
					await actions.get(API_URLS.GROUP.GROUP_TEAMS, {
						"group-id": groupId
					})
				);
			},
			getGroupMatches: async ({
				groupId,
				matchType,
				limit,
				after
			}: { groupId: string; matchType?: MatchTypes[] } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					matches: MatchModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.GROUP.GROUP_MATCHES, {
						"group-id": groupId,
						matchType: matchType?.length ? matchType.join(",") : undefined,
						limit,
						after
					})
				);
			},
			getGroupOverview: async (groupId: string) => {
				return BaseConverter.convert<{ group: CompetitionGroupModel; teamsCount: number; groupsCount: number }>(
					await actions.get(API_URLS.GROUP.GROUP_OVERVIEW, {
						"group-id": groupId
					})
				);
			},
			getGroupSchedule: async (id: string) => {
				return BaseConverter.convert<{ schedule: GroupScheduleModel }>(
					await actions.get(
						API_URLS.GROUP.GET_GROUP_SCHEDULE,
						{ "group-id": id },
						{
							resolvers: [
								{
									resolver: groupScheduleResolver,
									fields: ["schedule"]
								}
							]
						}
					)
				);
			},
			updateGroupSchedule: async ({
				groupId,
				info,
				matches
				// resetSchedule
			}: {
				groupId: string;
				info?: {
					startsOn: string;
					daysOfWeek: string[];
					matchesPerDay: number;
					rounds: number;
					matchDurationMinutes: number;
				};
				matches?: {
					create?: CreateScheduleMatchModel[];
					delete?: { id: string }[];
					update?: (Partial<CreateScheduleMatchModel> & { id: string })[];
				};
				// resetSchedule?: boolean;
			}) => {
				return BaseConverter.convert<{ schedule: GroupScheduleModel }>(
					await actions.post(API_URLS.GROUP.UPDATE_GROUP_SCHEDULE, {
						groupId,
						info,
						matches
						// resetSchedule
					})
				);
			},
			addTeamsToGroup: async (groupId: string, teamIds: string[]) => {
				return BaseConverter.convert<{ group: any; teams: any }>(
					await actions.post(API_URLS.GROUP.ADD_TEAMS_TO_GROUP, {
						groupId,
						teamIds
					})
				);
			},
			createGroup: async (groupData: {
				competitionId: string;
				name: string;
				ageId: string;
				genderId: string;
				levelId: string;
			}) => {
				return BaseConverter.convert<{ group: CompetitionGroupModel }>(
					await actions.post(API_URLS.GROUP.CREATE_GROUP, groupData)
				);
			},
			updateGroup: async (data: Partial<EditCompetitionGroupModel>) => {
				return BaseConverter.convert<{ group: CompetitionGroupModel }>(
					await actions.post(API_URLS.GROUP.UPDATE_GROUP, data)
				);
			},
			getDivisionGroups: async (divisionId: string, ignoreElimination: boolean) => {
				return BaseConverter.convert<{ groups: CompetitionGroupModel[] }>(
					await actions.get(
						API_URLS.GROUP.LIST_DIVISION_GROUPS,
						{
							divisionId,
							ignoreElimination
						},
						{
							resolvers: [
								{
									resolver: competitionGroupResolver,
									fields: ["groups"]
								}
							]
						}
					)
				);
			},
			getGroupPlayers: async ({
				groupId,
				skip = 0,
				limit = 10
			}: {
				groupId: string;
				skip?: number;
				limit?: number;
			}) => {
				return BaseConverter.convert<{ group: CompetitionGroupPlayersModel }>(
					await actions.get(
						API_URLS.GROUP.GET_GROUP_PLAYERS,
						{
							"group-id": groupId,
							skip,
							limit
						},
						{
							resolvers: [
								{
									resolver: competitionGroupPlayersResolver,
									fields: ["group"]
								}
							]
						}
					)
				);
			},
			getGroupStats: async (groupId: string) => {
				return BaseConverter.convert<{ stats: StatModel }>(
					await actions.get(
						API_URLS.GROUP.GET_GROUP_STATS,
						{
							"group-id": groupId
						},
						{
							resolvers: [
								{
									resolver: statsResolver,
									fields: ["stats"]
								}
							]
						}
					)
				);
			},
			getGroupTeamStats: async (groupId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.GROUP.GET_GROUP_TEAM_STATS,
						{
							"group-id": groupId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getGroupPlayerStats: async (groupId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.GROUP.GET_GROUP_PLAYER_STATS,
						{
							"group-id": groupId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getGroupInfo: async (id: string) => {
				return BaseConverter.convert<{ group: CompetitionGroupModel }>(
					await actions.get(API_URLS.GROUP.GET_GROUP_INFO, {
						"group-id": id
					})
				);
			},
			getFollowedGroups: async ({
				search,
				after
			}: {
				search?: string;
			} & PaginationParamsModel) => {
				return BaseConverter.convert<{
					groups: BaseCreateType[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.GROUP.LIST_FOLLOWED_GROUPS,
						{
							search: search || undefined,
							after: after || undefined
						},
						{
							resolvers: [
								{
									resolver: followedGroupsResolver
								}
							]
						}
					)
				);
			},
			substituteDivisionTeam: async ({
				divisionId,
				oldTeamId,
				newTeamId
			}: {
				divisionId: string;
				oldTeamId: string;
				newTeamId: string;
			}) => {
				return BaseConverter.convert<{ divisionId: string; oldTeamId: string; newTeamId: string }>(
					await actions.post(API_URLS.GROUP.SUBSTITUTE_DIVISION_TEAM, {
						divisionId,
						oldTeamId,
						newTeamId
					})
				);
			}
		}),
		[actions]
	);
};

export default useGroupApiService;

import { z } from "zod";

import type { EntityRoles } from "./FirebaseUserRole";
import { locationModel } from "./LocationModel";
import { mediaModelResolver } from "./MediaModel";

export const logoResolver = z.object({
	id: z.string(),
	variants: z.union([z.array(mediaModelResolver).optional(), mediaModelResolver.optional(), z.string().optional()])
});

export const authResolver = z.object({
	roles: z.array(z.string()) // EntityRoles
});

export const baseCreateType = z.object({
	id: z.string(),
	name: z.string().optional(),
	location: locationModel.nullable().optional(),
	about: z.string().nullable().optional(),
	phone: z.string().nullable().optional(),
	website: z.string().nullable().optional(),
	logo: logoResolver.nullable().optional(),
	auth: authResolver.optional()
});

export type BaseCreateType = z.infer<typeof baseCreateType>;
export type LogoModel = z.infer<typeof logoResolver>;
export type AuthModel = {
	roles: EntityRoles;
};

export default {
	TOKEN_KEY: "token",
	TOKEN_EXPIRED_KEY: "token-expired",
	WORKSPACE: "workspace",
	USER_KEY: "user",
	AUTH_DATA: "AuthData",
	COORDINATE: "Coord",
	HOOK_STORAGE_KEYS: {
		USER_DATA: "UserData",
		ADMIN_DATA: "AdminData",
		SEARCH_DATA: "SearchData",
		CREATE_LEAGUE_DATA: "CreateLeagueData",
		CREATE_CLUB_DATA: "CreateClubData",
		AUTH_DATA: "AuthData",
		LEAGUE_DATA: "LeagueData",
		CLUB_DATA: "ClubData",
		MATCH_DATA: "MatchData",
		ORGANIZATION_DATA: "OrganizationData",
		FACILITY_DATA: "FacilityData",
		PAYMENT_DATA: "PaymentData",
		SOCCER_PAYMENT_DATA: "SoccerPaymentData",
		INVITATION_DATA: "InvitationData",
		TEAM_DATA: "TeamData",
		EVENT_DATA: "EventData",
		PLAYER_DATA: "PlayerData",
		DASHBOARD_DATA: "DashboardData",
		MESSAGING_DATA: "MessagingData",
		COACH_DATA: "CoachData",
		FIREBASE_DATA: "FirebaseData",
		POST_DATA: "PostData",
		LANGUAGE_DATA: "LanguageData",
		STORE_DATA: "StoreData"
	},
	CORE_URLS: {
		HOME: "/home",
		LOGIN: "/auth/signIn",
		NOT_FOUND: "/404"
	},
	GLOBAL_CONSTANTS: {
		BACKEND_API_URL: import.meta.env.VITE_BACKEND_API_URL,
		LOCAL_STORAGE_PREFIX: import.meta.env.VITE_LOCAL_STORAGE_PREFIX,
		LOCAL_STORAGE_COGNITO_PREFIX: import.meta.env.VITE_LOCAL_STORAGE_COGNITO_PREFIX,
		TERMS_AND_CONDITIONS_LINK: import.meta.env.VITE_TERMS_AND_CONDITIONS_LINK,
		PRIVACY_POLICY_LINK: import.meta.env.VITE_PRIVACY_POLICY_LINK,
		COOKIE_USE_LINK: import.meta.env.VITE_COOKIE_USE_LINK,
		AMPLIFY_REGION: import.meta.env.VITE_AMPLIFY_REGION,
		AMPLIFY_POOL_ID: import.meta.env.VITE_AMPLIFY_POOL_ID,
		AMPLIFY_CLIENT_ID: import.meta.env.VITE_AMPLIFY_CLIENT_ID,
		AMPLIFY_IDENTITY_POOL_ID: import.meta.env.VITE_AMPLIFY_IDENTITY_POOL_ID,
		GOOGLE_MAPS_KEY: import.meta.env.VITE_GOOGLE_MAPS_KEY,
		GOOGLE_TIMEZONE_KEY: import.meta.env.VITE_GOOGLE_TIMEZONE_KEY,
		GOOGLE_TIMEZONE_URL: import.meta.env.VITE_GOOGLE_TIMEZONE_URL,
		AWS_COGNITO_DOMAIN_URL: import.meta.env.VITE_AWS_COGNITO_DOMAIN_URL,
		CONTACT_US_EMAIL: import.meta.env.VITE_CONTACT_US_EMAIL,
		CLUB_TEAM_IMPORT_GOOGLE_SHEET_URL: import.meta.env.VITE_CLUB_TEAM_IMPORT_GOOGLE_SHEET_URL,
		CLUB_BULK_UPLOAD_PLAYERS_EXAMPLE_GOOGLE_SHEET_URL: import.meta.env
			.VITE_CLUB_BULK_UPLOAD_PLAYERS_EXAMPLE_GOOGLE_SHEET_URL,
		AWS_BUCKET_NAME: import.meta.env.VITE_AWS_BUCKET_NAME,
		GOOGLE_MAPS_URL: import.meta.env.VITE_GOOGLE_MAPS_URL,
		AWS_CDN_URL: import.meta.env.VITE_AWS_CDN_URL,
		ENABLE_PAYMENT_FUNCTIONALITY: import.meta.env.VITE_ENABLE_PAYMENT_FUNCTIONALITY
			? (JSON.parse(import.meta.env.VITE_ENABLE_PAYMENT_FUNCTIONALITY) ?? false)
			: false,
		PAYMENT_ADMIN_FEE_AND_TAX_PERCENT:
			import.meta.env.VITE_PAYMENT_ADMIN_FEE_AND_TAX_PERCENT &&
			Number(import.meta.env.VITE_PAYMENT_ADMIN_FEE_AND_TAX_PERCENT)
				? Number(import.meta.env.VITE_PAYMENT_ADMIN_FEE_AND_TAX_PERCENT)
				: 0,
		ENV_STAGE: import.meta.env.VITE_ENV_STAGE,
		ALLOW_GOOGLE_AUTH: import.meta.env.VITE_ALLOW_GOOGLE_AUTH
			? (JSON.parse(import.meta.env.VITE_ALLOW_GOOGLE_AUTH) ?? false)
			: false,
		ALLOW_APPLE_AUTH: import.meta.env.VITE_ALLOW_APPLE_AUTH
			? (JSON.parse(import.meta.env.VITE_ALLOW_APPLE_AUTH) ?? false)
			: false,
		ENABLE_MESSAGING_FUNCTIONALITY: import.meta.env.VITE_ENABLE_MESSAGING_FUNCTIONALITY
			? (JSON.parse(import.meta.env.VITE_ENABLE_MESSAGING_FUNCTIONALITY) ?? false)
			: false,
		ENABLE_CONNECTING_FUNCTIONALITY: import.meta.env.VITE_ENABLE_CONNECTING_FUNCTIONALITY
			? (JSON.parse(import.meta.env.VITE_ENABLE_CONNECTING_FUNCTIONALITY) ?? false)
			: false,
		FIREBASE_CONFIG: {
			API_KEY: import.meta.env.VITE_FIREBASE_CONFIG_API_KEY,
			AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_CONFIG_AUTH_DOMAIN,
			PROJECT_ID: import.meta.env.VITE_FIREBASE_CONFIG_PROJECT_ID,
			STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_CONFIG_STORAGE_BUCKET,
			MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
			APP_ID: import.meta.env.VITE_FIREBASE_CONFIG_APP_ID,
			MEASUREMENT_ID: import.meta.env.VITE_FIREBASE_CONFIG_MEASUREMENT_ID,
			DYNAMIC_LINKS: {
				FIREBASE_DYNAMIC_LINK_DOMAIN: import.meta.env.VITE_FIREBASE_DYNAMIC_LINK_DOMAIN,
				FIREBASE_DYNAMIC_LINK_ENDPOINT: import.meta.env.VITE_FIREBASE_DYNAMIC_LINK_ENDPOINT,
				MOBILE_IOS_BUNDLE_ID: import.meta.env.VITE_MOBILE_IOS_BUNDLE_ID,
				MOBILE_ANDROID_BUNDLE_ID: import.meta.env.VITE_MOBILE_ANDROID_BUNDLE_ID,
				MOBILE_IOS_STORE_ID: import.meta.env.VITE_MOBILE_IOS_STORE_ID
			}
		},
		SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS,
		SENTRY_TRACE_PROPAGATION_TARGET: import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGET
	}
};

import { useMemo } from "react";

import { omit } from "ramda";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type {
	BaseCreateType,
	CompetitionModel,
	CreateCompetitionObject,
	LeagueModel,
	LogoModel,
	MediaModel,
	PaginationParamsModel,
	PaginationTokenType,
	SetupEntityModel,
	StatItemModel
} from "shared/types";
import { statItemResolver } from "shared/types";

import { followedLeaguesResolver } from "shared/types/LeagueModel";

import {
	convertCreateBaseEntityQuery,
	convertCreateSeasonQuery,
	convertSetupCompetitionQuery,
	convertSetupEntityQuery,
	convertUpdateBaseEntityQuery
} from "./converters";
import BaseConverter from "./converters/baseConverter";

const useLeagueApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createLeague: async (leagueData: Omit<LeagueModel, "id">) => {
				return BaseConverter.convert<{ league: LeagueModel }>(
					await actions.post(API_URLS.LEAGUE.CREATE, convertCreateBaseEntityQuery(leagueData))
				);
			},
			setupLeague: async (setupLeagueData: SetupEntityModel & { id: string }) => {
				return BaseConverter.convert<{ league: LeagueModel }>(
					await actions.post(API_URLS.LEAGUE.SETUP, {
						...convertSetupEntityQuery(setupLeagueData),
						leagueId: setupLeagueData.id
					})
				);
			},
			createLeagueCompetition: async (setupLeagueData: CreateCompetitionObject) => {
				return BaseConverter.convert<{ competition: CompetitionModel }>(
					await actions.post(API_URLS.LEAGUE.CREATE_COMPETITION, {
						...convertCreateSeasonQuery(setupLeagueData)
					})
				);
			},
			setupLeagueCompetition: async (setupLeagueData: Partial<CreateCompetitionObject>) => {
				return BaseConverter.convert(
					await actions.post(API_URLS.LEAGUE.SETUP_COMPETITION, {
						...convertSetupCompetitionQuery(setupLeagueData)
					})
				);
			},
			getLeagues: async () => {
				return BaseConverter.convert<{ leagues: LeagueModel[] }>(await actions.get(API_URLS.LEAGUE.GET_MINE));
			},
			getFollowedLeagues: async ({
				search,
				after
			}: {
				search?: string;
			} & PaginationParamsModel) => {
				return BaseConverter.convert<{
					leagues: BaseCreateType[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.LEAGUE.LIST_FOLLOWED_LEAGUES,
						{
							search: search || undefined,
							after: after || undefined
						},
						{
							resolvers: [
								{
									resolver: followedLeaguesResolver
								}
							]
						}
					)
				);
			},
			findLeagues: async ({ search, limit }: { search?: string; limit: number }) => {
				return BaseConverter.convert<{ leagues: LeagueModel[] }>(
					await actions.get(API_URLS.LEAGUE.FIND, {
						search,
						limit
					})
				);
			},
			getLeagueOverview: async (id: string) => {
				return BaseConverter.convert<{ league: LeagueModel }>(
					await actions.get(API_URLS.LEAGUE.GET_OVERVIEW, {
						"league-id": id
					})
				);
			},
			getLeagueInfo: async (id: string) => {
				return BaseConverter.convert<{ league: LeagueModel }>(
					await actions.get(API_URLS.LEAGUE.GET_INFO, {
						"league-id": id
					})
				);
			},
			getStructure: async (id: string) => {
				return BaseConverter.convert<{ league: LeagueModel }>(
					await actions.get(API_URLS.LEAGUE.GET_STRUCTURE, {
						"league-id": id
					})
				);
			},
			updateLeague: async (
				data: Partial<Omit<LeagueModel, "logo">> & { logo?: string | null | MediaModel | LogoModel; leagueId: string }
			) => {
				return BaseConverter.convert<{ league: LeagueModel }>(
					await actions.post(API_URLS.LEAGUE.UPDATE, {
						...convertUpdateBaseEntityQuery(omit(["leagueId"], data)),
						leagueId: data.leagueId,
						members: data.members
					})
				);
			},
			getCompetitions: async ({ leagueId, limit, after }: { leagueId: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{ league: LeagueModel; nextToken: PaginationTokenType }>(
					await actions.get(API_URLS.LEAGUE.GET_COMPETITIONS, {
						"league-id": leagueId,
						after,
						limit
					})
				);
			},
			getTeamStats: async (leagueId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.LEAGUE.GET_TEAM_STATS,
						{
							"league-id": leagueId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getPlayerStats: async (leagueId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.LEAGUE.GET_PLAYER_STATS,
						{
							"league-id": leagueId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			}
		}),
		[actions]
	);
};

export default useLeagueApiService;

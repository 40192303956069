import { useCallback, useMemo } from "react";

import { usePaymentStore } from "shared/contexts";

import { usePaymentApiService } from "shared/services";
import type { BackendEntityType, CardObject } from "shared/types";

import useToast from "./useToast";

const usePayment = () => {
	const paymentService = usePaymentApiService();

	const { setState, ...store } = usePaymentStore();

	const { showToast } = useToast();

	const methods = useMemo(
		() => ({
			submitPayment: async (params: {
				orderId: string;
				card: CardObject;
				isPayFullAmount: boolean;
				payAsEntityId?: string;
				payAsEntityType?: BackendEntityType;
			}) => {
				setState({ paying: true });

				try {
					await paymentService.submitPayment(params);
					return true;
				} catch (error) {
					console.error(error);
					return false;
				} finally {
					setState({ paying: false });
				}
			},
			getTransactionDetails: async (transactionId: string) => {
				setState({ loadingTransactionDetails: true });

				try {
					const { transaction } = await paymentService.getTransactionDetails(transactionId);

					setState({ transactionDetails: transaction });
				} catch (error) {
					console.error(error);
					showToast({ text: "Error loading transaction details" });
				} finally {
					setState({ loadingTransactionDetails: false });
				}
			}
		}),
		[paymentService, setState, showToast]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default usePayment;

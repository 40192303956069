import config from "config/appConfig";

import type { HashtagModel, PaginationTokenType, PostModel } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.POST_DATA}`;

export interface Values {
	loadingPosts: boolean;
	posts: PostModel[];
	nextListingPostsToken: PaginationTokenType;
	loadingHashtags: boolean;
	hashtags: HashtagModel[];
	nextListingHashtagsToken: PaginationTokenType;
}

export const initialState: IStateProps<Values> = {
	loadingPosts: false,
	posts: [],
	nextListingPostsToken: null,
	loadingHashtags: false,
	hashtags: [],
	nextListingHashtagsToken: null
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { z } from "zod";

import { attachmentResolver } from "./AttachmentModel";

import { logoResolver } from "./CreateTypes";

export enum conversationsEnum {
	CORE_USERS = "CORE_USERS",
	SOCCER_CLUB_MANAGERS = "SOCCER_CLUB_MANAGERS",
	SOCCER_CLUB_MANAGERS_AND_COACHES = "SOCCER_CLUB_MANAGERS_AND_COACHES",
	SOCCER_CLUB_MANAGERS_AND_PLAYERS = "SOCCER_CLUB_MANAGERS_AND_PLAYERS",
	SOCCER_CLUB_MANAGERS_AND_TEAM_COACHES_AND_PLAYERS = "SOCCER_CLUB_MANAGERS_AND_TEAM_COACHES_AND_PLAYERS",
	SOCCER_COMPETITION_LEAGUE_AND_CLUB_MANAGERS_AND_TEAMS_COACHES = "SOCCER_COMPETITION_LEAGUE_AND_CLUB_MANAGERS_AND_TEAMS_COACHES",
	SOCCER_LEAGUE_MANAGERS = "SOCCER_LEAGUE_MANAGERS"
}

export const localMessageStatus = {
	READ: "read",
	WAITING: "waiting",
	PLACEHOLDER: "_PLACEHOLDER_"
};

export const localMessagePosition = {
	LEFT: "left",
	RIGHT: "right"
};

const timeStamps = z.object({
	seconds: z.number(),
	nanoseconds: z.number()
});

const chatMessage = z.object({
	id: z.string(),
	tag: z.string(),
	sentAt: timeStamps.or(z.string()),
	removedAt: timeStamps.or(z.string()).nullable().optional(),
	editedAt: timeStamps.or(z.string()).nullable().optional(),
	sender: z.object({
		id: z.string(),
		name: z.string(),
		shortName: z.string(),
		avatar: logoResolver.nullable()
	}),
	body: z.object({
		text: z.string()
	}),
	attachments: z.array(attachmentResolver).optional()
});

export const conversationSubjectsResolver = z.object({
	type: z.nativeEnum(conversationsEnum),
	params: z.array(z.string())
});

export const lastParticipantResolver = z.object({
	avatar: logoResolver.nullable(),
	id: z.string(),
	name: z.string(),
	shortName: z.string()
});

export const conversationsResolver = z.object({
	id: z.string(),
	lastMessage: chatMessage.nullable().optional(),
	roles: z.array(z.string()).optional(),
	subject: conversationSubjectsResolver.optional(),
	muted: z.boolean(),
	pinned: z.boolean(),
	title: z.object({
		text: z.string(),
		image: logoResolver.nullable().optional()
	}),
	lastParticipants: z.array(lastParticipantResolver),
	lastOpenedAt: z.string().nullable()
});

export const chatResolver = conversationsResolver.omit({
	roles: true,
	subject: true
});

export type ConversationInfo = z.infer<typeof chatResolver>;
export type ChatModel = z.infer<typeof conversationsResolver>;
export type ChatMessage = z.infer<typeof chatMessage>;
export type MessageTimestamp = z.infer<typeof timeStamps>;
export type ConversationSubjects = z.infer<typeof conversationSubjectsResolver>;

export type ExtendedChatMessage = ChatMessage & {
	status: string;
	position: string;
	showMsgDate?: boolean;
	showAvatar?: boolean;
};

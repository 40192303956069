import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import type {
	BackendEntityType,
	CreatePaymentRequestObject,
	OrderStatus,
	PaginationParamsModel,
	PaginationTokenType,
	PaymentAccountMetricsModel,
	PaymentRequestModel,
	PaymentRequestOrderModel,
	PendingPaymentRequestOrderModel,
	ShortPaymentTransactionModel
} from "shared/types";
import {
	paginationTokenResolver,
	paymentAccountMetricsResolver,
	paymentRequestOrderResolver,
	paymentRequestResolver,
	pendingPaymentRequestOrderResolver,
	shortPaymentTransactionResolver
} from "shared/types";

import BaseConverter from "./converters/baseConverter";

const useSoccerPaymentApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createPaymentRequest: async (data: CreatePaymentRequestObject) => {
				return BaseConverter.convert(await actions.post(API_URLS.SOCCER.CREATE_PAYMENT_REQUEST, data));
			},
			getPaymentRequests: async (
				params: { objectId: string; objectType: BackendEntityType; linkedObjectId?: string } & PaginationParamsModel
			) => {
				return BaseConverter.convert<{ nextToken: PaginationTokenType; paymentRequests: PaymentRequestModel[] }>(
					await actions.get(API_URLS.SOCCER.LIST_PAYMENT_REQUESTS, params, {
						resolvers: [
							{ resolver: paymentRequestResolver, fields: ["paymentRequests"] },
							{
								resolver: paginationTokenResolver,
								fields: ["nextToken"]
							}
						]
					})
				);
			},
			getPaymentRequestOrders: async (
				params: {
					paymentRequestId: string;
					search?: string;
					status?: OrderStatus[];
				} & PaginationParamsModel
			) => {
				return BaseConverter.convert<{ nextToken: PaginationTokenType; orders: PaymentRequestOrderModel[] }>(
					await actions.get(
						API_URLS.SOCCER.LIST_PAYMENT_REQUEST_ORDERS,
						{
							...params,
							status: params?.status?.length ? params.status.flat().join(",") : []
						},
						{
							resolvers: [
								{
									resolver: paymentRequestOrderResolver,
									fields: ["orders"]
								},
								{
									resolver: paginationTokenResolver,
									fields: ["nextToken"]
								}
							]
						}
					)
				);
			},
			getPaymentHistory: async (params: PaginationParamsModel) => {
				return BaseConverter.convert<{
					nextToken: PaginationTokenType;
					transactions: ShortPaymentTransactionModel[];
				}>(
					await actions.get(API_URLS.SOCCER.LIST_PAYMENTS_HISTORY, params, {
						resolvers: [
							{
								resolver: shortPaymentTransactionResolver,
								fields: ["transactions"]
							},
							{
								resolver: paginationTokenResolver,
								fields: ["nextToken"]
							}
						]
					})
				);
			},
			getPaymentAccountMetrics: async (params: { objectId: string; startsAt?: Date; endsAt?: Date }) => {
				return BaseConverter.convert<PaymentAccountMetricsModel>(
					await actions.get(API_URLS.SOCCER.GET_PAYMENT_ACCOUNT_METRICS, params, {
						resolvers: [
							{
								resolver: paymentAccountMetricsResolver
							}
						]
					})
				);
			},
			refundForOrder: async (params: { orderId: string; amount: number }) => {
				return BaseConverter.convert<{ order: { id: string; refundedAmount: number } }>(
					await actions.post(API_URLS.SOCCER.REFUND_FOR_ORDER, params)
				);
			},
			removePaymentRequest: async (id: string) => {
				return BaseConverter.convert(
					await actions.post(API_URLS.SOCCER.REMOVE_PAYMENT_REQUEST, {
						id
					})
				);
			},
			getPendingOrders: async (
				params: { objectId: string; objectType: BackendEntityType; linkedObjectId?: string } & PaginationParamsModel
			) => {
				return BaseConverter.convert<{ nextToken: PaginationTokenType; orders: PendingPaymentRequestOrderModel[] }>(
					await actions.get(API_URLS.SOCCER.LIST_PENDING_ORDERS, params, {
						resolvers: [
							{
								resolver: pendingPaymentRequestOrderResolver,
								fields: ["orders"]
							},
							{
								resolver: paginationTokenResolver,
								fields: ["nextToken"]
							}
						]
					})
				);
			}
		}),
		[actions]
	);
};

export default useSoccerPaymentApiService;

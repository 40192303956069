import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type { GuardianKind, UserProfileModel } from "shared/types";
import { getAvatar } from "utils/getAvatar";

import BaseConverter from "./converters/baseConverter";

const useGuardianApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createChildProfile: async ({
				guardianKind,
				profile,
				email
			}: {
				profile: Partial<UserProfileModel>;
				email?: string;
				guardianKind?: GuardianKind;
			}) => {
				return BaseConverter.convert<{ ok: boolean; children: UserProfileModel[] }>(
					await actions.post(API_URLS.GUARDIAN.UPDATE_CHILDREN, {
						children: {
							create: [
								{
									profile: {
										...profile,
										avatar: getAvatar(profile.avatar)
									},
									email,
									guardianKind
								}
							]
						}
					})
				);
			}
		}),
		[actions]
	);
};

export default useGuardianApiService;

import { z } from "zod";

export enum TemporalDependencyTypes {
	INVITATION_CHECK = "temporalDependency.core.invitationsCheck",
	NOTIFICATIONS_CHECK = "temporalDependency.core.notificationsCheck",
	CHATS_CHECK = "temporalDependency.core.chatsCheck"
}

export const checkNotificationUpdatesResolver = z.object({
	notifications: z.boolean(),
	invitations: z.boolean(),
	chats: z.boolean()
});

export type CheckNotificationUpdatesModel = z.infer<typeof checkNotificationUpdatesResolver>;

import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type { LogoModel } from "shared/types";
import { logoResolver } from "shared/types";

import BaseConverter from "./converters/baseConverter";

const useGifApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			search: async ({ keyword, limit, offset }: { keyword: string; limit: number; offset: number }) => {
				return BaseConverter.convert<{ gifs: LogoModel[] }>(
					await actions.get(
						API_URLS.GIF.SEARCH,
						{
							q: keyword?.length ? keyword : undefined,
							offset,
							limit
						},
						{
							resolvers: [
								{
									resolver: logoResolver,
									fields: ["gifs"]
								}
							]
						}
					)
				);
			}
		}),
		[actions]
	);
};

export default useGifApiService;

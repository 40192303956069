export enum EntityFollowListEnum {
	CLUB = "Club",
	COMPETITION = "Competition",
	FACILITY = "Facility",
	GROUP = "Group",
	LEAGUE = "League",
	TEAM = "Team",
	ORG = "Org",
	PLAYER = "Player",
	COACH = "Coach",
	MATCH = "Match",
	POST = "Post",
	USER = "User"
}

import { useCallback, useMemo } from "react";

import usePlatformEnv from "./usePlatformEnv";

import { useToast } from "./index";
import { useTeamStore } from "../contexts";

import { useTeamApiService } from "../services";
import type { EntityRoles, FullTeamModel, MatchStatusEnum, PaginationParamsModel } from "../types";

const useTeam = () => {
	const teamService = useTeamApiService();

	const { setState, setInitial, ...store } = useTeamStore();

	const { showToast } = useToast();

	const { isShowRoleToasts } = usePlatformEnv();

	const methods = useMemo(() => {
		return {
			getTeamOverview: async (id: string) => {
				try {
					setState({ loading: true });
					const overview = await teamService.getTeamOverview(id);
					setState({ overview, loading: false });
					return overview;
				} catch {
					showToast({
						text: "Team couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					setState({ loading: false });
				}
			},
			updateTeam: (id: string, newTeamData: Partial<FullTeamModel>) => {
				setState(ctx => ({ overview: { ...ctx.overview, ...newTeamData } }));
				teamService.updateTeam(id, newTeamData.name!);
			},
			findTeams: async (search?: string, customParams?: Record<string, any>) => {
				const { teams } = await teamService.findTeams(search, customParams);
				return teams;
			},
			getFollowedTeams: async (params: { search?: string } & PaginationParamsModel) => {
				setState({ loadingFollowedTeams: true });
				const { teams, nextToken } = await teamService.getFollowedTeams(params);
				setState({ followedTeamsNextToken: nextToken, loadingFollowedTeams: false });
				return teams;
			},
			getPlayers: async ({ teamId, limit = 10, after }: { teamId: string } & PaginationParamsModel) => {
				setState({ loadingPlayers: true });
				if (!after) {
					setState({ players: [], nextListingPlayersToken: null });
				}
				try {
					const { team, nextToken } = await teamService.getPlayers({ limit, after, teamId });
					setState(ctx => ({
						players: !!after ? [...ctx.players, ...(team.players || [])] : team.players,
						nextListingPlayersToken: nextToken
					}));
					return team.players;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingPlayersToken: null });
				} finally {
					setState({ loadingPlayers: false });
				}
			},
			getTeamMatches: async ({
				limit = 10,
				after,
				...rest
			}: { teamId: string; filters?: MatchStatusEnum[] } & PaginationParamsModel) => {
				setState({ loadingTeamMatches: true });
				if (!after) {
					setState({ teamMatches: [], nextListingMatchesToken: null });
				}

				try {
					const { matches, afterToken } = await teamService.getTeamMatches({ limit, after, ...rest });
					setState(ctx => ({
						teamMatches: !!after ? [...ctx.teamMatches, ...matches] : matches,
						nextListingMatchesToken: afterToken
					}));
					return matches;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingMatchesToken: null });
				} finally {
					setState({ loadingTeamMatches: false });
				}
			},
			getTeamCompetitions: async (teamId: string) => {
				setState({ loadingTeamCompetitions: true });
				const { team } = await teamService.getTeamCompetitions(teamId);
				setState({ loadingTeamCompetitions: false });
				return team.competitions || [];
			},
			getTeamCompetitionsStats: async (teamId: string) => {
				setState({ loadingTeamCompetitionsStats: true });
				const { items } = await teamService.getTeamCompetitionsStats(teamId);
				setState({ loadingTeamCompetitionsStats: false });
				return items || [];
			},
			getTeamPlayersCompetitionStats: async (teamId: string, competitionId: string) => {
				setState({ loadingTeamPlayerCompetitionStats: true });
				const { items } = await teamService.getTeamPlayersCompetitionStats(teamId, competitionId);
				setState({ loadingTeamPlayerCompetitionStats: false });
				return items || [];
			},
			getTeamGroups: async (teamId: string) => {
				setState({ loadingTeamGroups: true });
				const { groups } = await teamService.getTeamGroups(teamId);
				setState({ loadingTeamGroups: false });
				return groups;
			},
			getTeamEvents: async (props: {
				teamId: string;
				matchStatus?: MatchStatusEnum;
				limit?: number;
				after?: string;
				before?: string;
				timestampGte?: string;
				timestampLt?: string;
			}) => {
				setState({ loadingTeamEvents: true });
				try {
					const { events, nextToken } = await teamService.getTeamEvents(props);

					setState(ctx => ({
						teamEvents: !!props.after ? [...ctx.teamMatches, ...events] : events,
						nextListingEventsToken: nextToken
					}));

					return { events, nextToken };
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingEventsToken: null });
				} finally {
					setState({ loadingTeamEvents: false });
				}
			},
			getTeamInfo: async (id: string, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loadingInfo: true });

				try {
					const { team } = await teamService.getTeamInfo(id);

					setState(ctx => ({ loadingInfo: ignoreLoading ? ctx.loadingInfo : false }));

					if (team?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for team: ${team.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return team;
				} catch {
					showToast({
						text: "Team couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingInfo: false });
					}
				}
			},
			setUserTeamRoles: (userTeamRoles: EntityRoles[]) => {
				setState({ userTeamRoles });
			},
			resetStore() {
				setInitial();
			}
		};
	}, [setState, teamService, setInitial, showToast, isShowRoleToasts]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useTeam;

import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	league: {
		create: {
			name: null,
			address: null,
			logo: null,
			story: null
		},
		invite: {
			people: null
		},
		setup: {
			level: null,
			category: null,
			gender: null
		},
		season: {
			create: null,
			find: null
		}
	}
});

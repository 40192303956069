import { z } from "zod";

import { logoResolver } from "./CreateTypes";

import type { LocationModel } from "./LocationModel";
import { locationModel } from "./LocationModel";

export enum MatchStatusEnum {
	NEEDS_TEAMS = "NEEDS_TEAMS",
	NEEDS_TIME = "NEEDS_TIME",
	NEEDS_LOCATION = "NEEDS_LOCATION",
	NEEDS_REFEREES = "NEEDS_REFEREES",
	READY_TO_BE_PLAYED = "READY_TO_BE_PLAYED", // time + location + teams + referee
	NOT_ASSIGNED = "NOT_ASSIGNED",
	CREATED = "CREATED",
	SCHEDULED = "SCHEDULED",
	READY_TO_PLAY = "READY_TO_PLAY",
	CHECKED_IN = "CHECKED_IN",
	ONGOING = "ONGOING",
	FINISHED = "FINISHED",
	COMPLETE = "COMPLETE"
}

export enum MatchCalculatedStatusEnum {
	CREATED = "CREATED", // time and location, but not referee
	SCHEDULED = "SCHEDULED" // referee, but no time or location
}

export enum MatchTypes {
	ELIMINATION_STAGE = "ELIMINATION_STAGE",
	FRIENDLY = "FRIENDLY",
	GROUP_STAGE = "GROUP_STAGE",
	SEASON = "SEASON"
}

export interface FriendlyMatchData {
	homeTeamId: string;
	awayTeamId: string;
	startsAt: string;
	endsAt: string;
	location: LocationModel;
}

export enum CalendarEventEnum {
	TRAINING = "TRAINING",
	SOCIAL = "SOCIAL",
	PRACTICE = "PRACTICE",
	FRIENDLY_MATCH = "FRIENDLY_MATCH",
	MATCH = "MATCH"
}

export enum FrequencyEnum {
	YEARLY = "YEARLY",
	MONTHLY = "MONTHLY",
	WEEKLY = "WEEKLY",
	DAILY = "DAILY"
}

export enum DayOfWeek {
	SUN = "SUN",
	MON = "MON",
	TUE = "TUE",
	WED = "WED",
	THU = "THU",
	FRI = "FRI",
	SAT = "SAT"
}

export const recurrencyOptions = z.object({
	freq: z.nativeEnum(FrequencyEnum),
	interval: z.number().optional(),
	dtstart: z.string().nullable().optional(),
	wkst: z.string().nullable().optional(),
	byweekday: z.array(z.string()).nullable().optional(),
	count: z.number().nullable().optional(),
	until: z.string().nullable().optional(),
	neverEnds: z.boolean().optional()
});

export const calendarEventObject = z.object({
	id: z.string().optional(),
	name: z.string(),
	description: z.string(),
	type: z.nativeEnum(CalendarEventEnum),
	startsAt: z.string(),
	endsAt: z.string(),
	teamId: z.string().nullable().optional(),
	private: z.boolean(),
	recurrencyOptions: recurrencyOptions.nullable().optional(),
	image: logoResolver.or(z.string()).optional().nullable(),
	location: locationModel.optional()
});

export type CalendarEventModel = z.infer<typeof calendarEventObject>;
export type RecurrencyOptions = z.infer<typeof recurrencyOptions>;

import { useCallback, useMemo, useRef } from "react";

import type { Unsubscribe } from "firebase/firestore";
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, startAfter } from "firebase/firestore";

import { DateTime } from "luxon";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getPaths } from "core/getPaths";

import { useFBMessagesStore } from "shared/contexts";
import { useFirebase, useToast, useUser } from "shared/hooks";
import { useMessagingApiService } from "shared/services";
import { localMessageStatus } from "shared/types";
import type {
	ChatMessage,
	ConversationInfo,
	ConversationSubjects,
	IgnoreStateUpdate,
	LogoModel,
	PaginationParamsModel,
	PostAttachmentModel,
	conversationsEnum
} from "shared/types";
import { uuidv4 } from "utils/serviceUtils/helpers";

enum FBDocChangeType {
	ADDED = "added",
	MODIFIED = "modified",
	REMOVED = "removed"
}

const { messaging } = getPaths();

let updateTimeTimeout: NodeJS.Timeout | null = null;

const useMessaging = () => {
	const messagesSubscriptions = useRef<Unsubscribe[] | null>(null);

	const { setState, ...store } = useFBMessagesStore();

	const { t } = useTranslation();

	const { getUser, getCurrentFirestore } = useFirebase();

	const { getData: getUserData } = useUser();
	const { user } = getUserData();

	const messagingApi = useMessagingApiService();

	const { showToast } = useToast();

	const navigate = useNavigate();

	const DB = useMemo(() => getCurrentFirestore(), [getCurrentFirestore]);

	const userId = useMemo(() => user?.person.id || "", [user?.person.id]);

	const methods = useMemo(
		() => ({
			loadOldMessages: async (conversationId: string, topMessage: ChatMessage) => {
				const response = await getDocs(
					query(
						collection(doc(DB, "conversations", conversationId), "messages"),
						orderBy("sentAt", "desc"),
						startAfter(topMessage.sentAt),
						limit(20)
					)
				);

				const newMessages: ChatMessage[] = [];
				response.docs.map(doc => {
					newMessages.push(doc.data() as ChatMessage);
				});
				newMessages.reverse();

				if (newMessages.length) {
					setState(ctx => {
						const currentMessages = ctx.messages?.messages;

						if (!currentMessages) {
							return {
								messages: {
									conversationId,
									messages: newMessages
								}
							};
						} else {
							return {
								messages: {
									...ctx.messages,
									messages: [...newMessages, ...currentMessages]
								}
							};
						}
					});
				}

				return {
					noNewMessages: !newMessages.length
				};
			},
			subscribeToMessages: async (id: string, setLastMessageId: (id: string) => void) => {
				let unsub: Unsubscribe | undefined;
				setState({ loadingChats: true });

				const authUser = await getUser();
				if (authUser && userId) {
					unsub = onSnapshot(
						query(collection(doc(DB, "conversations", id), "messages"), orderBy("sentAt", "desc"), limit(20)),
						docsSnap => {
							const messages = docsSnap
								.docChanges()
								.map(change => {
									switch (change.type) {
										case FBDocChangeType.ADDED:
											return {
												...change.doc.data(),
												body: {
													...change.doc.data().body,
													text: !!change.doc.data().removedAt
														? "this message has been deleted"
														: change.doc.data().body.text
												}
											};
										case FBDocChangeType.MODIFIED:
											return change.doc.data();
									}
								})
								.filter(Boolean)
								.reverse();

							setState(ctx => {
								const currentMessages = ctx.messages?.messages;

								if (!currentMessages || ctx.messages?.conversationId !== id) {
									return {
										messages: {
											conversationId: id,
											messages
										}
									};
								} else {
									messages.forEach(msg => {
										const foundPlaceholder = currentMessages.findIndex(m => m.tag === msg?.tag);

										if (foundPlaceholder > -1) {
											currentMessages[foundPlaceholder] = msg;
										} else {
											currentMessages.push(msg);
										}
									});

									return {
										messages: {
											...ctx.messages,
											messages: currentMessages
										}
									};
								}
							});

							messages.length && setLastMessageId(messages[messages.length - 1]!.tag);
						}
					);

					messagesSubscriptions.current = (messagesSubscriptions.current || []).concat([unsub]);
				}

				setState({ loadingChats: false });
				return unsub;
			},
			unsubscribeFromMessages: async () => {
				methods.clearMessages();
				(messagesSubscriptions.current || []).forEach(unsub => {
					unsub();
				});
			},
			getConversation: async (type: conversationsEnum, params: string[]) => {
				try {
					const { conversation } = await messagingApi.getConversation(type, params);

					if (conversation.lastParticipants) {
						setState({ lastParticipants: conversation.lastParticipants });
					}

					return conversation;
				} catch (err) {
					console.log(err);
				}
			},
			getConversationsList: async ({
				limit = 50,
				after,
				ignoreLoading,
				ignoreStateUpdate
			}: PaginationParamsModel & IgnoreStateUpdate) => {
				try {
					!ignoreLoading && setState({ loadingConversations: true });

					if (!after && !ignoreStateUpdate) {
						setState({ conversationsListNextToken: null });
					}

					const { conversations, nextToken } = await messagingApi.getConversationsList({ limit, after });

					setState(ctx => ({
						conversationsList: !!after ? [...ctx.conversationsList, ...conversations] : conversations,
						conversationsListNextToken: nextToken !== ctx?.conversationsListNextToken ? nextToken : null
					}));

					return { conversations, nextToken };
				} catch (err) {
					console.error(err);
					showToast({ text: "Can't load conversations", noIcon: true });
					return {
						conversationsList: [],
						conversationsListNextToken: null
					};
				} finally {
					!ignoreLoading && setState({ loadingConversations: false });
				}
			},
			prepareMessagePlaceholder: (id: string, message: string, conversationId: string): ChatMessage => {
				setState(ctx => ({
					conversationsList: ctx.conversationsList?.map(conversation =>
						conversation.id === conversationId
							? {
									...conversation,
									lastMessage: {
										body: {
											text: message
										}
									}
								}
							: conversation
					)
				}));

				return {
					id: localMessageStatus.PLACEHOLDER,
					body: {
						text: message
					},
					sender: {
						id: user!.person.id || "",
						name: user!.person.firstName || "",
						avatar: user!.person.avatar as LogoModel,
						shortName: user!.person.firstName || ""
					},
					sentAt: {
						seconds: DateTime.now().toUnixInteger(),
						nanoseconds: performance.now() * 1000
					},
					tag: id
				};
			},
			updateMessage: async (conversationId: string, messageId: string, updatedText: string) => {
				try {
					setState(ctx => ({
						messages: {
							...ctx.messages,
							messages: ctx.messages.messages.map(message => {
								if (message.id === messageId) {
									return {
										...message,
										editedAt: {
											seconds: new Date().getTime() / 1000,
											nanoseconds: performance.now() * 1000
										},
										body: {
											...message.body,
											text: updatedText
										}
									};
								}

								return message;
							})
						}
					}));

					await messagingApi.updateMessage(conversationId, messageId, updatedText);
				} catch (err) {
					console.log(err);
				}
			},
			sendMessage: async ({
				conversationId,
				message,
				attachments,
				noPlaceholder
			}: {
				conversationId: string;
				message: string;
				attachments?: PostAttachmentModel[];
				noPlaceholder?: boolean;
			}) => {
				try {
					const tag = uuidv4();

					if (!noPlaceholder) {
						const placeholder = methods.prepareMessagePlaceholder(tag, message, conversationId);

						methods.clearDraft(conversationId);

						setState(ctx => ({
							messages: ctx.messages
								? {
										...ctx.messages,
										messages: [...ctx.messages.messages, placeholder]
									}
								: {
										conversationId,
										messages: [placeholder]
									}
						}));
					}

					const response = messagingApi.sendMessage({
						conversationId,
						message: {
							tag,
							text: message
						},
						attachments
					});

					return { response };
				} catch (err) {
					console.log(err);
				}
			},
			updateConversation: async ({
				conversationId,
				mute,
				pin,
				updateLastOpened
			}: {
				conversationId: string;
				mute?: boolean;
				pin?: boolean;
				updateLastOpened?: boolean;
			}) => {
				try {
					await messagingApi.updateConversation({
						conversationId,
						mute,
						pin,
						updateLastOpened
					});

					if (mute !== undefined) {
						showToast({
							text: mute ? t("messaging:conversation_muted") : t("messaging:conversation_unmuted"),
							noIcon: true
						});
					}

					if (pin !== undefined) {
						showToast({
							text: pin ? t("messaging:conversation_pinned") : t("messaging:conversation_unpinned"),
							noIcon: true
						});
					}

					setState(ctx => {
						const updatedConversationInfo = { ...ctx.currentConversation };

						if (mute !== undefined) {
							updatedConversationInfo.muted = mute;
						}

						if (pin !== undefined) {
							updatedConversationInfo.pinned = pin;
						}

						if (pin !== updateLastOpened) {
							updatedConversationInfo.lastOpenedAt = new Date().toISOString();
						}

						return {
							currentConversation: updatedConversationInfo,
							conversationsList: ctx.conversationsList?.map(conversation =>
								conversation.id === conversationId ? updatedConversationInfo : conversation
							)
						};
					});
				} catch (err) {
					console.log(err);
				}
			},
			manageChat: async (conversationType: conversationsEnum, params: string[]) => {
				const conversation = await methods.getConversation(conversationType, params);
				if (conversation) {
					methods.setCurrentConversation({
						...conversation,
						subject: {
							params,
							type: conversationType
						}
					});
					navigate(`${messaging.getPath()}/${conversation.id}`);
				}
			},
			deleteMessage: async (conversationId: string, messageId: string) => {
				setState({
					deletingMessage: true
				});

				try {
					const response = await messagingApi.deleteMessage(conversationId, messageId);

					showToast({
						text: "Message successfully deleted",
						noIcon: true
					});

					setState(ctx => ({
						deleteMessageDialog: undefined,
						messages: {
							...ctx.messages!,
							messages: ctx.messages!.messages.map(message => {
								if (message?.id === messageId) {
									return {
										...message,
										body: {
											...message.body,
											text: "this message has been deleted"
										}
									};
								}

								return message;
							})
						}
					}));

					return response;
				} catch (err) {
					console.log(err);
				} finally {
					setState({
						deletingMessage: false
					});
				}
			},
			setCurrentConversation: (
				currentConversation?: Omit<ConversationInfo, "lastParticipants"> & {
					subject: ConversationSubjects;
				}
			) => {
				setState({ currentConversation });

				if (currentConversation?.id) {
					if (updateTimeTimeout) {
						clearInterval(updateTimeTimeout);
						updateTimeTimeout = null;
					}

					updateTimeTimeout = setTimeout(() => {
						methods.updateConversation({
							conversationId: currentConversation.id,
							updateLastOpened: true
						});
					}, 3000);
				}
			},
			setDeleteMessageDialog: (deleteMessageDialog?: { open: boolean; message?: ChatMessage }) => {
				setState({ deleteMessageDialog });
			},
			setEditMessageDialog: (editMessage?: ChatMessage) => {
				setState({ editMessage });
			},
			handleDraft: (conversationId: string, message: string) => {
				setState(ctx => {
					if (!!ctx.drafts.length) {
						const draft = ctx.drafts.find(draft => draft.conversationId === conversationId);
						if (draft) {
							return {
								drafts: ctx.drafts.map(draft => {
									if (draft.conversationId === conversationId) {
										return {
											conversationId,
											message
										};
									}

									return draft;
								})
							};
						}

						return {
							drafts: [...ctx.drafts, { conversationId, message }]
						};
					}

					return {
						drafts: [{ conversationId, message }]
					};
				});
			},
			clearDraft: (conversationId: string) => {
				setState(ctx => ({
					drafts: ctx.drafts.filter(draft => draft.conversationId !== conversationId)
				}));
			},
			clearMessages: () => {
				setState({
					messages: undefined
				});
			}
		}),
		[DB, setState, getUser, userId, messagingApi, user, showToast, navigate, t]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useMessaging;

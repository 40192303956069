import { routes as adminRoutes } from "apps/Admin/constants";
import { routes as authRoutes } from "apps/Auth/constants";
import { routes as clubRoutes } from "apps/Club/constants";
import { routes as competitionRoutes } from "apps/Competition/constants";
import { routes as competitionGroupRoutes } from "apps/CompetitionGroup/constants";
import { routes as facilityRoutes } from "apps/Facility/constants";
import { routes as invitationRoutes } from "apps/Invitation/constants";
import { routes as leagueRoutes } from "apps/League/constants";
import { routes as matchRoutes } from "apps/Match/constants";
import { routes as memberRoutes } from "apps/Member/constants";
import { routes as messagingRoutes } from "apps/Messaging/constants";
import { routes as refereeManagerRoutes } from "apps/RefereeManager/constants";
import { routes as teamRoutes } from "apps/Team/constants";

export const getPaths = () => ({
	admin: adminRoutes.admin,
	auth: authRoutes.auth,
	facility: facilityRoutes.facility,
	league: leagueRoutes.league,
	club: clubRoutes.club,
	refereeManager: refereeManagerRoutes.organization,
	refereeManagerReferee: refereeManagerRoutes.referee,
	member: memberRoutes,
	team: teamRoutes,
	competition: competitionRoutes.competition,
	group: competitionGroupRoutes.group,
	invitation: invitationRoutes,
	messaging: messagingRoutes.messaging,
	match: matchRoutes
});

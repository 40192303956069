import { useCallback, useMemo } from "react";

import { useOrganizationStore } from "shared/contexts";

import { useOrganizationApiService } from "shared/services";
import type {
	EntityRoles,
	LogoModel,
	MatchesFilterModel,
	MediaModel,
	OrganizationModel,
	PaginationParamsModel,
	RefereeModel
} from "shared/types";

import usePlatformEnv from "./usePlatformEnv";

import { useToast } from "./index";

const useRefereeManager = () => {
	const organizationService = useOrganizationApiService();

	const { setState, setInitial, ...store } = useOrganizationStore();

	const { showToast } = useToast();

	const { isShowRoleToasts } = usePlatformEnv();

	const methods = useMemo(
		() => ({
			createOrganization: async (organizationData: Omit<OrganizationModel, "id">) => {
				const { refereeOrganization } = await organizationService.createOrganization(organizationData);
				setState({ organization: refereeOrganization });
				return refereeOrganization;
			},
			updateNewOrg(
				newOrgData: Partial<Omit<OrganizationModel, "logo">> & { logo?: string | null | MediaModel | LogoModel }
			) {
				setState(ctx => ({
					newOrganization: { ...ctx.newOrganization, ...newOrgData }
				}));
			},
			getOrgOverview: async (id: string, ignoreLoading?: boolean) => {
				try {
					!ignoreLoading && setState({ loadingOverview: true });
					const { refereeOrganization } = await organizationService.getOrgOverview(id);
					setState(ctx => ({
						overview: refereeOrganization,
						loadingOverview: ignoreLoading ? ctx.loadingOverview : false
					}));
					return refereeOrganization;
				} catch {
					showToast({
						text: "Referee organization couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingOverview: false });
					}
				}
			},
			getOrgInfo: async (id: string, ignoreLoading?: boolean) => {
				try {
					!ignoreLoading && setState({ loadingInfo: true });
					const { reforg } = await organizationService.getOrgInfo(id);
					setState(ctx => ({
						info: reforg,
						loadingInfo: ignoreLoading ? ctx.loadingInfo : false
					}));
					if (reforg?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for org: ${reforg.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}
					return reforg;
				} catch {
					showToast({
						text: "Referee organization couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingInfo: false });
					}
				}
			},
			getOrgMatches: async ({
				limit = 10,
				after,
				...rest
			}: { orgId: string; filters?: Partial<MatchesFilterModel> } & PaginationParamsModel) => {
				setState({ loadingMatches: true });
				if (!after) {
					setState({ refereeMatches: [], nextListingMatchesToken: null });
				}

				try {
					const { matches, nextToken } = await organizationService.getOrgMatches({ limit, after, ...rest });
					setState(ctx => ({
						refereeMatches: !!after ? [...ctx.refereeMatches, ...matches] : matches,
						nextListingMatchesToken: nextToken
					}));
					return matches;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ nextListingMatchesToken: null });
				} finally {
					setState({ loadingMatches: false });
				}
			},
			getRefereeOrganizations: async (params: { search?: string; onlyMy?: boolean }, ignoreStateUpdate = false) => {
				if (!ignoreStateUpdate) {
					setState({ loading: true });
				}

				const { refereeOrganizations } = await organizationService.getRefereeOrganizations(params);

				if (!ignoreStateUpdate) {
					setState({ refereeOrganizations, loading: false });
				}

				return refereeOrganizations;
			},
			listOrgReferees: async (refOrgId: string) => {
				setState({ loading: true });
				const { refereeOrganization } = await organizationService.listOrgReferees(refOrgId);
				setState({ refereesListOfOrg: refereeOrganization.referees, loading: false });
				return refereeOrganization.referees;
			},
			updateOrgReferees: async (
				refOrgId: string,
				referees: {
					remove: { id: string }[];
					update: Partial<RefereeModel>[];
				}
			) => {
				setState({ updatingReferees: true });
				const { refereeOrganization } = await organizationService.updateOrgReferees(refOrgId, referees);
				if (refereeOrganization?.id) {
					if (referees.remove.length) {
						showToast({
							text: `${referees.remove.length > 1 ? referees.remove.length : ""} Referee${
								referees.remove.length > 1 ? "s were" : " was"
							} successfully removed.`,
							noIcon: true
						});
					}
				}
				setState({ updatingReferees: false });
				return true;
			},
			findReferees: async (params: {
				competitionId?: string;
				refereeOrgId?: string;
				search?: string;
				maxMiles?: number;
				limit?: number;
			}) => {
				setState({ loading: true });
				const { referees } = await organizationService.findReferees(params);
				setState({ searchReferees: referees, loading: false });
				return referees;
			},
			findRefereeOrgs: async (params: {
				competitionId?: string;
				search?: string;
				maxMiles?: number;
				limit?: number;
			}) => {
				setState({ loading: true });
				const { refereeOrganizations } = await organizationService.findRefereeOrgs(params);
				setState({ searchRefereeOrgs: refereeOrganizations, loading: false });
				return refereeOrganizations;
			},
			findRefereeOrgsForSearch: async (search?: string) => {
				const { refereeOrganizations } = await organizationService.findRefereeOrgsForSearch(search);
				return refereeOrganizations;
			},
			updateOrg: async (
				data: Partial<Omit<OrganizationModel, "logo">> & {
					refereeOrganizationId: string;
					logo?: string | null | MediaModel | LogoModel;
				},
				dontShowToast?: boolean
			) => {
				setState({ loading: true });

				try {
					const { refereeOrganization } = await organizationService.updateOrg(data);

					setState(ctx => ({ overview: { ...ctx.overview, ...refereeOrganization } }));

					!dontShowToast && showToast({ text: "Organization updated successfully", noIcon: true });
				} catch (error) {
					showToast({ text: "Error updating organization", noIcon: true });
					console.log("update failed", error);
				} finally {
					setState({ loading: false });
				}
			},
			setRefereeManagerSettingsModal: (refereeManagerSettingsModal?: { open: boolean; org: OrganizationModel }) => {
				setState({ refereeManagerSettingsModal });
			},
			setUserRefereeOrgRoles: (userRefereeOrgRoles: EntityRoles[]) => {
				setState({ userRefereeOrgRoles });
			},
			resetStore() {
				setInitial();
			}
		}),
		[setState, organizationService, setInitial, showToast, isShowRoleToasts]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useRefereeManager;

import { useMemo } from "react";

import { usePollApiService } from "shared/services";

import useToast from "./useToast";

const usePoll = () => {
	const pollService = usePollApiService();

	const { showToast } = useToast();

	const methods = useMemo(
		() => ({
			vote: async (params: {
				pollId: string;
				answers: {
					vote: { id: string }[];
					unvote: { id: string }[];
				};
			}) => {
				try {
					const { poll } = await pollService.vote(params);
					return poll;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				}
			}
		}),
		[pollService, showToast]
	);

	return { ...methods };
};

export default usePoll;

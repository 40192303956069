import type { ReactNode } from "react";
import React from "react";

import CoedIcon from "assets/icons/outlined/coed.svg?react";
import FemaleIcon from "assets/icons/outlined/female.svg?react";
import MaleIcon from "assets/icons/outlined/male.svg?react";

import { GenderEnum, GenderValEnum } from "../types";

const genderList: { label: string; value: GenderEnum; valValue: GenderValEnum; icon: ReactNode; id: string }[] = [
	{
		label: "Male",
		id: "male",
		icon: <MaleIcon className={"stroke fill"} />,
		value: GenderEnum.MALE,
		valValue: GenderValEnum.MALE
	},
	{
		label: "Female",
		id: "female",
		icon: <FemaleIcon className={"stroke fill"} />,
		value: GenderEnum.FEMALE,
		valValue: GenderValEnum.FEMALE
	},
	{
		label: "Coed",
		id: "coed",
		icon: <CoedIcon className={"stroke fill"} />,
		value: GenderEnum.COED,
		valValue: GenderValEnum.COED
	}
];

export { genderList };

import { z } from "zod";

import { clubResolver } from "./ClubModel";
import { logoResolver } from "./CreateTypes";
import { EntityCoreUserRoles } from "./FirebaseUserRole";
import { postAttachmentResolver, postResolver } from "./PostModel";
import { userProfileResolver } from "./UserModel";

export const personObject = z.object({
	id: z.string(),
	name: z.string().nullable().optional(),
	email: z.string().nullable(),
	avatar: logoResolver.nullable(),
	onboarded: z.boolean(),
	role: z.array(z.nativeEnum(EntityCoreUserRoles)).nullable()
});

export type PersonObject = z.infer<typeof personObject>;

export const reportedPersonResolver = z.object({
	id: z.string(),
	name: z.string().nullable().optional(),
	avatar: logoResolver.nullable(),
	reportedReasons: z.array(z.string()),
	reportedCount: z.number()
});

export type ReportedPersonModel = z.infer<typeof reportedPersonResolver>;

export const reportedPostResolver = postResolver.pick({ id: true, creator: true }).extend({
	attachments: z.array(postAttachmentResolver.partial()).optional(),
	reportedReasons: z.array(z.string()),
	reportedCount: z.number()
});

export type ReportedPostModel = z.infer<typeof reportedPostResolver>;

export enum AdminEntityActions {
	ARCHIVE = "ARCHIVE",
	UNARCHIVE = "UNARCHIVE"
}

export const adminBaseEntityObject = clubResolver.pick({ id: true, name: true, logo: true }).extend({
	removedAt: z.string().nullable(),
	owner: userProfileResolver
		.pick({
			id: true,
			name: true,
			gender: true,
			avatar: true,
			birthDate: true
		})
		.extend({
			emails: z
				.object({
					address: z.string().email(),
					verified: z.boolean(),
					primary: z.boolean()
				})
				.array()
		})
		.nullable()
});

export type AdminBaseEntityModel = z.infer<typeof adminBaseEntityObject>;

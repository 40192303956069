import { DateTime } from "luxon";

export const formatRelativeDate = (date: DateTime) => {
	if (DateTime.now().hasSame(date, "day")) return "Today";

	if (DateTime.now().minus({ days: 1 }).hasSame(date, "day")) return "Yesterday";

	return date.toFormat("LLL d");
};

export const convertDateToDateFormat = (date: string | null) => {
	return date ? DateTime.fromJSDate(new Date(date)).toFormat("yyyy-LL-dd") : null;
};

import { useCallback, useMemo } from "react";

import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, getIdToken, onAuthStateChanged, signInWithCustomToken, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { AppConfig } from "config";

import { useFirebaseStore } from "shared/contexts";

import type { FBUserModel } from "../types";

const useFirebase = () => {
	const store = useFirebaseStore();
	const { setState, setInitial } = useFirebaseStore();

	const methods = useMemo(() => {
		return {
			initialiseFirebase: () => {
				const config = AppConfig.GLOBAL_CONSTANTS.FIREBASE_CONFIG;
				const firebaseConfig = {
					apiKey: config.API_KEY,
					authDomain: config.AUTH_DOMAIN,
					projectId: config.PROJECT_ID,
					storageBucket: config.STORAGE_BUCKET,
					messagingSenderId: config.MESSAGING_SENDER_ID,
					appId: config.APP_ID,
					measurementId: config.MEASUREMENT_ID
				};

				return getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
			},
			getAuth: () => {
				const app = methods.initialiseFirebase();
				return getAuth(app);
			},
			authenticateUser: async (fbToken: string) => {
				const auth = methods.getAuth();

				try {
					const authData = await signInWithCustomToken(auth, fbToken);
					setState({ user: authData.user });
					return authData.user;
				} catch (e) {
					console.error("Can not auth in firebase: ", e);
					return null;
				}
			},
			getUser: async (): Promise<FBUserModel | null> => {
				const auth = methods.getAuth();

				return new Promise((resolve, reject) => {
					onAuthStateChanged(auth, user => {
						if (user) {
							resolve(user as FBUserModel);
						} else {
							console.error("FB user not auth in firebase");
							reject(null);
						}
					});
				});
			},
			getCurrentFirestore: () => {
				const app = methods.initialiseFirebase();
				return getFirestore(app);
			},
			logout: () => {
				const auth = methods.getAuth();
				signOut(auth);
			},
			resetStore() {
				setInitial();
			}
		};
	}, [setState, setInitial]);

	const refreshUserToken = useCallback(() => {
		if (store.user) {
			getIdToken(store.user);
		}
	}, [store.user]);

	return { ...methods, refreshUserToken };
};

export default useFirebase;

import type { ButtonProps } from "@mui/material";

export enum SizesEnum {
	AUTO = "auto",
	XS = "xsmall",
	UNDER_SM = "under-small",
	SM = "small",
	UNDER_MD = "under-medium",
	MD = "medium",
	LG = "large",
	XL = "xlarge",
	XXL = "xxlarge"
}

// Button component types
export enum ButtonSizesEnum {
	AUTO = "auto",
	XS = "xsmall",
	UNDER_SM = "under-small",
	SM = "small",
	UNDER_MD = "under-medium",
	MD = "medium",
	LG = "large",
	XL = "xlarge",
	XXL = "xxlarge"
}

export enum ButtonVariantsEnum {
	FILLED = "filled",
	OUTLINED = "outlined",
	LINK = "link",
	GHOST = "ghost",
	DANGER = "danger"
}

export interface ButtonsPropsBase {
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	variant?: ButtonVariantsEnum;
	ref?: ButtonProps["ref"];
	disabled?: boolean;
}

// Text component types
export enum TextVariantsEnum {
	H1 = "H1",
	H2 = "H2",
	H3 = "H3",
	H4 = "H4",
	H5 = "H5",
	H6 = "H6",
	H7 = "H7",
	H8 = "H8",
	BodyLarge = "BodyLarge",
	BodyMedium = "BodyMedium",
	BodySmall = "BodySmall",
	BodyXSmall = "BodyXSmall",
	Number = "Number",
	ButtonLarge = "ButtonLarge",
	ButtonMedium = "ButtonMedium",
	ButtonSmall = "ButtonSmall",
	Caption = "Caption",
	Caption2 = "Caption2",
	Caption3 = "Caption3",
	Title = "Title",
	SPAN = "SPAN",
	INHERIT_SPAN = "INHERIT_SPAN",
	Numbers14 = "Numbers14",
	ERROR = "ERROR"
}

export enum TextStylesEnum {
	Default = "default",
	Alternative = "alternative",
	Hint = "hint",
	Primary = "primary",
	Alert = "alert",
	Disabled = "disabled",
	Inactive = "inactive"
}

export enum MenuListPositionOpts {
	TOP_LEFT = "top-left",
	TOP_CENTER = "top-center",
	TOP_RIGHT = "top-right",
	CENTER_LEFT = "center-left",
	CENTER_CENTER = "center-center",
	CENTER_RIGHT = "center-right",
	BOTTOM_LEFT = "bottom-left",
	BOTTOM_CENTER = "bottom-center",
	BOTTOM_RIGHT = "bottom-right"
}

export enum MenuDotsVariants {
	DEFAULT = "DEFAULT",
	ACTIVE = "ACTIVE"
}

export enum UploaderSizesEnum {
	Small96 = "Small96",
	Small240 = "Small240",
	Rectangle420 = "Rectangle420"
}

export enum ErrorMessageTypes {
	DEFAULT = "DEFAULT",
	WARNING = "WARNING",
	HINT = "HINT"
}

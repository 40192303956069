import { z } from "zod";

// Gender group value = GenderGroupEnum
export enum GenderEnum {
	MALE = "BOYS",
	FEMALE = "GIRLS",
	COED = "COEDS"
}

// Gender
export enum GenderValEnum {
	MALE = "MALE",
	FEMALE = "FEMALE",
	COED = "MIXED"
}

export const ZGenderEnum = z.enum([GenderEnum.MALE, GenderEnum.FEMALE, GenderEnum.COED]);

export enum ReverseGenderEnum {
	BOYS = "Male",
	GIRLS = "Female",
	COEDS = "Coed"
}

import type { CreateFlowStep } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

export interface Values {
	step: number;
	validating?: boolean;
	stepsKeys: CreateFlowStep[];
	allowGoNext: boolean;
	nextCallback?: () => boolean;
	backCallback?: () => boolean;
	callbackValidating: boolean;
}

export const initialState: IStateProps<Values> = {
	step: 1,
	validating: false,
	stepsKeys: [],
	allowGoNext: true,
	callbackValidating: false
};

export default createStore<Values>({
	initialState,
	persistant: true
});

import { z } from "zod";

import type { LogoModel } from "./CreateTypes";

export enum SearchableEntitiesEnum {
	LEAGUES = "Leagues",
	COMPETITIONS = "Competitions",
	GROUPS = "Groups",
	CLUBS = "Clubs",
	REFEREE_ORGANIZATIONS = "Referee Organizations",
	FACILITIES = "Facilities",
	PLAYERS = "Players",
	COACHES = "Coaches",
	USERS = "Users",
	TEAMS = "Teams",
	STORES = "Stores"
}

export interface SearchResultModel {
	id: string;
	name: string;
	address: string;
	link?: string;
	logo?: LogoModel | null;
}

export const paginationTokenResolver = z.string().nullable().optional();
export type PaginationTokenType = z.infer<typeof paginationTokenResolver>;

export interface PaginationParamsModel {
	limit?: number;
	after?: PaginationTokenType;
	before?: PaginationTokenType;
}

export interface PaginationSearchModel extends PaginationParamsModel {
	search?: string;
	limit?: number;
	after?: string;
	// role?: Roles;
}

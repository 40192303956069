import { z } from "zod";

import type { PaginationTokenType } from "shared/types";

import { baseCreateType, logoResolver } from "./CreateTypes";
import { EntitySoccerClubRoles } from "./FirebaseUserRole";
import { InvitationStatus } from "./Invitation";

export const managerInvitationResolver = baseCreateType.pick({ id: true }).extend({
	status: z.nativeEnum(InvitationStatus),
	avatar: logoResolver.nullable(),
	email: z.string(),
	name: z.string().nullable(),
	roles: z.array(z.nativeEnum(EntitySoccerClubRoles)),
	role: z.nativeEnum(EntitySoccerClubRoles),
	shortName: z.string().nullable()
});

export const entityManagementInvitationResolver = baseCreateType
	.pick({
		id: true,
		logo: true,
		name: true
	})
	.extend({
		members: z.array(managerInvitationResolver)
	});

export type ManagerInvitation = z.infer<typeof managerInvitationResolver>;
export type EntityManagementInvitation = z.infer<typeof entityManagementInvitationResolver>;

export interface GetClubMembersResponse {
	club: EntityManagementInvitation;
	nextToken: PaginationTokenType;
}

export interface GetLeagueMembersResponse {
	league: EntityManagementInvitation;
	nextToken: PaginationTokenType;
}

export interface GetFacilityMembersResponse {
	facility: EntityManagementInvitation;
	nextToken: PaginationTokenType;
}

export interface GetRefOrgMembersResponse {
	refereeOrganization: EntityManagementInvitation;
	nextToken: PaginationTokenType;
}

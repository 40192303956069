import config from "config/appConfig";

import type { SearchResultModel } from "shared/types";
import { SearchableEntitiesEnum } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.SEARCH_DATA}`;

export interface Values {
	loading: boolean;
	searchHistory: string[];
	searchResults: SearchResultModel[];
	searchType: SearchableEntitiesEnum;
}

export const initialState: IStateProps<Values> = {
	loading: false,
	searchHistory: [],
	searchResults: [],
	searchType: SearchableEntitiesEnum.LEAGUES
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { useMemo } from "react";

import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import appConfig from "config/appConfig";

// import { setupCache } from "axios-cache-adapter";

import type { z } from "zod";

import { handleZodResolvers } from "utils/handleZodResolvers";

import ApiError from "./ApiError";

import useJwt from "./jwt";

import { useUnAuthorizedInterceptor } from "../interceptors";

// const cache = setupCache({
// 	maxAge: 15 * 60 * 1000, // 15 mins
// 	exclude: {
// 		// Only exclude PUT, PATCH and DELETE methods from cache
// 		methods: ["put", "patch", "delete"]
// 	}
// });

interface OptionProps {
	customErrorHandlers?: any[];
}

interface AxiosZodOptions extends AxiosRequestConfig {
	resolvers?: {
		resolver: z.ZodNullable<any> | z.ZodOptional<any> | z.ZodObject<any> | z.ZodLazy<z.ZodObject<any>>;
		fields?: string[];
	}[];
}

const useHttpActions = (options?: OptionProps) => {
	const { injectJwt, injectBase } = useJwt();
	const interceptUnAuthorized = useUnAuthorizedInterceptor();

	return useMemo(() => {
		const errorInterceptors: any[] = options?.customErrorHandlers || [interceptUnAuthorized];

		const config: AxiosRequestConfig = {
			baseURL: appConfig.GLOBAL_CONSTANTS.BACKEND_API_URL,
			// timeout: 5000,
			withCredentials: false,
			validateStatus: status => status >= 200 && status < 300
			// adapter: cache.adapter
		};

		const request = axios.create(config);

		request.interceptors.response.use(
			response => response,
			response => {
				if (response.response) {
					const { status, data } = response.response;
					const apiErrorInstance = new ApiError(status, data.errors || [], data.message);
					errorInterceptors.forEach(f => f(apiErrorInstance));
					throw apiErrorInstance;
				} else {
					throw Error(response.message);
				}
			}
		);
		return {
			get: async (url: string, params?: any, options?: AxiosZodOptions): Promise<any> => {
				try {
					const { resolvers, ...rest } = options || {};
					const response = await request.get(url, await injectJwt({ params, ...rest }));

					if (resolvers) return handleZodResolvers(resolvers, response);

					return response;
				} catch (error) {
					console.error(error);
				}
			},
			post: async <T>(url: string, formData: T, options?: AxiosZodOptions): Promise<any> => {
				try {
					const { resolvers, ...rest } = options || {};
					const response = await request.post(url, formData, await injectJwt(rest));

					if (resolvers) return handleZodResolvers(resolvers, response);

					return response;
				} catch (error) {
					console.error(error);
				}
			},
			delete: async <T>(
				url: string,
				data?: any,
				params?: any,
				options?: AxiosZodOptions
			): Promise<AxiosResponse<T>> => {
				const config = { url, data, params, ...options };
				return request.delete(url, await injectJwt(config));
			},
			put: async <T>(url: string, data: any, params?: any, options?: AxiosZodOptions): Promise<AxiosResponse<T>> => {
				return request.put(url, data, { params, ...(await injectJwt(options)) });
			},
			patch: async <T>(url: string, data: any, params?: any, options?: AxiosZodOptions): Promise<AxiosResponse<T>> => {
				return request.patch(url, data, { params, ...(await injectJwt(options)) });
			},
			getOriginal: <T>(url: string, params?: any, options?: AxiosZodOptions): Promise<AxiosResponse<T>> => {
				return request.get(url, { params, ...injectBase(options) });
			},
			putOriginal: <T>(url: string, data: any, params?: any, options?: AxiosZodOptions): Promise<AxiosResponse<T>> => {
				return request.put(url, data, { params, ...options });
			},
			getBlob: async (url: string, options?: AxiosZodOptions): Promise<string> => {
				return axios({
					url,
					method: "GET",
					responseType: "blob",
					...(await injectJwt(options))
				}).then(response => window.URL.createObjectURL(new Blob([response.data], { type: "text/csv;charset=utf-8;" })));
			}
		};
	}, [injectJwt, injectBase, interceptUnAuthorized, options]);
};

export default useHttpActions;

import { z } from "zod";

import { EntitySoccerLeagueRoles, leagueCompetitionResolver, paginationTokenResolver } from "shared/types";

import { baseCreateType } from "./CreateTypes";

import { setupStruct } from "./SetupStructModel";

export const followedLeaguesResolver = z.object({
	nextToken: paginationTokenResolver,
	previousToken: paginationTokenResolver,
	leagues: baseCreateType.array()
});

export const leagueResolver = z.lazy(() =>
	baseCreateType.extend({
		label: z.string().optional(),
		distance: z.number().optional(),
		struct: setupStruct.optional().or(z.null()),
		competitions: leagueCompetitionResolver.array().optional(),
		members: z
			.object({
				add: z.array(
					z.object({
						email: z.string(),
						role: z.nativeEnum(EntitySoccerLeagueRoles)
					})
				),
				remove: z.array(
					z.object({
						userId: z.string(),
						role: z.nativeEnum(EntitySoccerLeagueRoles)
					})
				)
			})
			.optional()
	})
);

export type LeagueModel = z.infer<typeof leagueResolver>;

import config from "config/appConfig";

import type { PaginationTokenType, StoreModel } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.STORE_DATA}`;

export interface Values {
	loading: boolean;
	stores: StoreModel[];
	submitting: boolean;
	nextToken: PaginationTokenType;
	previousToken: PaginationTokenType;
}

export const initialState: IStateProps<Values> = {
	loading: false,
	stores: [],
	submitting: false,
	nextToken: null,
	previousToken: null
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { useEffect, useState } from "react";

import { useDebounce } from "./index";

const useWindowSize = (debounce = 0) => {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({
		width: undefined,
		height: undefined
	});

	const debouncedWindowSize = useDebounce(windowSize, debounce || 300);

	useEffect(() => {
		// only execute all the code below in client side
		if (typeof window !== "undefined") {
			// Handler to call on window resize
			const handleResize = () => {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight
				});
			};

			// Add event listener
			window.addEventListener("resize", handleResize);

			// Call handler right away so state gets updated with initial window size
			handleResize();

			// Remove event listener on cleanup
			return () => window.removeEventListener("resize", handleResize);
		}
	}, []);

	return debouncedWindowSize || windowSize;
};

export default useWindowSize;

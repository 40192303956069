import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type { PollModel } from "shared/types";
import { pollResolver } from "shared/types";

import BaseConverter from "./converters/baseConverter";

const usePollApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			vote: async ({
				pollId,
				answers
			}: {
				pollId: string;
				answers: {
					vote: { id: string }[];
					unvote: { id: string }[];
				};
			}) => {
				return BaseConverter.convert<{ poll: PollModel }>(
					await actions.post(
						API_URLS.POLL.VOTE,
						{
							pollId,
							answers
						},
						{
							resolvers: [
								{
									resolver: pollResolver,
									fields: ["poll"]
								}
							]
						}
					)
				);
			}
		}),
		[actions]
	);
};

export default usePollApiService;

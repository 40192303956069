import config from "config/appConfig";

import type {
	BulkPlayerUploadValidateModel,
	ClubModel,
	EntityRoles,
	FullTeamModel,
	LeagueCompetitionModel,
	MatchModel,
	PaginationTokenType,
	PaymentRequestModel,
	PendingPaymentRequestOrderModel,
	PlayerModel,
	SetupEntityModel,
	SetupStructModel,
	ShortPaymentTransactionModel,
	TeamFiltersModelWithId,
	TeamModel
} from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.CLUB_DATA}`;

export interface Values {
	club?: ClubModel;
	clubs: ClubModel[];
	newClub?: Partial<ClubModel>;
	newSetupClub?: Partial<SetupEntityModel>;
	loading: boolean;
	loadingOverview: boolean;
	loadingInfo: boolean;
	overview?: ClubModel;
	info?: ClubModel;
	structure?: SetupStructModel | null;
	loadingStructure: boolean;
	loadingMatches: boolean;

	teams: FullTeamModel[];
	loadingTeams: false;
	nextListingTeamsToken: PaginationTokenType;

	competitions: LeagueCompetitionModel[];
	loadingCompetitions: boolean;
	tempTeams: TeamModel[];
	loadingTeamFilters: boolean;
	teamFilters?: TeamFiltersModelWithId;
	clubSettingsModal?: {
		open: boolean;
		club: ClubModel;
	};
	loadingPlayers: boolean;
	players: PlayerModel[];
	creatingPlayers: boolean;

	addingPlayers: boolean;

	loadingTeamCompetitionStats: boolean;
	loadingPlayerCompetitionStats: boolean;
	loadingCompetitionStats: boolean;
	playerBulkUpload?: BulkPlayerUploadValidateModel;
	uploadingPlayers: boolean;
	userClubRoles: EntityRoles[];
	matches: MatchModel[];
	nextListingCompetitionsToken: PaginationTokenType;
	nextListingPlayersToken: PaginationTokenType;
	nextListingMatchesToken: PaginationTokenType;
	loadingFollowedClubs: boolean;
	followedClubsNextToken: PaginationTokenType;
	inviting: boolean;
	bulkUploadFileUrl?: string;

	paymentRequests: PaymentRequestModel[];
	loadingPaymentRequests: boolean;
	nextListingPaymentRequestsToken: PaginationTokenType;

	pendingOrders: PendingPaymentRequestOrderModel[];
	loadingPendingOrders: boolean;
	nextListingPendingOrdersToken: PaginationTokenType;

	loadingPaymentTransactions: boolean;
	paymentTransactions: ShortPaymentTransactionModel[];
	paymentTransactionsNextToken: PaginationTokenType;

	submitting: boolean;

	loadingPlayerTags: boolean;
	playerTags: string[];
}

export const initialState: IStateProps<Values> = {
	club: undefined,
	clubs: [],
	newClub: undefined,
	newSetupClub: undefined,
	loading: false,
	loadingOverview: false,
	loadingInfo: false,
	overview: undefined,
	structure: undefined,
	loadingStructure: false,
	loadingMatches: false,

	teams: [],
	loadingTeams: false,
	nextListingTeamsToken: null,

	competitions: [],
	loadingCompetitions: false,
	loadingTeamFilters: false,
	teamFilters: undefined,
	clubSettingsModal: undefined,
	tempTeams: [],
	loadingPlayers: false,
	players: [],

	creatingPlayers: false,

	addingPlayers: false,

	loadingTeamCompetitionStats: false,
	loadingPlayerCompetitionStats: false,
	loadingCompetitionStats: false,
	playerBulkUpload: undefined,
	uploadingPlayers: false,
	userClubRoles: [],
	matches: [],
	nextListingCompetitionsToken: null,
	nextListingPlayersToken: null,
	nextListingMatchesToken: null,
	loadingFollowedClubs: false,
	followedClubsNextToken: null,
	inviting: false,
	bulkUploadFileUrl: undefined,

	paymentRequests: [],
	loadingPaymentRequests: false,
	nextListingPaymentRequestsToken: null,

	pendingOrders: [],
	loadingPendingOrders: false,
	nextListingPendingOrdersToken: null,

	loadingPaymentTransactions: false,
	paymentTransactions: [],
	paymentTransactionsNextToken: null,

	submitting: false,

	loadingPlayerTags: false,
	playerTags: []
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

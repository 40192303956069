import { z } from "zod";

import { baseCreateType, logoResolver } from "./CreateTypes";
import { EntitySoccerRefereeOrgRoles } from "./FirebaseUserRole";
import { GenderValEnum } from "./GenderEnum";
import { InvitationStatus } from "./Invitation";
import { ratingResolver } from "./RatingModel";

export enum RefereeRoleEnum {
	MAIN = "MAIN",
	ASSISTANT = "ASSISTANT",
	OTHER = "OTHER"
}

export const refereeResolver = baseCreateType.omit({ name: true }).extend({
	name: z.string().optional().nullable(),
	shortName: z.string().optional().nullable(),
	firstName: z.string().optional().nullable(),
	lastName: z.string().optional().nullable(),
	birthDate: z.string().optional().nullable(),
	gender: z.nativeEnum(GenderValEnum).optional().nullable(),
	email: z.string().email().optional().nullable(),
	status: z.nativeEnum(InvitationStatus).optional(),
	avatar: logoResolver.nullable(),
	rating: ratingResolver.optional()
});

export const organizationResolver = baseCreateType.extend({
	referees: z.array(refereeResolver).optional(),
	members: z
		.object({
			add: z.array(
				z.object({
					email: z.string(),
					role: z.nativeEnum(EntitySoccerRefereeOrgRoles)
				})
			),
			remove: z.array(
				z.object({
					userId: z.string(),
					role: z.nativeEnum(EntitySoccerRefereeOrgRoles)
				})
			)
		})
		.optional()
});

export type OrganizationModel = z.infer<typeof organizationResolver>;
export type RefereeModel = z.infer<typeof refereeResolver>;

import config from "config/appConfig";

import type { CoachModel, PaginationTokenType } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.COACH_DATA}`;

export interface Values {
	inviting: boolean;
	loading: boolean;
	coaches?: CoachModel[];
	nextListingToken: PaginationTokenType;
	nextListingTeamCoachesToken: PaginationTokenType;
}

export const initialState: IStateProps<Values> = {
	inviting: false,
	loading: false,
	coaches: undefined,
	nextListingToken: null,
	nextListingTeamCoachesToken: null
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

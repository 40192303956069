import { useCallback, useMemo } from "react";

import type { StorageAccessLevel } from "@aws-amplify/core";
import type { TransferProgressEvent, UploadDataOutput } from "@aws-amplify/storage";
import { uploadData } from "@aws-amplify/storage";

import { AppConfig } from "config";
import type { MediaModel } from "shared/types";
import { MediaModelSizeEnum } from "shared/types";
import { uuidv4 } from "utils/serviceUtils/helpers";

const useS3Uploader = () => {
	const generateFileUrlByKey = useCallback((key: string, level: StorageAccessLevel) => {
		const levelName = level === "guest" ? "public" : level;
		return `https://${AppConfig.GLOBAL_CONSTANTS.AWS_BUCKET_NAME}.s3.${AppConfig.GLOBAL_CONSTANTS.AMPLIFY_REGION}.amazonaws.com/${levelName}/${key}`;
	}, []);

	const prepareUploadCallback = useCallback(
		({
			fileUrl,
			fileName = uuidv4(),
			bucketName = AppConfig.GLOBAL_CONSTANTS.AWS_BUCKET_NAME,
			region = AppConfig.GLOBAL_CONSTANTS.AMPLIFY_REGION,
			name = MediaModelSizeEnum.source,
			mimeType = "",
			level = "guest"
		}: {
			fileUrl: string;
			fileName?: string;
			bucketName?: string;
			region?: string;
			name?: MediaModelSizeEnum;
			level?: StorageAccessLevel;
			mimeType?: string;
		}): MediaModel => ({
			path: generateFileUrlByKey(fileUrl, level),
			bucketName,
			region,
			name,
			url: null,
			width: null,
			height: null,
			id: fileName,
			mimeType,
			blurhash: null
		}),
		[generateFileUrlByKey]
	);

	const methods = useMemo(
		() => ({
			uploadFile: ({
				file,
				level = "guest",
				handleProgressUpdate,
				handleComplete,
				handleError
			}: {
				file: File;
				level?: StorageAccessLevel;
				handleProgressUpdate?: (progress: number) => void;
				handleComplete?: (uploadedFileInfo: MediaModel) => void;
				handleError?: (err?: string) => void;
			}) => {
				const fileName = uuidv4();

				const upload = uploadData({
					key: fileName,
					data: file,
					options: {
						accessLevel: level,
						onProgress: (event: TransferProgressEvent) => {
							event?.totalBytes &&
								handleProgressUpdate &&
								handleProgressUpdate(event.transferredBytes / event.totalBytes);
						}
					}
				});

				try {
					upload.result
						.then(() => {
							handleComplete &&
								handleComplete(prepareUploadCallback({ fileUrl: fileName, level, mimeType: file.type, fileName }));
						})
						.catch(err => {
							console.log("Can't upload file: ", err);
							handleError && handleError(err);
						});
				} catch (err) {
					console.log("Can't upload file: ", err);
					handleError && handleError("Can't upload file: ");
					return null;
				}

				return upload;
			},
			cancelUpload: (uploader: UploadDataOutput | null | undefined) => {
				uploader?.cancel();
			}
		}),
		[prepareUploadCallback]
	);

	return { ...methods };
};

export default useS3Uploader;

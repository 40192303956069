import { useCallback, useMemo } from "react";

import { useGroupStore } from "shared/contexts";
import { useToast } from "shared/hooks";
import { useGroupApiService } from "shared/services";
import type {
	CompetitionGroupModel,
	CreateScheduleMatchModel,
	EditCompetitionGroupModel,
	EntityRoles,
	IgnoreStateUpdate,
	MatchTypes,
	PaginationParamsModel
} from "shared/types";

import usePlatformEnv from "./usePlatformEnv";

const useGroup = () => {
	const groupService = useGroupApiService();

	const store = useGroupStore();
	const { setState, setInitial } = useGroupStore();

	const { showToast } = useToast();

	const { isShowRoleToasts } = usePlatformEnv();

	const methods = useMemo(() => {
		return {
			findGroups: (search?: string) => {
				return groupService.findGroups(search);
			},
			getGroupTeams: async (groupId: string) => {
				setState({ loadingGroupTeams: true });

				try {
					const { group } = await groupService.getGroupTeams(groupId);
					setState({ groupTeams: group.teams });
					return group.teams;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return [];
				} finally {
					setState({ loadingGroupTeams: false });
				}
			},
			getGroupMatches: async ({
				limit = 10,
				after,
				ignoreLoading,
				ignoreStateUpdate,
				...rest
			}: { groupId: string; matchType?: MatchTypes[] } & PaginationParamsModel & IgnoreStateUpdate) => {
				if (!ignoreLoading) {
					setState({ loadingGroupMatches: true });
				}

				if (!after && !ignoreStateUpdate) {
					setState({ groupMatches: [], nextListingGroupMatchesToken: null });
				}

				try {
					const { matches, nextToken } = await groupService.getGroupMatches({ limit, after, ...rest });
					if (!ignoreStateUpdate) {
						setState(ctx => ({
							groupMatches: !!after ? [...ctx.groupMatches, ...matches] : matches,
							nextListingGroupMatchesToken: ctx?.nextListingGroupMatchesToken !== nextToken ? nextToken : null
						}));
					}

					return {
						matches,
						nextToken
					};
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					if (!ignoreStateUpdate) {
						setState({ nextListingGroupMatchesToken: null });
					}

					return {
						matches: [],
						nextToken: null
					};
				} finally {
					if (!ignoreLoading) {
						setState({ loadingGroupMatches: false });
					}
				}
			},
			getGroupOverview: async (groupId: string) => {
				try {
					setState({ loadingGroupOverview: true });
					const { group, teamsCount, groupsCount } = await groupService.getGroupOverview(groupId);

					setState({
						groupOverview: group,
						groupOverviewTeamsCount: teamsCount,
						groupOverviewSubGroupsCount: groupsCount
					});
					return {
						group,
						teamsCount,
						groupsCount
					};
				} catch {
					showToast({
						text: "Competition group couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					setState({ loadingGroupOverview: false });
				}
			},
			getGroupSchedule: async (id: string) => {
				const { schedule } = await groupService.getGroupSchedule(id);

				return schedule;
			},
			createGroup: async (groupData: {
				competitionId: string;
				name: string;
				ageId: string;
				genderId: string;
				levelId: string;
			}) => {
				setState({ submitting: true });
				try {
					const { group } = await groupService.createGroup(groupData);

					setState({ submitting: false });
					return group;
				} catch (e) {
					console.error("Can't create group: ", e);
					setState({ submitting: false });
				}
			},
			updateGroupSchedule: async ({
				matches,
				...rest
			}: {
				groupId: string;
				info?: {
					startsOn: string;
					daysOfWeek: string[];
					matchesPerDay: number;
					rounds: number;
					matchDurationMinutes: number;
				};
				matches?: {
					create?: CreateScheduleMatchModel[];
					delete?: { id: string }[];
					update?: (Partial<CreateScheduleMatchModel> & { id: string })[];
				};
				// resetSchedule?: boolean;
			}) => {
				if (!matches?.create?.length && !matches?.delete?.length && !matches?.update?.length) return;

				setState({ submitting: true });

				try {
					const { schedule } = await groupService.updateGroupSchedule({
						matches,
						...rest
					});

					return schedule;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			addTeamsToGroup: async (groupId: string, teamIds: string[]) => {
				setState({ submitting: true });

				try {
					const result = await groupService.addTeamsToGroup(groupId, teamIds);
					setState({ submitting: false });
					return result;
				} catch (e) {
					setState({ submitting: false });
					console.error("Can't add teams to group: ", e);
				}
			},
			updateGroup: async ({
				data,
				updateOverview = false,
				includeSubmitting = false
			}: {
				data: Partial<EditCompetitionGroupModel>;
				updateOverview?: boolean;
				includeSubmitting?: boolean;
			}) => {
				if (updateOverview) {
					setState(ctx => ({ groupOverview: { ...ctx.groupOverview, ...data } }));
				}

				if (includeSubmitting) {
					setState({ submitting: true });
				}

				try {
					const { group } = await groupService.updateGroup(data);

					if (data?.teams?.add?.length && !data?.teams?.remove?.length) {
						showToast({
							text: "Selected teams were successfully added to the selected group",
							noIcon: true
						});
					}

					if (data?.teams?.remove?.length && !data?.teams?.add?.length) {
						showToast({
							text: "Selected teams were successfully removed from selected group",
							noIcon: true
						});
					}

					if (data?.teams?.remove?.length && data?.teams?.add?.length) {
						showToast({
							text: "Teams were successfully updated for selected group",
							noIcon: true
						});
					}

					return group;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return null;
				} finally {
					if (includeSubmitting) {
						setState({ submitting: false });
					}
				}
			},
			getGroupInfo: async (id: string, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loadingInfo: true });

				try {
					const { group } = await groupService.getGroupInfo(id);

					setState(ctx => ({ loadingInfo: ignoreLoading ? ctx.loadingInfo : false }));

					if (group?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for group: ${group.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return group;
				} catch {
					showToast({
						text: "Group couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingInfo: false });
					}
				}
			},
			setEditGroupModal: (editGroupModal?: { group: CompetitionGroupModel; onClose?: () => void }) => {
				setState({ editGroupModal });
			},
			getDivisionGroups: async (divisionId: string, ignoreElimination = false) => {
				setState({ loadingDivisionGroups: true });

				try {
					const { groups } = await groupService.getDivisionGroups(divisionId, ignoreElimination);
					setState({ divisionGroups: groups || [] });
					return groups || [];
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					setState({ divisionGroups: [] });
					return [];
				} finally {
					setState({ loadingDivisionGroups: false });
				}
			},
			getGroupPlayers: async (data: { groupId: string; skip?: number; limit?: number }) => {
				setState({ loadingGroupPlayers: true });
				const { group } = await groupService.getGroupPlayers(data);

				setState({ groupPlayers: group.players || [], loadingGroupPlayers: false });

				return group.players || [];
			},
			getGroupStats: async (groupId: string) => {
				setState({ loadingGroupStats: true });
				const { stats } = await groupService.getGroupStats(groupId);

				setState({ loadingGroupStats: false });

				return stats || null;
			},
			getGroupTeamStats: async (groupId: string) => {
				setState({ loadingGroupTeamStats: true });
				const { items } = await groupService.getGroupTeamStats(groupId);

				setState({ loadingGroupTeamStats: false });

				return items || [];
			},
			getGroupPlayerStats: async (groupId: string) => {
				setState({ loadingGroupPlayerStats: true });
				const { items } = await groupService.getGroupPlayerStats(groupId);

				setState({ loadingGroupPlayerStats: false });

				return items || [];
			},
			setUserCompetitionGroupRoles: (userCompetitionGroupRoles: EntityRoles[]) => {
				setState({ userCompetitionGroupRoles });
			},
			getFollowedGroups: async (params: { search?: string } & PaginationParamsModel) => {
				setState({ loadingFollowedGroups: true });
				const { groups, nextToken } = await groupService.getFollowedGroups(params);
				setState({ followedGroupsNextToken: nextToken, loadingFollowedGroups: false });
				return groups;
			},
			substituteDivisionTeam: async (params: { divisionId: string; oldTeamId: string; newTeamId: string }) => {
				setState({ submitting: true });

				try {
					await groupService.substituteDivisionTeam(params);
					showToast({
						text: "Team is successfully substituted",
						noIcon: true
					});
				} catch (error) {
					console.error(error);
					showToast({ text: "Something went wrong", noIcon: true });
				} finally {
					setState({ submitting: false });
				}
			},
			resetStore() {
				setInitial();
			}
		};
	}, [groupService, setState, setInitial, showToast, isShowRoleToasts]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useGroup;

import { z } from "zod";

import type { GenderEnum } from "shared/types";
import {
	AgeGenderLevelModel,
	BackendEntityType,
	GroupStatusEnum,
	InvitationStatus,
	baseCreateType,
	clubResolver,
	mediaModelResolver,
	paginationTokenResolver,
	paymentRequestOrderResolver,
	scoutResolver,
	statsResolver,
	userEducationResolver
} from "shared/types";

import { authResolver, logoResolver } from "./CreateTypes";
import { facilityModel, fieldInfo } from "./FacilityModel";
import { leagueResolver } from "./LeagueModel";
import { PaymentRequestTypes, paymentRequestOrderBaseResolver } from "./PaymentModel";
import { playerResolver } from "./PlayerTypes";
import { CompetitionOpenRegistrationTypes, CompetitionRegistrationTypes } from "./SetupSeasonModel";
import { structAge, structGender, structLevel } from "./SetupStructModel";
import { fullTeamResolver } from "./TeamModel";
import { userProfileEmailResolver, userProfileResolver } from "./UserModel";

export enum GroupKind {
	ELIMINATION_STAGE = "ELIMINATION_STAGE",
	ELIMINATION_RUNNERUP = "ELIMINATION_RUNNER_UP",
	DIVISION = "DIVISION",
	GROUP_STAGE = "GROUP_STAGE",
	SEASON = "SEASON"
}

export enum StageKind {
	ELIMINATION = "ELIMINATION",
	GROUP = "GROUP",
	ELIMINATION_RUNNERUP = "ELIMINATION_RUNNERUP"
}

export enum AdvancingRuleType {
	GROUP_DEFAULT_RANKING = "GROUP_DEFAULT_RANKING",
	SINGLE_GROUP_RANKING = "SINGLE_GROUP_RANKING", // WORLD_CUP
	GROUP_RUNNER_UP_RANKING = "GROUP_RUNNER_UP_RANKING",
	ELIMINATION_MATCH = "ELIMINATION_MATCH"
}

export enum GroupFillStrategy {
	BYE = "BYE",
	WILDCARD = "WILDCARD"
}

export enum CompetitionTypes {
	SEASON = "SEASON",
	TOURNAMENT = "TOURNAMENT"
}

export enum TieBreakerEnums {
	HEAD_TO_HEAD = "HEAD_TO_HEAD",
	MOST_WINS = "MOST_WINS",
	GOAL_DIFFERENTIAL = "GOAL_DIFFERENTIAL",
	GOALS_FOR = "GOALS_FOR",
	GOALS_AGAINST = "GOALS_AGAINST",
	PENALTY_SHOOTOUT = "PENALTY_SHOOTOUT",
	CLEAN_SHEETS = "CLEAN_SHEETS"
	// MOST_SHUTOUTS = "Most shutouts"
}

export enum TournamentSettingsTypes {
	STRING = "string",
	NUMBER = "number",
	BOOLEAN = "boolean"
}

export enum CompetitionAdvancingStyles {
	WORLD_CUP = "WORLD_CUP",
	RANKING = "RANKING"
}

export const tournamentStructSettingsResolver = z.object({
	id: z.string(),
	name: z.string(),
	type: z.nativeEnum(TournamentSettingsTypes),
	value: z.string()
});

export type TournamentStructSettingsModel = z.infer<typeof tournamentStructSettingsResolver>;

export const tournamentStructAgeResolver = structAge.extend({
	settingsId: z.string().optional().nullable(),
	settings: tournamentStructSettingsResolver.array().optional().nullable()
});

export type TournamentStructAgeModel = z.infer<typeof tournamentStructAgeResolver>;

export const tournamentStructLevelResolver = structLevel.extend({
	description: z.string().optional().nullable()
});

export type TournamentStructLevelModel = z.infer<typeof tournamentStructLevelResolver>;

export const tournamentStructResolver = z.object({
	id: z.string(),
	ages: tournamentStructAgeResolver.array(),
	genders: structGender.array(),
	levels: tournamentStructLevelResolver.array()
});

export type TournamentStructModel = z.infer<typeof tournamentStructResolver>;

export const competitionSettingsResolver = z.object({
	advancingStyle: z.nativeEnum(CompetitionAdvancingStyles).optional(),
	drawPoints: z.number().optional(),
	lossPoints: z.number().optional(),
	winPoints: z.number().optional()
});

export const competitionOpenRegistrationResolver = z.object({
	id: z.string(),
	openRegistrationType: z.nativeEnum(CompetitionOpenRegistrationTypes),
	playerTag: z.string().nullable(),
	registrationFee: z.number().nullable(),
	playerClub: baseCreateType.pick({ id: true, name: true, logo: true }),
	paymentRequest: paymentRequestOrderBaseResolver.extend({
		type: z.nativeEnum(PaymentRequestTypes),
		objectId: z.string(),
		objectType: z.nativeEnum(BackendEntityType)
	})
});

export type CompetitionOpenRegistrationModel = z.infer<typeof competitionOpenRegistrationResolver>;

export const competitionResolver = baseCreateType
	.pick({ id: true, name: true, logo: true, auth: true, location: true, website: true, phone: true })
	.extend({
		league: leagueResolver.optional(),
		type: z.nativeEnum(CompetitionTypes),
		startsOn: z.string().optional(),
		endsOn: z.string().optional(),
		registrationEndsOn: z.string().optional(),
		paymentEndsOn: z.string().optional(),
		stats: statsResolver.optional().nullable(),
		description: z.string().optional().nullable(),
		private: z.boolean().optional(),
		struct: tournamentStructResolver.optional().or(z.null()),
		settings: competitionSettingsResolver.optional(),
		hasPlayedMatches: z.boolean().optional(),
		hasPayments: z.boolean().optional(),
		registrationType: z.nativeEnum(CompetitionRegistrationTypes).optional(),
		openRegistration: competitionOpenRegistrationResolver.nullable().optional()
	});

export type CompetitionModel = z.infer<typeof competitionResolver>;

export const leagueCompetitionResolver = baseCreateType
	.pick({
		id: true,
		name: true,
		logo: true
	})
	.extend({
		type: z.nativeEnum(CompetitionTypes),
		startsOn: z.string(),
		endsOn: z.string(),
		createdAt: z.string(),
		appliedClubCount: z.number(),
		appliedTeamCount: z.number(),
		acceptedClubCount: z.number(),
		acceptedTeamCount: z.number(),
		rejectedClubCount: z.number(),
		rejectedTeamCount: z.number(),
		hasPlayedMatches: z.boolean().optional(),
		hasPayments: z.boolean().optional()
	});
export type LeagueCompetitionModel = z.infer<typeof leagueCompetitionResolver>;

export const leagueCompetitionWithTeamsResolver = leagueCompetitionResolver.extend({
	teams: z.array(fullTeamResolver).optional().nullable()
});
export type LeagueCompetitionWithTeamsModel = z.infer<typeof leagueCompetitionWithTeamsResolver>;

export const competitionTeamResolver = fullTeamResolver.extend({
	group: z
		.object({
			id: z.string()
		})
		.optional()
		.nullable()
});

export type CompetitionTeamModel = z.infer<typeof competitionTeamResolver>;

export const getCompetitionTeamResolver = z.object({
	id: z.string(),
	teams: z
		.array(
			fullTeamResolver.extend({
				group: z
					.object({
						id: z.string()
					})
					.nullable()
					.optional()
			})
		)
		.optional()
});

export type GetCompetitionTeamModel = z.infer<typeof getCompetitionTeamResolver>;

export const groupCompetitionBasicInfo = baseCreateType.pick({ id: true, name: true, logo: true });

export const competitionGroupResolver = AgeGenderLevelModel.extend({
	id: z.string(),
	createdAt: z.string().optional(),
	status: z.nativeEnum(GroupStatusEnum).optional(),
	name: z.string(),
	competition: groupCompetitionBasicInfo.or(competitionResolver).optional(),
	teams: z.array(fullTeamResolver).optional().nullable(),
	logo: logoResolver.nullable().optional(),
	auth: authResolver.optional(),
	kind: z.nativeEnum(GroupKind).optional().nullable()
});

export type CompetitionGroupModel = z.infer<typeof competitionGroupResolver>;

export type FetchCompetitionTeamsParams = {
	competitionId: string;
	clubId?: string;
	status?: InvitationStatus;
	ungrouped?: boolean;
	ages?: (string | number)[];
	genders?: GenderEnum[];
	levels?: string[];
	search?: string;
	requestGroup?: boolean;
};

export const editCompetitionGroupResolver = z.object({
	groupId: z.string(),
	name: z.string().optional(),
	ageId: z.string().optional(),
	genderId: z.string().optional(),
	levelId: z.string().optional(),
	teams: z
		.object({
			add: z.array(z.object({ id: z.string() })),
			remove: z.array(z.object({ id: z.string() }))
		})
		.optional(),
	logo: z.union([z.string(), mediaModelResolver, logoResolver]).optional().nullable()
});

export type EditCompetitionGroupModel = z.infer<typeof editCompetitionGroupResolver>;

export const competitionGroupPlayersResolver = z.object({
	id: z.string(),
	players: playerResolver.array().nullable()
});

export type CompetitionGroupPlayersModel = z.infer<typeof competitionGroupPlayersResolver>;
export type GroupCompetitionBasicInfo = z.infer<typeof groupCompetitionBasicInfo>;

export const groupTeamStatsResolver = z.object({
	id: z.string(),
	teams: fullTeamResolver.array().nullable()
});

export type GroupTeamStatsModel = z.infer<typeof groupTeamStatsResolver>;

export const followedCompetitionsResolver = z.object({
	nextToken: paginationTokenResolver,
	previousToken: paginationTokenResolver,
	competitions: baseCreateType.array()
});

export const followedGroupsResolver = z.object({
	nextToken: paginationTokenResolver,
	previousToken: paginationTokenResolver,
	groups: baseCreateType.array()
});

export const competitionCreatedFieldReservationResolver = z.object({
	id: z.string(),
	supportedPlayersPerTeam: z.number(),
	fieldId: z.string(),
	startsAt: z.string(),
	endsAt: z.string()
});

export type CompetitionCreatedFieldReservationModel = z.infer<typeof competitionCreatedFieldReservationResolver>;

export const competitionRemovedFieldReservationResolver = competitionCreatedFieldReservationResolver.pick({ id: true });

export type CompetitionRemovedFieldReservationModel = z.infer<typeof competitionRemovedFieldReservationResolver>;

export const competitionFieldWithReservationResolver = fieldInfo
	.omit({
		rating: true
	})
	.extend({
		reservations: competitionCreatedFieldReservationResolver.array(),
		facility: facilityModel
	});

export type CompetitionFieldWithReservationModel = z.infer<typeof competitionFieldWithReservationResolver>;

export const competitionCreateDivisionStageSettingsResolver = z.object({
	groupTeamCount: z.number().optional(),
	advancingTeamCount: z.number().optional(),
	thirdPlace: z.boolean().optional(),
	groupFillStrategy: z.nativeEnum(GroupFillStrategy).optional()
});

export type CompetitionCreateDivisionStageSettingsModel = z.infer<
	typeof competitionCreateDivisionStageSettingsResolver
>;

export const competitionCreateDivisionStageGroupSettingsResolver = z.object({
	teamCount: z.number()
});

export type CompetitionCreateDivisionStageGroupSettingsModel = z.infer<
	typeof competitionCreateDivisionStageGroupSettingsResolver
>;

export const competitionCreateDivisionStageGroupTeamResolver = z.object({
	id: z.string(),
	ord: z.number()
});

export type CompetitionCreateDivisionStageGroupTeamModel = z.infer<
	typeof competitionCreateDivisionStageGroupTeamResolver
>;

export const competitionCreateDivisionStageGroupResolver = z.object({
	name: z.string(),
	settings: competitionCreateDivisionStageGroupSettingsResolver,
	runnerUp: z.boolean(),
	teams: z.array(competitionCreateDivisionStageGroupTeamResolver).optional()
});

export type CompetitionCreateDivisionStageGroupModel = z.infer<typeof competitionCreateDivisionStageGroupResolver>;

export const competitionCreateDivisionStageResolver = z.object({
	kind: z.nativeEnum(StageKind),
	settings: competitionCreateDivisionStageSettingsResolver,
	groups: z.array(competitionCreateDivisionStageGroupResolver).optional()
});

export type CompetitionCreateDivisionStageModel = z.infer<typeof competitionCreateDivisionStageResolver>;

export const competitionCreateDivisionResolver = z.object({
	name: z.string(),
	ageId: z.string(),
	genderId: z.string(),
	levelId: z.string(),
	feePerTeam: z.number(),
	stages: z.array(competitionCreateDivisionStageResolver)
});

export type CompetitionCreateDivisionModel = z.infer<typeof competitionCreateDivisionResolver>;

export const tournamentDivisionStageGroupTeamResolver = fullTeamResolver.partial().extend({
	age: structAge.partial(),
	gender: structGender.partial(),
	level: structLevel.partial(),
	ord: z.number().optional().nullable()
});

export type TournamentDivisionStageGroupTeamModel = z.infer<typeof tournamentDivisionStageGroupTeamResolver>;

export const tournamentDivisionStageGroupResolver = competitionGroupResolver
	.omit({ auth: true, competition: true, teams: true })
	.extend({
		kind: z.nativeEnum(GroupKind),
		age: structAge.partial(),
		gender: structGender.partial(),
		level: structLevel.partial(),
		teams: tournamentDivisionStageGroupTeamResolver.array()
	});

export type TournamentDivisionStageGroupModel = z.infer<typeof tournamentDivisionStageGroupResolver>;

export const tournamentDivisionStageResolver = z.object({
	id: z.string(),
	kind: z.nativeEnum(StageKind),
	settings: competitionCreateDivisionStageSettingsResolver,
	groups: tournamentDivisionStageGroupResolver.array()
});

export type TournamentDivisionStageModel = z.infer<typeof tournamentDivisionStageResolver>;

export const tournamentDivisionResolver = z.object({
	id: z.string(),
	name: z.string(),
	logo: logoResolver.nullable().optional(),
	age: structAge.partial(),
	gender: structGender.partial(),
	level: structLevel.partial(),
	kind: z.nativeEnum(GroupKind),
	status: z.nativeEnum(GroupStatusEnum),
	stages: tournamentDivisionStageResolver.array(),
	settings: z
		.object({
			feePerTeam: z.number()
		})
		.optional()
		.nullable()
});

export type TournamentDivisionModel = z.infer<typeof tournamentDivisionResolver>;

export const competitionWithScoutsResolver = competitionResolver.pick({ id: true }).extend({
	scouts: z.lazy(() => scoutResolver.array())
});

export const competitionPlayerResolver = playerResolver.extend({
	jerseyNumber: z.number().optional().nullable(),
	education: z.lazy(() => userEducationResolver.pick({ schoolName: true, endsOn: true })).nullable(),
	team: fullTeamResolver
		.pick({ id: true, name: true, logo: true, age: true, gender: true, level: true, club: true })
		.optional()
});

export type CompetitionPlayerModel = z.infer<typeof competitionPlayerResolver>;

export const getCompetitionPlayersResolver = z.object({
	id: z.string(),
	players: z.array(competitionPlayerResolver)
});

export type GetCompetitionPlayersModel = z.infer<typeof getCompetitionPlayersResolver>;

export const competitionTeamPlayersResolver = competitionPlayerResolver
	.pick({
		id: true,
		name: true,
		shortName: true,
		avatar: true,
		jerseyNumber: true,
		education: true
	})
	.extend({
		onboarded: z.boolean(),
		birthDate: z.string().nullable(),
		emails: userProfileEmailResolver.array(),
		guardians: z
			.object({
				guardian: userProfileResolver.pick({
					id: true,
					name: true,
					shortName: true,
					avatar: true,
					onboarded: true,
					birthDate: true,
					emails: true
				}),
				guardianshipId: z.string(),
				status: z.nativeEnum(InvitationStatus)
			})
			.array()
	});

export type CompetitionTeamPlayersModel = z.infer<typeof competitionTeamPlayersResolver>;

export const getCompetitionPlayersByTeamsResolver = baseCreateType.pick({ id: true, logo: true, name: true }).extend({
	teams: z.array(
		fullTeamResolver
			.pick({ id: true, name: true, logo: true, age: true, gender: true, level: true, club: true })
			.extend({
				players: z.array(competitionTeamPlayersResolver)
			})
	)
});

export type GetCompetitionPlayersByTeamsModel = z.infer<typeof getCompetitionPlayersByTeamsResolver>;

export const competitionClubTeamWithPaymentResolver = fullTeamResolver.extend({
	suggestedLevel: structLevel.nullable().optional(),
	order: z.lazy(() =>
		paymentRequestOrderResolver
			.pick({ id: true, status: true })
			.extend({
				paymentRequestId: z.string()
			})
			.nullable()
	),
	divisions: tournamentDivisionResolver.pick({ id: true, name: true, logo: true }).array(),
	hasScheduledMatches: z.boolean().optional()
});
export type CompetitionClubTeamWithPaymentModel = z.infer<typeof competitionClubTeamWithPaymentResolver>;

export const competitionClubWithTeamsResolver = z.lazy(() =>
	clubResolver.pick({ id: true, name: true, logo: true }).extend({
		teams: competitionClubTeamWithPaymentResolver.array()
	})
);
export type CompetitionClubWithTeamsModel = z.infer<typeof competitionClubWithTeamsResolver>;

export const competitionClubsTeamsResolver = competitionResolver
	.pick({
		id: true,
		name: true,
		logo: true
	})
	.extend({
		clubs: competitionClubWithTeamsResolver.array()
	});

export type CompetitionClubsTeamsModel = z.infer<typeof competitionClubsTeamsResolver>;

export enum FBEventType {
	CORE_UPLOAD_PROGRESS = "UPLOAD_PROGRESS"
}

export enum FBEventDataType {
	SOCCER_PLAYERS = "SOCCER_PLAYERS"
}

export enum ValidationFatalErrorCodes {
	emptySheet = "error.fatal.emptySheet",
	fatalError = "error.fatal.fatalError",
	tooManySheets = "error.fatal.tooManySheets"
}

export enum ValidationProgressGenericCodes {
	success = "progress.generic.success",
	emptyCell = "progress.generic.emptyCell",
	emptyRow = "progress.generic.emptyRow"
}

export enum ValidationProgressFailCodes {
	failParseRow = "progress.fail.failParseRow"
}

export enum ValidationGenericErrorCodes {
	invalidCellFormat = "error.generic.invalidCellFormat",
	invalidDateValue = "error.generic.invalidDateValue",
	invalidDateTimeValue = "error.generic.invalidDateTimeValue",
	invalidEmailValue = "error.generic.invalidEmailValue",
	invalidEnumValue = "error.generic.invalidEnumValue",
	invalidGenderValue = "error.generic.invalidGenderValue",
	invalidNull = "error.generic.invalidNull",
	invalidStringValue = "error.generic.invalidStringValue"
}

export interface FBEventData {
	upload: {
		id: string;
		type: FBEventDataType;
		sourceUrl: string;
	};
	progress: {
		validation: {
			done?: boolean;
			sheetNumber?: number;
			rowNumber?: number;
			errors?: {
				sheetNumber: null | number;
				rowNumber: null | number;
				cellNumber: null | number;
				code: ValidationFatalErrorCodes | ValidationGenericErrorCodes;
			}[];
			result?: ValidationProgressGenericCodes | ValidationProgressFailCodes;
		};
	};
}

export interface FBEvent {
	triggeredAt: {
		seconds: number;
		nanoseconds: number;
	};
	type: FBEventType;
	data: FBEventData;
}

import { Suspense, lazy, useMemo } from "react";

import { toast } from "react-toastify";

import type { ToastModelConfig } from "shared/types";

const ToastNotification = lazy(() => import("shared/Components/ToastNotification"));

const useToast = () => {
	return useMemo(
		() => ({
			showToast: async ({ type, position = "bottom-right", autoCloseMs = 10000, ...rest }: ToastModelConfig) => {
				toast(
					({ closeToast }) => (
						<Suspense>
							<ToastNotification type={type} onClose={closeToast} {...rest} />
						</Suspense>
					),
					{
						position: position,
						...rest,
						autoClose: autoCloseMs,
						hideProgressBar: true,
						closeOnClick: false,
						rtl: false,
						pauseOnFocusLoss: true,
						draggable: false,
						pauseOnHover: false
					}
				);
			},
			clearAllToasts: () => {
				toast.dismiss();
			}
		}),
		[]
	);
};

export default useToast;

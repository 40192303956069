import { z } from "zod";

import type { GenderEnum } from "./index";
import { AgeGroupEnum } from "./index";

export interface TabsOpts {
	label: string;
	route: string;
	value: string;
	showFor?: string[];
	hidden?: boolean;
}

export const setupCategoryObject = z.object({
	id: z.string(),
	name: z.string(),
	behavior: z.nativeEnum(AgeGroupEnum).nullable().optional(),
	date: z.string().or(z.date()),
	matchFormat: z.string().optional(),
	duration: z.number().optional(),
	includeHalftime: z.boolean().optional(),
	matchBuffer: z.number().optional(),
	refereePerGame: z.number().optional(),
	value: z.number().optional().nullable(),
	yearOffset: z.number().optional().nullable()
});

export type SetupCategoryModel = z.infer<typeof setupCategoryObject>;

export interface SetupLevelModel {
	id: string;
	name: string;
	description?: string;
}

export interface SetupEntityModel {
	levels: SetupLevelModel[];
	categories: SetupCategoryModel[];
	genders: GenderEnum[];
}

export const genericLevelId = "DEFAULT";

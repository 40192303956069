import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import type {
	BaseCreateType,
	BulkPlayerUploadValidateModel,
	ClubCompetitionsModel,
	ClubModel,
	ClubPlayersModel,
	ClubTeamModel,
	CreateClubPlayerModel,
	EntitySoccerClubRoles,
	FetchClubPlayersParams,
	FetchClubTeamsParams,
	LogoModel,
	MatchModel,
	MatchesFilterModel,
	PaginationParamsModel,
	PaginationTokenType,
	SetupEntityModel,
	StatItemModel,
	TeamFiltersModelWithId,
	TeamModel,
	UpdateClubPlayerFormModal,
	UploadValidateTypes
} from "shared/types";
import {
	bulkPlayerUploadValidateResolver,
	clubPlayersResolver,
	clubResolver,
	clubTeamResolver,
	paginationTokenResolver,
	statItemResolver,
	teamFiltersResolverWithId
} from "shared/types";
import { followedClubsResolver } from "shared/types/ClubModel";

import { convertCreateBaseEntityQuery, convertSetupEntityQuery, convertUpdateBaseEntityQuery } from "./converters";
import BaseConverter from "./converters/baseConverter";

const useClubApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createClub: async (clubData: Omit<ClubModel, "id">) => {
				return BaseConverter.convert<{ club: ClubModel }>(
					await actions.post(API_URLS.CLUB.CREATE, convertCreateBaseEntityQuery(clubData), {
						resolvers: [
							{
								resolver: clubResolver,
								fields: ["club"]
							}
						]
					})
				);
			},
			updateClub: async (
				data: Partial<Omit<ClubModel, "logo">> & {
					logo?: LogoModel | string | null;
					clubId: string;
				}
			) => {
				return BaseConverter.convert<{ club: ClubModel }>(
					await actions.post(API_URLS.CLUB.UPDATE, {
						...convertUpdateBaseEntityQuery(data),
						clubId: data.clubId,
						members: data.members
					})
				);
			},
			setupClub: async (setupClubData: SetupEntityModel & { id: string }) => {
				return BaseConverter.convert<{ club: ClubModel }>(
					await actions.post(API_URLS.CLUB.SETUP, {
						...convertSetupEntityQuery(setupClubData),
						clubId: setupClubData.id
					})
				);
			},
			createClubTeams: async (data: { teams: TeamModel[]; id: string }) => {
				return BaseConverter.convert<{ club: ClubTeamModel }>(
					await actions.post(API_URLS.CLUB.CREATE_TEAMS, {
						teams: data.teams,
						clubId: data.id
					})
				);
			},
			deleteClubTeams: async (clubId: string, teams: { id: string }[]) => {
				return BaseConverter.convert<{ club: { id: string; deletedTeamIds: { id: string }[] } }>(
					await actions.post(API_URLS.CLUB.DELETE_TEAMS, {
						teams,
						clubId
					})
				);
			},
			getFollowedClubs: async ({
				search,
				after
			}: {
				search?: string;
			} & PaginationParamsModel) => {
				return BaseConverter.convert<{
					clubs: BaseCreateType[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.CLUB.LIST_FOLLOWED_CLUBS,
						{
							search: search || undefined,
							after: after || undefined
						},
						{
							resolvers: [{ resolver: followedClubsResolver }]
						}
					)
				);
			},
			getClubMatches: async ({
				clubId,
				filters,
				after,
				limit
			}: {
				clubId: string;
				filters?: Partial<MatchesFilterModel>;
			} & PaginationParamsModel) => {
				return BaseConverter.convert<{
					matches: MatchModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.CLUB.GET_MATCHES, {
						"club-id": clubId,
						"match-status": !!filters?.status?.length ? filters.status.join(",") : undefined,
						limit,
						after
					})
				);
			},
			getClubs: async ({
				search,
				onlyMy,
				clubRoles
			}: {
				search?: string;
				onlyMy?: boolean;
				clubRoles?: EntitySoccerClubRoles[];
			}) => {
				return BaseConverter.convert<{ clubs: ClubModel[] }>(
					await actions.get(onlyMy ? API_URLS.CLUB.GET_MINE : API_URLS.CLUB.GET, {
						search,
						clubRoles: clubRoles?.length ? clubRoles.join(",") : undefined
					})
				);
			},
			getClubOverview: async (id: string) => {
				return BaseConverter.convert<{ club: ClubModel }>(
					await actions.get(API_URLS.CLUB.GET_OVERVIEW, {
						"club-id": id
					})
				);
			},
			getClubInfo: async (id: string) => {
				return BaseConverter.convert<{ club: ClubModel }>(
					await actions.get(API_URLS.CLUB.GET_INFO, {
						"club-id": id
					})
				);
			},
			getStructure: async (id: string) => {
				return BaseConverter.convert<{ club: ClubModel }>(
					await actions.get(API_URLS.CLUB.GET_STRUCTURE, {
						"club-id": id
					})
				);
			},
			getTeams: async ({
				clubId,
				ages,
				genders,
				levels,
				notInCompetitionId = "",
				search,
				limit = 50,
				after
			}: FetchClubTeamsParams & PaginationParamsModel) => {
				const params: {
					clubId: string;
					ages?: string;
					genders?: string;
					levels?: string;
					search?: string;
					"not-in-competition-id"?: string;
				} & PaginationParamsModel = {
					clubId,
					limit,
					after
				};

				if (ages?.length) params["ages"] = ages.join(",");
				if (genders?.length) params["genders"] = genders.join(",");
				if (levels?.length) params["levels"] = levels.join(",");
				if (search) params["search"] = search;
				if (!!notInCompetitionId) params["not-in-competition-id"] = notInCompetitionId; // TODO: check if we used it

				return BaseConverter.convert<{ club: ClubTeamModel; nextToken: PaginationTokenType }>(
					await actions.get(API_URLS.CLUB.GET_TEAMS, params, {
						resolvers: [
							{
								fields: ["club"],
								resolver: clubTeamResolver
							},
							{
								fields: ["nextToken"],
								resolver: paginationTokenResolver
							}
						]
					})
				);
			},
			getCompetitions: async ({
				clubId,
				withTeams,
				limit,
				after
			}: {
				clubId: string;
				withTeams?: boolean;
			} & PaginationParamsModel) => {
				return BaseConverter.convert<{
					club: ClubCompetitionsModel;
					nextToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.CLUB.GET_COMPETITIONS, {
						"club-id": clubId,
						limit,
						after,
						"with-teams": withTeams
					})
				);
			},
			getTeamFilters: async (clubId: string) => {
				return BaseConverter.convert<{ club: TeamFiltersModelWithId }>(
					await actions.get(
						API_URLS.CLUB.GET_TEAM_FILTERS,
						{
							"club-id": clubId
						},
						{ resolvers: [{ resolver: teamFiltersResolverWithId, fields: ["club"] }] }
					)
				);
			},
			createPlayers: async (clubId: string, players: Partial<CreateClubPlayerModel>[]) => {
				return BaseConverter.convert<{ club: { id: string }; createdPlayerCount: number }>(
					await actions.post(API_URLS.CLUB.CREATE_PLAYERS, {
						clubId,
						players
					})
				);
			},
			addPlayersByCompetition: async (competitionId: string, players: { id: string }[]) => {
				return BaseConverter.convert<{ club: { id: string }; createdPlayerCount: number }>(
					await actions.post(API_URLS.CLUB.ADD_PLAYERS_TO_CLUB_BY_COMPETITION, {
						competitionId,
						players
					})
				);
			},
			uploadPlayers: async ({
				clubId,
				teamId,
				upload
			}: {
				clubId: string;
				teamId?: string;
				upload: { id: string; sourceUrl: string };
			}) => {
				return BaseConverter.convert<{ club: { id: string }; createdPlayerCount: number }>(
					await actions.post(API_URLS.CLUB.UPLOAD_CLUB_PLAYERS, {
						clubId,
						teamId,
						upload
					})
				);
			},
			getPlayers: async ({
				clubId,
				limit,
				after,
				search,
				ages,
				genders,
				playerTags,
				teams
			}: FetchClubPlayersParams & PaginationParamsModel) => {
				return BaseConverter.convert<{ club: ClubPlayersModel; nextToken: PaginationTokenType }>(
					await actions.get(
						API_URLS.CLUB.GET_PLAYERS,
						{
							"club-id": clubId,
							search,
							playerAges: ages?.length ? ages.join(",") : undefined,
							playerGenders: genders?.length ? genders.join(",") : undefined,
							playerTeams: teams?.length ? teams.join(",") : undefined,
							playerTags: playerTags?.length ? playerTags.join(",") : undefined,
							limit,
							after
						},
						{
							resolvers: [{ resolver: clubPlayersResolver, fields: ["club"] }]
						}
					)
				);
			},
			updatePlayers: async ({
				clubId,
				players
			}: {
				clubId: string;
				players: {
					remove: { id: string }[];
					update: UpdateClubPlayerFormModal[];
				};
			}) => {
				return BaseConverter.convert<{ club: { id: string }; updatedPlayerCount: number }>(
					await actions.post(API_URLS.CLUB.UPDATE_PLAYERS, {
						clubId,
						players
					})
				);
			},
			getCompetitionStats: async (clubId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.CLUB.GET_COMPETITION_STATS,
						{
							"club-id": clubId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getTeamCompetitionStats: async (clubId: string, competitionId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.CLUB.GET_TEAM_COMPETITION_STATS,
						{
							"club-id": clubId,
							"competition-id": competitionId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getPlayerCompetitionStats: async (clubId: string, competitionId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.CLUB.GET_PLAYER_COMPETITION_STATS,
						{
							"club-id": clubId,
							"competition-id": competitionId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			startValidateBulkUploadPlayers: async (type: UploadValidateTypes, url: string) => {
				return BaseConverter.convert<{ upload: BulkPlayerUploadValidateModel }>(
					await actions.post(
						API_URLS.UPLOADS.VALIDATE,
						{
							type,
							url
						},
						{
							resolvers: [
								{
									resolver: bulkPlayerUploadValidateResolver,
									fields: ["upload"]
								}
							]
						}
					)
				);
			},
			updateClubTeams: async (
				clubId: string,
				teams: { update: { id: string; competitions: { remove: { id: string }[] } }[] }
			) => {
				return BaseConverter.convert<{ club: { id: string } }>(
					await actions.post(API_URLS.CLUB.UPDATE_CLUB_TEAMS, {
						clubId,
						teams
					})
				);
			},
			getPlayerTags: async (clubId: string) => {
				return BaseConverter.convert<{ playerTags: string[] }>(
					await actions.get(API_URLS.CLUB.GET_PLAYER_TAGS, {
						clubId
					})
				);
			},
			resendPlayersInvitation: async (clubId: string, players: { id: string }[]) => {
				return BaseConverter.convert<{
					club: string;
					invitations: { id: string; player: { id: string; email: string } };
				}>(
					await actions.post(API_URLS.CLUB.RESEND_PLAYERS_INVITATION, {
						clubId,
						players
					})
				);
			}
		}),
		[actions]
	);
};

export default useClubApiService;

import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "./converters/baseConverter";

import type { EventModel, PaginationParamsModel, PaginationTokenType } from "../types";

const useEventApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getEvents: async ({ limit = 10, before, after }: PaginationParamsModel) => {
				return BaseConverter.convert<{
					events: EventModel[];
					previousToken: PaginationTokenType;
					nextToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.EVENT.GET_LIST, {
						limit,
						before,
						after
					})
				);
			}
		}),
		[actions]
	);
};

export default useEventApiService;

import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type { FacilityModel, LogoModel, MediaModel } from "shared/types";
import type { CrudFieldModel, FacilityPhotos, FieldInfo, UpdateFacilityData } from "shared/types/FacilityModel";
import { facilityModel } from "shared/types/FacilityModel";

import { convertCreateBaseEntityQuery, convertUpdateBaseEntityQuery } from "./converters";
import BaseConverter from "./converters/baseConverter";

const useFacilityApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createFacility: async (facilityData: Omit<FacilityModel, "id">) => {
				return BaseConverter.convert<{ facility: { id: string } }>(
					await actions.post(API_URLS.FACILITY.CREATE, convertCreateBaseEntityQuery(facilityData))
				);
			},
			getFacilityOverview: async (id: string) => {
				return BaseConverter.convert<{ facility: FacilityModel }>(
					await actions.get(API_URLS.FACILITY.GET_OVERVIEW, {
						"facility-id": id
					})
				);
			},
			getFacilityInfo: async (id: string) => {
				return BaseConverter.convert<{ facility: FacilityModel }>(
					await actions.get(API_URLS.FACILITY.GET_INFO, {
						"facility-id": id
					})
				);
			},
			getFacilityPhotos: async (id: string) => {
				return BaseConverter.convert<{ facility: FacilityPhotos }>(
					await actions.get(API_URLS.FACILITY.GET_PHOTOS, {
						"facility-id": id
					})
				);
			},
			getFacilityFields: async (id: string) => {
				return BaseConverter.convert<{ facility: FacilityModel }>(
					await actions.get(
						API_URLS.FACILITY.FACILITY_FIELDS,
						{
							"facility-id": id
						},
						{
							resolvers: [
								{
									resolver: facilityModel,
									fields: ["facility"]
								}
							]
						}
					)
				);
			},
			updateFacility: async (
				data: Partial<Omit<UpdateFacilityData, "logo">> & { logo?: string | null | MediaModel | LogoModel }
			) => {
				return BaseConverter.convert<{ facility: FacilityModel }>(
					await actions.post(API_URLS.FACILITY.UPDATE, {
						...convertUpdateBaseEntityQuery(data),
						facilityId: data.facilityId,
						photos: data.photos,
						members: data.members
					})
				);
			},
			createFields: async (facilityId: string, fields: FieldInfo[]) => {
				return BaseConverter.convert<{ fields: { id: string }[] }>(
					await actions.post(API_URLS.FIELDS.CREATE, { facilityId, fields })
				);
			},
			updateFacilityFields: async ({
				facilityId,
				create,
				update,
				remove
			}: {
				facilityId: string;
				create: CrudFieldModel[];
				update: CrudFieldModel[];
				remove: { id: string }[];
			}) => {
				return BaseConverter.convert<{ fields: { id: string }[] }>(
					await actions.post(API_URLS.FIELDS.UPDATE, {
						facilityId,
						fields: {
							create,
							update,
							remove
						}
					})
				);
			},
			getFacilities: async ({ search, onlyMy }: { search?: string; onlyMy?: boolean }) => {
				return BaseConverter.convert<{ facilities: FacilityModel[] }>(
					await actions.get(onlyMy ? API_URLS.FACILITY.GET_MINE : API_URLS.FACILITY.GET, {
						search
					})
				);
			},
			findFacilities: async ({
				competitionId,
				search,
				maxMiles,
				limit,
				withFields
			}: {
				competitionId?: string;
				search?: string;
				maxMiles?: number;
				limit?: number;
				withFields?: boolean;
			}) => {
				return BaseConverter.convert<{ facilities: FacilityModel[] }>(
					await actions.get(API_URLS.FACILITY.FIND, {
						"as-competition-id": competitionId,
						search,
						"max-miles": maxMiles,
						limit,
						withFields
					})
				);
			}
		}),
		[actions]
	);
};

export default useFacilityApiService;

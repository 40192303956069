import { useCallback, useMemo } from "react";

import { usePlayerStore } from "shared/contexts";

import { usePlayerApiService } from "shared/services";

import useToast from "./useToast";

import type { PaginationParamsModel } from "../types";

const usePlayer = () => {
	const playerService = usePlayerApiService();
	const { setState, ...store } = usePlayerStore();

	const { showToast } = useToast();

	const methods = useMemo(
		() => ({
			getPlayerInfo: async (id: string) => {
				try {
					setState({ loading: true });
					const { player } = await playerService.getPlayerInfo(id);
					setState({ info: player, loading: false });
					return player;
				} catch {
					showToast({
						text: "Player couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					setState({ loading: false });
				}
			},
			getPlayerOverview: async (id: string) => {
				setState({ loadingOverview: true });
				const { player } = await playerService.getPlayerOverview(id);
				setState({ overview: player, loadingOverview: false });
				return player;
			},
			findPlayers: async (search?: string, customParams?: Record<string, any>) => {
				const { players } = await playerService.findPlayers(search, customParams);
				return players;
			},
			getPlayerMatches: async ({
				id,
				limit,
				after,
				before
			}: {
				id: string;
				limit: number;
			} & PaginationParamsModel) => {
				setState({ loadingMatches: true });
				try {
					const { matches, nextToken, previousToken } = await playerService.getPlayerMatches({
						id,
						limit,
						after,
						before
					});

					setState(ctx => ({
						matches:
							after && ctx.matches.playerId === id
								? {
										...ctx.matches,
										matches: [...ctx.matches.matches, ...matches]
									}
								: {
										playerId: id,
										matches
									},
						beforeToken: previousToken,
						afterToken: nextToken,
						loadingMatches: false
					}));

					return matches;
				} catch (error) {
					console.error(error);
					showToast({ text: "Error getting player matches", noIcon: true });
					setState({ loadingMatches: false });
				}
			},
			getPlayerGroups: async (playerId: string) => {
				setState({ loadingPlayerGroups: true });
				const { groups } = await playerService.getPlayerGroups(playerId);
				setState({ loadingPlayerGroups: false });
				return groups;
			},
			getStats: async (playerId: string) => {
				setState({ loadingStats: true });
				const { items } = await playerService.getPlayerStats(playerId);
				setState({ loadingStats: false });
				return items;
			}
		}),
		[playerService, setState, showToast]
	);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default usePlayer;

import { buildRouteTree } from "utils/routeUtils";

export const routes = buildRouteTree({
	auth: {
		signIn: null,
		logout: null,
		accountType: null,
		otp: null,
		createGuardian: null,
		login: null,
		register: null,
		forgotPassword: null,
		resetPassword: null,
		emailConfirmation: null,
		guardian: {
			create: null,
			childProfile: null,
			fillProfile: null,
			fillChildProfile: null
		},
		fbAuthenticate: null,
		account: {
			create: null,
			fill: null,
			location: null,
			created: null
		},
		complete: null
	}
});

import { pick } from "ramda";

import type { LocationModel, MediaModel, UpdateUserProfileModelProps, UserProfileModel } from "shared/types";

export const convertUserProfileQuery = (
	data: UpdateUserProfileModelProps
): Partial<
	Omit<UserProfileModel, "id" | "onboarded" | "avatar" | "shortName" | "location"> & {
		avatar?: string | null;
		location?: Pick<LocationModel, "lat" | "lng">;
	}
> => {
	let logo: string | null | undefined = (data?.avatar as MediaModel)?.path || (data?.avatar as string) || null;
	if (Array.isArray(data?.avatar) || data?.avatar === undefined) {
		logo = undefined;
	}
	return {
		firstName: data?.firstName || undefined,
		lastName: data?.lastName || undefined,
		birthDate: data?.birthDate || undefined,
		gender: data?.gender || undefined,
		about: data?.about || undefined,
		avatar: logo,
		height: data?.height
			? {
					value: data.height,
					unit: "METER"
				}
			: undefined,
		preferredFoot: data.preferredFoot,
		preferredSoccerPosition: data.preferredSoccerPosition,
		country: typeof data.country === "string" ? data.country : data.country?.value,
		location: data?.location
			? {
					...pick(["lat", "lng"], data.location)
				}
			: undefined
	};
};

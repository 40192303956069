import { useMemo } from "react";

import { useGuardianApiService } from "shared/services";
import type { GuardianKind, UserProfileModel } from "shared/types";

import useToast from "./useToast";

const useGuardian = () => {
	const guardianService = useGuardianApiService();

	const { showToast } = useToast();

	const methods = useMemo(
		() => ({
			createChildProfile: async (props: {
				profile: Partial<UserProfileModel>;
				email?: string;
				guardianKind?: GuardianKind;
			}) => {
				try {
					const { children } = await guardianService.createChildProfile(props);
					return children;
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
				}
			}
		}),
		[guardianService, showToast]
	);

	return { ...methods };
};

export default useGuardian;

import config from "config/appConfig";

import type { ChatMessage, ChatModel, ConversationInfo, ConversationSubjects } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.MESSAGING_DATA}`;

export interface Values {
	chats: ChatModel[];
	loadingChats: boolean;
	loadingConversations: boolean;
	conversationsList: ChatModel[];
	conversationsListNextToken?: string;
	messages?: {
		conversationId: string;
		messages: ChatMessage[];
	};
	currentConversation?: Omit<ConversationInfo, "lastParticipants"> & {
		subject: ConversationSubjects;
	};
	lastParticipants?: ConversationInfo["lastParticipants"];
	deleteMessageDialog?: {
		open: boolean;
		message?: ChatMessage;
	};
	deletingMessage: boolean;
	editMessage?: ChatMessage;
	drafts: {
		conversationId: string;
		message: string;
	}[];
}

export const initialState: IStateProps<Values> = {
	chats: [],
	loadingChats: false,
	loadingConversations: false,
	deletingMessage: false,
	conversationsList: [],
	drafts: []
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { useEffect, useState } from "react";

const useCheckIsEndDateValid = ({ startDate, endDate }: { startDate: string | null; endDate: string | null }) => {
	const [isValidEndDate, setIsValidEndDate] = useState(true);

	useEffect(() => {
		if (startDate && endDate) {
			const isValid = new Date(startDate).getTime() <= new Date(endDate).getTime();
			setIsValidEndDate(isValid);
		}
	}, [endDate, startDate]);

	return isValidEndDate;
};

export default useCheckIsEndDateValid;

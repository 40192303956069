import config from "config/appConfig";

import type { EntityRoles, MatchModel, OrganizationModel, PaginationTokenType, RefereeModel } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.ORGANIZATION_DATA}`;

export interface Values {
	organization?: OrganizationModel;
	newOrganization?: Partial<OrganizationModel>;
	loading: boolean;
	loadingOverview?: boolean;
	loadingInfo?: boolean;
	refereeOrganizations: OrganizationModel[];
	searchReferees: RefereeModel[];
	searchRefereeOrgs: Partial<OrganizationModel>[];
	refereesListOfOrg: RefereeModel[];
	overview?: OrganizationModel;
	info?: OrganizationModel;
	refereeManagerSettingsModal?: {
		open: boolean;
		org: OrganizationModel;
	};
	inviteRefereeDialog?: {
		open: boolean;
	};
	loadingMatches: boolean;
	refereeMatches: MatchModel[];
	userRefereeOrgRoles: EntityRoles[];
	updatingReferees: boolean;
	nextListingMatchesToken: PaginationTokenType;
}

export const initialState: IStateProps<Values> = {
	organization: undefined,
	newOrganization: undefined,
	loading: false,
	loadingOverview: false,
	loadingInfo: false,
	refereeOrganizations: [],
	searchReferees: [],
	searchRefereeOrgs: [],
	refereesListOfOrg: [],
	inviteRefereeDialog: undefined,
	loadingMatches: false,
	refereeMatches: [],
	userRefereeOrgRoles: [],
	updatingReferees: false,
	nextListingMatchesToken: null,
	overview: undefined,
	info: undefined
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { z } from "zod";

export enum AttachmentKind {
	ENTITY = "entity",
	GIF = "gif",
	HASHTAG = "hashtag",
	IMAGE = "image",
	MENTION = "mention",
	POLL = "poll",
	VIDEO = "video"
}

export enum BackendEntityType {
	// Core
	CoreCalendarEvent = "entity.core.calendarEvent",
	CoreEmailAddress = "entity.core.emailAddress",
	CorePerson = "entity.core.person",
	CoreStore = "entity.core.store",
	CorePost = "entity.core.post",
	CorePrincipal = "entity.core.principal",

	// Payments
	PaymentsPaymentRequest = "entity.payments.paymentRequest",

	// Soccer
	SoccerClub = "entity.soccer.club",
	SoccerCompetition = "entity.soccer.competition",
	SoccerFacility = "entity.soccer.facility",
	SoccerField = "entity.soccer.field",
	SoccerGroup = "entity.soccer.group",
	SoccerLeague = "entity.soccer.league",
	SoccerMatch = "entity.soccer.match",
	SoccerReforg = "entity.soccer.reforg",
	SoccerSchedule = "entity.soccer.schedule",
	SoccerSquad = "entity.soccer.squad",
	SoccerTeam = "entity.soccer.team"
}

export const attachmentResolver = z.object({
	kind: z.nativeEnum(AttachmentKind),
	name: z.string().optional().nullable(),
	entity: z
		.object({
			type: z.nativeEnum(BackendEntityType).optional(),
			id: z.string(),
			name: z.string().nullable(),
			info: z.any()
		})
		.optional()
		.nullable(),
	gif: z.object({ giphyId: z.string() }).nullable().optional(),
	image: z.object({ url: z.string() }).optional().nullable(),
	mention: z
		.object({
			userId: z.string()
		})
		.nullable()
		.optional(),
	video: z.object({ url: z.string() }).optional().nullable()
});

export type AttachmentModel = z.infer<typeof attachmentResolver>;

import { useEffect } from "react";

import useCreateFlowRouter from "./useCreateFlowRouter";

const useUpdateStepData = ({ nextCb, backCb }: { nextCb?: () => void; backCb?: () => void }) => {
	const { registerNextCallback, registerBackCallback } = useCreateFlowRouter();

	useEffect(() => {
		nextCb && registerNextCallback(nextCb);
		backCb && registerBackCallback(backCb);

		return () => {
			registerNextCallback();
			registerBackCallback();
		};
	}, [registerBackCallback, registerNextCallback, nextCb, backCb]);
};

export default useUpdateStepData;

export enum LocalisationEntities {
	COMMON = "common",
	FEED = "feed",
	PLAYER = "player",
	DATE = "date",
	ERROR = "error",
	MATCH = "match",
	INVITATION = "invitation",
	PAYMENT = "payment",
	FACILITY = "facility",
	MESSAGING = "messaging",
	PROFILE = "profile",
	COMPETITION = "competition",
	ADMIN = "admin"
}

export enum SupportedLanguages {
	ENGLISH = "en",
	SPANISH = "es",
	RUSSIAN = "ru"
}

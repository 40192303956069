import { useMemo } from "react";

import { useShareApiService } from "shared/services";
import type { FirebaseLinkOptions } from "shared/services/useShareApiService";
import type { EntityFollowListEnum } from "shared/types";

import useToast from "./useToast";

const useShare = () => {
	const { showToast } = useToast();
	const shareService = useShareApiService();

	const methods = useMemo(
		() => ({
			copyLink: async ({
				socialMetaTagInfo,
				analyticsInfo,
				dontCopyToClipboard,
				customLink
			}: {
				socialMetaTagInfo: FirebaseLinkOptions["socialMetaTagInfo"];
				analyticsInfo: FirebaseLinkOptions["analyticsInfo"];
				dontCopyToClipboard?: boolean;
				customLink?: string;
			}) => {
				const { shortLink } = await shareService.createFBLink(
					{
						socialMetaTagInfo,
						analyticsInfo
					},
					customLink
				);

				if (shortLink && !dontCopyToClipboard) {
					await navigator.clipboard.writeText(shortLink);
					showToast({ text: "Link copied to clipboard", noIcon: true });
				}

				return shortLink;
			},
			async prepareMessage({ entityType, creatorName }: { entityType?: EntityFollowListEnum; creatorName?: string }) {
				return `Check out this ${entityType} ${creatorName ? `by ${creatorName}` : "on Tapin Sports"}`;
			}
		}),
		[shareService, showToast]
	);

	return { ...methods };
};

export default useShare;

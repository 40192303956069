import { useCallback, useMemo } from "react";

import useToast from "./useToast";

import { useUser } from "./index";
import { useEventStore } from "../contexts";

import { useEventApiService } from "../services";
import type { EventModel, PaginationParamsModel, RSVPStatusEnum } from "../types";

const useEvent = () => {
	const eventService = useEventApiService();

	const { setState, setInitial, ...store } = useEventStore();

	const { updateMyAttendance } = useUser();

	const { showToast } = useToast();

	const methods = useMemo(() => {
		return {
			getEvents: async (params: PaginationParamsModel) => {
				setState({ loading: true, loadingBefore: !!params?.before, loadingAfter: !!params?.after });
				try {
					if (!params.before && !params.after) {
						setState({
							previousToken: null,
							nextToken: null
						});
					}
					const { events, previousToken, nextToken } = await eventService.getEvents(params);
					setState(ctx => ({
						events: params?.after
							? //
								[...ctx.events, ...events]
							: params?.before
								? [...events, ...ctx.events]
								: events,
						previousToken: params?.after ? ctx.previousToken : previousToken,
						nextToken: params?.before ? ctx.nextToken : nextToken
					}));
					return {
						events,
						nextToken,
						previousToken
					};
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });
					return {
						events: [],
						nextToken: null,
						previousToken: null
					};
				} finally {
					setState({ loading: false, loadingBefore: false, loadingAfter: false });
				}
			},
			updateEventRSVPStatus: async (matchId: string, status: RSVPStatusEnum) => {
				await updateMyAttendance(matchId, status);

				setState(ctx => ({
					events: [...ctx.events].map(event =>
						(event as EventModel).soccer.match?.id === matchId
							? {
									...event,
									soccer: {
										...event.soccer,
										myAttendanceStatus: status
									}
								}
							: event
					)
				}));
			},
			resetStore() {
				setInitial();
			}
		};
	}, [setState, eventService, setInitial, updateMyAttendance, showToast]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useEvent;

import { useCallback, useMemo } from "react";

import { useInvitationApiService } from "shared/services";

import { uniqueifyDataByKey } from "utils/uniqueifyData";

import { useToast } from "./index";
import { useInvitationStore } from "../contexts";
import type { EntityFollowListEnum, InvitationModel } from "../types";

const useInvitation = () => {
	const invitationService = useInvitationApiService();

	const { setState, setInitial, ...store } = useInvitationStore();

	const { showToast } = useToast();

	const methods = useMemo(() => {
		return {
			getInfo: async (invitationId: string) => {
				setState({ loading: true });
				const { invitation } = await invitationService.getInfo(invitationId);
				setState({ loading: false });
				return invitation;
			},
			inviteReferees: async (refereeOrganizationId: string, emails: string[]) => {
				const { invitations } = await invitationService.inviteReferees(refereeOrganizationId, emails);
				showToast({ text: "Org invitations were sent to the selected referees", noIcon: true });
				return invitations;
			},
			setTempInvitation: async (tempInvitation?: Partial<InvitationModel>) => {
				setState({ tempInvitation });
			},
			acceptInvitation: async (invitationId: string, asPersonId?: string) => {
				try {
					const invitation = await invitationService.acceptInvitation(invitationId, asPersonId);

					if (invitation) {
						showToast({ text: "You have successfully accepted an invitation", noIcon: true });
					}

					return invitation;
				} catch (e) {
					showToast({ text: "Couldn't accept invitation, please try again later.", noIcon: true });
					return null;
				}
			},
			getInvitations: async ({ limit, previousToken }: { limit: number; previousToken?: string }) => {
				setState({ loading: true });
				try {
					const {
						invitations,
						nextToken,
						previousToken: prevToken
					} = await invitationService.getInvitations(limit, previousToken);

					setState(ctx => ({
						nextToken: nextToken || null,
						previousToken: prevToken || null,
						invitations:
							!!ctx.invitations.length && previousToken
								? uniqueifyDataByKey([...ctx.invitations, ...invitations])
								: uniqueifyDataByKey(invitations)
					}));

					return invitations;
				} catch (error) {
					console.log(error);
					showToast({ text: "Could not get notifications", noIcon: true });
				} finally {
					setState({ loading: false });
				}
			},
			inviteToFollowEntity: async (props: { entityId: string; emails: string[]; entityType: EntityFollowListEnum }) => {
				setState({ inviting: true });
				try {
					const response = await invitationService.inviteToFollowEntity(props);
					response &&
						showToast({ text: `Invitations were sent to ${response.createdInvitationCount} followers`, noIcon: true });
				} catch (error) {
					showToast({ text: "Error inviting followers", noIcon: true });
					console.error("Invite followers failed", error);
				} finally {
					setState({ inviting: false });
				}
			},
			setInvitation: (invitation: Partial<InvitationModel>) => {
				setState(ctx => ({
					invitations: (ctx.invitations || []).map(i => (i.id === invitation.id ? { ...i, ...invitation } : i))
				}));
			},
			setInvitations: (invitations: InvitationModel[]) => {
				setState({ invitations });
			},
			resetStore() {
				setInitial();
			}
		};
	}, [invitationService, showToast, setState, setInitial]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useInvitation;

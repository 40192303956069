import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import type {
	LogoModel,
	MatchModel,
	MatchesFilterModel,
	MediaModel,
	OrganizationModel,
	PaginationParamsModel,
	PaginationTokenType,
	RefereeModel
} from "shared/types";
import { refereeResolver } from "shared/types";

import { convertCreateBaseEntityQuery, convertUpdateBaseEntityQuery } from "./converters";

import BaseConverter from "./converters/baseConverter";

const useOrganizationApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createOrganization: async (organizationData: Omit<OrganizationModel, "id">) => {
				return BaseConverter.convert<{ refereeOrganization: OrganizationModel }>(
					await actions.post(API_URLS.ORGANIZATION.CREATE, convertCreateBaseEntityQuery(organizationData))
				);
			},
			getOrgOverview: async (id: string) => {
				return BaseConverter.convert<{ refereeOrganization: OrganizationModel }>(
					await actions.get(API_URLS.ORGANIZATION.GET_OVERVIEW, {
						"referee-organization-id": id
					})
				);
			},
			getOrgInfo: async (id: string) => {
				return BaseConverter.convert<{ reforg: OrganizationModel }>(
					await actions.get(API_URLS.ORGANIZATION.GET_INFO, {
						"reforg-id": id
					})
				);
			},
			getOrgMatches: async ({
				orgId,
				filters,
				limit,
				after
			}: { orgId: string; filters?: Partial<MatchesFilterModel> } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					matches: MatchModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.ORGANIZATION.GET_MATCHES, {
						"referee-organization-id": orgId,
						"match-status": !!filters?.status?.length ? filters.status.join(",") : undefined,
						limit,
						after
					})
				);
			},
			getRefereeOrganizations: async ({ search, onlyMy }: { search?: string; onlyMy?: boolean }) => {
				return BaseConverter.convert<{ refereeOrganizations: OrganizationModel[] }>(
					await actions.get(onlyMy ? API_URLS.ORGANIZATION.GET_MINE : API_URLS.ORGANIZATION.GET, {
						search
					})
				);
			},
			findReferees: async ({
				competitionId,
				refereeOrgId,
				search,
				maxMiles,
				limit
			}: {
				competitionId?: string;
				refereeOrgId?: string;
				search?: string;
				maxMiles?: number;
				limit?: number;
			}) => {
				return BaseConverter.convert<{ referees: Partial<RefereeModel>[] }>(
					await actions.get(
						API_URLS.ORGANIZATION.FIND_REFEREES,
						{
							"as-competition-id": competitionId,
							"as-referee-organization-id": refereeOrgId,
							search,
							"max-miles": maxMiles,
							limit
						},
						{
							resolvers: [
								{
									fields: ["referees"],
									resolver: refereeResolver.partial()
								}
							]
						}
					)
				);
			},
			findRefereeOrgs: async ({
				competitionId,
				search,
				maxMiles,
				limit
			}: {
				competitionId?: string;
				search?: string;
				maxMiles?: number;
				limit?: number;
			}) => {
				return BaseConverter.convert<{ refereeOrganizations: Partial<OrganizationModel>[] }>(
					await actions.get(API_URLS.ORGANIZATION.FIND_REFEREE_ORGS, {
						"as-competition-id": competitionId,
						search,
						"max-miles": maxMiles,
						limit
					})
				);
			},
			findRefereeOrgsForSearch: async (search?: string) => {
				return BaseConverter.convert<{ refereeOrganizations: Partial<OrganizationModel>[] }>(
					await actions.get(API_URLS.ORGANIZATION.FIND_REFEREE_ORGS_FOR_SEARCH, {
						search
					})
				);
			},
			listOrgReferees: async (refOrgId: string) => {
				return BaseConverter.convert<{ refereeOrganization: OrganizationModel }>(
					await actions.get(API_URLS.ORGANIZATION.LIST_REFEREE_ORG_REFEREES, {
						"referee-organization-id": refOrgId
					})
				);
			},
			updateOrgReferees: async (
				refOrgId: string,
				referees: {
					remove: { id: string }[];
					update: Partial<RefereeModel>[];
				}
			) => {
				return BaseConverter.convert<{ refereeOrganization: { id: string } }>(
					await actions.post(API_URLS.ORGANIZATION.UPDATE_REFEREES, {
						refereeOrganizationId: refOrgId,
						referees
					})
				);
			},
			updateOrg: async (
				data: Partial<Omit<OrganizationModel, "logo">> & {
					refereeOrganizationId: string;
					logo?: string | null | MediaModel | LogoModel;
				}
			) => {
				return BaseConverter.convert<{ refereeOrganization: OrganizationModel }>(
					await actions.post(API_URLS.ORGANIZATION.UPDATE, {
						...convertUpdateBaseEntityQuery(data),
						refereeOrganizationId: data.refereeOrganizationId,
						members: data.members
					})
				);
			}
		}),
		[actions]
	);
};

export default useOrganizationApiService;

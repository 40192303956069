import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import { convertUserProfileQuery } from "./converters";
import BaseConverter from "./converters/baseConverter";

import type {
	AttendanceModel,
	BaseCreateType,
	CheckNotificationUpdatesModel,
	NewConnectionResponseModel,
	PaginationParamsModel,
	PaginationTokenType,
	PendingPaymentRequestOrderModel,
	RSVPStatusEnum,
	SearchConnectionModel,
	ShortPaymentTransactionModel,
	TemporalDependencyTypes,
	UserEducationModel,
	UserExperienceModel,
	UserModel,
	UserOverviewModel,
	UserProfileModel,
	UserSocialLinkedAccountModel
} from "../types";
import {
	attendanceResolver,
	checkNotificationUpdatesResolver,
	newConnectionResolver,
	paginationTokenResolver,
	pendingPaymentRequestOrderResolver,
	personEducationResolver,
	personExperienceResolver,
	personSocialLinkedAccountsResolver,
	searchConnectionResolver,
	shortPaymentTransactionResolver,
	userOverviewResolver,
	userResolver
} from "../types";
import type { ChildListModel, UpdateUserProfileModelProps, UserEmailsModel } from "../types/UserModel";
import {
	childListResolver,
	followedUsersResolver,
	searchPersonResolver,
	userEmailsObjects,
	userProfileResolver
} from "../types/UserModel";

const useUserService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			followEntity: (id: string, entityType: string) => {
				return actions.post(API_URLS.INTERESTS.FOLLOW(entityType), {
					[`${entityType}Id`]: id
				});
			},
			getKids: async () => {
				return BaseConverter.convert<ChildListModel>(
					await actions.get(API_URLS.USER.GET_KIDS, null, {
						resolvers: [
							{
								resolver: childListResolver
							}
						]
					})
				);
			},
			unfollowEntity: (id: string, entityType: string) => {
				return actions.post(API_URLS.INTERESTS.UNFOLLOW(entityType), {
					[`${entityType}Id`]: id
				});
			},
			getUserInfo: async () => {
				return BaseConverter.convert<UserModel>(
					await actions.get(API_URLS.USER.CURRENT_USER_INFO, null, {
						resolvers: [
							{
								resolver: userResolver
							}
						]
					})
				);
			},
			getUserProfileInfo: async (userId: string) => {
				return BaseConverter.convert<{ person: UserProfileModel; connection: NewConnectionResponseModel }>(
					await actions.get(
						API_URLS.USER.GET_INFO,
						{ "person-id": userId },
						{
							resolvers: [
								{
									fields: ["person"],
									resolver: userProfileResolver
								},
								{
									fields: ["connection"],
									resolver: newConnectionResolver
								}
							]
						}
					)
				);
			},
			getOverview: async (userId: string) => {
				return BaseConverter.convert<{ person: UserOverviewModel }>(
					await actions.get(
						API_URLS.USER.GET_OVERVIEW,
						{ "person-id": userId },
						{
							resolvers: [
								{
									fields: ["person"],
									resolver: userOverviewResolver
								}
							]
						}
					)
				);
			},
			getFollowedUsers: async ({ search, after }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					people: BaseCreateType[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.USER.LIST_FOLLOWED_USERS,
						{
							search: search || undefined,
							after: after || undefined
						},
						{
							resolvers: [
								{
									resolver: followedUsersResolver
								}
							]
						}
					)
				);
			},
			updateUserProfile: async (profile: Partial<UpdateUserProfileModelProps>) => {
				return BaseConverter.convert<{ person: UserProfileModel }>(
					await actions.post(API_URLS.USER.UPDATE_PROFILE, convertUserProfileQuery(profile), {
						resolvers: [
							{
								resolver: userProfileResolver,
								fields: ["person"]
							}
						]
					})
				);
			},
			updatePersonProfile: async (profile: Partial<UpdateUserProfileModelProps> & { personId: string }) => {
				return BaseConverter.convert<{ person: UserProfileModel }>(
					await actions.post(
						API_URLS.USER.UPDATE_PERSON_PROFILE,
						{
							personId: profile.personId,
							...convertUserProfileQuery(profile)
						},
						{
							resolvers: [
								{
									resolver: userProfileResolver,
									fields: ["person"]
								}
							]
						}
					)
				);
			},
			connectWithUser: async (connectedUserId: string) => {
				return BaseConverter.convert<{ connection: NewConnectionResponseModel }>(
					await actions.post(
						API_URLS.USER.CONNECT,
						{
							connectedUserId
						},
						{
							resolvers: [
								{
									resolver: newConnectionResolver,
									fields: ["connection"]
								}
							]
						}
					)
				);
			},
			rejectUserConnectionRequest: async (connectedUserId: string) => {
				return BaseConverter.convert<{ connection: NewConnectionResponseModel }>(
					await actions.post(
						API_URLS.USER.REJECT_CONNECTION,
						{
							connectedUserId
						},
						{
							resolvers: [
								{
									resolver: newConnectionResolver,
									fields: ["connection"]
								}
							]
						}
					)
				);
			},
			cancelConnectionUserRequest: async (connectedUserId: string) => {
				return BaseConverter.convert<{ connection: NewConnectionResponseModel }>(
					await actions.post(
						API_URLS.USER.CANCEL_CONNECTION,
						{
							connectedUserId
						},
						{
							resolvers: [
								{
									resolver: newConnectionResolver,
									fields: ["connection"]
								}
							]
						}
					)
				);
			},
			checkEmailAvailability: async (email: string) => {
				return BaseConverter.convert<{
					available: boolean;
					login: string;
					email: string;
					providers: string[];
					emailVerified: boolean;
					emailLogin: string;
				}>(await actions.post(API_URLS.USER.CHECK_EMAIL_AVAILABILITY, { email }));
			},
			listMyEmails: async () => {
				return BaseConverter.convert<UserEmailsModel>(
					await actions.get(API_URLS.USER.LIST_MY_EMAILS, null, {
						resolvers: [
							{
								resolver: userEmailsObjects
							}
						]
					})
				);
			},
			addEmailAddress: async (address: string) => {
				return actions.post(API_URLS.USER.ADD_EMAIL_ADDRESS, { address });
			},
			confirmNewEmail: async (confirmationSessionId: string, confirmationCode: string) => {
				return actions.post(API_URLS.USER.CONFIRM_EMAIL_ADDRESS, {
					confirmationSessionId,
					confirmationCode
				});
			},
			updateMyAttendance: async (entityId: string, status: RSVPStatusEnum) => {
				return BaseConverter.convert<{ attendance: AttendanceModel }>(
					await actions.post(
						API_URLS.USER.UPDATE_MY_ATTENDANCE,
						{
							entityId,
							entityType: "MATCH",
							status
						},
						{
							resolvers: [
								{
									resolver: attendanceResolver,
									fields: ["attendance"]
								}
							]
						}
					)
				);
			},
			findUsers: async (search?: string) => {
				return BaseConverter.convert<{ people: UserProfileModel[] }>(
					await actions.get(
						API_URLS.USER.FIND_USERS,
						{
							search
						},
						{
							resolvers: [
								{
									resolver: searchPersonResolver,
									fields: ["people"]
								}
							]
						}
					)
				);
			},
			unsubscribeFromEmails: async () => {
				return actions.post(API_URLS.USER.UNSUBSCRIBE_FROM_EMAILS, {});
			},
			searchConnections: async ({ search, before, after, limit }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					users: SearchConnectionModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.USER.SEARCH_CONNECTIONS,
						{
							search,
							limit,
							after,
							before
						},
						{
							resolvers: [
								{
									resolver: searchConnectionResolver,
									fields: ["users"]
								},
								{
									resolver: paginationTokenResolver,
									fields: ["nextToken"]
								}
							]
						}
					)
				);
			},
			deleteAccount: async () => {
				return BaseConverter.convert<{ success: boolean }>(await actions.post(API_URLS.USER.DELETE_ACCOUNT, {}));
			},
			checkNotificationUpdates: async () => {
				return BaseConverter.convert<CheckNotificationUpdatesModel>(
					await actions.get(
						API_URLS.NOTIFICATION_UPDATES.CHECK,
						{},
						{
							resolvers: [
								{
									resolver: checkNotificationUpdatesResolver
								}
							]
						}
					)
				);
			},
			updateNotificationTimeCheck: async (temporalDependencyTypes: { type: TemporalDependencyTypes }[]) => {
				return BaseConverter.convert<{ updatedAt: string }>(
					await actions.post(API_URLS.NOTIFICATION_UPDATES.UPDATE_TIME_CHECK, { temporalDependencyTypes })
				);
			},
			getPersonExperiences: async (personId: string) => {
				return BaseConverter.convert<{ person: { id: string; experiences: UserExperienceModel[] } }>(
					await actions.get(
						API_URLS.USER.GET_PERSON_EXPERIENCE,
						{
							personId
						},
						{
							resolvers: [
								{
									resolver: personExperienceResolver
								}
							]
						}
					)
				);
			},
			updateMyExperience: async (params: {
				add?: Omit<UserExperienceModel, "id">[];
				remove?: Pick<UserExperienceModel, "id">[];
				update?: (Partial<UserExperienceModel> & Pick<UserExperienceModel, "id">)[];
			}) => {
				return BaseConverter.convert<{ person: { id: string } }>(
					await actions.post(API_URLS.USER.UPDATE_MY_EXPERIENCE, params)
				);
			},
			updatePersonExperience: async (params: {
				personId: string;
				add?: Omit<UserExperienceModel, "id">[];
				remove?: Pick<UserExperienceModel, "id">[];
				update?: (Partial<UserExperienceModel> & Pick<UserExperienceModel, "id">)[];
			}) => {
				return BaseConverter.convert<{ person: { id: string } }>(
					await actions.post(API_URLS.USER.UPDATE_PERSON_EXPERIENCE, params)
				);
			},
			getPersonEducation: async (personId: string) => {
				return BaseConverter.convert<{ person: { id: string; education: UserEducationModel[] } }>(
					await actions.get(
						API_URLS.USER.GET_PERSON_EDUCATION,
						{
							personId
						},
						{
							resolvers: [
								{
									resolver: personEducationResolver
								}
							]
						}
					)
				);
			},
			updateMyEducation: async (params: {
				add?: Partial<Omit<UserEducationModel, "id">>[];
				remove?: Pick<UserEducationModel, "id">[];
				update?: (Partial<UserEducationModel> & Pick<UserEducationModel, "id">)[];
			}) => {
				return BaseConverter.convert<{ person: { id: string } }>(
					await actions.post(API_URLS.USER.UPDATE_MY_EDUCATION, params)
				);
			},
			updatePersonEducation: async (params: {
				personId: string;
				add?: Partial<Omit<UserEducationModel, "id">>[];
				remove?: Pick<UserEducationModel, "id">[];
				update?: (Partial<UserEducationModel> & Pick<UserEducationModel, "id">)[];
			}) => {
				return BaseConverter.convert<{ person: { id: string } }>(
					await actions.post(API_URLS.USER.UPDATE_PERSON_EDUCATION, params)
				);
			},
			getPersonSocialLinkedAccounts: async (personId: string) => {
				return BaseConverter.convert<{ person: { id: string; linkedAccounts: UserSocialLinkedAccountModel[] } }>(
					await actions.get(
						API_URLS.USER.GET_PERSON_SOCIAL_LINKED_ACCOUNTS,
						{
							personId
						},
						{
							resolvers: [
								{
									resolver: personSocialLinkedAccountsResolver
								}
							]
						}
					)
				);
			},
			updateMySocialLinkedAccounts: async (params: {
				add?: Partial<Omit<UserSocialLinkedAccountModel, "id">>[];
				remove?: Pick<UserSocialLinkedAccountModel, "id">[];
				update?: (Partial<UserSocialLinkedAccountModel> & Pick<UserSocialLinkedAccountModel, "id">)[];
			}) => {
				return BaseConverter.convert<{ person: { id: string } }>(
					await actions.post(API_URLS.USER.UPDATE_MY_SOCIAL_LINKED_ACCOUNTS, params)
				);
			},
			updatePersonSocialLinkedAccounts: async (params: {
				personId: string;
				add?: Partial<Omit<UserSocialLinkedAccountModel, "id">>[];
				remove?: Pick<UserSocialLinkedAccountModel, "id">[];
				update?: (Partial<UserSocialLinkedAccountModel> & Pick<UserSocialLinkedAccountModel, "id">)[];
			}) => {
				return BaseConverter.convert<{ person: { id: string } }>(
					await actions.post(API_URLS.USER.UPDATE_PERSON_SOCIAL_LINKED_ACCOUNTS, params)
				);
			},
			getPaymentHistory: async (params: PaginationParamsModel) => {
				return BaseConverter.convert<{
					nextToken: PaginationTokenType;
					transactions: ShortPaymentTransactionModel[];
				}>(
					await actions.get(API_URLS.USER.LIST_MY_TRANSACTIONS, params, {
						resolvers: [
							{
								resolver: shortPaymentTransactionResolver,
								fields: ["transactions"]
							},
							{
								resolver: paginationTokenResolver,
								fields: ["nextToken"]
							}
						]
					})
				);
			},
			getPendingOrders: async (params: PaginationParamsModel) => {
				return BaseConverter.convert<{ nextToken: PaginationTokenType; orders: PendingPaymentRequestOrderModel[] }>(
					await actions.get(API_URLS.USER.LIST_MY_PENDING_ORDERS, params, {
						resolvers: [
							{
								resolver: pendingPaymentRequestOrderResolver,
								fields: ["orders"]
							},
							{
								resolver: paginationTokenResolver,
								fields: ["nextToken"]
							}
						]
					})
				);
			}
		}),
		[actions]
	);
};

export default useUserService;

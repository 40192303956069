import { z } from "zod";

import { baseCreateType, logoResolver } from "./CreateTypes";
import { GenderValEnum } from "./GenderEnum";
import { InvitationStatus } from "./Invitation";
import { fullTeamResolver } from "./TeamModel";

const coachClubInviteResolver = z.object({
	club: z.object({
		id: z.string()
	}),
	createdCoachCount: z.number(),
	emailSendFailures: z.array(
		z.object({
			address: z.string()
		})
	)
});

export const coachResolver = baseCreateType
	.pick({
		id: true,
		name: true,
		phone: true
	})
	.extend({
		status: z.nativeEnum(InvitationStatus),
		email: z.string(),
		firstName: z.string().optional().nullable(),
		lastName: z.string().optional().nullable(),
		shortName: z.string().nullable(),
		gender: z.nativeEnum(GenderValEnum).nullable().optional(),
		birthDate: z.string().nullable().optional(),
		avatar: logoResolver.nullable().optional(),
		teams: fullTeamResolver.pick({ id: true, name: true, logo: true, club: true, stats: true }).array().optional(),
		clubs: z
			.array(
				baseCreateType.pick({
					id: true,
					logo: true,
					name: true
				})
			)
			.optional()
	});

export type CoachModel = z.infer<typeof coachResolver>;
export type CoachClubInviteResolver = z.infer<typeof coachClubInviteResolver>;

export interface CreateClubCoachModal {
	birthDate: string;
	teams?: { id: string }[];
	gender: GenderValEnum;
	firstName: string;
	lastName: string;
	email?: string | null;
}

export interface UpdateClubCoachFormModal
	extends Pick<CoachModel, "id" | "firstName" | "lastName" | "gender" | "birthDate" | "avatar"> {
	teams: { add: { id: string }[]; remove: { id: string }[] };
}

import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import type {
	MatchModel,
	PaginationParamsModel,
	PaginationTokenType,
	PlayerInfoModel,
	PlayerModel,
	PlayerOverviewModel,
	StatItemModel,
	TeamGroupsModel
} from "shared/types";
import {
	matchModel,
	paginationTokenResolver,
	playerInfoResolver,
	playerOverviewResolver,
	statItemResolver
} from "shared/types";

import BaseConverter from "./converters/baseConverter";

const usePlayerApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getPlayerInfo: async (id: string) => {
				return BaseConverter.convert<{ player: PlayerInfoModel }>(
					await actions.get(
						API_URLS.PLAYER.GET_INFO,
						{
							"player-id": id
						},
						{
							resolvers: [
								{
									fields: ["player"],
									resolver: playerInfoResolver
								}
							]
						}
					)
				);
			},
			getPlayerOverview: async (id: string) => {
				return BaseConverter.convert<{ player: PlayerOverviewModel }>(
					await actions.get(
						API_URLS.PLAYER.GET_OVERVIEW,
						{
							"player-id": id
						},
						{
							resolvers: [
								{
									fields: ["player"],
									resolver: playerOverviewResolver
								}
							]
						}
					)
				);
			},
			findPlayers: async (search?: string, customParams?: Record<string, any>) => {
				return BaseConverter.convert<{ players: PlayerModel[] }>(
					await actions.get(API_URLS.PLAYER.FIND_PLAYERS, {
						search,
						...customParams
					})
				);
			},
			getPlayerMatches: async ({
				id,
				limit,
				after,
				before
			}: {
				id: string;
				limit: number;
			} & PaginationParamsModel) => {
				return BaseConverter.convert<{
					matches: MatchModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.PLAYER.GET_PLAYER_MATCHES,
						{ "player-id": id, limit, after, before },
						{
							resolvers: [
								{
									fields: ["matches"],
									resolver: matchModel
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								},
								{
									fields: ["previousToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			getPlayerGroups: async (playerId: string) => {
				return BaseConverter.convert<{ groups: TeamGroupsModel[] }>(
					await actions.get(API_URLS.PLAYER.GET_PLAYER_OVERVIEW, {
						"player-id": playerId
					})
				);
			},
			getPlayerStats: async (playerId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.PLAYER.GET_PLAYER_STATS,
						{
							"player-id": playerId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			}
		}),
		[actions]
	);
};

export default usePlayerApiService;

import { z } from "zod";

import {
	AgeGroupEnum,
	BackendEntityType,
	GenderEnum,
	MatchResultEnum,
	baseCreateType,
	competitionGroupResolver,
	competitionResolver,
	leagueCompetitionResolver,
	paginationTokenResolver,
	ratingResolver
} from "shared/types";

import { AgeGenderLevelModel } from "./AgeGenderLevelModel";
import { logoResolver } from "./CreateTypes";
import { InvitationStatus } from "./Invitation";
import { playerResolver } from "./PlayerTypes";
import { structLevel } from "./SetupStructModel";
import { statsResolver } from "./StatModel";

export const genericPlayerId = "GENERIC_PLAYER";

export interface TeamModel {
	name: string;
	ageId: string;
	levelId: string;
	genderId: string;
	id?: string;
}

export const followedTeamsResolver = z.object({
	nextToken: paginationTokenResolver,
	previousToken: paginationTokenResolver,
	teams: baseCreateType.array()
});

export const standingResolver = z.object({
	position: z.number(),
	points: z.number(),
	matchesWon: z.number(),
	matchesLost: z.number(),
	matchesPlayed: z.number(),
	goalsScored: z.number(),
	goalsConceded: z.number(),
	goalsPerMatch: z.number(),
	starsReceived: z.number(),
	starsPerMatch: z.number(),
	yellowCards: z.number(),
	redCards: z.number(),
	rating: z.lazy(() => ratingResolver.optional()),
	conductPoints: z.number().optional()
});

const playerResolverWithStats = statsResolver.extend({
	id: z.string(),
	name: z.string(),
	avatar: logoResolver.nullable()
});

export const fullTeamResolver = AgeGenderLevelModel.extend({
	id: z.string(),
	name: z.string(),
	status: z.nativeEnum(InvitationStatus).optional(),
	club: baseCreateType.pick({ id: true, logo: true, name: true, location: true }).optional(),
	competitions: z.array(z.lazy(() => competitionResolver.pick({ id: true, name: true, logo: true }))).optional(),
	competitionCount: z.number().optional(),
	players: z.number().optional().or(z.array(playerResolverWithStats)),
	coach: z.string().optional(),
	groupId: z.string().optional(),
	standing: standingResolver.nullable().optional(),
	stats: statsResolver.nullable().optional(),
	rating: z.lazy(() => ratingResolver.optional()),
	score: z.number().optional().nullable(),
	previousMatches: z
		.array(
			baseCreateType.pick({ id: true }).extend({
				result: z.nativeEnum(MatchResultEnum)
			})
		)
		.optional()
		.nullable(),
	nextMatches: z
		.array(
			baseCreateType.pick({ id: true }).extend({
				opponentTeam: z.lazy(() =>
					baseCreateType.pick({ id: true }).extend({
						name: z.string(),
						club: baseCreateType.pick({ id: true, logo: true, name: true }).optional()
					})
				)
			})
		)
		.optional()
		.nullable(),
	playerCount: z.number().optional(),
	coaches: z
		.array(
			baseCreateType.pick({ id: true, name: true }).extend({
				shortName: z.string().nullable(),
				avatar: logoResolver.nullable().optional()
			})
		)
		.optional(),
	suggestedLevelId: z.string().optional().nullable(),
	suggestedLevel: structLevel.nullable().optional()
});

export const matchAttachmentInfo = baseCreateType
	.pick({
		id: true,
		location: true,
		name: true
	})
	.extend({
		homeTeam: fullTeamResolver.optional().nullable(),
		awayTeam: fullTeamResolver.optional().nullable(),
		startsAt: z.string().optional(),
		type: z.nativeEnum(BackendEntityType)
	});

export type MatchAttachmentInfoModel = z.infer<typeof matchAttachmentInfo>;

export type PlayerResolverWithStats = z.infer<typeof playerResolverWithStats>;

export type FullTeamModel = z.infer<typeof fullTeamResolver>;

export type StandingModel = z.infer<typeof standingResolver>;

export const teamFiltersResolver = z.object({
	ages: z.array(
		z.object({
			type: z.nativeEnum(AgeGroupEnum),
			value: z.number().or(z.null())
		})
	),
	genders: z.array(
		z.object({
			value: z.nativeEnum(GenderEnum)
		})
	),
	levels: z.array(
		z.object({
			value: z.number()
		})
	)
});

export type TeamFiltersModel = z.infer<typeof teamFiltersResolver>;

export const teamFiltersResolverWithId = z.object({
	id: z.string(),
	filters: teamFiltersResolver
});

export type TeamFiltersModelWithId = z.infer<typeof teamFiltersResolverWithId>;

export const teamPlayersResolver = z.lazy(() =>
	z.object({
		id: z.string().optional(),
		players: playerResolver.array().optional()
	})
);

export type TeamPlayersModel = z.infer<typeof teamPlayersResolver>;

export const teamCompetitionsResolver = z.lazy(() =>
	z.object({
		id: z.string().optional(),
		competitions: z.array(leagueCompetitionResolver)
	})
);
export type TeamCompetitionsModel = z.infer<typeof teamCompetitionsResolver>;

export const teamGroupsResolver = z.lazy(() =>
	competitionGroupResolver.pick({ id: true, logo: true, name: true, teams: true }).extend({
		competition: baseCreateType.pick({ id: true, name: true, logo: true }).extend({
			league: baseCreateType.pick({ id: true, name: true }).optional()
		}),
		division: baseCreateType.pick({ id: true, name: true, logo: true }).optional().nullable()
	})
);
export type TeamGroupsModel = z.infer<typeof teamGroupsResolver>;

export const teamCompetitionsStatsResolver = z.lazy(() =>
	z.object({
		id: z.string().optional(),
		competitions: z.array(competitionResolver.extend({ stats: statsResolver.nullable().optional() }))
	})
);
export type TeamCompetitionsStatsModel = z.infer<typeof teamCompetitionsStatsResolver>;

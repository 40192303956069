import { z } from "zod";

import { AgeGroupEnum } from "./AgeGroupEnum";

import { GenderEnum } from "./GenderEnum";

export const structAge = z.object({
	id: z.string(),
	ord: z.number(),
	type: z.nativeEnum(AgeGroupEnum),
	day: z.number().nullable(),
	month: z.number().nullable(),
	yearOffset: z.number().nullable().optional(),
	value: z.number().optional(),
	name: z.string()
});

export type StructAgeModel = z.infer<typeof structAge>;

export const structGender = z.object({
	id: z.string(),
	ord: z.number(),
	value: z.nativeEnum(GenderEnum)
});

export type StructGenderModel = z.infer<typeof structGender>;

export const structLevel = z.object({
	id: z.string(),
	ord: z.number(),
	name: z.string()
});

export type StructLevelModel = z.infer<typeof structLevel>;

export const setupStruct = z.object({
	id: z.string(),
	ages: z.array(structAge),
	genders: z.array(structGender),
	levels: z.array(structLevel)
});

export type SetupStructModel = z.infer<typeof setupStruct>;

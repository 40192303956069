import type { DocumentData } from "firebase/firestore";

import type { FBEvent, FBNotification } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

export interface Values {
	notifications: FBNotification[];
	events: FBEvent[];
	nextToken?: DocumentData;
	loading: boolean;
}

export const initialState: IStateProps<Values> = {
	notifications: [],
	events: [],
	nextToken: undefined,
	loading: false
};

export default createStore<Values>({
	initialState
});

import { useMemo } from "react";

import { API_URLS } from "shared/constants";

import useHttpActions from "shared/services/api/core/useHttpActions";
import type {
	PaginationParamsModel,
	PaginationTokenType,
	PersonObject,
	ReportedPersonModel,
	ReportedPostModel
} from "shared/types";
import { paginationTokenResolver, personObject, reportedPersonResolver, reportedPostResolver } from "shared/types";

import { BaseConverter } from "./converters";

import type { AdminBaseEntityModel, AdminEntityActions } from "../types/AdminTypes";
import { adminBaseEntityObject } from "../types/AdminTypes";

const useAdminApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			listUsers: async ({ search, after }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{ people: PersonObject[]; nextToken: PaginationTokenType }>(
					await actions.get(
						API_URLS.ADMIN.LIST_USERS,
						{
							search,
							after
						},
						{
							resolvers: [
								{
									resolver: personObject,
									fields: ["people"]
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			assignUserRoles: async (admins: { add?: { id: string }[]; remove?: { id: string }[] }) => {
				return BaseConverter.convert(
					await actions.post(API_URLS.ADMIN.ASSIGN_USER_ROLES, {
						admins
					})
				);
			},
			listReportedUsers: async ({ limit, after }: PaginationParamsModel) => {
				return BaseConverter.convert<{ people: ReportedPersonModel[]; nextToken: PaginationTokenType }>(
					await actions.get(
						API_URLS.ADMIN.LIST_REPORTED_USERS,
						{
							limit,
							after
						},
						{
							resolvers: [
								{
									resolver: reportedPersonResolver,
									fields: ["people"]
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			banUsers: async (personIds: string[]) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.ADMIN.BAN_USERS, {
						personIds
					})
				);
			},
			ignoreReportedUsers: async (personIds: string[]) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.ADMIN.IGNORE_REPORTED_USERS, {
						personIds
					})
				);
			},
			listReportedPosts: async ({ limit, after }: PaginationParamsModel) => {
				return BaseConverter.convert<{ posts: ReportedPostModel[]; nextToken: PaginationTokenType }>(
					await actions.get(
						API_URLS.ADMIN.LIST_REPORTED_POSTS,
						{
							limit,
							after
						},
						{
							resolvers: [
								{
									resolver: reportedPostResolver,
									fields: ["posts"]
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			deleteReportedPosts: async (postIds: string[]) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.ADMIN.DELETE_REPORTED_POSTS, {
						postIds
					})
				);
			},
			ignoreReportedPosts: async (postIds: string[]) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.ADMIN.IGNORE_REPORTED_POSTS, {
						postIds
					})
				);
			},
			listClubs: async ({ search, after, before }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					clubs: AdminBaseEntityModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.ADMIN.LIST_CLUBS,
						{
							search,
							after,
							before
						},
						{
							resolvers: [
								{
									resolver: adminBaseEntityObject,
									fields: ["clubs"]
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								},
								{
									fields: ["previousToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			manageClub: async (clubId: string, action: AdminEntityActions) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.ADMIN.MANAGE_CLUB, {
						clubId,
						action
					})
				);
			},
			listLeagues: async ({ search, after, before }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					leagues: AdminBaseEntityModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.ADMIN.LIST_LEAGUES,
						{
							search,
							after,
							before
						},
						{
							resolvers: [
								{
									resolver: adminBaseEntityObject,
									fields: ["leagues"]
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								},
								{
									fields: ["previousToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			manageLeague: async (leagueId: string, action: AdminEntityActions) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.ADMIN.MANAGE_LEAGUE, {
						leagueId,
						action
					})
				);
			},
			listFacilities: async ({ search, after, before }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					facilities: AdminBaseEntityModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.ADMIN.LIST_FACILITIES,
						{
							search,
							after,
							before
						},
						{
							resolvers: [
								{
									resolver: adminBaseEntityObject,
									fields: ["facilities"]
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								},
								{
									fields: ["previousToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			manageFacility: async (facilityId: string, action: AdminEntityActions) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.ADMIN.MANAGE_FACILITY, {
						facilityId,
						action
					})
				);
			},
			listRefOrgs: async ({ search, after, before }: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					refOrgs: AdminBaseEntityModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.ADMIN.LIST_REF_ORGS,
						{
							search,
							after,
							before
						},
						{
							resolvers: [
								{
									resolver: adminBaseEntityObject,
									fields: ["refOrgs"]
								},
								{
									fields: ["nextToken"],
									resolver: paginationTokenResolver
								},
								{
									fields: ["previousToken"],
									resolver: paginationTokenResolver
								}
							]
						}
					)
				);
			},
			manageRefOrg: async (refOrgId: string, action: AdminEntityActions) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.ADMIN.MANAGE_REF_ORG, {
						refOrgId,
						action
					})
				);
			}
		}),
		[actions]
	);
};

export default useAdminApiService;

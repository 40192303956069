export enum FillDataFlows {
	GOOGLE = "GOOGLE",
	CUSTOM = "CUSTOM"
}

export enum TableColumnFields {
	INDEX = "INDEX",
	FIRSTNAME = "FIRSTNAME",
	LASTNAME = "LASTNAME",
	EMAIL = "EMAIL",
	BIRTHDATE = "BIRTHDATE",
	GENDER = "GENDER",
	JERSEY_NUMBER = "JERSEY_NUMBER"
}

import { useMemo } from "react";

import { AppConfig } from "config";

import { EnvStages } from "../types";

const usePlatformEnv = () => {
	const isShowRoleToasts = useMemo(
		() =>
			AppConfig.GLOBAL_CONSTANTS.ENV_STAGE === EnvStages.DEV ||
			AppConfig.GLOBAL_CONSTANTS.ENV_STAGE === EnvStages.LOCAL,
		[]
	);

	return { isShowRoleToasts };
};

export default usePlatformEnv;

import { useCallback, useMemo } from "react";

import useToast from "./useToast";

import { useAdminStore } from "../contexts";

import { useAdminApiService } from "../services";
import type { PaginationParamsModel } from "../types";
import type { AdminEntityActions } from "../types/AdminTypes";

const useAdmin = () => {
	const adminService = useAdminApiService();

	const store = useAdminStore();
	const { setState, setInitial } = useAdminStore();

	const { showToast } = useToast();

	const methods = useMemo(() => {
		return {
			listUsers: async ({ search, after }: { search?: string } & PaginationParamsModel) => {
				setState({ listingUsers: true });
				try {
					if (!after) {
						setState({ people: [], listPeopleNextToken: null });
					}

					const { nextToken, people } = await adminService.listUsers({ search, after });
					setState(ctx => ({ listPeopleNextToken: nextToken, people: !!after ? [...ctx.people, ...people] : people }));

					return people;
				} catch (err) {
					showToast({
						text: "Error listing users"
					});
				} finally {
					setState({ listingUsers: false });
				}
			},
			assignUserRoles: async (admins: { add?: { id: string }[]; remove?: { id: string }[] }) => {
				setState({ assigningUserRoles: true });
				try {
					await adminService.assignUserRoles(admins);
				} catch (err) {
					showToast({
						text: "Error assigning user roles"
					});
				} finally {
					setState({ assigningUserRoles: false });
				}
			},
			listReportedUsers: async ({ after, ...rest }: PaginationParamsModel) => {
				setState({ listingReportedUsers: true });
				try {
					if (!after) {
						setState({ reportedPeople: [], listReportedPeopleNextToken: null });
					}

					const { nextToken, people } = await adminService.listReportedUsers({ after, ...rest });
					setState(ctx => ({
						listReportedPeopleNextToken: nextToken,
						reportedPeople: !!after ? [...ctx.reportedPeople, ...people] : people
					}));

					return people;
				} catch (err) {
					showToast({
						text: "Error listing reported users"
					});
				} finally {
					setState({ listingReportedUsers: false });
				}
			},
			banUsers: async (personIds: string[]) => {
				setState({ banningUsers: true });
				try {
					await adminService.banUsers(personIds);
				} catch (err) {
					showToast({
						text: "Error ban users"
					});
				} finally {
					setState({ banningUsers: false });
				}
			},
			ignoreReportedUsers: async (personIds: string[]) => {
				setState({ ignoringReportedUsers: true });
				try {
					await adminService.ignoreReportedUsers(personIds);
				} catch (err) {
					showToast({
						text: "Error ignoring reported users"
					});
				} finally {
					setState({ ignoringReportedUsers: false });
				}
			},
			listReportedPosts: async ({ after, ...rest }: PaginationParamsModel) => {
				setState({ listingReportedPosts: true });
				try {
					if (!after) {
						setState({ reportedPosts: [], listReportedPostsNextToken: null });
					}

					const { nextToken, posts } = await adminService.listReportedPosts({ after, ...rest });
					setState(ctx => ({
						listReportedPostsNextToken: nextToken,
						reportedPosts: !!after ? [...ctx.reportedPosts, ...posts] : posts
					}));

					return posts;
				} catch (err) {
					showToast({
						text: "Error listing reported posts"
					});
				} finally {
					setState({ listingReportedPosts: false });
				}
			},
			deleteReportedPosts: async (postIds: string[]) => {
				setState({ deletingReportedPosts: true });
				try {
					await adminService.deleteReportedPosts(postIds);
				} catch (err) {
					showToast({
						text: "Error deleting reported posts"
					});
				} finally {
					setState({ deletingReportedPosts: false });
				}
			},
			ignoreReportedPosts: async (postIds: string[]) => {
				setState({ ignoringReportedPosts: true });
				try {
					await adminService.ignoreReportedPosts(postIds);
				} catch (err) {
					showToast({
						text: "Error ignoring reported posts"
					});
				} finally {
					setState({ ignoringReportedPosts: false });
				}
			},
			listClubs: async ({ search, after }: { search?: string } & PaginationParamsModel) => {
				setState({ loadingClubs: true });
				try {
					if (!after) {
						setState({ clubs: [], listClubNextToken: null });
					}

					const { nextToken, previousToken, clubs } = await adminService.listClubs({ search, after });
					setState(ctx => ({
						listClubNextToken: nextToken,
						listClubPreviousToken: previousToken,
						clubs: !!after ? [...ctx.clubs, ...clubs] : clubs
					}));

					return {
						clubs,
						nextToken,
						previousToken
					};
				} catch (err) {
					showToast({
						text: "Error listing clubs"
					});
					return {
						clubs: [],
						nextToken: null,
						previousToken: null
					};
				} finally {
					setState({ loadingClubs: false });
				}
			},
			manageClub: async (clubId: string, action: AdminEntityActions) => {
				setState({ submitting: true });
				try {
					const { success } = await adminService.manageClub(clubId, action);
					return success;
				} catch (err) {
					showToast({
						text: "Can't manage club"
					});
					return false;
				} finally {
					setState({ submitting: false });
				}
			},
			listLeagues: async ({ search, after }: { search?: string } & PaginationParamsModel) => {
				setState({ loadingLeagues: true });
				try {
					if (!after) {
						setState({ leagues: [], listLeagueNextToken: null });
					}

					const { nextToken, previousToken, leagues } = await adminService.listLeagues({ search, after });
					setState(ctx => ({
						listLeagueNextToken: nextToken,
						listLeaguePreviousToken: previousToken,
						leagues: !!after ? [...ctx.leagues, ...leagues] : leagues
					}));

					return {
						leagues,
						nextToken,
						previousToken
					};
				} catch (err) {
					showToast({
						text: "Error listing leagues"
					});
					return {
						leagues: [],
						nextToken: null,
						previousToken: null
					};
				} finally {
					setState({ loadingLeagues: false });
				}
			},
			manageLeague: async (leagueId: string, action: AdminEntityActions) => {
				setState({ submitting: true });
				try {
					const { success } = await adminService.manageLeague(leagueId, action);
					return success;
				} catch (err) {
					showToast({
						text: "Can't manage league"
					});
					return false;
				} finally {
					setState({ submitting: false });
				}
			},
			listFacilities: async ({ search, after }: { search?: string } & PaginationParamsModel) => {
				setState({ loadingFacilities: true });
				try {
					if (!after) {
						setState({ facilities: [], listFacilityNextToken: null });
					}

					const { nextToken, previousToken, facilities } = await adminService.listFacilities({ search, after });
					setState(ctx => ({
						listFacilityNextToken: nextToken,
						listFacilityPreviousToken: previousToken,
						facilities: !!after ? [...ctx.facilities, ...facilities] : facilities
					}));

					return {
						facilities,
						nextToken,
						previousToken
					};
				} catch (err) {
					showToast({
						text: "Error listing facilities"
					});
					return {
						facilities: [],
						nextToken: null,
						previousToken: null
					};
				} finally {
					setState({ loadingFacilities: false });
				}
			},
			manageFacility: async (facilityId: string, action: AdminEntityActions) => {
				setState({ submitting: true });
				try {
					const { success } = await adminService.manageFacility(facilityId, action);
					return success;
				} catch (err) {
					showToast({
						text: "Can't manage facility"
					});
					return false;
				} finally {
					setState({ submitting: false });
				}
			},
			listRefOrgs: async ({ search, after }: { search?: string } & PaginationParamsModel) => {
				setState({ loadingRefOrgs: true });
				try {
					if (!after) {
						setState({ refOrgs: [], listRefOrgNextToken: null });
					}

					const { nextToken, previousToken, refOrgs } = await adminService.listRefOrgs({ search, after });
					setState(ctx => ({
						listRefOrgNextToken: nextToken,
						listRefOrgPreviousToken: previousToken,
						refOrgs: !!after ? [...ctx.refOrgs, ...refOrgs] : refOrgs
					}));

					return {
						refOrgs,
						nextToken,
						previousToken
					};
				} catch (err) {
					showToast({
						text: "Error listing referee organizations"
					});
					return {
						refOrgs: [],
						nextToken: null,
						previousToken: null
					};
				} finally {
					setState({ loadingRefOrgs: false });
				}
			},
			manageRefOrg: async (refOrgId: string, action: AdminEntityActions) => {
				setState({ submitting: true });
				try {
					const { success } = await adminService.manageRefOrg(refOrgId, action);
					return success;
				} catch (err) {
					showToast({
						text: "Can't manage referee organization"
					});
					return false;
				} finally {
					setState({ submitting: false });
				}
			},
			resetStore: () => {
				setInitial();
			}
		};
	}, [setInitial, setState, showToast, adminService]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useAdmin;

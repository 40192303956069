import config from "config/appConfig";

import type {
	CompetitionModel,
	CreateCompetitionObject,
	EntityRoles,
	LeagueCompetitionModel,
	LeagueModel,
	PaginationTokenType,
	PaymentRequestModel,
	SetupEntityModel,
	SetupStructModel
} from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.LEAGUE_DATA}`;

export interface Values {
	league?: LeagueModel;
	leagues: LeagueModel[];
	newLeague?: Partial<LeagueModel>;
	newSetupLeague?: Partial<SetupEntityModel>;
	leagueCompetition?: CompetitionModel;
	loading: boolean;
	loadingOverview: boolean;
	loadingInfo: boolean;
	season?: CreateCompetitionObject;
	overview?: LeagueModel;
	structure?: SetupStructModel | null;
	loadingStructure: boolean;
	competitions?: LeagueCompetitionModel[];
	loadingCompetitions: boolean;
	leagueSettingsModal?: {
		open: boolean;
		league: LeagueModel;
	};
	loadingTeamStats: boolean;
	loadingPlayerStats: boolean;
	userLeagueRoles: EntityRoles[];
	nextListingCompetitionsToken: PaginationTokenType;
	loadingFollowedLeagues: boolean;
	followedLeaguesNextToken: PaginationTokenType;

	paymentRequests: PaymentRequestModel[];
	loadingPaymentRequests: boolean;
	nextListingPaymentRequestsToken: PaginationTokenType;
}

export const initialState: IStateProps<Values> = {
	league: undefined,
	leagues: [],
	newLeague: undefined,
	newSetupLeague: undefined,
	leagueCompetition: undefined,
	loading: false,
	loadingOverview: false,
	loadingInfo: false,
	season: undefined,
	overview: undefined,
	structure: undefined,
	loadingStructure: false,
	competitions: [],
	loadingCompetitions: false,
	leagueSettingsModal: undefined,
	loadingTeamStats: false,
	loadingPlayerStats: false,
	userLeagueRoles: [],
	nextListingCompetitionsToken: null,
	loadingFollowedLeagues: false,
	followedLeaguesNextToken: null,

	paymentRequests: [],
	loadingPaymentRequests: false,
	nextListingPaymentRequestsToken: null
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { useCallback, useMemo } from "react";

import { omit } from "ramda";

import { getPaths } from "core/getPaths";
import { useCompetitionStore } from "shared/contexts";
import { useShare, useToast } from "shared/hooks";
import { useCompetitionApiService } from "shared/services";
import type {
	CompetitionCreateDivisionModel,
	CompetitionGroupModel,
	CompetitionModel,
	EntityRoles,
	FetchCompetitionTeamsParams,
	FullTeamModel,
	GroupStatusEnum,
	IgnoreStateUpdate,
	InvitationStatus,
	LogoModel,
	MatchesFilterModel,
	MediaModel,
	NewFieldReservationModel,
	NewScoutModel,
	PaginationParamsModel,
	UpdateFieldReservationModel
} from "shared/types";

import usePlatformEnv from "./usePlatformEnv";

import type { UpdateScoutModel } from "../types/ScoutModel";

const { competition: competitionRoutes } = getPaths();

const useCompetition = () => {
	const competitionService = useCompetitionApiService();

	const store = useCompetitionStore();
	const { setState, setInitial } = useCompetitionStore();

	const { showToast } = useToast();

	const { isShowRoleToasts } = usePlatformEnv();

	const { copyLink } = useShare();

	const methods = useMemo(() => {
		return {
			findCompetitions: async (params: {
				clubId?: string;
				search?: string;
				maxMiles?: number;
				limit?: number;
				noStateUpdate?: boolean;
			}) => {
				setState({ loading: true });
				const { competitions } = await competitionService.findCompetitions(omit(["noStateUpdate"], params));

				params?.noStateUpdate ? setState({ loading: false }) : setState({ competitions, loading: false });

				return competitions;
			},
			applyToCompetition: async (params: {
				clubId: string;
				competitionId: string;
				teams?: Partial<FullTeamModel>[];
			}) => {
				setState({ submitting: true });

				try {
					await competitionService.applyToCompetition(params);
					showToast({
						text: params?.teams?.length
							? "Competition application was sent for selected team"
							: "Competition application was sent for selected club",
						noIcon: true
					});
				} catch (e) {
					console.error("Can't apply to competition: ", e);
				} finally {
					setState({ submitting: false });
				}
			},
			getStructure: async (id: string, ignoreLoading?: boolean, ignoreStateUpdate?: boolean) => {
				if (!ignoreLoading) {
					setState({ loadingStructure: true });
				}

				try {
					const { competition } = await competitionService.getStructure(id);

					if (!ignoreStateUpdate) {
						setState({ structure: competition.struct });
					}

					return competition.struct;
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't fetch competition structure.",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingStructure: false });
					}
				}
			},
			getTeams: async (params: FetchCompetitionTeamsParams, ignoreStateUpdate = false) => {
				if (!ignoreStateUpdate) {
					setState({ loadingTeams: true });
				}

				try {
					const { competition } = await competitionService.getTeams(params);

					if (!ignoreStateUpdate) {
						setState({ teams: competition.teams });
					}

					return competition.teams;
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't fetch competition teams.",
						noIcon: true
					});
					return [];
				} finally {
					setState({ loadingTeams: false });
				}
			},
			getClubsWithTeams: async (
				params: FetchCompetitionTeamsParams & { withPayments?: boolean },
				ignoreStateUpdate = false
			) => {
				if (!ignoreStateUpdate) {
					setState({ loadingClubsWithTeams: true });
				}

				try {
					const { competition } = await competitionService.getClubsWithTeams(params);

					if (!ignoreStateUpdate) {
						setState({ clubsWithTeams: competition.clubs });
					}

					return competition.clubs;
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't fetch competition clubs with teams.",
						noIcon: true
					});
					return [];
				} finally {
					if (!ignoreStateUpdate) {
						setState({ loadingClubsWithTeams: false });
					}
				}
			},
			getClubs: async (params: { competitionId: string; status: InvitationStatus }) => {
				setState({ loadingClubs: true });
				const { clubs } = await competitionService.getClubs(params);
				setState({ clubs, loadingClubs: false });
				return clubs;
			},
			getCompetitionMatches: async ({
				limit = 20,
				after,
				ignoreStateUpdate,
				ignoreLoading,
				...rest
			}: {
				competitionId: string;
				filters?: MatchesFilterModel;
			} & PaginationParamsModel &
				IgnoreStateUpdate) => {
				if (!ignoreLoading) {
					setState({ loadingMatches: true });
				}

				if (!after && !ignoreStateUpdate) {
					setState({ matches: [], nextListingMatchesToken: null });
				}

				try {
					const { matches, nextToken } = await competitionService.getCompetitionMatches({ limit, after, ...rest });

					if (!ignoreStateUpdate) {
						setState(ctx => ({
							matches: !!after ? [...ctx.matches, ...matches] : matches,
							nextListingMatchesToken: ctx?.nextListingMatchesToken !== nextToken ? nextToken : null
						}));
					}

					return {
						matches,
						nextToken
					};
				} catch (err) {
					console.error(err);
					showToast({ text: "Something went wrong", noIcon: true });

					if (!ignoreStateUpdate) {
						setState({ nextListingMatchesToken: null });
					}

					return {
						matches: [],
						nextToken: null
					};
				} finally {
					if (!ignoreLoading) {
						setState({ loadingMatches: false });
					}
				}
			},
			getOverview: async (competitionId: string, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loadingOverview: true });

				try {
					const { competition } = await competitionService.getOverview(competitionId);
					setState({ overview: competition });
					return competition;
				} catch {
					showToast({
						text: "Competition couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingOverview: false });
					}
				}
			},
			getInfo: async (competitionId: string, ignoreLoading?: boolean) => {
				!ignoreLoading && setState({ loadingInfo: true });

				try {
					const { competition } = await competitionService.getInfo(competitionId);
					setState(ctx => ({ info: competition, loadingInfo: ignoreLoading ? ctx.loadingInfo : false }));

					if (competition?.auth?.roles && isShowRoleToasts) {
						showToast({
							text: `Roles for competition: ${competition.auth.roles.join(", ")}`,
							noIcon: true,
							position: "bottom-left"
						});
					}

					return competition;
				} catch {
					showToast({
						text: "Competition couldn't be found",
						noIcon: true
					});
					return null;
				} finally {
					if (!ignoreLoading) {
						setState({ loadingInfo: false });
					}
				}
			},
			getCompetitionReferees: async (competitionId: string) => {
				setState({ loadingReferees: true });
				const { refereeOrganizations } = await competitionService.getCompetitionReferees(competitionId);

				setState({ loadingReferees: false });

				return refereeOrganizations;
			},
			updateCompetitionSettings: async (
				updateData: Omit<CompetitionModel, "id" | "league" | "type" | "logo"> & {
					logo?: string | null | LogoModel | MediaModel;
					competitionId: string;
					refereeOrganizations: {
						add: { id: string }[];
						remove: { id: string }[];
					};
				}
			) => {
				setState({ submitting: true });
				try {
					await competitionService.updateCompetitionSettings(updateData);

					if (updateData.competitionId) {
						methods.getOverview(updateData.competitionId, true);
					}

					showToast({ text: "Competition settings were successfully updated", noIcon: true });
				} catch (e) {
					console.error("Couldn't update competition settings ", e);
				} finally {
					setState({ submitting: false });
				}
			},
			listCompetitionFieldReservations: async ({
				competitionId
			}: {
				competitionId: string;
				startsAt?: Date;
				endsAt?: Date;
			}) => {
				setState({ loadingFieldReservations: true });
				try {
					const { fields } = await competitionService.listCompetitionFieldReservations(competitionId);
					setState({ fieldReservations: fields });
				} catch (e) {
					console.error("Couldn't load field reservations ", e);
				} finally {
					setState({ loadingFieldReservations: false });
				}
			},
			updateCompetitionFieldReservations: async (
				competitionId: string,
				reservations: {
					create: NewFieldReservationModel[];
					update: UpdateFieldReservationModel[];
					remove: { id: string }[];
				}
			) => {
				setState({ submitting: true });
				try {
					return await competitionService.updateCompetitionFieldReservations(competitionId, reservations);
				} catch (e) {
					console.error(e);
					return {
						created: [],
						removed: [],
						updated: []
					};
				} finally {
					setState({ submitting: false });
				}
			},
			getTeamFilters: async (competitionId: string) => {
				setState({ loadingTeamFilters: true });
				const { competition } = await competitionService.getTeamFilters(competitionId);

				setState({ teamFilters: competition.filters, loadingTeamFilters: false });

				return competition.filters;
			},
			setCompetitionSettingsModal: (competitionSettingsModal?: boolean | CompetitionModel | string) => {
				setState({ competitionSettingsModal });
			},
			getCompetitionGroups: async (
				data: { competitionId: string; status?: GroupStatusEnum; withTeams?: boolean },
				ignoreStateUpdates = false
			) => {
				if (!ignoreStateUpdates) {
					setState({ loadingGroups: true });
				}

				const { groups } = await competitionService.getCompetitionGroups(data);

				if (!ignoreStateUpdates) {
					setState({ groups, loadingGroups: false });
				}

				return groups;
			},
			getFollowedCompetitions: async (params: { search?: string } & PaginationParamsModel) => {
				setState({ loadingFollowedCompetitions: true });
				const { competitions, nextToken } = await competitionService.getFollowedCompetitions(params);
				setState({ followedCompetitionsNextToken: nextToken, loadingFollowedCompetitions: false });
				return competitions;
			},
			getCompetitionTeamStats: async (competitionId: string) => {
				setState({ loadingCompetitionTeamStats: true });
				const { items } = await competitionService.getCompetitionTeamStats(competitionId);

				setState({ loadingCompetitionTeamStats: false });

				return items || [];
			},
			getCompetitionPlayerStats: async (competitionId: string) => {
				setState({ loadingCompetitionPlayerStats: true });
				const { items } = await competitionService.getCompetitionPlayerStats(competitionId);

				setState({ loadingCompetitionPlayerStats: false });

				return items || [];
			},
			getCompetitionDivisions: async (competitionId: string) => {
				setState({ loadingCompetitionDivisions: true });

				try {
					const { divisions } = await competitionService.getCompetitionDivisions(competitionId);
					setState({ divisions });
					return divisions;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't load competition divisions.", noIcon: true });
					return [];
				} finally {
					setState({ loadingCompetitionDivisions: false });
				}
			},
			getCompetitionDivision: async (divisionId: string, stateOptions?: IgnoreStateUpdate) => {
				if (!stateOptions?.ignoreStateUpdate) {
					setState({ loadingCompetitionDivision: true });
				}

				try {
					const { division } = await competitionService.getCompetitionDivision(divisionId);
					return division;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't load competition division.", noIcon: true });
					return null;
				} finally {
					if (!stateOptions?.ignoreStateUpdate) {
						setState({ loadingCompetitionDivision: false });
					}
				}
			},
			createDivisions: async (competitionId: string, divisions: CompetitionCreateDivisionModel[]) => {
				setState({ submitting: true });

				try {
					const { divisions: createdDivisions } = await competitionService.createDivisions(competitionId, divisions);
					showToast({ text: "Competition divisions were successfully created", noIcon: true });
					return createdDivisions;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't crew new competition divisions.", noIcon: true });
					return [];
				} finally {
					setState({ submitting: false });
				}
			},
			updateDivision: async (competitionId: string, division: CompetitionCreateDivisionModel) => {
				setState({ submitting: true });

				try {
					const { division: updatedDivision } = await competitionService.updateDivision(competitionId, division);
					showToast({ text: "Competition division was successfully updated", noIcon: true });
					return updatedDivision;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't updated competition division.", noIcon: true });
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			deleteDivision: async (competitionId: string, divisionId: string) => {
				setState({ deleting: true });

				try {
					const { division } = await competitionService.deleteDivision(competitionId, divisionId);
					showToast({ text: "Competition division was successfully deleted", noIcon: true });
					return division.id;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't delete competition division.", noIcon: true });
					return null;
				} finally {
					setState({ deleting: false });
				}
			},
			setUserCompetitionRoles: (userCompetitionRoles: EntityRoles[]) => {
				setState({ userCompetitionRoles });
			},
			setGroups: (groups: CompetitionGroupModel[]) => {
				setState({ groups });
			},
			removeTeamsInGroup: (groupId: string, teamIds: string[]) => {
				setState(ctx => ({
					groups: ctx.groups.map(x =>
						x.id === groupId
							? {
									...x,
									teams: x.teams.filter(t => !teamIds.some(tr => tr === t.id))
								}
							: x
					)
				}));
			},
			updateStatusOfGroup: (groupId: string, status: GroupStatusEnum) => {
				setState(ctx => ({
					groups: ctx.groups.map(x => (x.id === groupId ? { ...x, status } : x))
				}));
			},
			getFavoriteCompetitionDivisions: async () => {
				setState({ loadingFavoriteCompetitionDivisions: true });

				try {
					const { competition, competitions, divisions, groups } =
						await competitionService.getFavoriteCompetitionDivisions();
					setState({
						favoriteCompetition: competition,
						favoriteCompetitions: competitions,
						favoriteCompetitionDivisions: divisions,
						favoriteCompetitionGroups: groups
					});
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't load favorite competition divisions.", noIcon: true });
				} finally {
					setState({ loadingFavoriteCompetitionDivisions: false });
				}
			},
			listScouts: async (competitionId: string) => {
				setState({ loadingScouts: true });

				try {
					const { competition } = await competitionService.listScouts(competitionId);
					setState({ scouts: competition.scouts });
					return competition.scouts;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't get scouts of competition.", noIcon: true });
				} finally {
					setState({ loadingScouts: false });
				}
			},
			updateScouts: async (
				competitionId: string,
				scouts: {
					add?: NewScoutModel[];
					remove?: { id: string }[];
					update?: UpdateScoutModel[];
				}
			) => {
				setState({ updatingScouts: true });

				try {
					const { competition } = await competitionService.updateScouts(competitionId, scouts);

					if (competition?.id) {
						if (scouts.add?.length) {
							showToast({
								text: `${scouts.add.length > 1 ? scouts.add.length : ""} Scout${
									scouts.add.length > 1 ? "s were" : " was"
								} successfully created.`,
								noIcon: true
							});
						}
						if (scouts.remove?.length) {
							showToast({
								text: `${scouts.remove.length > 1 ? scouts.remove.length : ""} Scout${
									scouts.remove.length > 1 ? "s were" : " was"
								} successfully removed.`,
								noIcon: true
							});
						}
						if (scouts.update?.length) {
							showToast({
								text: `${scouts.update.length > 1 ? scouts.update.length : ""} Scout${
									scouts.update.length > 1 ? "s were" : " was"
								} successfully updated.`,
								noIcon: true
							});
						}
					}
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't update competition scouts.", noIcon: true });
				} finally {
					setState({ updatingScouts: false });
				}
			},
			inviteScouts: async (competitionId: string, emails: string[]) => {
				setState({ updatingScouts: true });

				try {
					const { invitations } = await competitionService.inviteScouts(competitionId, emails);
					showToast({ text: "Competition invitations were sent to the selected scouts", noIcon: true });
					return invitations;
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't invite scouts to competition.", noIcon: true });
					return [];
				} finally {
					setState({ updatingScouts: false });
				}
			},
			updateTeams: async (
				competitionId: string,
				teams: {
					remove: { id: string }[];
				}
			) => {
				setState({ updatingTeams: true });

				try {
					const { competition } = await competitionService.updateTeams(competitionId, teams);

					if (competition?.id) {
						if (teams.remove.length) {
							showToast({
								text: `${teams.remove.length > 1 ? teams.remove.length : ""} Team${
									teams.remove.length > 1 ? "s were" : " was"
								} successfully removed.`,
								noIcon: true
							});
						}
					}
				} catch (err) {
					console.error(err);
					showToast({ text: "Couldn't update competition teams.", noIcon: true });
				} finally {
					setState({ updatingTeams: false });
				}
			},
			getPlayers: async (competitionId: string, stateOptions?: IgnoreStateUpdate) => {
				if (!stateOptions?.ignoreLoading) {
					setState({ loadingPlayers: true });
				}

				try {
					const { competition } = await competitionService.getPlayers(competitionId);

					if (!stateOptions?.ignoreStateUpdate) {
						setState({ players: competition.players });
					}

					return competition.players;
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't fetch competition players.",
						noIcon: true
					});
					return [];
				} finally {
					setState({ loadingPlayers: false });
				}
			},
			getPlayersByTeams: async (competitionId: string, stateOptions?: IgnoreStateUpdate) => {
				if (!stateOptions?.ignoreLoading) {
					setState({ loadingPlayersInTeams: true });
				}

				try {
					const { competition } = await competitionService.getPlayersByTeams(competitionId);

					if (!stateOptions?.ignoreStateUpdate) {
						setState({ teamPlayers: competition.teams });
					}

					return competition.teams;
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't fetch competition players.",
						noIcon: true
					});
					return [];
				} finally {
					setState({ loadingPlayersInTeams: false });
				}
			},
			deleteCompetition: async (competitionId: string) => {
				try {
					setState({ submitting: true });

					await competitionService.deleteCompetition(competitionId);
					showToast({ text: "Competition was successfully deleted", noIcon: true });
					return true;
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't delete competition.",
						noIcon: true
					});
					return false;
				} finally {
					setState({ submitting: false });
				}
			},
			generateOpenRegistrationPaymentOrder: async (params: {
				competitionId: string;
				players?: { id: string }[];
				teams?: { id: string; clubId: string }[];
			}) => {
				try {
					setState({ submitting: true });
					const { order } = await competitionService.generateOpenRegistrationPaymentOrder(params);
					return order;
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't generate open registration payment order for competition.",
						noIcon: true
					});
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			getAudiences: async (competitionId: string) => {
				try {
					setState({ loadingAudiences: true });
					const { competition } = await competitionService.getAudiences(competitionId);
					setState({ audiences: competition.audiences });
					return competition.audiences;
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't get competition open registration audiences.",
						noIcon: true
					});
					return [];
				} finally {
					setState({ loadingAudiences: false });
				}
			},
			appendToOpenRegistration: async (params: {
				competitionId: string;
				players?: { id: string }[];
				teams?: { id: string; clubId: string }[];
			}) => {
				try {
					setState({ submitting: true });
					await competitionService.appendToOpenRegistration(params);
				} catch (err) {
					console.error(err);
					showToast({
						text: "Couldn't append players/teams to competition.",
						noIcon: true
					});
					return null;
				} finally {
					setState({ submitting: false });
				}
			},
			getLinkToOpenRegistration: async (competition: Partial<CompetitionModel>, dontCopyToClipboard = false) => {
				const link = await copyLink({
					customLink: `${competitionRoutes.getPath()}/${competition.id}?registerNow=true`,
					socialMetaTagInfo: {
						socialTitle: `${competition?.name}`,
						socialDescription: "Register now for this competition on Tapin Sports"
					},
					analyticsInfo: {
						googlePlayAnalytics: {
							utmCampaign: "share",
							utmMedium: "link",
							utmTerm: "sports",
							utmSource: "link",
							utmContent: "share link"
						}
					},
					dontCopyToClipboard
				});
				return link;
			},
			resetStore() {
				setInitial();
			}
		};
	}, [competitionService, setState, setInitial, showToast, isShowRoleToasts, copyLink]);

	const getData = useCallback(() => {
		return store;
	}, [store]);

	return { ...methods, getData };
};

export default useCompetition;

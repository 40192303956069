import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import type { GoogleSheetLinkModel } from "shared/types";
import { googleSheetLinkResolver } from "shared/types";

import BaseConverter from "./converters/baseConverter";

const useUploadsApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getGoogleSheet: async () => {
				return BaseConverter.convert<{ sheet: GoogleSheetLinkModel }>(
					await actions.post(API_URLS.UPLOADS.GET_GOOGLE_SHEET, null, {
						resolvers: [
							{
								resolver: googleSheetLinkResolver,
								fields: ["sheet"]
							}
						]
					})
				);
			}
		}),
		[actions]
	);
};

export default useUploadsApiService;

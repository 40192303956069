import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import BaseConverter from "./converters/baseConverter";

import type {
	CreateStoreModel,
	EditStoreModel,
	PaginationParamsModel,
	PaginationTokenType,
	StoreModel
} from "../types";
import { paginationTokenResolver, storeResolver } from "../types";

const useStoreService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			createStore: async (newStore: CreateStoreModel) => {
				return BaseConverter.convert<{ store: StoreModel }>(
					await actions.post(API_URLS.SOCCER.CREATE_STORE, newStore, {
						resolvers: [
							{
								fields: ["store"],
								resolver: storeResolver
							}
						]
					})
				);
			},
			updateStore: async (store: EditStoreModel) => {
				return BaseConverter.convert<{ store: StoreModel }>(
					await actions.post(API_URLS.SOCCER.UPDATE_STORE, store, {
						resolvers: [
							{
								fields: ["store"],
								resolver: storeResolver
							}
						]
					})
				);
			},
			deleteStore: async (id: string) => {
				return BaseConverter.convert<{ store: { id: string } }>(
					await actions.post(API_URLS.SOCCER.DELETE_STORE, {
						id
					})
				);
			},
			getStore: async (id: string) => {
				return BaseConverter.convert<{ store: StoreModel }>(
					await actions.get(
						API_URLS.STORE.GET_STORE,
						{
							id
						},
						{
							resolvers: [
								{
									fields: ["store"],
									resolver: storeResolver
								}
							]
						}
					)
				);
			},
			getEntityStores: async (entityId: string) => {
				return BaseConverter.convert<{ stores: StoreModel[] }>(
					await actions.get(
						API_URLS.STORE.GET_ENTITY_STORES,
						{
							entityId
						},
						{
							resolvers: [
								{
									fields: ["stores"],
									resolver: storeResolver
								}
							]
						}
					)
				);
			},
			findStores: async (params: { search?: string } & PaginationParamsModel) => {
				return BaseConverter.convert<{
					stores: StoreModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.STORE.FIND_STORES, params, {
						resolvers: [
							{
								fields: ["stores"],
								resolver: storeResolver
							},
							{
								resolver: paginationTokenResolver,
								fields: ["nextToken"]
							},
							{
								resolver: paginationTokenResolver,
								fields: ["previousToken"]
							}
						]
					})
				);
			}
		}),
		[actions]
	);
};

export default useStoreService;

import { useMemo } from "react";

import { useEntityManagementApiService } from "shared/services";

import type { EntityFollowListEnum } from "../types";

const useEntityManagement = () => {
	const entityManagementService = useEntityManagementApiService();

	const methods = useMemo(() => {
		return {
			getEntityMembers: async <Response>({
				entity,
				id,
				limit,
				after
			}: {
				entity: EntityFollowListEnum;
				id: string;
				limit?: number;
				after?: string;
			}) => {
				try {
					const response = await entityManagementService.getEntityMembers<Response>({ entity, id, limit, after });
					return response;
				} catch (err) {
					console.log(err);
				}
			}
		};
	}, [entityManagementService]);

	return { ...methods };
};

export default useEntityManagement;

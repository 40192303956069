import type { ReactNode } from "react";

import type { ToastOptions } from "react-toastify/dist/types";

export enum ToastType {
	SUCCESS = "SUCCESS",
	WARNING = "WARNING",
	ERROR = "ERROR"
}

export interface ToastAction {
	label: string;
	onSelect?: () => void;
}

export interface ToastModelConfig extends Omit<ToastOptions, "type"> {
	title?: string;
	text: string;
	icon?: ReactNode;
	type?: ToastType;
	autoCloseMs?: number;
	actions?: ToastAction | ToastAction[];
	noIcon?: boolean;
	closeable?: boolean;
}

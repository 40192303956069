import config from "config/appConfig";

import type { PaginationTokenType, PaymentAccountMetricsModel, PaymentRequestOrderModel } from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.SOCCER_PAYMENT_DATA}`;

export interface Values {
	loading: boolean;
	creating: boolean;
	submitting: boolean;

	loadingPaymentRequests: boolean;
	paymentRequestsNextToken: PaginationTokenType;

	loadingPaymentRequestOrders: boolean;
	paymentRequestOrders: PaymentRequestOrderModel[];
	paymentRequestOrdersNextToken: PaginationTokenType;

	loadingPaymentAccountMetrics: boolean;
	paymentAccountMetrics?: PaymentAccountMetricsModel;

	loadingPaymentTransactions: boolean;
}

export const initialState: IStateProps<Values> = {
	loading: false,
	creating: false,
	submitting: false,

	loadingPaymentRequests: false,
	paymentRequestsNextToken: null,

	loadingPaymentRequestOrders: false,
	paymentRequestOrders: [],
	paymentRequestOrdersNextToken: null,

	loadingPaymentAccountMetrics: false,
	paymentAccountMetrics: undefined,

	loadingPaymentTransactions: false
};

export default createStore<Values>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});

import { useMemo } from "react";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import type { InvitationModel, PaginationTokenType } from "shared/types";
import { EntityFollowListEnum } from "shared/types";
import { getFollowEPURL } from "utils/getFollowEPURL";

import BaseConverter from "./converters/baseConverter";

const useInvitationApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			getInfo: async (invitationId: string) => {
				return BaseConverter.convert<{ invitation: InvitationModel }>(
					await actions.get(API_URLS.INVITATION.GET, {
						"invitation-id": invitationId
					})
				);
			},
			inviteReferees: async (refereeOrganizationId: string, emails: string[]) => {
				return BaseConverter.convert<{ invitations: InvitationModel[] }>(
					await actions.post(API_URLS.INVITATION.INVITE_REFEREES, {
						refereeOrganizationId,
						referees: emails.map(email => ({
							email,
							firstName: null,
							lastName: null,
							gender: null,
							birthDate: null
						}))
					})
				);
			},
			acceptInvitation: async (invitationId: string, asPersonId?: string) => {
				return BaseConverter.convert<InvitationModel>(
					await actions.post(API_URLS.INVITATION.ACCEPT_INVITATION, {
						invitationId,
						asPersonId
					})
				);
			},
			getInvitations: async (limit: number, nextToken?: string) => {
				return BaseConverter.convert<{
					invitations: InvitationModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(await actions.get(API_URLS.INVITATION.LIST_INVITATIONS, { limit, before: nextToken }));
			},
			inviteToFollowEntity: async ({
				emails,
				entityId,
				entityType
			}: {
				entityId: string;
				emails: string[];
				entityType: EntityFollowListEnum;
			}) => {
				const url = getFollowEPURL(entityType);

				if (url) {
					return BaseConverter.convert<{ createdInvitationCount: number }>(
						await actions.post(url, {
							[entityType === EntityFollowListEnum.USER || entityType === EntityFollowListEnum.PLAYER
								? "personId"
								: `${entityType.toLowerCase()}Id`]: entityId,
							emails
						})
					);
				}
			}
		}),
		[actions]
	);
};

export default useInvitationApiService;

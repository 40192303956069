import { DateTime, Interval } from "luxon";

export const getAge = (birthDate?: string | null) => {
	const luxonDate = birthDate ? DateTime.fromISO(birthDate) : undefined;
	let years: number | undefined;
	if (luxonDate && luxonDate.isValid) {
		const diff = Interval.fromDateTimes(luxonDate, DateTime.now());
		years = Math.floor(diff.length("year"));
	}
	return years;
};

export const getYearDateByAgeValue = (age: number) => {
	const year = DateTime.now().year - age + 1;
	return {
		year,
		date: DateTime.now().set({ year, month: 1, day: 1 }).toJSDate()
	};
};

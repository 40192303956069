import { z } from "zod";

import type { GenderEnum } from "shared/types";
import {
	EntitySoccerClubRoles,
	InvitationStatus,
	baseCreateType,
	fullTeamResolver,
	leagueCompetitionResolver,
	leagueCompetitionWithTeamsResolver,
	paginationTokenResolver,
	setupStruct
} from "shared/types";

import { playerResolver } from "./PlayerTypes";

export const followedClubsResolver = z.object({
	nextToken: paginationTokenResolver,
	previousToken: paginationTokenResolver,
	clubs: baseCreateType.array()
});

export const clubResolver = baseCreateType.extend({
	label: z.string().optional(),
	struct: setupStruct.optional().or(z.null()),
	status: z.nativeEnum(InvitationStatus).optional(),
	playerCount: z.number().optional(),
	members: z
		.object({
			add: z.array(
				z.object({
					email: z.string(),
					role: z.nativeEnum(EntitySoccerClubRoles)
				})
			),
			remove: z.array(
				z.object({
					userId: z.string(),
					role: z.nativeEnum(EntitySoccerClubRoles)
				})
			)
		})
		.optional()
});

export type ClubModel = z.infer<typeof clubResolver>;

export const clubTeamResolver = z.object({
	id: z.string().optional(),
	teams: fullTeamResolver.array().optional()
});

export type ClubTeamModel = z.infer<typeof clubTeamResolver>;

export const clubPlayersResolver = z.object({
	id: z.string().optional(),
	players: playerResolver.array()
});

export type ClubPlayersModel = z.infer<typeof clubPlayersResolver>;

export const clubCompetitionsResolver = z.object({
	id: z.string().optional(),
	competitions: z.array(leagueCompetitionResolver.or(leagueCompetitionWithTeamsResolver)).optional()
});

export type ClubCompetitionsModel = z.infer<typeof clubCompetitionsResolver>;

export interface FetchClubTeamsParams {
	clubId: string;
	ages?: string[];
	genders?: GenderEnum[];
	levels?: string[];
	notInCompetitionId?: string; // TODO: not used?!
	search?: string;
}

export interface FetchClubPlayersParams extends Pick<FetchClubTeamsParams, "clubId" | "search" | "ages" | "genders"> {
	teams?: string[];
	playerTags?: string[];
}

import type {
	CompetitionGroupModel,
	EntityRoles,
	FullTeamModel,
	MatchModel,
	PaginationTokenType,
	PlayerModel
} from "shared/types";
import type { IStateProps } from "utils/StateOf";
import createStore from "utils/createStore";

export interface Values {
	submitting?: boolean;

	loadingGroupOverview?: boolean;
	groupOverview?: CompetitionGroupModel;
	groupOverviewTeamsCount: number;
	groupOverviewSubGroupsCount: number;

	editGroupModal?: { group: CompetitionGroupModel; onClose?: () => void };
	loadingGroupPlayers: boolean;
	groupPlayers: PlayerModel[];
	loadingGroupStats: boolean;
	loadingGroupTeamStats: boolean;
	loadingGroupPlayerStats: boolean;
	loadingGroupMatches: boolean;
	userCompetitionGroupRoles: EntityRoles[];
	groupMatches: MatchModel[];
	nextListingGroupMatchesToken: PaginationTokenType;
	loadingFollowedGroups: boolean;
	followedGroupsNextToken: PaginationTokenType;

	loadingGroupTeams?: boolean;
	groupTeams: FullTeamModel[];

	loadingDivisionGroups: boolean;
	divisionGroups: CompetitionGroupModel[];
}

export const initialState: IStateProps<Values> = {
	submitting: false,

	loadingGroupOverview: false,
	groupOverview: undefined,
	groupOverviewTeamsCount: 0,
	groupOverviewSubGroupsCount: 0,

	editGroupModal: undefined,
	loadingGroupPlayers: false,
	groupPlayers: [],
	loadingGroupStats: false,
	loadingGroupTeamStats: false,
	loadingGroupPlayerStats: false,
	loadingGroupMatches: false,
	userCompetitionGroupRoles: [],
	groupMatches: [],
	nextListingGroupMatchesToken: null,
	loadingFollowedGroups: false,
	followedGroupsNextToken: null,

	loadingGroupTeams: false,
	groupTeams: [],

	loadingDivisionGroups: false,
	divisionGroups: []
};

export default createStore<Values>({
	initialState,
	persistant: true
});
